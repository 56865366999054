import React, { useState, useContext } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Navbar } from '../../components/Navbar';
import { SidebarPublica } from '../../components/SidebarPublica';
import { Context } from '../../Context/AuthContext';

export const Ajuda = () => {
  const { state } = useLocation();

    const history = useHistory();

    return (
        <>
            <Navbar />
            
            <div className="content">
            
            <SidebarPublica active="ajuda" />
                

                <div className="wrapper">
                <div className="row">


  
  <p> <h3>Configurações Google Chrome</h3>

  <ul>Clique nos 3 pontinhos no canto superior direito</ul>
  <ul>Clique em Configurações</ul>
  <ul>Clique Privacidade e segurança</ul>
  <ul>Configurações do site</ul>
  <ul>Mais configurações de conteúdo</ul>
  <ul>Conteúdo não seguro adicione  <code>[*.]operacoesrio.com.br</code></ul>
</p>
</div>




                  </div>
                  </div>

      </>
    );
}