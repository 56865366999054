import React, { useContext } from 'react';
import { Context } from '../../Context/AuthContext';

import { Link } from 'react-router-dom';

export const Sidebar = (props) => {

    const { handleLogout } = useContext(Context);

    return (
        <div id="barsSidebar" className="sidebar">


{(localStorage.getItem('perfil') === "master") && <>

            
              {/* <Link to="/mapa" className={props.active === "mapa" ? "sidebar-nav active" : "sidebar-nav"} ><i className="icon fas fa-map"></i><span>Mapa</span></Link> */}
              <Link to="/dashboard" className={props.active === "dashboard" ? "sidebar-nav active" : "sidebar-nav"} ><i className="icon fas fa-tachometer-alt"></i><span> Dashboard</span></Link>
              <Link to="/portfolio" className={props.active === "portfolio" ? "sidebar-nav active" : "sidebar-nav"} ><i className="icon fas fa-table"></i><span> Portfólio</span></Link>
              <Link to="/relatoriooperacional" className={props.active === "relatoriooperacional" ? "sidebar-nav active" : "sidebar-nav"} ><i className="icon fas fa-file"></i><span> Relatório Operacional</span></Link>
              <Link to="/agenda" className={props.active === "agenda" ? "sidebar-nav active" : "sidebar-nav"} ><i className="icon fas fa-file"></i><span> Agenda</span></Link>
              
              <hr />
              
              <Link to="/users" className={props.active === "users" ? "sidebar-nav active" : "sidebar-nav"}><i className="icon fas fa-users"></i><span>Usuários</span></Link>
              </>
        }

<Link to="#" onClick={handleLogout} className="sidebar-nav"><i className="icon fas fa-sign-out-alt"></i><span>Sair</span></Link>
        </div>
    )
}