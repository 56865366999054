import React, { useEffect, useState } from 'react';
import { Navbar } from '../../components/Navbar';
import { Sidebar } from '../../components/Sidebar';


export const Eventos = () => {

    const [countdown, setCountdown] = useState(300000)

    useEffect(() => {
      const timerId = setInterval(() => {
        setCountdown(prevState => prevState - 1)
        
            window.location.reload();
      }, countdown);
      return () => clearInterval(timerId)
    }, [countdown])
  

    return (
        <div>
            <Navbar />
            <div className="content">
                <Sidebar />

                <div className="wrapper">

                    <div className="row">

                        <iframe title="" width="100%" height="800" src="https://app.powerbi.com/view?r=eyJrIjoiNzQ1ZTMwNTEtMTQzNS00OGI5LWFiYTMtNzlmYmM5MTYyMGNkIiwidCI6IjhkYzFiNzM0LTEyYTYtNDNmZC1iMjdkLWE3Yzg0ZmQ0MzdkMiJ9" frameborder="0" allowFullScreen="true"></iframe>

                    </div>

                </div>
            </div>
        </div>
    );
}