import React, { useState, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useHistory, useLocation } from 'react-router-dom';
import { Navbar } from '../../components/Navbar';
import { Sidebar } from '../../components/Sidebar';
import axios from 'axios';
import { Context } from '../../Context/AuthContext';

import './index.css';
import { date } from 'yup/lib/locale';

export const RelatorioOperacional = () => {


    const [countdown, setCountdown] = useState(0)

   


    const { state } = useLocation();
    const history = useHistory();

    const { signIn } = useContext(Context);
    const [disparo, setDisparo] = useState({
       
        number: "",
        message: "",
        isWhisper: false,
        forceSend: true,
        verifyContact: false,
        delayInSeconds: 0
    });

    const valorInput = e => setDisparo({ ...disparo, [e.target.name]: e.target.value });


    const loginSubmit = async e => {
        e.preventDefault();
        const headers = {
            'Content-Type': 'application/json',
            'access-token': '655cf2e8d01bda96c79fc8db'
        }

        await axios.post("https://api.computei.com.br/core/v2/api/chats/send-text", disparo, { headers })
            .then((response) => {
                console.log(response);
                setStatus({
                    type: 'success',
                    mensagem:  'Mensagem enviada com sucesso!',
                    loading: false
                });

                 setCountdown(7200)
                
                return history.push('/relatoriooperacional');
            }).catch((err) => {
                if (err.response) {
                    console.log(err.response);
                } else {
                    console.log("Erro: tente mais tarde");
                    setStatus({
                        type: 'error',
                        mensagem: "Erro: tente mais tarde!",
                        loading: false
                    });
                    
                }
            });
    }

    const [status, setStatus] = useState({
        type: state ? state.type : "",
        mensagem: state ? state.mensagem : "",
        loading: false
    });

     useEffect(() => {
      const timerId = setInterval(() => {
        setCountdown(prevState => prevState - 1)
        setStatus({
            type: "",
            mensagem: "",
            loading: false
        });

        
      }, 7200);
      return () => clearInterval(timerId)
    }, [countdown])

    return (
        <div>
            <Navbar />
            
            <div className="content">

            <Sidebar active="relatoriooperacional" />
                
            <div className="wrapper">
                
            <div className="table-responsive">

                    <form onSubmit={loginSubmit} typeof='default' className="form-login">

                        {status.type === 'error' ? <p className="alert-danger">{status.mensagem}</p> : ""}
                        {status.type === 'success' ? <h3><p className="alert-success">{status.mensagem}</p></h3> : ""}

                        {status.loading ? <p className="alert-success">Validando...</p> : ""}

                        <div className="row" style={{ background: '#ECEDF0'}}>

<div className="col-xl-12 col-lg-7">
    <h1>ENVIO DE MENSAGEM DE WHATSAPP DO RELATÓRIO OPERACIONAL </h1>
</div>
</div>


                        <div className="row" style={{ background: '#ECEDF0'}}>

                        <div className="col-xl-12 col-lg-7">
                        <Form.Control as="textarea" type="textarea" name="message" rows="23" placeholder="Digite a Mensagem" onChange={valorInput} required/>
                        </div>
                
                        </div>

                        <div className="row" style={{ background: '#ECEDF0'}}>
                        <div className="col-xl-6 col-lg-7">
                                        <Form.Select className="d-grid gap-2" size="lg"  type="text" name="number" id="number" placeholder="Digite o phoneNumber" onChange={valorInput} required>
                                            <option value="">Selecione um Contato</option>
                                            <option value="5521984660193">Chefe Belchior</option>
                                                <option value="5521999877400">Vairão</option>
                                                <option value="5521997811982">Leonardo</option>
                                                <option value="5521996517905">Coordenação</option>
                                                <option value="5521981145040">Ana Carla - Planejamento (APR)</option>
                                                <option value="5521995775222">Vitor Sá - Planejamento (APR)</option>
                                                <option value="5521988174900">Brendon - Planejamento (APR)</option>
                                        </Form.Select>
                        </div>
                        <div className="col-xl-6 col-lg-7">
                        <div className="d-grid gap-2">
                        {status.loading ? <Button size="lg" variant="success" type="submit" ><h3>Enviando...</h3></Button> : <Button variant="success" type="submit" className="button-login"><h3>Enviar Mensagem no Whatsapp</h3></Button>}
                        </div>
                        </div>
                    </div>
                    </form>
                    </div>  
            </div>  
            </div>
        </div>
    );
}