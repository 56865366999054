import React, { useEffect, useState } from 'react';
import { Navbar } from '../../components/Navbar';
import { Sidebar } from '../../components/Sidebar';


import 'moment/locale/pt-br'
import axios from 'axios';

import './sb-admin-2.min.css'
  
export const Portfolio = () => {
  const moment = require('moment');
  
  const [countdown, setCountdown] = useState(300000)
  const [estagio, setEstagio] = useState("Normalidade");
  const [tempoestagio, setTempoEstagio] = useState(" ");
  
  
  const getAlertas = async () => {
    console.count("Reiniciou getAlertas   " +  moment(Date.now()).format('DD/MM/YYYY HH:mm:ss'));
    await axios({
        method: 'get',
        baseURL: 'http://aplicativo.cocr.com.br/estagio_api'
                        
    }).then(estagioatual => {

try {

  setTempoEstagio(moment(estagioatual.data.inicio).format('DD/MM/YYYY HH:mm') + " " + "(" + moment(estagioatual.data.inicio).startOf('hour').fromNow() + "" + ")");
  setEstagio(estagioatual.data.estagio);
} catch (error) {
  console.log(error)
  setTempoEstagio("Não conseguimos o acesso a api que retorna informações do Estágio Atual da Cidade");
  setEstagio("Branco");
}
    }).catch(err => {
      console.log(err)
      setTempoEstagio("Não conseguimos o acesso a api que retorna informações do Estágio Atual da Cidade");
      setEstagio("Branco");
    });
}
useEffect(() => {getAlertas();}, [])

  useEffect(() => {
    const timerId = setInterval(() => {
      setCountdown(prevState => prevState - 1)
      
      getAlertas();
      
    }, countdown);
    return () => clearInterval(timerId)
  }, [countdown])

    return (
        <div>
            <Navbar />
            
            <div className="content">

            <Sidebar active="portfolio" />
                

                <div className="wrapper">


                
                <div className="table-responsive">

              
                <div className="row" style={{ background: "#ECEDF0"}}>
            <div class="col-lg-4 mb-4">
                <div class="card shadow mb-4">
    <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Painel de Ocorrências</h6>
        
    </div>
    <div class="card-body">
        <div class="text-center">
            <img class="img-fluid px-3 px-sm-4 mt-3 mb-4" src="/operacoesrio.png" alt="..." />
        </div>
        <p>Informações em tempo real sobre as principais ocorrências da Cidade.</p>
        <a target="_blank" rel="nofollow" href="https://cor.computei.com.br">Visualizar Painel de Ocorrências &rarr;</a>
    </div>
                </div>
            </div>


            <div class="col-lg-4 mb-4">
                <div class="card shadow mb-4">
    <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Painel Sala de Situação</h6>
        
    </div>
    <div class="card-body">
        <div class="text-center">
            <img class="img-fluid px-3 px-sm-4 mt-3 mb-4" src="/saladesituacao.png" alt="..." />
        </div>
        <p>Dashboard Painel do Comando para visualização das Ocorrências Abertas e Fechadas pelos demais órgãos da Prefeitura. Também um histórico das ocorrências.</p>
        <a target="_blank" rel="nofollow" href="/operacoesrio">Visualizar &rarr;</a>
    </div>
                </div>
            </div>


            <div class="col-lg-4 mb-4">
                <div class="card shadow mb-4">
    <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Pontos de Monitoramento RIO-ÁGUAS</h6>
        
    </div>
    <div class="card-body">
        <div class="text-center">
            <img class="img-fluid px-3 px-sm-4 mt-3 mb-4" src="/rioaguas.png" alt="..." />
        </div>
        <p>Monitoramento dos Pontos idetificados pela Rio-Águas e a integração de informações de Ocorrências Climáticas do Comando.</p>
        <a target="_blank" rel="nofollow" href="/rioaguas">Visualizar &rarr;</a>
    </div>
                </div>
            </div>


       

            <div class="col-lg-4 mb-4">
                <div class="card shadow mb-4">
    <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Eventos Mapeados</h6>
        
    </div>
    <div class="card-body">
        <div class="text-center">
            <img class="img-fluid px-3 px-sm-4 mt-3 mb-4" src="/eventos.png" alt="..." />
        </div>
        <p>Monitoramento de Eventos, público estimado e sua distribuição nas regiões da Cidade.</p>
        <a target="_blank" rel="nofollow" href="/eventos">Visualizar &rarr;</a>
    </div>
                </div>
            </div>

            </div>

            
                
                </div>


                
                
                

                </div>
            </div>

     
        </div>
    );
}