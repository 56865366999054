import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, Tooltip, ScaleControl, LayersControl } from "react-leaflet";
import { Icon } from "leaflet";
import { Navbar } from '../../components/Navbar';

import { SidebarPublica } from '../../components/SidebarPublica';
            

import { Chart } from "react-google-charts";
import { useEffect, useState } from 'react';

import 'moment/locale/pt-br'
import _ from 'lodash';
import axios from 'axios';

import favorito from "../../Data/favorito.json"

const open = new Icon({ iconUrl: "/open.gif", iconSize: [35, 35] })
const camera = new Icon({ iconUrl: "/camera.png", iconSize: [15, 15] })
const operacaopolicial = new Icon({ iconUrl: "/operacaopolicial.png", iconSize: [40, 40] })
const buraconavia = new Icon({ iconUrl: "/buraconavia.png", iconSize: [35, 35] })
const obrasnavia = new Icon({ iconUrl: "/obrasnavia.png", iconSize: [40, 40] })
const falhanosemaforo = new Icon({ iconUrl: "/falhanosemaforo.png", iconSize: [30, 30] })
const acidenteleve = new Icon({ iconUrl: "/acidenteleve.png", iconSize: [40, 40] })
const asfaltoliso = new Icon({ iconUrl: "/asfaltoliso.png", iconSize: [40, 40] })
const evento = new Icon({ iconUrl: "/evento.png", iconSize: [40, 40], shadowSize:   [50, 64] })
const manifestacao = new Icon({ iconUrl: "/manifestacao.png", iconSize: [40, 40], shadowSize:   [50, 64] })
const deslizamento = new Icon({ iconUrl: "/deslizamento.png", iconSize: [40, 40], shadowSize:   [50, 64] })
const vazamentodeagua = new Icon({ iconUrl: "/vazamentodeagua.png", iconSize: [30, 30] })
const bueiro = new Icon({ iconUrl: "/bueiro.png", iconSize: [40, 40] })
const incendio = new Icon({ iconUrl: "/incendio.png", iconSize: [30, 30] })
const quedadearvore = new Icon({ iconUrl: "/quedadearvore.png", iconSize: [45, 45] })




const tableOptions = {
  allowHtml: true
};

const formatters = [
  {
      type: "BarFormat",
      column: 1,
      options: {
          width: 120
      },
  },
];


export const options = {
    title: "Total de Ocorrências",
    legend: { position: 'none' },
    colors: ['#ce6b01'],
   
    backgroundColor: '#FFF',
    chartArea: { width: "100%", height: "100%" },
    
    hAxis: {
//      title: "Total de Ocorrências Abertas",
      minValue: 0,
      format: 0
      
    },
    vAxis: {
      //title: "Ocorrências por POP",
      scaleType: 'log'
    },
  };

  export const optionsPop = {
    //title: "Total de Ocorrências",
    colors: ['#ce6b01'],
    legend: { position: 'none' },
    backgroundColor: '#FFF',
    chartArea: { width: "50%", height: "90%" },
    
    
    hAxis: {
//      title: "Total de Ocorrências Abertas",
      minValue: 0,
      format: 0
      
    },
    vAxis: {
      //title: "Ocorrências por POP",
      scaleType: 'log'
      
    },
  };

  function pops(params) {
  
    var pop = params;
    if (pop === 1) {pop = 'Acidente/enguiço sem vítima';
    } else if (pop === 2) {pop = 'Acidente com vítima(s)';
    } else if (pop === 3) {pop = 'Acidente com vítima(s) fatal(is)';
    } else if (pop === 4) {pop = 'Incêndio em veículo(s)';
    } else if (pop === 5) {pop = 'Bolsão dágua em via';
    } else if (pop === 6) {pop = 'Alagamentos e enchentes';
    } else if (pop === 7) {pop = 'Manifestação em local público';
    } else if (pop === 8) {pop = 'Incêndio em imóvel';
    } else if (pop === 9) {pop = 'Sinais de trânsito com mau funcionamento';
    } else if (pop === 10) {pop = 'Reintegração de Posse';
    } else if (pop === 11) {pop = 'Queda de árvore';
    } else if (pop === 12) {pop = 'Queda de poste';
    } else if (pop === 13) {pop = 'Acidente com queda de carga';
    } else if (pop === 14) {pop = 'Incêndio no entorno de vias públicas';
    } else if (pop === 15) {pop = 'Incêndio dentro de túneis';
    } else if (pop === 16) {pop = 'Vazamento de água / esgoto';
    } else if (pop === 17) {pop = 'Falta de luz / Apagão';
    } else if (pop === 18) {pop = 'Implosão';
    } else if (pop === 19) {pop = 'Queda de estrutura de alvenaria';
    } else if (pop === 20) {pop = 'Vazamento de gás';
    } else if (pop === 21) {pop = 'Evento em local público ou particular';
    } else if (pop === 22) {pop = 'Atropelamento';
    } else if (pop === 23) {pop = 'Afundamento de Pista / Buraco na via';
    } else if (pop === 24) {pop = 'Abalroamento';
    } else if (pop === 25) {pop = 'Obra em local público';
    } else if (pop === 26) {pop = 'Operação policial';
    } else if (pop === 27) {pop = 'Bloco de Rua';
    } else if (pop === 28) {pop = 'Deslizamento';
    } else if (pop === 29) {pop = 'Animal em local público';
    } else if (pop === 30) {pop = 'Acionamento de sirenes';
    } else if (pop === 31) {pop = 'Alagamento';
    } else if (pop === 32) {pop = 'Enchente';
    } else if (pop === 33) {pop = 'Lâmina dágua';
    } else if (pop === 34) {pop = 'Acidente ambiental';
    } else if (pop === 35) {pop = 'Bueiro';
    } else if (pop === 36) {pop = 'Resgate ou remoção de Animais Terrestres e Aéreos';
    } else if (pop === 37) {pop = 'Remoção de Animais Mortos na Areia';
    } else if (pop === 38) {pop = 'Resgate de Animal Marinho Preso em rede / Encalhado';
    } else if (pop === 39) {pop = 'Incendio em vegetacao';
    } else if (pop === 40) {pop = 'Galho sobre fiação';
    } else {pop = 'vazio';}
  
    return pop
    
  }

  

  
function zonas(params) {

  
    var zona = params;
    if (zona === "São Cristóvão") {zona = 'CENTRAL';
} else if (zona === "Benfica") {zona = 'CENTRAL';
} else if (zona === "Caju") {zona = 'CENTRAL';
} else if (zona === "Catumbi") {zona = 'CENTRAL';
} else if (zona === "Centro") {zona = 'CENTRAL';
} else if (zona === "Cidade Nova") {zona = 'CENTRAL';
} else if (zona === "Estácio") {zona = 'CENTRAL';
} else if (zona === "Gamboa") {zona = 'CENTRAL';
} else if (zona === "Lapa") {zona = 'CENTRAL';
} else if (zona === "Mangueira") {zona = 'CENTRAL';
} else if (zona === "Paquetá") {zona = 'CENTRAL';
} else if (zona === "Rio Comprido") {zona = 'CENTRAL';
} else if (zona === "Santa Teresa") {zona = 'CENTRAL';
} else if (zona === "Santo Cristo") {zona = 'CENTRAL';
} else if (zona === "Saúde") {zona = 'CENTRAL';
} else if (zona === "Vasco da Gama") {zona = 'CENTRAL';
} else if (zona === "Alto da Boa Vista") {zona = 'NORTE';
} else if (zona === "Andaraí") {zona = 'NORTE';
} else if (zona === "Grajaú") {zona = 'NORTE';
} else if (zona === "Grajau") {zona = 'NORTE';
} else if (zona === "Maracanã") {zona = 'NORTE';
} else if (zona === "Praça da Bandeira") {zona = 'NORTE';
} else if (zona.includes("Praca da Bandeira")) {zona = 'NORTE';
} else if (zona === "Tijuca") {zona = 'NORTE';
} else if (zona === "Vila Isabel") {zona = 'NORTE';
} else if (zona === "Abolição") {zona = 'NORTE';
} else if (zona === "Água Santa") {zona = 'NORTE';
} else if (zona === "Cachambi") {zona = 'NORTE';
} else if (zona === "Del Castilho") {zona = 'NORTE';
} else if (zona === "Encantado") {zona = 'NORTE';
} else if (zona === "Engenho de Dentro") {zona = 'NORTE';
} else if (zona === "Engenho Novo") {zona = 'NORTE';
} else if (zona === "Higienópolis") {zona = 'NORTE';
} else if (zona === "Ilha do Governador") {zona = 'NORTE';
} else if (zona === "Fundao") {zona = 'NORTE';
} else if (zona === "Fundão") {zona = 'NORTE';
} else if (zona === "Cidade Universitária da Universidade Federal do Rio de Janeiro") {zona = 'NORTE';
} else if (zona === "Jacaré") {zona = 'NORTE';
} else if (zona === "Jacarezinho") {zona = 'NORTE';
} else if (zona === "Lins de Vasconcelos") {zona = 'NORTE';
} else if (zona === "Manguinhos") {zona = 'NORTE';
} else if (zona === "Maria da Graça") {zona = 'NORTE';
} else if (zona === "Méier") {zona = 'NORTE';
} else if (zona === "Piedade") {zona = 'NORTE';
} else if (zona === "Pilares") {zona = 'NORTE';
} else if (zona === "Riachuelo") {zona = 'NORTE';
} else if (zona === "Rocha") {zona = 'NORTE';
} else if (zona === "Sampaio") {zona = 'NORTE';
} else if (zona === "São Francisco Xavier") {zona = 'NORTE';
} else if (zona === "Todos os Santos") {zona = 'NORTE';
} else if (zona === "Bonsucesso") {zona = 'NORTE';
} else if (zona === "Bancários") {zona = 'NORTE';
} else if (zona === "Cacuia") {zona = 'NORTE';
} else if (zona === "Cidade Universitária") {zona = 'NORTE';
} else if (zona === "Cocotá") {zona = 'NORTE';
} else if (zona === "Freguesia") {zona = 'NORTE';
} else if (zona === "Galeão") {zona = 'NORTE';
} else if (zona === "Jardim Carioca") {zona = 'NORTE';
} else if (zona === "Jardim Guanabara") {zona = 'NORTE';
} else if (zona === "Maré") {zona = 'NORTE';
} else if (zona === "Moneró") {zona = 'NORTE';
} else if (zona === "Olaria") {zona = 'NORTE';
} else if (zona === "Pitangueiras") {zona = 'NORTE';
} else if (zona === "Portuguesa") {zona = 'NORTE';
} else if (zona === "Praia da Bandeira") {zona = 'NORTE';
} else if (zona === "Ramos") {zona = 'NORTE';
} else if (zona === "Ribeira") {zona = 'NORTE';
} else if (zona === "Tauá") {zona = 'NORTE';
} else if (zona === "Zumbi") {zona = 'NORTE';
} else if (zona === "Acari") {zona = 'NORTE';
} else if (zona === "Anchieta") {zona = 'NORTE';
} else if (zona === "Barros Filho") {zona = 'NORTE';
} else if (zona === "Bento Ribeiro") {zona = 'NORTE';
} else if (zona === "Brás de Pina") {zona = 'NORTE';
} else if (zona === "Campinho") {zona = 'NORTE';
} else if (zona === "Cavalcanti") {zona = 'NORTE';
} else if (zona === "Cavalcante") {zona = 'NORTE';
} else if (zona === "Cascadura") {zona = 'NORTE';
} else if (zona === "Coelho Neto") {zona = 'NORTE';
} else if (zona === "Colégio") {zona = 'NORTE';
} else if (zona === "Complexo do Alemão") {zona = 'NORTE';
} else if (zona === "Cordovil") {zona = 'NORTE';
} else if (zona === "Costa Barros") {zona = 'NORTE';
} else if (zona === "Engenheiro Leal") {zona = 'NORTE';
} else if (zona === "Engenho da Rainha") {zona = 'NORTE';
} else if (zona === "Guadalupe") {zona = 'NORTE';
} else if (zona === "Galeao") {zona = 'NORTE';
} else if (zona === "Galeão") {zona = 'NORTE';
} else if (zona === "Honório Gurgel") {zona = 'NORTE';
} else if (zona === "Inhaúma") {zona = 'NORTE';
} else if (zona === "Inhauma") {zona = 'NORTE';
} else if (zona === "Irajá") {zona = 'NORTE';
} else if (zona === "Jardim América") {zona = 'NORTE';
} else if (zona === "Madureira") {zona = 'NORTE';
} else if (zona === "Marechal Hermes") {zona = 'NORTE';
} else if (zona === "Mal. Hermes") {zona = 'NORTE';
} else if (zona === "Oswaldo Cruz") {zona = 'NORTE';
} else if (zona === "Parada de Lucas") {zona = 'NORTE';
} else if (zona === "Parque Anchieta") {zona = 'NORTE';
} else if (zona === "Parque Colúmbia") {zona = 'NORTE';
} else if (zona === "Parque Columbia") {zona = 'NORTE';
} else if (zona === "Pavuna") {zona = 'NORTE';
} else if (zona === "Penha") {zona = 'NORTE';
} else if (zona === "Penha Circular") {zona = 'NORTE';
} else if (zona === "Quintino Bocaiúva") {zona = 'NORTE';
} else if (zona === "Quintino Bocaiuva") {zona = 'NORTE';
} else if (zona === "Ricardo de Albuquerque") {zona = 'NORTE';
} else if (zona === "Rocha Miranda") {zona = 'NORTE';
} else if (zona === "Tomás Coelho") {zona = 'NORTE';
} else if (zona === "Tomas Coelho") {zona = 'NORTE';
} else if (zona === "Turiaçu") {zona = 'NORTE';
} else if (zona === "Turiacu") {zona = 'NORTE';
} else if (zona === "Vaz Lobo") {zona = 'NORTE';
} else if (zona === "Váz Lobo") {zona = 'NORTE';
} else if (zona === "Vicente de Carvalho") {zona = 'NORTE';
} else if (zona === "Vigario Geral") {zona = 'NORTE';
} else if (zona === "Vigário Geral") {zona = 'NORTE';
} else if (zona === "Vila da Penha") {zona = 'NORTE';
} else if (zona === "Vila Kosmos") {zona = 'NORTE';
} else if (zona === "Vista Alegre") {zona = 'NORTE';
} else if (zona === "São Conrado") {zona = 'SUL';
} else if (zona === "Sao Conrado") {zona = 'SUL';
} else if (zona === "Lagoa") {zona = 'SUL';
} else if (zona === "Botafogo") {zona = 'SUL';
} else if (zona === "Catete") {zona = 'SUL';
} else if (zona === "Copacabana") {zona = 'SUL';
} else if (zona.includes("Arpoador")) {zona = 'SUL';
} else if (zona === "Cosme Velho") {zona = 'SUL';
} else if (zona === "Flamengo") {zona = 'SUL';
} else if (zona === "Gávea") {zona = 'SUL';
} else if (zona === "Gavea") {zona = 'SUL';
} else if (zona === "Glória") {zona = 'SUL';
} else if (zona === "Gloria") {zona = 'SUL';
} else if (zona === "Humaitá") {zona = 'SUL';
} else if (zona === "Humaita") {zona = 'SUL';
} else if (zona === "Ipanema") {zona = 'SUL';
} else if (zona === "Jardim Botânico") {zona = 'SUL';
} else if (zona === "Jardim Botanico") {zona = 'SUL';
} else if (zona === "Laranjeiras") {zona = 'SUL';
} else if (zona === "Leblon") {zona = 'SUL';
} else if (zona === "Leme") {zona = 'SUL';
} else if (zona.includes("Rocinha")) {zona = 'SUL';
} else if (zona === "Urca") {zona = 'SUL';
} else if (zona === "Vidigal") {zona = 'SUL';
} else if (zona === "Anil") {zona = 'OESTE';
} else if (zona === "Barra da Tijuca") {zona = 'OESTE';
} else if (zona === "Camorim") {zona = 'OESTE';
} else if (zona === "Cidade de Deus") {zona = 'OESTE';
} else if (zona === "Curicica") {zona = 'OESTE';
} else if (zona === "Freguesia de Jacarepaguá") {zona = 'OESTE';
} else if (zona === "Gardênia Azul") {zona = 'OESTE';
} else if (zona === "Gardenia Azul") {zona = 'OESTE';
} else if (zona === "Grumari") {zona = 'OESTE';
} else if (zona === "Itanhangá") {zona = 'OESTE';
} else if (zona === "Itanhanga") {zona = 'OESTE';
} else if (zona === "Jacarepaguá") {zona = 'OESTE';
} else if (zona === "Jacarepagua") {zona = 'OESTE';
} else if (zona === "Joá") {zona = 'OESTE';
} else if (zona === "Joa") {zona = 'OESTE';
} else if (zona === "Praça Seca") {zona = 'OESTE';
} else if (zona === "Praca Seca") {zona = 'OESTE';
} else if (zona === "Pechincha") {zona = 'OESTE';
} else if (zona === "Recreio dos Bandeirantes") {zona = 'OESTE';
} else if (zona === "Rio das Pedras") {zona = 'OESTE';
} else if (zona === "Tanque") {zona = 'OESTE';
} else if (zona === "Taquara") {zona = 'OESTE';
} else if (zona === "Vargem Grande") {zona = 'OESTE';
} else if (zona === "Vargem Pequena") {zona = 'OESTE';
} else if (zona === "Vila Valqueire") {zona = 'OESTE';
} else if (zona === "Jardim Sulacap") {zona = 'OESTE';
} else if (zona === "Bangu") {zona = 'OESTE';
} else if (zona === "Bangú") {zona = 'OESTE';
} else if (zona === "Campo dos Afonsos") {zona = 'OESTE';
} else if (zona === "Deodoro") {zona = 'OESTE';
} else if (zona === "Gericinó") {zona = 'OESTE';
} else if (zona === "Magalhães Bastos") {zona = 'OESTE';
} else if (zona === "Padre Miguel") {zona = 'OESTE';
} else if (zona === "Realengo") {zona = 'OESTE';
} else if (zona === "Santíssimo") {zona = 'OESTE';
} else if (zona === "Senador Camará") {zona = 'OESTE';
} else if (zona === "Vila Kennedy") {zona = 'OESTE';
} else if (zona === "Vila Militar") {zona = 'OESTE';
} else if (zona === "Barra de Guaratiba") {zona = 'OESTE';
} else if (zona === "Campo Grande") {zona = 'OESTE';
} else if (zona === "Cosmos") {zona = 'OESTE';
} else if (zona === "Guaratiba") {zona = 'OESTE';
} else if (zona === "Inhoaíba") {zona = 'OESTE';
} else if (zona === "Paciência") {zona = 'OESTE';
} else if (zona === "Paciencia") {zona = 'OESTE';
} else if (zona === "Pedra de Guaratiba") {zona = 'OESTE';
} else if (zona === "Santa Cruz") {zona = 'OESTE';
} else if (zona === "Senador Vasconcelos") {zona = 'OESTE';
} else if (zona === "Sepetiba") {zona = 'OESTE';
    } else {zona = 'N/D';}
  
    return zona
    
  }
                                                    
  
  



  export const Cameras = (props) => { 
    
    return (
<>
      {props.show === "Sim" ? 

              
 
        
           
      <>

<Marker position={[-22.900259,-43.177031]} icon={camera}><Popup><span className='title-content'>Av. Pres.Vargas X R. 1º Março -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000001' alt='Av. Pres.Vargas X R. 1º Março' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.901392,-43.179391]} icon={camera}><Popup><span className='title-content'>Av. Pres.Vargas X Av. Rio Branco -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000002' alt='Av. Pres.Vargas X Av. Rio Branco' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.904902,-43.190353]} icon={camera}><Popup><span className='title-content'>Av. Pres.Vargas X Pça. da República -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000003' alt='Av. Pres.Vargas X Pça. da República' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.809417,-43.25154399]} icon={camera}><Popup><span className='title-content'>Av. 20 de Janeiro X Terminal 1 - Brt -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=000004' alt='Av. 20 de Janeiro X Terminal 1 - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913741,-43.174155]} icon={camera}><Popup><span className='title-content'>Av. Rio Branco X Av. Beira Mar -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000005' alt='Av. Rio Branco X Av. Beira Mar' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908029,-43.176985]} icon={camera}><Popup><span className='title-content'>Av. Rio Branco X Av. Alm. Barroso -  Centro da Cidade - Carioca</span><img src='http://187.111.99.18:9004/?CODE=000006' alt='Av. Rio Branco X Av. Alm. Barroso' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.815593,-43.242096]} icon={camera}><Popup><span className='title-content'>Av. 20 de Janeiro X Base da Guarda Municipal - Brt -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=000007' alt='Av. 20 de Janeiro X Base da Guarda Municipal - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908246,-43.186069]} icon={camera}><Popup><span className='title-content'>R. Visconde Do Rio Branco X R. Dos Inválidos - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000008' alt='R. Visconde Do Rio Branco X R. Dos Inválidos - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906766,-43.172901]} icon={camera}><Popup><span className='title-content'>Av. Presidente Antonio Carlos X Av. Almirante Barroso -  Centro da Cidade - Castelo</span><img src='http://187.111.99.18:9004/?CODE=000009' alt='Av. Presidente Antonio Carlos X Av. Almirante Barroso' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911155,-43.193351]} icon={camera}><Popup><span className='title-content'>R. Santana X R. Frei Caneca - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000010' alt='R. Santana X R. Frei Caneca - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913996,-43.204558]} icon={camera}><Popup><span className='title-content'>Largo Do Estácio -  Centro da Cidade - Estacio</span><img src='http://187.111.99.18:9004/?CODE=000011' alt='Largo Do Estácio' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93456,-43.187492]} icon={camera}><Popup><span className='title-content'>R. das Laranjeiras X Soares Cabral -  Zona Sul - Laranjeiras</span><img src='http://187.111.99.18:9004/?CODE=000012' alt='R. das Laranjeiras X Soares Cabral' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.943196,-43.18166]} icon={camera}><Popup><span className='title-content'>Praia de Botafogo X Viaduto Santiago Dantas -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000013' alt='Praia de Botafogo X Viaduto Santiago Dantas' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94935,-43.184177]} icon={camera}><Popup><span className='title-content'>R. São Clemente X R. Muniz de Barreto -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000014' alt='R. São Clemente X R. Muniz de Barreto' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.952073,-43.19582]} icon={camera}><Popup><span className='title-content'>R. São Clemente X Consulado Português -  Zona Sul - Humaita</span><img src='http://187.111.99.18:9004/?CODE=000015' alt='R. São Clemente X Consulado Português' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.961257,-43.212939]} icon={camera}><Popup><span className='title-content'>R. Jardim Botânico Próximo Ao Parque Lage -  Zona Sul - Jardim Botanico</span><img src='http://187.111.99.18:9004/?CODE=000016' alt='R. Jardim Botânico Próximo Ao Parque Lage' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.963021,-43.204446]} icon={camera}><Popup><span className='title-content'>Av. Borges de Medeiros X Av. Epitácio Pessoa -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000017' alt='Av. Borges de Medeiros X Av. Epitácio Pessoa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.976902,-43.21908]} icon={camera}><Popup><span className='title-content'>R. Mário Ribeiro X Av. Borges de Medeiros -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000018' alt='R. Mário Ribeiro X Av. Borges de Medeiros' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.954405,-43.192948]} icon={camera}><Popup><span className='title-content'>R. Voluntários da Pátria X R. Real Grandeza -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000019' alt='R. Voluntários da Pátria X R. Real Grandeza' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.942467,-43.178155]} icon={camera}><Popup><span className='title-content'>ATERRO X AV. OSWALDO CRUZ -  Zona Sul - Flamengo</span><img src='http://187.111.99.18:9004/?CODE=000020' alt='ATERRO X AV. OSWALDO CRUZ' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910919,-43.213874]} icon={camera}><Popup><span className='title-content'>Praça da Bandeira -  Centro da Cidade - Praca da Bandeira</span><img src='http://187.111.99.18:9004/?CODE=000021' alt='Praça da Bandeira' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910177,-43.233605]} icon={camera}><Popup><span className='title-content'>Av. Pres. Castelo Branco X R. Radialista Waldir Amaral -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=000022' alt='Av. Pres. Castelo Branco X R. Radialista Waldir Amaral' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.963906,-43.178505]} icon={camera}><Popup><span className='title-content'>R. Barata Ribeiro X R. Duvivier -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000023' alt='R. Barata Ribeiro X R. Duvivier' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.965117,-43.176944]} icon={camera}><Popup><span className='title-content'>Av. Nossa Sra. de Copacabana X R. Ronald de Carvalho -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000024' alt='Av. Nossa Sra. de Copacabana X R. Ronald de Carvalho' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.964967,-43.173588]} icon={camera}><Popup><span className='title-content'>Av. Atlântica X Av. Princesa Isabel -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000025' alt='Av. Atlântica X Av. Princesa Isabel' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.971867,-43.184628]} icon={camera}><Popup><span className='title-content'>Av. Atlântica X R. Figueiredo de Magalhães -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000026' alt='Av. Atlântica X R. Figueiredo de Magalhães' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.971621,-43.18743]} icon={camera}><Popup><span className='title-content'>Av. N. Sra. de Copacabana X R. Sta. Clara -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000027' alt='Av. N. Sra. de Copacabana X R. Sta. Clara' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984335,-43.189473]} icon={camera}><Popup><span className='title-content'>Av. Atlântica X R. Rainha Elisabeth -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000028' alt='Av. Atlântica X R. Rainha Elisabeth' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.976515,-43.194135]} icon={camera}><Popup><span className='title-content'>Corte Do Cantagalo X Pça. Eugênio Jardim -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000029' alt='Corte Do Cantagalo X Pça. Eugênio Jardim' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.986985,-43.190727]} icon={camera}><Popup><span className='title-content'>R. Raul Pompéia X R. Francisco Otaviano -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000030' alt='R. Raul Pompéia X R. Francisco Otaviano' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.986892,-43.197786]} icon={camera}><Popup><span className='title-content'>Av. Vieira Souto X R. Rainha Elizabeth -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=000031' alt='Av. Vieira Souto X R. Rainha Elizabeth' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.980723,-43.207148]} icon={camera}><Popup><span className='title-content'>Av. Epitácio Pessoa X R. Maria Quitéria -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=000032' alt='Av. Epitácio Pessoa X R. Maria Quitéria' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.987068,-43.222374]} icon={camera}><Popup><span className='title-content'>Av. Delfim Moreira X R. Bartolomeu Mitre -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=000033' alt='Av. Delfim Moreira X R. Bartolomeu Mitre' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98483,-43.195183]} icon={camera}><Popup><span className='title-content'>R. Visconde de Pirajá X R. Gomes Carneiro -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=000034' alt='R. Visconde de Pirajá X R. Gomes Carneiro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.972881,-43.190783]} icon={camera}><Popup><span className='title-content'>R. Barata Ribeiro X R. Constante Ramos -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000035' alt='R. Barata Ribeiro X R. Constante Ramos' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91028841,-43.20929763]} icon={camera}><Popup><span className='title-content'>Trevo das Forças Armadas -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000036' alt='Trevo das Forças Armadas' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.831010,-43.237093]} icon={camera}><Popup><span className='title-content'>Estr. Do Galeão - Base Aérea Ilha - Brt -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=000037' alt='Estr. Do Galeão - Base Aérea Ilha - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.855061,-43.251987]} icon={camera}><Popup><span className='title-content'>R. Teixeira de Castro X Av. dos Campeoes - Brt -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=000038' alt='R. Teixeira de Castro X Av. dos Campeoes - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910115,-43.171723]} icon={camera}><Popup><span className='title-content'>Av. Pres. Antonio Carlos X Av. Franklin Roosevelt -  Centro da Cidade - Castelo</span><img src='http://187.111.99.18:9004/?CODE=000039' alt='Av. Pres. Antonio Carlos X Av. Franklin Roosevelt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906984,-43.182051]} icon={camera}><Popup><span className='title-content'>Praça Tiradentes -  Centro da Cidade - Carioca</span><img src='http://187.111.99.18:9004/?CODE=000040' alt='Praça Tiradentes' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913628,-43.178807]} icon={camera}><Popup><span className='title-content'>Lapa - FIXA -  Centro da Cidade - Lapa</span><img src='http://187.111.99.18:9004/?CODE=000041' alt='Lapa - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.933241,-43.174673]} icon={camera}><Popup><span className='title-content'>R. Praia Do Flamengo X R. Barão Do Flamengo -  Zona Sul - Flamengo</span><img src='http://187.111.99.18:9004/?CODE=000042' alt='R. Praia Do Flamengo X R. Barão Do Flamengo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.957511,-43.199065]} icon={camera}><Popup><span className='title-content'>R. Humaitá X R. Macedo Sobrinho -  Zona Sul - Humaita</span><img src='http://187.111.99.18:9004/?CODE=000043' alt='R. Humaitá X R. Macedo Sobrinho' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96639,-43.219492]} icon={camera}><Popup><span className='title-content'>R. Jardim Botânico X R. Pacheco Leão -  Zona Sul - Jardim Botanico</span><img src='http://187.111.99.18:9004/?CODE=000044' alt='R. Jardim Botânico X R. Pacheco Leão' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.978488,-43.226668]} icon={camera}><Popup><span className='title-content'>Praça Sibéluis -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=000045' alt='Praça Sibéluis' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.950509,-43.181605]} icon={camera}><Popup><span className='title-content'>R. Voluntários da Pátria X R. Praia de Botafogo -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000046' alt='R. Voluntários da Pátria X R. Praia de Botafogo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.955582,-43.178137]} icon={camera}><Popup><span className='title-content'>AV. LAOURO SODRÉ X R. GENERAL GOIS MONTEIRO -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000047' alt='AV. LAOURO SODRÉ X R. GENERAL GOIS MONTEIRO' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915067,-43.228917]} icon={camera}><Popup><span className='title-content'>Av. Maracanã X R. Eurico Rabelo -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=000048' alt='Av. Maracanã X R. Eurico Rabelo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.968321,-43.185329]} icon={camera}><Popup><span className='title-content'>R. Barata Ribeiro X R. Siqueira Campos -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000049' alt='R. Barata Ribeiro X R. Siqueira Campos' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.979945,-43.191186]} icon={camera}><Popup><span className='title-content'>Av. N. Sra. de Copacabana X R. Alm. Gonçalves -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000050' alt='Av. N. Sra. de Copacabana X R. Alm. Gonçalves' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984059,-43.207227]} icon={camera}><Popup><span className='title-content'>R. Visconde de Pirajá X R. Maria Quitéria -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=000051' alt='R. Visconde de Pirajá X R. Maria Quitéria' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.983772,-43.218038]} icon={camera}><Popup><span className='title-content'>R. Ataulfo de Paiva X R. Afrânio de Melo Franco -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=000052' alt='R. Ataulfo de Paiva X R. Afrânio de Melo Franco' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911375,-43.173341]} icon={camera}><Popup><span className='title-content'>Av. Presidente Wilson X Av. Calógeras -  Centro da Cidade - Cinelandia</span><img src='http://187.111.99.18:9004/?CODE=000053' alt='Av. Presidente Wilson X Av. Calógeras' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.973309,-43.385863]} icon={camera}><Popup><span className='title-content'>Av. Embaixador Abelardo Bueno X Estr. Cel. Pedro Correa -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000054' alt='Av. Embaixador Abelardo Bueno X Estr. Cel. Pedro Correa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.923148,-43.373789]} icon={camera}><Popup><span className='title-content'>Av. Nelson Cardoso X Estrada dos Bandeirantes - Brt -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=000055' alt='Av. Nelson Cardoso X Estrada dos Bandeirantes - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.985163,-43.189169]} icon={camera}><Popup><span className='title-content'>Drummond - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000056' alt='Drummond - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.973546,-43.364096]} icon={camera}><Popup><span className='title-content'>Av. Ayrton Senna X R. Abelardo Bueno -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000057' alt='Av. Ayrton Senna X R. Abelardo Bueno' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984825,-43.365726]} icon={camera}><Popup><span className='title-content'>Av. Ayrton Senna X Av. Via Parque -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000058' alt='Av. Ayrton Senna X Av. Via Parque' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.995624,-43.365883]} icon={camera}><Popup><span className='title-content'>Av. Ayrton Senna X Hospital Lourenço Jorge -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000059' alt='Av. Ayrton Senna X Hospital Lourenço Jorge' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.010777,-43.365974]} icon={camera}><Popup><span className='title-content'>Av. Ayrton Senna X Av. Lúcio Costa -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000060' alt='Av. Ayrton Senna X Av. Lúcio Costa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.010846,-43.33168999]} icon={camera}><Popup><span className='title-content'>Lúcio Costa x posto 5 -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000061' alt='Lúcio Costa x posto 5' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.012976,-43.31833]} icon={camera}><Popup><span className='title-content'>Av. Sernambetiba X Praça Do O -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000062' alt='Av. Sernambetiba X Praça Do O' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.000096,-43.353792]} icon={camera}><Popup><span className='title-content'>Av. das Américas X R. Felicissimo Cardoso -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000063' alt='Av. das Américas X R. Felicissimo Cardoso' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.000824,-43.331445]} icon={camera}><Popup><span className='title-content'>Av Américas x estação BRT Afrânio Costa -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000064' alt='Av Américas x estação BRT Afrânio Costa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.003304,-43.32392]} icon={camera}><Popup><span className='title-content'>Av Américas x estação BRT Bosque Marapendi -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000065' alt='Av Américas x estação BRT Bosque Marapendi' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.007514,-43.304474]} icon={camera}><Popup><span className='title-content'>R. Armando Lombardi X Av. Min. Ivan Lins -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000066' alt='R. Armando Lombardi X Av. Min. Ivan Lins' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.999993,-43.269206]} icon={camera}><Popup><span className='title-content'>Praia de São Conrado X Av. prof. Mendes de Morais -  Zona Sul - Sao Conrado</span><img src='http://187.111.99.18:9004/?CODE=000067' alt='Praia de São Conrado X Av. prof. Mendes de Morais' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.996514,-43.260427]} icon={camera}><Popup><span className='title-content'>Autoestrada Lagoa-Barra Em Frente Shopping Fashion Mall -  Zona Sul - Sao Conrado</span><img src='http://187.111.99.18:9004/?CODE=000068' alt='Autoestrada Lagoa-Barra Em Frente Shopping Fashion Mall' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910167,-43.22163]} icon={camera}><Popup><span className='title-content'>Av. Pres. Castelo Branco X R. General Canabarro -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000069' alt='Av. Pres. Castelo Branco X R. General Canabarro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.924089,-43.236241]} icon={camera}><Popup><span className='title-content'>R. Pereira Nunes X R. Barão de Mesquita -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000070' alt='R. Pereira Nunes X R. Barão de Mesquita' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.920765,-43.222661]} icon={camera}><Popup><span className='title-content'>São Francisco Xavier X Heitor Beltrão - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000071' alt='São Francisco Xavier X Heitor Beltrão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.932703,-43.241217]} icon={camera}><Popup><span className='title-content'>R. Conde de Bonfim X R. Uruguai -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000072' alt='R. Conde de Bonfim X R. Uruguai' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.924669,-43.233547]} icon={camera}><Popup><span className='title-content'>R. Conde de Bonfim X R. General Rocca -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000073' alt='R. Conde de Bonfim X R. General Rocca' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913827,-43.235707]} icon={camera}><Popup><span className='title-content'>Boulevard 28 de Setembro X R. Felipe Camarão -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000074' alt='Boulevard 28 de Setembro X R. Felipe Camarão' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.922275,-43.247679]} icon={camera}><Popup><span className='title-content'>R. Uruguai X R. Maxwell -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000075' alt='R. Uruguai X R. Maxwell' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91837,-43.232143]} icon={camera}><Popup><span className='title-content'>Av. Maracanã X R. Deputado Soares Filho -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000076' alt='Av. Maracanã X R. Deputado Soares Filho' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9186,-43.251042]} icon={camera}><Popup><span className='title-content'>R. Teodoro da Silva X R. Barão de São Francisco -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000077' alt='R. Teodoro da Silva X R. Barão de São Francisco' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908611,-43.238374]} icon={camera}><Popup><span className='title-content'>Av. Pres. Castelo Branco X R. São Francisco Xavier -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000078' alt='Av. Pres. Castelo Branco X R. São Francisco Xavier' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.920197,-43.25966]} icon={camera}><Popup><span className='title-content'>R. Teodoro da Silva X R. Alexandre Calaza -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000079' alt='R. Teodoro da Silva X R. Alexandre Calaza' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.905136,-43.269896]} icon={camera}><Popup><span className='title-content'>Av. Marechal Rondom X R. Barão Do Bom Retiro -  Centro da Cidade - Engenho Novo</span><img src='http://187.111.99.18:9004/?CODE=000080' alt='Av. Marechal Rondom X R. Barão Do Bom Retiro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90246088,-43.27744961]} icon={camera}><Popup><span className='title-content'>R. 24 de Maio X R. Cônego Tobias -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=000082' alt='R. 24 de Maio X R. Cônego Tobias' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.895252,-43.295713]} icon={camera}><Popup><span className='title-content'>R. Arquias Cordeiro X R. José dos Reis (Engenhão) -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=000083' alt='R. Arquias Cordeiro X R. José dos Reis (Engenhão)' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.902057,-43.280339]} icon={camera}><Popup><span className='title-content'>R. Dias da Cruz X  R. Ana Barbosa -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=000085' alt='R. Dias da Cruz X  R. Ana Barbosa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.905236,-43.292852]} icon={camera}><Popup><span className='title-content'>R. Dias da Cruz X R. Maranhão -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=000086' alt='R. Dias da Cruz X R. Maranhão' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.897278,-43.285727]} icon={camera}><Popup><span className='title-content'>R. Amaro Cavalcanti X Viaduto de Todos Os Santos - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=000087' alt='R. Amaro Cavalcanti X Viaduto de Todos Os Santos - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.899336,-43.278542]} icon={camera}><Popup><span className='title-content'>R. Aristides Caire X R. Santa Fé -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=000088' alt='R. Aristides Caire X R. Santa Fé' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.883491,-43.291715]} icon={camera}><Popup><span className='title-content'>Av. Dom Helder Câmara X Viaduto Cristóvão Colombo -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=000089' alt='Av. Dom Helder Câmara X Viaduto Cristóvão Colombo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.887579,-43.285181]} icon={camera}><Popup><span className='title-content'>Av. Dom Helder Câmara X R. Gonzaga de Campos -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=000090' alt='Av. Dom Helder Câmara X R. Gonzaga de Campos' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.887434,-43.23249]} icon={camera}><Popup><span className='title-content'>Av. Brasil X Viaduto Ataulfo Alves -  Zona Norte - Benfica</span><img src='http://187.111.99.18:9004/?CODE=000092' alt='Av. Brasil X Viaduto Ataulfo Alves' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.892969,-43.239578]} icon={camera}><Popup><span className='title-content'>R. Senador Bernardo Monteiro X R. São Luiz Gonzaga -  Zona Norte - Benfica</span><img src='http://187.111.99.18:9004/?CODE=000093' alt='R. Senador Bernardo Monteiro X R. São Luiz Gonzaga' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90029,-43.225348]} icon={camera}><Popup><span className='title-content'>Largo da Cancela X R. São Luiz Gonzaga -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000094' alt='Largo da Cancela X R. São Luiz Gonzaga' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93909,-43.183244]} icon={camera}><Popup><span className='title-content'>R. Pinheiro Machado  Altura da R. Carlos de Campos -  Zona Sul - Flamengo</span><img src='http://187.111.99.18:9004/?CODE=000095' alt='R. Pinheiro Machado  Altura da R. Carlos de Campos' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.933196,-43.184628]} icon={camera}><Popup><span className='title-content'>R. Pinheiro Machado  Altura da R. das Laranjeiras -  Zona Sul - Laranjeiras</span><img src='http://187.111.99.18:9004/?CODE=000096' alt='R. Pinheiro Machado  Altura da R. das Laranjeiras' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.934568,-43.184539]} icon={camera}><Popup><span className='title-content'>Viaduto Eng. Noronha  Sob Viaduto Jardel Filho -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000097' alt='Viaduto Eng. Noronha  Sob Viaduto Jardel Filho' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.919632,-43.194175]} icon={camera}><Popup><span className='title-content'>Av. 31 de Março  Saída Do Túnel Santa Bárbara -  Centro da Cidade - Catumbi</span><img src='http://187.111.99.18:9004/?CODE=000098' alt='Av. 31 de Março  Saída Do Túnel Santa Bárbara' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913982,-43.195426]} icon={camera}><Popup><span className='title-content'>Av. 31 de Março X Praça Apoteose -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=000099' alt='Av. 31 de Março X Praça Apoteose' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911124,-43.196035]} icon={camera}><Popup><span className='title-content'>Av. 31 de Março X Av. Salvador de Sá -  Centro da Cidade - Apoteose</span><img src='http://187.111.99.18:9004/?CODE=000100' alt='Av. 31 de Março X Av. Salvador de Sá' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.907515940614300,-43.19712450181770]} icon={camera}><Popup><span className='title-content'>Av. 31 de Março  Altura da Av. Presidente Vargas -  Centro da Cidade - Praca Onze</span><img src='http://187.111.99.18:9004/?CODE=000101' alt='Av. 31 de Março  Altura da Av. Presidente Vargas' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906448,-43.213027]} icon={camera}><Popup><span className='title-content'>Francisco Eugenio X Figueiredo de Melo X Estação Leopoldina -  Centro da Cidade - Praca da Bandeira</span><img src='http://187.111.99.18:9004/?CODE=000102' alt='Francisco Eugenio X Figueiredo de Melo X Estação Leopoldina' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.897505,-43.218133]} icon={camera}><Popup><span className='title-content'>Linha Vermelha  Km 0 -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000103' alt='Linha Vermelha  Km 0' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906202,-43.213831]} icon={camera}><Popup><span className='title-content'>Vias Elevadas Prof. Eng. Rufino de Almeida  Altura Leopoldina  Pista Superior -  Centro da Cidade - Praca da Bandeira</span><img src='http://187.111.99.18:9004/?CODE=000104' alt='Vias Elevadas Prof. Eng. Rufino de Almeida  Altura Leopoldina  Pista Superior' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.857624,-43.257354]} icon={camera}><Popup><span className='title-content'>R. Cardoso de Moraes X R. Emilio Zaluar - Brt -  Zona Norte - Ramos</span><img src='http://187.111.99.18:9004/?CODE=000105' alt='R. Cardoso de Moraes X R. Emilio Zaluar - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.850865,-43.263564]} icon={camera}><Popup><span className='title-content'>R. Leonídia X R. Vassalo Caruso - Brt -  Zona Norte - Olaria</span><img src='http://187.111.99.18:9004/?CODE=000106' alt='R. Leonídia X R. Vassalo Caruso - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.845602,-43.267863]} icon={camera}><Popup><span className='title-content'>R. Ibiapina X R. Uranos - Brt -  Zona Norte - Olaria</span><img src='http://187.111.99.18:9004/?CODE=000107' alt='R. Ibiapina X R. Uranos - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911078,-43.168378]} icon={camera}><Popup><span className='title-content'>Av. General Justo  Próximo Ao Aeroporto Santos Dumont -  Centro da Cidade - Castelo</span><img src='http://187.111.99.18:9004/?CODE=000108' alt='Av. General Justo  Próximo Ao Aeroporto Santos Dumont' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.842680,-43.271842]} icon={camera}><Popup><span className='title-content'>R. Ibiapina X R. Tomaz Ribeiro - Brt -  Zona Norte - Olaria</span><img src='http://187.111.99.18:9004/?CODE=000109' alt='R. Ibiapina X R. Tomaz Ribeiro - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.950001,-43.177674]} icon={camera}><Popup><span className='title-content'>Av. Venceslau Brás  Próximo Ao Gmar -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000111' alt='Av. Venceslau Brás  Próximo Ao Gmar' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96029,-43.204096]} icon={camera}><Popup><span className='title-content'>Viaduto Saint Hilaire  Saída Do Túnel Rebouças  Sobre A Rua Jardim Botânico -  Zona Sul - Humaita</span><img src='http://187.111.99.18:9004/?CODE=000112' alt='Viaduto Saint Hilaire  Saída Do Túnel Rebouças  Sobre A Rua Jardim Botânico' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.963085,-43.212512]} icon={camera}><Popup><span className='title-content'>Av. Borges de Medeiros  Altura da Av. Lineu de Paula Machado -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000113' alt='Av. Borges de Medeiros  Altura da Av. Lineu de Paula Machado' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96485,-43.21552]} icon={camera}><Popup><span className='title-content'>Av. Borges de  Medeiros  Altura da R. J. J. Seabra -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000114' alt='Av. Borges de  Medeiros  Altura da R. J. J. Seabra' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96773141,-43.21745192]} icon={camera}><Popup><span className='title-content'>Av. Borges de  Medeiros  Altura da R. Gal. Garzon -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000115' alt='Av. Borges de  Medeiros  Altura da R. Gal. Garzon' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.980392,-43.214439]} icon={camera}><Popup><span className='title-content'>Av. Epitácio Pessoa  Próximo Ao Jardim de Alah -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000116' alt='Av. Epitácio Pessoa  Próximo Ao Jardim de Alah' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9744,-43.226147]} icon={camera}><Popup><span className='title-content'>R. Jardim Botânico  Altura da Praça Santos Dumont -  Zona Sul - Gavea</span><img src='http://187.111.99.18:9004/?CODE=000117' alt='R. Jardim Botânico  Altura da Praça Santos Dumont' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.976344,-43.198633]} icon={camera}><Popup><span className='title-content'>Av. Epitácio Pessoa  Próximo Ao Corte Do Cantagalo -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000118' alt='Av. Epitácio Pessoa  Próximo Ao Corte Do Cantagalo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.972396,-43.203072]} icon={camera}><Popup><span className='title-content'>Av. Epitácio Pessoa  Próximo Ao Parque da Catacumba -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000119' alt='Av. Epitácio Pessoa  Próximo Ao Parque da Catacumba' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.992837,-43.253635]} icon={camera}><Popup><span className='title-content'>Autoestrada Lagoa-Barra X Av. Niemeyer -  Zona Sul - Sao Conrado</span><img src='http://187.111.99.18:9004/?CODE=000120' alt='Autoestrada Lagoa-Barra X Av. Niemeyer' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.981412,-43.22647]} icon={camera}><Popup><span className='title-content'>Praça Baden Powell -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=000121' alt='Praça Baden Powell' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.998735,-43.26893]} icon={camera}><Popup><span className='title-content'>Autoestrada X Estr. Do Joá - Próximo Ao Túnel de São Conrado -  Zona Sul - Sao Conrado</span><img src='http://187.111.99.18:9004/?CODE=000122' alt='Autoestrada X Estr. Do Joá - Próximo Ao Túnel de São Conrado' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.002544,-43.275531]} icon={camera}><Popup><span className='title-content'>Autoestrada X Túnel São Conrado -  Zona Sul - Sao Conrado</span><img src='http://187.111.99.18:9004/?CODE=000123' alt='Autoestrada X Túnel São Conrado' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906274,-43.182290]} icon={camera}><Popup><span className='title-content'>Praça Tiradentes X A.Passos -  Centro da Cidade - Praca Tiradentes</span><img src='http://187.111.99.18:9004/?CODE=000124' alt='Praça Tiradentes X A.Passos' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.012415,-43.293128]} icon={camera}><Popup><span className='title-content'>Autoestrada Lagoa-Barra X R. Maria Luiza Pitanga -  Zona Oeste - Joa</span><img src='http://187.111.99.18:9004/?CODE=000126' alt='Autoestrada Lagoa-Barra X R. Maria Luiza Pitanga' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00666760,-43.30903886]} icon={camera}><Popup><span className='title-content'>Av. Armando Lombardi acesso Barra Point -  Zona Oeste - Joa</span><img src='http://187.111.99.18:9004/?CODE=000127' alt='Av. Armando Lombardi acesso Barra Point' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.006850633573300,-43.31362023809810]} icon={camera}><Popup><span className='title-content'>Av. Armando Lombardi -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000128' alt='Av. Armando Lombardi' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.001383,-43.329437]} icon={camera}><Popup><span className='title-content'>Av. das Américas  Próximo Ao Nº 2211  Hotel Dunas -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000129' alt='Av. das Américas  Próximo Ao Nº 2211  Hotel Dunas' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.999886,-43.354569]} icon={camera}><Popup><span className='title-content'>Av. das Américas Altura da Av. Luis Carlos Prestes -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000131' alt='Av. das Américas Altura da Av. Luis Carlos Prestes' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00016481,-43.36935058]} icon={camera}><Popup><span className='title-content'>Av. das Américas x Av. Ayrton Senna - Cebolão - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000132' alt='Av. das Américas x Av. Ayrton Senna - Cebolão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.000405,-43.347326]} icon={camera}><Popup><span className='title-content'>Av. das Américas X R. Jornalista Ricardo Marinho -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000133' alt='Av. das Américas X R. Jornalista Ricardo Marinho' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.003217,-43.324739]} icon={camera}><Popup><span className='title-content'>Av. das Américas X Av. Afonso Arinos de Mello Franco - Eurobarra -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000134' alt='Av. das Américas X Av. Afonso Arinos de Mello Franco - Eurobarra' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.965357,-43.357022]} icon={camera}><Popup><span className='title-content'>Av. Ayrton Senna  Próximo Ao Senac -  Zona Oeste - Gardenia Azul</span><img src='http://187.111.99.18:9004/?CODE=000136' alt='Av. Ayrton Senna  Próximo Ao Senac' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.841588,-43.274756]} icon={camera}><Popup><span className='title-content'>R. Ibiapina X R. Monsenhor Alves - Brt -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=000137' alt='R. Ibiapina X R. Monsenhor Alves - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91563,-43.210022]} icon={camera}><Popup><span className='title-content'>Elevado Paulo de Frontin - Altura da Rua João Paulo I -  Centro da Cidade - Estacio</span><img src='http://187.111.99.18:9004/?CODE=000138' alt='Elevado Paulo de Frontin - Altura da Rua João Paulo I' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.895623,-43.214936]} icon={camera}><Popup><span className='title-content'>Av. Brasil x R. Santos Lima -  Centro da Cidade - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000139' alt='Av. Brasil x R. Santos Lima' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.934877,-43.209638]} icon={camera}><Popup><span className='title-content'>Saída Túnel Rebouças -  Centro da Cidade - Rio Comprido</span><img src='http://187.111.99.18:9004/?CODE=000141' alt='Saída Túnel Rebouças' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908367,-43.23606]} icon={camera}><Popup><span className='title-content'>R. Visconde de Niterói Altura Mangueira -  Zona Norte - Mangueira</span><img src='http://187.111.99.18:9004/?CODE=000142' alt='R. Visconde de Niterói Altura Mangueira' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.83992099,-43.28118452]} icon={camera}><Popup><span className='title-content'>Av. Braz de Pina X R CMTE. CONCEIÇÃO - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=000143' alt='Av. Braz de Pina X R CMTE. CONCEIÇÃO - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.864467,-43.248167]} icon={camera}><Popup><span className='title-content'>Av. Brasil X R. Paris -  Zona Norte - Bonsucesso</span><img src='http://187.111.99.18:9004/?CODE=000146' alt='Av. Brasil X R. Paris' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.847789,-43.246994]} icon={camera}><Popup><span className='title-content'>Av. Brasil X AV. BRIGADEIRO TROMPOWSKI -  Zona Norte - Ramos</span><img src='http://187.111.99.18:9004/?CODE=000147' alt='Av. Brasil X AV. BRIGADEIRO TROMPOWSKI' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.826687,-43.275307]} icon={camera}><Popup><span className='title-content'>Av. Brasil X Av. Lobo Junior -  Zona Norte - Penha Circular</span><img src='http://187.111.99.18:9004/?CODE=000148' alt='Av. Brasil X Av. Lobo Junior' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.815594,-43.288689]} icon={camera}><Popup><span className='title-content'>Av. Brasil X Washington Luiz -  Zona Norte - Cordovil</span><img src='http://187.111.99.18:9004/?CODE=000149' alt='Av. Brasil X Washington Luiz' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911171,-43.205686]} icon={camera}><Popup><span className='title-content'>Prefeitura CASS -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=000150' alt='Prefeitura CASS' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.837788,-43.288355]} icon={camera}><Popup><span className='title-content'>Av. Braz de Pina X Rua Jacarau - Brt -  Zona Norte - Penha Circular</span><img src='http://187.111.99.18:9004/?CODE=000151' alt='Av. Braz de Pina X Rua Jacarau - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.82909,-43.345501]} icon={camera}><Popup><span className='title-content'>Av. Brasil X Automovel Clube -  Zona Norte - Coelho Neto</span><img src='http://187.111.99.18:9004/?CODE=000152' alt='Av. Brasil X Automovel Clube' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.83251628,-43.35410016]} icon={camera}><Popup><span className='title-content'>Av. Brasil X Altura R. João Paulo -  Zona Norte - Barros Filho</span><img src='http://187.111.99.18:9004/?CODE=000153' alt='Av. Brasil X Altura R. João Paulo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86524217,-43.42713159]} icon={camera}><Popup><span className='title-content'>Av. Brasil X Altura Estr. Do Engenho Novo - FIXA -  Zona Norte - Realengo</span><img src='http://187.111.99.18:9004/?CODE=000155' alt='Av. Brasil X Altura Estr. Do Engenho Novo - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.860384,-43.50789800]} icon={camera}><Popup><span className='title-content'>Av. Brasil x Santíssimo -  Zona Norte - Santissimo</span><img src='http://187.111.99.18:9004/?CODE=000156' alt='Av. Brasil x Santíssimo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.861377,-43.541479]} icon={camera}><Popup><span className='title-content'>Av. Brasil X Alt. Km 42 -  Mendanha -  Zona Oeste - Bangu</span><img src='http://187.111.99.18:9004/?CODE=000157' alt='Av. Brasil X Alt. Km 42 -  Mendanha' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.893129,-43.67449199]} icon={camera}><Popup><span className='title-content'>Av. Brasil x Entrada de Santa Cruz -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=000158' alt='Av. Brasil x Entrada de Santa Cruz' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.888427,-43.559933]} icon={camera}><Popup><span className='title-content'>Estr. Do Mendanha X Lgo. da Maçonaria - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=000159' alt='Estr. Do Mendanha X Lgo. da Maçonaria - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.907507,-43.565812]} icon={camera}><Popup><span className='title-content'>Av. Cesario de Melo X Estr. Do Monteiro -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=000160' alt='Av. Cesario de Melo X Estr. Do Monteiro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.890386,-43.223166]} icon={camera}><Popup><span className='title-content'>Linha Vermelha - Km 1 X Pista Superior -  Centro da Cidade - Caju</span><img src='http://187.111.99.18:9004/?CODE=000161' alt='Linha Vermelha - Km 1 X Pista Superior' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89458,-43.219829]} icon={camera}><Popup><span className='title-content'>Linha Vermelha - Km 1 X Pista Inferior -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000162' alt='Linha Vermelha - Km 1 X Pista Inferior' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.807437,-43.288879]} icon={camera}><Popup><span className='title-content'>Linha Vermelha - Km 14 X Rodovia Washington Luiz -  Zona Norte - Cordovil</span><img src='http://187.111.99.18:9004/?CODE=000167' alt='Linha Vermelha - Km 14 X Rodovia Washington Luiz' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.839228,-43.296019]} icon={camera}><Popup><span className='title-content'>Av. Braz de Pina X Av. Vicente de Carvalho - Brt -  Zona Norte - Braz de Pina</span><img src='http://187.111.99.18:9004/?CODE=000168' alt='Av. Braz de Pina X Av. Vicente de Carvalho - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88554119,-43.22631930]} icon={camera}><Popup><span className='title-content'>AV. BRASIL ALTURA DA LINHA VERMELHA -  Zona Norte - Caju</span><img src='http://187.111.99.18:9004/?CODE=000169' alt='AV. BRASIL ALTURA DA LINHA VERMELHA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.930045,-43.237439]} icon={camera}><Popup><span className='title-content'>R. Conde de Bonfim X R. José Higino -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000171' alt='R. Conde de Bonfim X R. José Higino' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.887381,-43.23122]} icon={camera}><Popup><span className='title-content'>Av. Brasil X Gae -  Zona Norte - Benfica</span><img src='http://187.111.99.18:9004/?CODE=000173' alt='Av. Brasil X Gae' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.000582,-43.382231]} icon={camera}><Popup><span className='title-content'>Av. das America X Novo Leblon -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000174' alt='Av. das America X Novo Leblon' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.916124,-43.227038]} icon={camera}><Popup><span className='title-content'>R. São Francisco Xavier X R. General Canabarro -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=000175' alt='R. São Francisco Xavier X R. General Canabarro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90517,-43.241737]} icon={camera}><Popup><span className='title-content'>VIADUTO AGENOR  DE OLIVEIRA -  Zona Norte - Sao Francisco Xavier</span><img src='http://187.111.99.18:9004/?CODE=000176' alt='VIADUTO AGENOR  DE OLIVEIRA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910702,-43.224346]} icon={camera}><Popup><span className='title-content'>Viaduto Oduvaldo Cozzi  x São Francisco Xavier -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000178' alt='Viaduto Oduvaldo Cozzi  x São Francisco Xavier' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.842347,-43.299856]} icon={camera}><Popup><span className='title-content'>Av. Vicente de Carvalho X Rua Caroem - Brt -  Zona Norte - Vila da Penha</span><img src='http://187.111.99.18:9004/?CODE=000179' alt='Av. Vicente de Carvalho X Rua Caroem - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910088,-43.183019]} icon={camera}><Popup><span className='title-content'>Av. Chile X R. Do Lavradio -  Centro da Cidade - Lapa</span><img src='http://187.111.99.18:9004/?CODE=000181' alt='Av. Chile X R. Do Lavradio' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95125490,-43.19447818]} icon={camera}><Popup><span className='title-content'>R. São Clemente, 398 -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000182' alt='R. São Clemente, 398' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.912748,-43.209633]} icon={camera}><Popup><span className='title-content'>Av. Paulo de Frontin X R. Joaquim Palhares -  Centro da Cidade - Estacio</span><img src='http://187.111.99.18:9004/?CODE=000183' alt='Av. Paulo de Frontin X R. Joaquim Palhares' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.845981,-43.302541]} icon={camera}><Popup><span className='title-content'>Av. Vicente de Carvalho X Rua Fláminia - Brt -  Zona Norte - Vila da Penha</span><img src='http://187.111.99.18:9004/?CODE=000184' alt='Av. Vicente de Carvalho X Rua Fláminia - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.852261,-43.315800]} icon={camera}><Popup><span className='title-content'>R. Eng. Mario de Carvalho X Av. Pst Martin Luther King Jr - Brt -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=000185' alt='R. Eng. Mario de Carvalho X Av. Pst Martin Luther King Jr - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.852568,-43.319641]} icon={camera}><Popup><span className='title-content'>R. Eng. Mario de Carvalho X R. Luiza de Carvalho - Brt -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=000186' alt='R. Eng. Mario de Carvalho X R. Luiza de Carvalho - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.856806,-43.329207]} icon={camera}><Popup><span className='title-content'>Av. Edgar Romero X Av. Vicente de Carvalho - Brt -  Zona Norte - Vaz Lobo</span><img src='http://187.111.99.18:9004/?CODE=000187' alt='Av. Edgar Romero X Av. Vicente de Carvalho - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.877333,-43.336211]} icon={camera}><Popup><span className='title-content'>Vd. Pref. Negrão de Lima X Estação Brt Madureira - FIXA -  Zona Norte - Madureira</span><img src='http://187.111.99.18:9004/?CODE=000189' alt='Vd. Pref. Negrão de Lima X Estação Brt Madureira - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.892254,-43.348311]} icon={camera}><Popup><span className='title-content'>R. Candido Benicio X R. Capitão Menezes - Brt -  Zona Norte - Campinho</span><img src='http://187.111.99.18:9004/?CODE=000190' alt='R. Candido Benicio X R. Capitão Menezes - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.896962,-43.351732]} icon={camera}><Popup><span className='title-content'>R. Candido Benicio X R. Baronesa - Brt -  Zona Oeste - Praca Seca</span><img src='http://187.111.99.18:9004/?CODE=000191' alt='R. Candido Benicio X R. Baronesa - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.904349,-43.356801]} icon={camera}><Popup><span className='title-content'>R. Candido Benicio X Brt Ipase -  Zona Oeste - Praca Seca</span><img src='http://187.111.99.18:9004/?CODE=000192' alt='R. Candido Benicio X Brt Ipase' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.912524,-43.360592]} icon={camera}><Popup><span className='title-content'>R. Candido Benicio X R. Godofredo Viana - Brt -  Zona Oeste - Tanque</span><img src='http://187.111.99.18:9004/?CODE=000193' alt='R. Candido Benicio X R. Godofredo Viana - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.987883,-43.194320]} icon={camera}><Popup><span className='title-content'>Tom Jobim -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=000194' alt='Tom Jobim' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.918460,-43.365330]} icon={camera}><Popup><span className='title-content'>Av. Nelson Cardoso X Estr. Do Cafunda - Brt -  Zona Oeste - Tanque</span><img src='http://187.111.99.18:9004/?CODE=000195' alt='Av. Nelson Cardoso X Estr. Do Cafunda - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.929257,-43.37399900]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes X R. André Rocha - Brt -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=000196' alt='Estr. dos Bandeirantes X R. André Rocha - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.958017,-43.381144]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes X R. Soldado José da Costa - Brt -  Zona Oeste - Curicica</span><img src='http://187.111.99.18:9004/?CODE=000198' alt='Estr. dos Bandeirantes X R. Soldado José da Costa - Brt' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.949809,-43.189867]} icon={camera}><Popup><span className='title-content'>R. São Clemente 226 Botafogo -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000199' alt='R. São Clemente 226 Botafogo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.967397,-43.390732]} icon={camera}><Popup><span className='title-content'>Estr. Cel. Pedro Corrêa X Estação Brt Pedro Corrêa -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=000200' alt='Estr. Cel. Pedro Corrêa X Estação Brt Pedro Corrêa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90951664,-43.20381032]} icon={camera}><Popup><span className='title-content'>Av. Pres. Vargas -  Alt. Dos Correios -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=000203' alt='Av. Pres. Vargas -  Alt. Dos Correios' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.902447,-43.175917]} icon={camera}><Popup><span className='title-content'>R. PRIMEIRO DE MARÇO X R. DO OUVIDOR -  Centro da Cidade</span><img src='http://187.111.99.18:9004/?CODE=000204' alt='R. PRIMEIRO DE MARÇO X R. DO OUVIDOR' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913002,-43.191236]} icon={camera}><Popup><span className='title-content'>R. Do Riachuelo X Av. Henriques Valadares -  Centro da Cidade - Carioca</span><img src='http://187.111.99.18:9004/?CODE=000205' alt='R. Do Riachuelo X Av. Henriques Valadares' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.895548,-43.219326]} icon={camera}><Popup><span className='title-content'>R. Bela X Campo de São Cristóvão (Embaixo da Linha Vermelha) -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000206' alt='R. Bela X Campo de São Cristóvão (Embaixo da Linha Vermelha)' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906449,-43.176181]} icon={camera}><Popup><span className='title-content'>R. México X Av. Nilo Peçanha -  Centro da Cidade - Carioca</span><img src='http://187.111.99.18:9004/?CODE=000207' alt='R. México X Av. Nilo Peçanha' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90910393,-43.22229402]} icon={camera}><Popup><span className='title-content'>R. Gen. Herculano Gomes X  R.Alm. Baltazar -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000209' alt='R. Gen. Herculano Gomes X  R.Alm. Baltazar' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906993,-43.217919]} icon={camera}><Popup><span className='title-content'>R. São Cristóvão X R. Francisco Eugênio -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000211' alt='R. São Cristóvão X R. Francisco Eugênio' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.909565,-43.17612600]} icon={camera}><Popup><span className='title-content'>Av. Rio Branco X R. Evaristo da Veiga -  Centro da Cidade - Cinelandia</span><img src='http://187.111.99.18:9004/?CODE=000212' alt='Av. Rio Branco X R. Evaristo da Veiga' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.902976,-43.183893]} icon={camera}><Popup><span className='title-content'>Av. Passos X Av. Pres. Vargas -  Centro da Cidade - Presidente Vargas</span><img src='http://187.111.99.18:9004/?CODE=000213' alt='Av. Passos X Av. Pres. Vargas' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.925058,-43.208885]} icon={camera}><Popup><span className='title-content'>R. Santa Alexandrina X R. da Estrela -  Centro da Cidade - Rio Comprido</span><img src='http://187.111.99.18:9004/?CODE=000214' alt='R. Santa Alexandrina X R. da Estrela' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89275,-43.214221]} icon={camera}><Popup><span className='title-content'>R. Alm. Mariath X Av. Rio de Janeiro -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000217' alt='R. Alm. Mariath X Av. Rio de Janeiro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.892544,-43.216696]} icon={camera}><Popup><span className='title-content'>Into X Av. Brasil -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000218' alt='Into X Av. Brasil' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.907542,-43.199565]} icon={camera}><Popup><span className='title-content'>Av. Presidente Vargas x alt. Sambódromo - Sent. Praça da Bandeira -  Centro da Cidade - Estacio</span><img src='http://187.111.99.18:9004/?CODE=000219' alt='Av. Presidente Vargas x alt. Sambódromo - Sent. Praça da Bandeira' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.907556,-43.174821]} icon={camera}><Popup><span className='title-content'>Av. Alm. Barroso X Av. Graça Aranha -  Centro da Cidade - Carioca</span><img src='http://187.111.99.18:9004/?CODE=000220' alt='Av. Alm. Barroso X Av. Graça Aranha' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.909147,-43.200377]} icon={camera}><Popup><span className='title-content'>R. Benedito Hipólito X R. Carmo Neto -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=000221' alt='R. Benedito Hipólito X R. Carmo Neto' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914365,-43.199465]} icon={camera}><Popup><span className='title-content'>R. Frei Caneca X R. Heitor Carrilho -  Centro da Cidade - Estacio</span><img src='http://187.111.99.18:9004/?CODE=000222' alt='R. Frei Caneca X R. Heitor Carrilho' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911104,-43.192409]} icon={camera}><Popup><span className='title-content'>R. Mem de Sá X R. de Santana - FIXA -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=000224' alt='R. Mem de Sá X R. de Santana - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.912220,-43.188301]} icon={camera}><Popup><span className='title-content'>Praça da Cruz Vermelha -  Centro da Cidade - Lapa</span><img src='http://187.111.99.18:9004/?CODE=000225' alt='Praça da Cruz Vermelha' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90737878,-43.19426186]} icon={camera}><Popup><span className='title-content'>R. Benedito Hipolito X R. Santana -  Centro da Cidade - Praca XI</span><img src='http://187.111.99.18:9004/?CODE=000226' alt='R. Benedito Hipolito X R. Santana' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908966,-43.188710]} icon={camera}><Popup><span className='title-content'>Praça da República X R. Vinte de Abril -  Centro da Cidade - Lapa</span><img src='http://187.111.99.18:9004/?CODE=000228' alt='Praça da República X R. Vinte de Abril' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.905056,-43.217854]} icon={camera}><Popup><span className='title-content'>Av. Pedro Ii X R. São Cristóvão -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000229' alt='Av. Pedro Ii X R. São Cristóvão' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89962,-43.223047]} icon={camera}><Popup><span className='title-content'>R. São Luiz Gonzaga X Campo de São Cristóvão -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000230' alt='R. São Luiz Gonzaga X Campo de São Cristóvão' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89847963,-43.20726840]} icon={camera}><Popup><span className='title-content'>R. Equador X Alt. da Saida dos Onibus -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000232' alt='R. Equador X Alt. da Saida dos Onibus' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.966504,-43.216696]} icon={camera}><Popup><span className='title-content'>Av. Borges de Medeiros X R. Saturnino de Brito -  Zona Sul - Jardim Botanico</span><img src='http://187.111.99.18:9004/?CODE=000235' alt='Av. Borges de Medeiros X R. Saturnino de Brito' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.940188,-43.198325]} icon={camera}><Popup><span className='title-content'>R. Cosme Velho X Igreja S. Judas Tadeu -  Zona Sul - Cosme Velho</span><img src='http://187.111.99.18:9004/?CODE=000236' alt='R. Cosme Velho X Igreja S. Judas Tadeu' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90009425,-43.20830772]} icon={camera}><Popup><span className='title-content'>R. Cmte Garcia Pires x R. Equador -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000237' alt='R. Cmte Garcia Pires x R. Equador' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.983220,-43.228159]} icon={camera}><Popup><span className='title-content'>R. Visconde de Albuquerque X R. Leôncio Corrêa -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=000239' alt='R. Visconde de Albuquerque X R. Leôncio Corrêa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.956816,-43.191987]} icon={camera}><Popup><span className='title-content'>R. Mena Barreto X R. Real Grandeza -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000240' alt='R. Mena Barreto X R. Real Grandeza' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.999332,-43.247810]} icon={camera}><Popup><span className='title-content'>Av. Niemeyer, 393 - São Conrado - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=000241' alt='Av. Niemeyer, 393 - São Conrado - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96065734,-43.20797045]} icon={camera}><Popup><span className='title-content'>R. Jardim Botanico X R. Maria Angélica -  Zona Sul - Humaita</span><img src='http://187.111.99.18:9004/?CODE=000242' alt='R. Jardim Botanico X R. Maria Angélica' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.962938,-43.211589]} icon={camera}><Popup><span className='title-content'>Av. Borges de Medeiros X R. Lineu de Paula Machado -  Zona Sul - Jardim Botanico</span><img src='http://187.111.99.18:9004/?CODE=000243' alt='Av. Borges de Medeiros X R. Lineu de Paula Machado' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.986178,-43.209329]} icon={camera}><Popup><span className='title-content'>Av. Vieira Souto X R. Garcia D´ávila -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=000244' alt='Av. Vieira Souto X R. Garcia D´ávila' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.989077,-43.227988]} icon={camera}><Popup><span className='title-content'>Av. Delfim Moreira X Av. Niemeyer -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=000245' alt='Av. Delfim Moreira X Av. Niemeyer' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.902296,-43.181304]} icon={camera}><Popup><span className='title-content'>Av. Presidente Vargas X R. Uruguaiana -  Centro da Cidade - Uruguaiana</span><img src='http://187.111.99.18:9004/?CODE=000247' alt='Av. Presidente Vargas X R. Uruguaiana' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.979009,-43.218498]} icon={camera}><Popup><span className='title-content'>R. Gilberto Cardoso X Av. Afrânio de Melo Franco -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=000249' alt='R. Gilberto Cardoso X Av. Afrânio de Melo Franco' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.976922,-43.23055]} icon={camera}><Popup><span className='title-content'>Rua Marquês de São Vicente X R. Vice-Gov. Rubens Berardo - FIXA -  Zona Sul - Gavea</span><img src='http://187.111.99.18:9004/?CODE=000250' alt='Rua Marquês de São Vicente X R. Vice-Gov. Rubens Berardo - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98375,-43.194079]} icon={camera}><Popup><span className='title-content'>R. Bulhões de Carvalho X R. Francisco Sá -  Zona Sul - General Osorio</span><img src='http://187.111.99.18:9004/?CODE=000252' alt='R. Bulhões de Carvalho X R. Francisco Sá' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.987207,-43.191612]} icon={camera}><Popup><span className='title-content'>R. Bulhões de Carvalho X R. Francisco Otaviano -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=000253' alt='R. Bulhões de Carvalho X R. Francisco Otaviano' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.977439,-43.192835]} icon={camera}><Popup><span className='title-content'>R. Miguel Lemos X R. Barata Ribeiro -  Zona Sul - Cantagalo</span><img src='http://187.111.99.18:9004/?CODE=000254' alt='R. Miguel Lemos X R. Barata Ribeiro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.968163,-43.187943]} icon={camera}><Popup><span className='title-content'>R. Tonelero X R. Figueiredo Magalhães -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=000255' alt='R. Tonelero X R. Figueiredo Magalhães' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97589930,-43.18782909]} icon={camera}><Popup><span className='title-content'>Av. Atlântica x R Bolivar - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000256' alt='Av. Atlântica x R Bolivar - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.963323,-43.170004]} icon={camera}><Popup><span className='title-content'>Av. Atlântica X R. Anchieta -  Zona Sul - Leme</span><img src='http://187.111.99.18:9004/?CODE=000257' alt='Av. Atlântica X R. Anchieta' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.956348,-43.185979]} icon={camera}><Popup><span className='title-content'>R. Gal. Polidoro X R. Paulo Barreto -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000258' alt='R. Gal. Polidoro X R. Paulo Barreto' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.970898,-43.217291]} icon={camera}><Popup><span className='title-content'>Av. Borges Medeiros X Altura Do Parque dos Patins -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000259' alt='Av. Borges Medeiros X Altura Do Parque dos Patins' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.951251,-43.184273]} icon={camera}><Popup><span className='title-content'>R. Voluntários da Pátria X R. Nelson Mandela -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000260' alt='R. Voluntários da Pátria X R. Nelson Mandela' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.953172,-43.188536]} icon={camera}><Popup><span className='title-content'>R. Voluntários da Pátria X R. Dona Mariana -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000261' alt='R. Voluntários da Pátria X R. Dona Mariana' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94962,-43.188759]} icon={camera}><Popup><span className='title-content'>R. São Clemente X R. Guilhermina Guinle -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000262' alt='R. São Clemente X R. Guilhermina Guinle' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.947694,-43.182621]} icon={camera}><Popup><span className='title-content'>Praia de Botafogo X R. Prof. Alfredo Gomes -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000263' alt='Praia de Botafogo X R. Prof. Alfredo Gomes' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.948139,-43.182033]} icon={camera}><Popup><span className='title-content'>AV. DAS NAÇÕES UNIDAS X ALT. BOTAFOGO PRAIA SHOPPING - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000264' alt='AV. DAS NAÇÕES UNIDAS X ALT. BOTAFOGO PRAIA SHOPPING - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.982338,-43.223948]} icon={camera}><Popup><span className='title-content'>R. Humberto de Campos X Av. Bartolomeu Mitre -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=000265' alt='R. Humberto de Campos X Av. Bartolomeu Mitre' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93817201,-43.19062690]} icon={camera}><Popup><span className='title-content'>R. das Laranjeiras X Praça David Ben Gurion -  Zona Sul - Laranjeiras</span><img src='http://187.111.99.18:9004/?CODE=000266' alt='R. das Laranjeiras X Praça David Ben Gurion' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.992613,-43.251731]} icon={camera}><Popup><span className='title-content'>Auto Est. Lagoa Barra -  Zona Sul - Sao Conrado</span><img src='http://187.111.99.18:9004/?CODE=000267' alt='Auto Est. Lagoa Barra' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.931059,-43.177708]} icon={camera}><Popup><span className='title-content'>R. Do Catete  X R. das Laranjeiras -  Zona Sul - Largo do Machado</span><img src='http://187.111.99.18:9004/?CODE=000268' alt='R. Do Catete  X R. das Laranjeiras' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.958034,-43.191192]} icon={camera}><Popup><span className='title-content'>R. Real Grandeza  X R. Gal Polidoro -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000269' alt='R. Real Grandeza  X R. Gal Polidoro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.983701,-43.213552]} icon={camera}><Popup><span className='title-content'>R. Visconde de Pirajá X Av. Henrique Dumont -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=000270' alt='R. Visconde de Pirajá X Av. Henrique Dumont' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984649,-43.198693]} icon={camera}><Popup><span className='title-content'>R. Visc. de Pirajá X R. Teixeira de Melo (Pça. Gal. Osório) -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=000272' alt='R. Visc. de Pirajá X R. Teixeira de Melo (Pça. Gal. Osório)' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984552,-43.202878]} icon={camera}><Popup><span className='title-content'>R. Visconde de Pirajá X R. Vinícius de Moraes -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=000273' alt='R. Visconde de Pirajá X R. Vinícius de Moraes' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.976522,-43.198178]} icon={camera}><Popup><span className='title-content'>Corte de Cantagalo -  Zona Sul - Cantagalo</span><img src='http://187.111.99.18:9004/?CODE=000275' alt='Corte de Cantagalo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.986577,-43.203073]} icon={camera}><Popup><span className='title-content'>Av. Vieira Souto X R. Vinícius de Moraes -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=000276' alt='Av. Vieira Souto X R. Vinícius de Moraes' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.962256,-43.176012]} icon={camera}><Popup><span className='title-content'>Rua Barata Ribeiro X R. Prado Junior -  Zona Sul - Leme</span><img src='http://187.111.99.18:9004/?CODE=000277' alt='Rua Barata Ribeiro X R. Prado Junior' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.967156,-43.18629]} icon={camera}><Popup><span className='title-content'>R. Tonelero X R. Siqueira Campos -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000278' alt='R. Tonelero X R. Siqueira Campos' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.954951,-43.187384]} icon={camera}><Popup><span className='title-content'>R. Mena Barreto X R. Dª Mariana -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000279' alt='R. Mena Barreto X R. Dª Mariana' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984847,-43.211492]} icon={camera}><Popup><span className='title-content'>R. Prudente de Morais X R. Anibal de Mendonça -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=000281' alt='R. Prudente de Morais X R. Anibal de Mendonça' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.968746,-43.183737]} icon={camera}><Popup><span className='title-content'>Av. N. Sra. de Copacabana X R. Hilário de Gouveia -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000282' alt='Av. N. Sra. de Copacabana X R. Hilário de Gouveia' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96739308,-43.18194752]} icon={camera}><Popup><span className='title-content'>AV Nossa Senhora de Copacabana X Republica no Peru -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000283' alt='AV Nossa Senhora de Copacabana X Republica no Peru' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.966257,-43.178962]} icon={camera}><Popup><span className='title-content'>Av. N. Sra. de Copacabana X R. Rodolfo Dantas -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000284' alt='Av. N. Sra. de Copacabana X R. Rodolfo Dantas' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984728,-43.217651]} icon={camera}><Popup><span className='title-content'>Av. Gal. San Martin X Av. Afrânio de Melo Franco -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=000285' alt='Av. Gal. San Martin X Av. Afrânio de Melo Franco' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.964232,-43.17495]} icon={camera}><Popup><span className='title-content'>Av. N. Sra. de Copacabana X R. Prado Junior -  Zona Sul - Leme</span><img src='http://187.111.99.18:9004/?CODE=000286' alt='Av. N. Sra. de Copacabana X R. Prado Junior' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984856,-43.190283]} icon={camera}><Popup><span className='title-content'>Av. N. Sra. de Copacabana X Av. Rainha Elisabeth -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000287' alt='Av. N. Sra. de Copacabana X Av. Rainha Elisabeth' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98293,-43.190882]} icon={camera}><Popup><span className='title-content'>Av. N. Sra. de Copacabana X R. Francisco Sá -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000288' alt='Av. N. Sra. de Copacabana X R. Francisco Sá' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.980866,-43.191258]} icon={camera}><Popup><span className='title-content'>Av. N. Sra. de Copacabana X R. Sá Ferreira -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000289' alt='Av. N. Sra. de Copacabana X R. Sá Ferreira' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.974051,-43.189123]} icon={camera}><Popup><span className='title-content'>Av. N. Sra. de Copacabana X R. Constante Ramos -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000290' alt='Av. N. Sra. de Copacabana X R. Constante Ramos' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.969131,-43.180741]} icon={camera}><Popup><span className='title-content'>Av. Atlântica X R. Rep. Do Peru - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000291' alt='Av. Atlântica X R. Rep. Do Peru - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.970583,-43.183404]} icon={camera}><Popup><span className='title-content'>Av. Atlântica X R. Siqueira Campos -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000292' alt='Av. Atlântica X R. Siqueira Campos' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97817912,-43.18856240]} icon={camera}><Popup><span className='title-content'>Av. Atlântica X R. Miguel Lemos - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000293' alt='Av. Atlântica X R. Miguel Lemos - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.970376,-43.188329]} icon={camera}><Popup><span className='title-content'>R. Barata Ribeiro X R. Santa Clara -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000294' alt='R. Barata Ribeiro X R. Santa Clara' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.977952,-43.190786]} icon={camera}><Popup><span className='title-content'>Av. N. Sra. de Copacabana X R. Miguel Lemos -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000295' alt='Av. N. Sra. de Copacabana X R. Miguel Lemos' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.964755,-43.179553]} icon={camera}><Popup><span className='title-content'>R. Barata Ribeiro X R. Rodolfo Dantas -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000296' alt='R. Barata Ribeiro X R. Rodolfo Dantas' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.950095,-43.190989]} icon={camera}><Popup><span className='title-content'>R. São Clemente X R. Sorocaba -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000297' alt='R. São Clemente X R. Sorocaba' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.979536,-43.202644]} icon={camera}><Popup><span className='title-content'>R. Epitacio Pessoa X R. Vinicius de Morais -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000298' alt='R. Epitacio Pessoa X R. Vinicius de Morais' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.946188,-43.182567]} icon={camera}><Popup><span className='title-content'>Praia de Botafogo X R. Visc. de Ouro Preto -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000299' alt='Praia de Botafogo X R. Visc. de Ouro Preto' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.949047,-43.182428]} icon={camera}><Popup><span className='title-content'>Praia de Botafogo X R. São Clemente -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000300' alt='Praia de Botafogo X R. São Clemente' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.985222,-43.222071]} icon={camera}><Popup><span className='title-content'>Av. Gal. San Martin X R. João Lira - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=000301' alt='Av. Gal. San Martin X R. João Lira - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.943791,-43.183737]} icon={camera}><Popup><span className='title-content'>R. Muniz Barreto X R. Marques de Olinda -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000302' alt='R. Muniz Barreto X R. Marques de Olinda' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.947813,-43.184209]} icon={camera}><Popup><span className='title-content'>R. Muniz Barreto X R. Alfredo Gomes -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000303' alt='R. Muniz Barreto X R. Alfredo Gomes' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.939691,-43.202062]} icon={camera}><Popup><span className='title-content'>R. Cosme Velho X Acesso Túnel Rebouças -  Centro da Cidade - Cosme Velho</span><img src='http://187.111.99.18:9004/?CODE=000304' alt='R. Cosme Velho X Acesso Túnel Rebouças' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.953009,-43.172418]} icon={camera}><Popup><span className='title-content'>Av. Pasteur X Alt. Instituto Benjamim Constant -  Zona Sul - Urca</span><img src='http://187.111.99.18:9004/?CODE=000305' alt='Av. Pasteur X Alt. Instituto Benjamim Constant' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95134,-43.175154]} icon={camera}><Popup><span className='title-content'>Av. Pasteur X R. Venceslau -  Zona Sul - Urca</span><img src='http://187.111.99.18:9004/?CODE=000306' alt='Av. Pasteur X R. Venceslau' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.938604,-43.173695]} icon={camera}><Popup><span className='title-content'>Praia Do Flamengo X Av. Oswaldo Cruz - FIXA -  Zona Sul - Flamengo</span><img src='http://187.111.99.18:9004/?CODE=000308' alt='Praia Do Flamengo X Av. Oswaldo Cruz - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.930591,-43.179231]} icon={camera}><Popup><span className='title-content'>Largo do Machado x Bento Lisboa -  Zona Sul - Largo do Machado</span><img src='http://187.111.99.18:9004/?CODE=000310' alt='Largo do Machado x Bento Lisboa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.930077,-43.174478]} icon={camera}><Popup><span className='title-content'>Praia Do Flamengo X R. Dois de Dezembro -  Zona Sul - Flamengo</span><img src='http://187.111.99.18:9004/?CODE=000311' alt='Praia Do Flamengo X R. Dois de Dezembro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.923635,-43.177375]} icon={camera}><Popup><span className='title-content'>R. Pedro Américo X R. do Catete -  Zona Sul - Catete</span><img src='http://187.111.99.18:9004/?CODE=000312' alt='R. Pedro Américo X R. do Catete' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.925769,-43.176602]} icon={camera}><Popup><span className='title-content'>R. Do Catete X R. Silveira Martins -  Zona Sul - Catete</span><img src='http://187.111.99.18:9004/?CODE=000313' alt='R. Do Catete X R. Silveira Martins' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.927656,-43.173941]} icon={camera}><Popup><span className='title-content'>Praia Do Flamengo X R. Ferreira Viana -  Zona Sul - Flamengo</span><img src='http://187.111.99.18:9004/?CODE=000314' alt='Praia Do Flamengo X R. Ferreira Viana' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.920482,-43.177031]} icon={camera}><Popup><span className='title-content'>R. da Glória X R. Benjamim Constant -  Zona Sul - Gloria</span><img src='http://187.111.99.18:9004/?CODE=000315' alt='R. da Glória X R. Benjamim Constant' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95212,-43.182288]} icon={camera}><Popup><span className='title-content'>R. Gal. Polidoro X R. da Passagem -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000318' alt='R. Gal. Polidoro X R. da Passagem' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95451562,-43.18112382]} icon={camera}><Popup><span className='title-content'>R. da Passagem X R. Arnaldo Quintela -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000319' alt='R. da Passagem X R. Arnaldo Quintela' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96455461,-43.19058382]} icon={camera}><Popup><span className='title-content'>Praça Vereador Rocha Leão, 50 -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000320' alt='Praça Vereador Rocha Leão, 50 -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.985582,-43.198693]} icon={camera}><Popup><span className='title-content'>R. Prudente de Morais X R. Teixeira de Melo -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=000321' alt='R. Prudente de Morais X R. Teixeira de Melo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95318721,-43.17743397]} icon={camera}><Popup><span className='title-content'>R. Gal. Severiano X Pça. Ozanan -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000322' alt='R. Gal. Severiano X Pça. Ozanan' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.972322,-43.191944]} icon={camera}><Popup><span className='title-content'>R. Constante Ramos X R. Pompeu Loureiro -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000323' alt='R. Constante Ramos X R. Pompeu Loureiro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.975532,-43.193532]} icon={camera}><Popup><span className='title-content'>R. Pompeu Loureiro X R. Bolivar -  Zona Sul - Cantagalo</span><img src='http://187.111.99.18:9004/?CODE=000324' alt='R. Pompeu Loureiro X R. Bolivar' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.958226,-43.196848]} icon={camera}><Popup><span className='title-content'>R. Visc. Silva X Largo Do Ibam -  Zona Sul - Humaita</span><img src='http://187.111.99.18:9004/?CODE=000325' alt='R. Visc. Silva X Largo Do Ibam' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96144335,-43.20486169]} icon={camera}><Popup><span className='title-content'>R. Jardim Botanico X R. Prof. Saldanha - FIXA -  Zona Sul - Jardim Botanico</span><img src='http://187.111.99.18:9004/?CODE=000326' alt='R. Jardim Botanico X R. Prof. Saldanha - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99236313,-43.25165276]} icon={camera}><Popup><span className='title-content'>Auto Estr. Lagoa-Barra X Est. da Gávea -  Zona Sul - Sao Conrado</span><img src='http://187.111.99.18:9004/?CODE=000328' alt='Auto Estr. Lagoa-Barra X Est. da Gávea' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99805801,-43.26732513]} icon={camera}><Popup><span className='title-content'>Auto Estr. Lagoa-Barra X Alt. Gávea Golf Clube -  Zona Sul - Sao Conrado</span><img src='http://187.111.99.18:9004/?CODE=000329' alt='Auto Estr. Lagoa-Barra X Alt. Gávea Golf Clube' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.985163,-43.207175]} icon={camera}><Popup><span className='title-content'>R. Prudente de Morais X R. Maria Quiteria -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=000330' alt='R. Prudente de Morais X R. Maria Quiteria' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.973053,-43.203244]} icon={camera}><Popup><span className='title-content'>Av. Epitacio Pessoa X Alt. Do Parque da Catacumba -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000331' alt='Av. Epitacio Pessoa X Alt. Do Parque da Catacumba' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.981409,-43.22117]} icon={camera}><Popup><span className='title-content'>R. Adalberto Ferreira X R. Cupertino Durão -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=000332' alt='R. Adalberto Ferreira X R. Cupertino Durão' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.923061,-43.231072]} icon={camera}><Popup><span className='title-content'>R. Conde de Bonfim X R. Almirante Cochrane -  Zona Norte - Sao Francisco Xavier</span><img src='http://187.111.99.18:9004/?CODE=000333' alt='R. Conde de Bonfim X R. Almirante Cochrane' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.921915,-43.221416]} icon={camera}><Popup><span className='title-content'>R. Conde de Bonfim X R. São Francisco Xavier -  Zona Norte - Sao Francisco Xavier</span><img src='http://187.111.99.18:9004/?CODE=000334' alt='R. Conde de Bonfim X R. São Francisco Xavier' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.925631,-43.23494]} icon={camera}><Popup><span className='title-content'>Rua Conde de Bonfim X Rua Pinto Figueiredo -  Zona Norte - Saens Pena</span><img src='http://187.111.99.18:9004/?CODE=000335' alt='Rua Conde de Bonfim X Rua Pinto Figueiredo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908225,-43.219909]} icon={camera}><Popup><span className='title-content'>Predio Ipiranga -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000336' alt='Predio Ipiranga' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.924237,-43.240396]} icon={camera}><Popup><span className='title-content'>R. Barão de Mesquita X R. José Higino -  Zona Norte - Saens Pena</span><img src='http://187.111.99.18:9004/?CODE=000337' alt='R. Barão de Mesquita X R. José Higino' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.923931,-43.251908]} icon={camera}><Popup><span className='title-content'>Rua Barão de Mesquita X Rua Paula Brito -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=000338' alt='Rua Barão de Mesquita X Rua Paula Brito' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913763,-43.234355]} icon={camera}><Popup><span className='title-content'>R. São Francisco Xavier X Av. Prof. Manoel de Abreu -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=000339' alt='R. São Francisco Xavier X Av. Prof. Manoel de Abreu' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92050585,-43.21674664]} icon={camera}><Popup><span className='title-content'>R. Haddock Lobo X R. Engenheiro Adel -  Zona Norte - Sao Francisco Xavier</span><img src='http://187.111.99.18:9004/?CODE=000341' alt='R. Haddock Lobo X R. Engenheiro Adel' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.916006,-43.2515]} icon={camera}><Popup><span className='title-content'>Rua Visc. de Santa Izabel X Barão de São Francisco -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=000342' alt='Rua Visc. de Santa Izabel X Barão de São Francisco' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93441891,-43.24311448]} icon={camera}><Popup><span className='title-content'>Rua Conde de Bonfim X R. Radmaker -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000344' alt='Rua Conde de Bonfim X R. Radmaker' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.912302,-43.226630]} icon={camera}><Popup><span className='title-content'>Av. Maracanã X Mata Machado -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=000345' alt='Av. Maracanã X Mata Machado' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.928457,-43.239731]} icon={camera}><Popup><span className='title-content'>Av. Maracanã X R. José Higino -  Zona Norte - Saens Pena</span><img src='http://187.111.99.18:9004/?CODE=000347' alt='Av. Maracanã X R. José Higino' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9304736,-43.24212]} icon={camera}><Popup><span className='title-content'>Rua Uruguai  X Av. Maracanã -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000349' alt='Rua Uruguai  X Av. Maracanã' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.916935,-43.262422]} icon={camera}><Popup><span className='title-content'>Grajaú-Jacarepaguá (Subida Grajaú) -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=000351' alt='Grajaú-Jacarepaguá (Subida Grajaú)' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.917582,-43.245506]} icon={camera}><Popup><span className='title-content'>R. Teodoro da Silva X R. Sousa Franco -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=000352' alt='R. Teodoro da Silva X R. Sousa Franco' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.916767,-43.241801]} icon={camera}><Popup><span className='title-content'>R. Teodoro da Silva X R. Gonzaga Bastos -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=000353' alt='R. Teodoro da Silva X R. Gonzaga Bastos' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915699,-43.235321]} icon={camera}><Popup><span className='title-content'>R. Professor Manoel de Abreu X R. Felipe Camarao -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=000354' alt='R. Professor Manoel de Abreu X R. Felipe Camarao' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.919689,-43.233926]} icon={camera}><Popup><span className='title-content'>Av. Maracanã X R. Major Ávila -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=000355' alt='Av. Maracanã X R. Major Ávila' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.916451,-43.25003]} icon={camera}><Popup><span className='title-content'>Boulevard 28 de Setembro X Pça Barão de Drumond -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=000356' alt='Boulevard 28 de Setembro X Pça Barão de Drumond' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915393,-43.242037]} icon={camera}><Popup><span className='title-content'>Boulevard 28 de Setembro X R. Gonzaga Bastos -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=000357' alt='Boulevard 28 de Setembro X R. Gonzaga Bastos' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.912127,-43.233954]} icon={camera}><Popup><span className='title-content'>R. Professor Eurico Rabelo X R. Rad. Valdir Amaral -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=000358' alt='R. Professor Eurico Rabelo X R. Rad. Valdir Amaral' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910967,-43.238104]} icon={camera}><Popup><span className='title-content'>R. São Francisco Xavier X R. Luiz de Matos -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=000359' alt='R. São Francisco Xavier X R. Luiz de Matos' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914306,-43.219056]} icon={camera}><Popup><span className='title-content'>R. Mariz E Barros X R. Campos Sales -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=000361' alt='R. Mariz E Barros X R. Campos Sales' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91119,-43.216786]} icon={camera}><Popup><span className='title-content'>R. Teixeira Soares X R. Pará X R. Paraíba -  Zona Norte - Praca da Bandeira</span><img src='http://187.111.99.18:9004/?CODE=000362' alt='R. Teixeira Soares X R. Pará X R. Paraíba' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.82214057,-43.31976235]} icon={camera}><Popup><span className='title-content'>Av. Brasil X Trevo das Margaridas -  Zona Norte - Vista Alegre</span><img src='http://187.111.99.18:9004/?CODE=000363' alt='Av. Brasil X Trevo das Margaridas' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.916885,-43.260158]} icon={camera}><Popup><span className='title-content'>R. Visconde de Santa Isabel X R. Alexandre Calaza -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=000364' alt='R. Visconde de Santa Isabel X R. Alexandre Calaza' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.918308,-43.217307]} icon={camera}><Popup><span className='title-content'>R. Dr. Satamini X R. Campos Sales -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=000365' alt='R. Dr. Satamini X R. Campos Sales' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.922062,-43.237368]} icon={camera}><Popup><span className='title-content'>R. Pereira Nunes X R. Baltazar Lisboa -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=000366' alt='R. Pereira Nunes X R. Baltazar Lisboa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.912639,-43.215954]} icon={camera}><Popup><span className='title-content'>R. Mariz E Barros X R. Felisberto de Menezes - FIXA -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=000367' alt='R. Mariz E Barros X R. Felisberto de Menezes - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.938841,-43.247659]} icon={camera}><Popup><span className='title-content'>R. Conde de Bonfim X R. Basileia - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000368' alt='R. Conde de Bonfim X R. Basileia - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.925154,-43.225606]} icon={camera}><Popup><span className='title-content'>R. Conde de Bonfim X R. dos Araújos - FIXA -  Zona Norte - Sao Francisco Xavier</span><img src='http://187.111.99.18:9004/?CODE=000369' alt='R. Conde de Bonfim X R. dos Araújos - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.921968,-43.230195]} icon={camera}><Popup><span className='title-content'>R. Almirante Cochrane X R. Pareto -  Zona Norte - Sao Francisco Xavier</span><img src='http://187.111.99.18:9004/?CODE=000370' alt='R. Almirante Cochrane X R. Pareto' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.884797,-43.298447]} icon={camera}><Popup><span className='title-content'>Av. D. Helder Câmara X R. da Abolição -  Zona Norte - Abolicao</span><img src='http://187.111.99.18:9004/?CODE=000373' alt='Av. D. Helder Câmara X R. da Abolição' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.895729,-43.294817]} icon={camera}><Popup><span className='title-content'>Av. Amaro Cavalcante X Estação Ferroviaria Do Engenho de Dentro -  Zona Norte - Engenho de Dentro</span><img src='http://187.111.99.18:9004/?CODE=000378' alt='Av. Amaro Cavalcante X Estação Ferroviaria Do Engenho de Dentro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89904457,-43.28062217]} icon={camera}><Popup><span className='title-content'>R. Arquias Cordeiro x R. Coração de Maria - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=000380' alt='R. Arquias Cordeiro x R. Coração de Maria - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.895291,-43.274074]} icon={camera}><Popup><span className='title-content'>R. Aristides Caire X R. Capitão Rezende -  Zona Norte - Cachambi</span><img src='http://187.111.99.18:9004/?CODE=000381' alt='R. Aristides Caire X R. Capitão Rezende' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88668057,-43.28010564]} icon={camera}><Popup><span className='title-content'>Av. Dom Helder Camara x R. Pedras Altas X R. Silva Mourão - Fixa -  Zona Norte - Cachambi</span><img src='http://187.111.99.18:9004/?CODE=000382' alt='Av. Dom Helder Camara x R. Pedras Altas X R. Silva Mourão - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.904789,-43.288912]} icon={camera}><Popup><span className='title-content'>R. Dias da Cruz X R. Vilela Tavares -  Zona Norte - Engenho de Dentro</span><img src='http://187.111.99.18:9004/?CODE=000384' alt='R. Dias da Cruz X R. Vilela Tavares' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86797353,-43.34119058]} icon={camera}><Popup><span className='title-content'>Parque de Madureira Palco Praça Do Samba -  Zona Norte - Madureira</span><img src='http://187.111.99.18:9004/?CODE=000386' alt='Parque de Madureira Palco Praça Do Samba' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.903837,-43.278715]} icon={camera}><Popup><span className='title-content'>R. Hemengarda x Joaquim Meier -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=000388' alt='R. Hemengarda x Joaquim Meier' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86537704,-43.34391449]} icon={camera}><Popup><span className='title-content'>Parque de Madureira -  Zona Norte - Madureira</span><img src='http://187.111.99.18:9004/?CODE=000389' alt='Parque de Madureira' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86391126,-43.34562848]} icon={camera}><Popup><span className='title-content'>Parque Madureira - Predio da Administração -  Zona Norte - Madureira</span><img src='http://187.111.99.18:9004/?CODE=000390' alt='Parque Madureira - Predio da Administração' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.891867,-43.287808]} icon={camera}><Popup><span className='title-content'>R. Piaui X R. Dr. Padilha -  Zona Norte - Engenho de Dentro</span><img src='http://187.111.99.18:9004/?CODE=000391' alt='R. Piaui X R. Dr. Padilha' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88488391,-43.27794905]} icon={camera}><Popup><span className='title-content'>D.Helder Camara x R. Cachambi - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=000392' alt='D.Helder Camara x R. Cachambi - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906716,-43.276305]} icon={camera}><Popup><span className='title-content'>R. Hemengarda X R. Lins de Vasconcelos -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=000393' alt='R. Hemengarda X R. Lins de Vasconcelos' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.903605,-43.284321]} icon={camera}><Popup><span className='title-content'>R. Dias da Cruz X R. Magalhaes Couto -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=000394' alt='R. Dias da Cruz X R. Magalhaes Couto' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.899907,-43.281401]} icon={camera}><Popup><span className='title-content'>R. Medina X R. Silva Rabelo -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=000395' alt='R. Medina X R. Silva Rabelo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86239608,-43.34684248]} icon={camera}><Popup><span className='title-content'>Parque de Madureira - Pista de Skate - FIXA -  Zona Norte - Madureira</span><img src='http://187.111.99.18:9004/?CODE=000396' alt='Parque de Madureira - Pista de Skate - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86162286,-43.34668336]} icon={camera}><Popup><span className='title-content'>Parque Madureira - Quadras - FIXA -  Zona Norte - Madureira</span><img src='http://187.111.99.18:9004/?CODE=000397' alt='Parque Madureira - Quadras - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.87964422,-43.34171860]} icon={camera}><Popup><span className='title-content'>R. Domingos Lopes X R. Maria Lopes - FIXA -  Zona Norte - Madureira</span><img src='http://187.111.99.18:9004/?CODE=000398' alt='R. Domingos Lopes X R. Maria Lopes - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.904344,-43.272722]} icon={camera}><Popup><span className='title-content'>R. Vinte E Quatro de Maio X R. Lins de Vasconcelos -  Zona Norte - Engenho Novo</span><img src='http://187.111.99.18:9004/?CODE=000401' alt='R. Vinte E Quatro de Maio X R. Lins de Vasconcelos' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.822964,-43.230965]} icon={camera}><Popup><span className='title-content'>Estrada Do Galeão X Alt. Rua Vinte de Janeiro -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=000404' alt='Estrada Do Galeão X Alt. Rua Vinte de Janeiro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97315994,-43.39799721]} icon={camera}><Popup><span className='title-content'>Abelardo Bueno - Em Frente 3600 -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=000405' alt='Abelardo Bueno - Em Frente 3600' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.972959,-43.392742]} icon={camera}><Popup><span className='title-content'>Abelardo Bueno X R. Jorge Faraj -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=000406' alt='Abelardo Bueno X R. Jorge Faraj' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.865914,-43.255459]} icon={camera}><Popup><span className='title-content'>R. CARDOSO DE MORAES X RUA DONA ISABEL -  Zona Norte - Bonsucesso</span><img src='http://187.111.99.18:9004/?CODE=000407' alt='R. CARDOSO DE MORAES X RUA DONA ISABEL' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.973264,-43.378744]} icon={camera}><Popup><span className='title-content'>R. Abelardo Bueno X R. Arroio Pavuna -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=000410' alt='R. Abelardo Bueno X R. Arroio Pavuna' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.970977,-43.388803]} icon={camera}><Popup><span className='title-content'>R. Cel. Pedro Correia X R. Aroazes -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=000411' alt='R. Cel. Pedro Correia X R. Aroazes' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.865291,-43.252775]} icon={camera}><Popup><span className='title-content'>Av. Nova York X Av. Bruxelas -  Zona Norte - Bonsucesso</span><img src='http://187.111.99.18:9004/?CODE=000412' alt='Av. Nova York X Av. Bruxelas' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.853566,-43.252155]} icon={camera}><Popup><span className='title-content'>Av. Teixeira de Castro X R. Barreiros -  Zona Norte - Ramos</span><img src='http://187.111.99.18:9004/?CODE=000414' alt='Av. Teixeira de Castro X R. Barreiros' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86275,-43.253951]} icon={camera}><Popup><span className='title-content'>R. Cardoso de Moraes X R. Teixeira de Castro X R. Bonsucesso -  Zona Norte - Bonsucesso</span><img src='http://187.111.99.18:9004/?CODE=000415' alt='R. Cardoso de Moraes X R. Teixeira de Castro X R. Bonsucesso' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.852548,-43.261778]} icon={camera}><Popup><span className='title-content'>R. Leopoldina Rego X Viad. de Ramos -  Zona Norte - Ramos</span><img src='http://187.111.99.18:9004/?CODE=000416' alt='R. Leopoldina Rego X Viad. de Ramos' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.847387,-43.265759]} icon={camera}><Popup><span className='title-content'>R. Leopoldina Rego X R. Dr. Alfredo Barcelos -  Zona Norte - Olaria</span><img src='http://187.111.99.18:9004/?CODE=000418' alt='R. Leopoldina Rego X R. Dr. Alfredo Barcelos' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.829115,-43.277078]} icon={camera}><Popup><span className='title-content'>Av. Lobo Junior X Rua Cuba -  Zona Norte - Penha Circular</span><img src='http://187.111.99.18:9004/?CODE=000419' alt='Av. Lobo Junior X Rua Cuba' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8360719,-43.2883229]} icon={camera}><Popup><span className='title-content'>Rua Bento Cardoso x Rua Irapua -  Zona Norte - Penha Circular</span><img src='http://187.111.99.18:9004/?CODE=000420' alt='Rua Bento Cardoso x Rua Irapua' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.842977,-43.238479]} icon={camera}><Popup><span className='title-content'>Linha Vermelha Alt. da Av. Brigadeiro Trompowski -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=000421' alt='Linha Vermelha Alt. da Av. Brigadeiro Trompowski' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.871603,-43.253429]} icon={camera}><Popup><span className='title-content'>R. Leopoldo Bulhões Alt. da Linha Amarela -  Zona Norte - Bonsucesso</span><img src='http://187.111.99.18:9004/?CODE=000423' alt='R. Leopoldo Bulhões Alt. da Linha Amarela' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.856187,-43.24768]} icon={camera}><Popup><span className='title-content'>Av. Brasil X Rua Teixeira Ribeiro -  Zona Norte - Ramos</span><img src='http://187.111.99.18:9004/?CODE=000425' alt='Av. Brasil X Rua Teixeira Ribeiro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.836912,-43.258611]} icon={camera}><Popup><span className='title-content'>AV.BRASIL X R. FILOMENA NUNES -  Zona Norte - Olaria</span><img src='http://187.111.99.18:9004/?CODE=000430' alt='AV.BRASIL X R. FILOMENA NUNES' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.842696,-43.238659]} icon={camera}><Popup><span className='title-content'>Linha Vermelha X Hospital Universitário (Ufrj) -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=000432' alt='Linha Vermelha X Hospital Universitário (Ufrj)' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.850397,-43.309662]} icon={camera}><Popup><span className='title-content'>Av. Vicente de Carvalho X Av. Meriti -  Zona Norte - Vila da Penha</span><img src='http://187.111.99.18:9004/?CODE=000442' alt='Av. Vicente de Carvalho X Av. Meriti' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.853322,-43.313861]} icon={camera}><Popup><span className='title-content'>Av. Martin Luther  X Av. Vicente de Carvalho -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=000443' alt='Av. Martin Luther  X Av. Vicente de Carvalho' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.805722,-43.366053]} icon={camera}><Popup><span className='title-content'>Av. Sargento de Milícias X R. Sargento Fernandes Fontes -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=000446' alt='Av. Sargento de Milícias X R. Sargento Fernandes Fontes' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97858205,-43.40781011]} icon={camera}><Popup><span className='title-content'>Rua Salvador Allende X Pedro Calmon -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000448' alt='Rua Salvador Allende X Pedro Calmon' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.847071,-43.324671]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Jr. X Av. Monsenhor Felix - FIXA -  Zona Norte - Iraja</span><img src='http://187.111.99.18:9004/?CODE=000450' alt='Av. Pastor Martin Luther King Jr. X Av. Monsenhor Felix - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.839329,-43.311646]} icon={camera}><Popup><span className='title-content'>Av. Brás de Pina X R. Pe. Roser X Av. Meriti (Largo Do Bicão) - FIXA -  Zona Norte - Vila da Penha</span><img src='http://187.111.99.18:9004/?CODE=000451' alt='Av. Brás de Pina X R. Pe. Roser X Av. Meriti (Largo Do Bicão) - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.885049,-43.310734]} icon={camera}><Popup><span className='title-content'>Av. Dom Hélder Câmara X R. Pde Manoel da Nóbrega -  Zona Norte - Quintino</span><img src='http://187.111.99.18:9004/?CODE=000452' alt='Av. Dom Hélder Câmara X R. Pde Manoel da Nóbrega' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.803363,-43.347073]} icon={camera}><Popup><span className='title-content'>Rodovia Pres. Dutra X Linha Vermelha -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=000453' alt='Rodovia Pres. Dutra X Linha Vermelha' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.879062,-43.356686]} icon={camera}><Popup><span className='title-content'>Estr. Intendente Magalhães X R. Henrique de Melo - FIXA -  Zona Norte - Campinho</span><img src='http://187.111.99.18:9004/?CODE=000454' alt='Estr. Intendente Magalhães X R. Henrique de Melo - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.882581,-43.337642]} icon={camera}><Popup><span className='title-content'>Av. Ernani Cardoso X Alberto Silva -  Zona Norte - Cascadura</span><img src='http://187.111.99.18:9004/?CODE=000455' alt='Av. Ernani Cardoso X Alberto Silva' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88302488,-43.34265525]} icon={camera}><Popup><span className='title-content'>R. Candido Benicio X Estrada Intendente Magalhães - FIXA -  Zona Norte - Campinho</span><img src='http://187.111.99.18:9004/?CODE=000456' alt='R. Candido Benicio X Estrada Intendente Magalhães - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.882067,-43.33202]} icon={camera}><Popup><span className='title-content'>Av. Ernani Cardoso X R. Padre Telêmaco - FIXA -  Zona Norte - Campinho</span><img src='http://187.111.99.18:9004/?CODE=000457' alt='Av. Ernani Cardoso X R. Padre Telêmaco - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88216538,-43.32467071]} icon={camera}><Popup><span className='title-content'>Av. D. Helder Câmara X R. Cer. Daltro - FIXA -  Zona Norte - Cascadura</span><img src='http://187.111.99.18:9004/?CODE=000458' alt='Av. D. Helder Câmara X R. Cer. Daltro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.87229,-43.336387]} icon={camera}><Popup><span className='title-content'>Av. Ministro Edgard Romero X R. Conselheiro Galvão -  Zona Norte - Madureira</span><img src='http://187.111.99.18:9004/?CODE=000460' alt='Av. Ministro Edgard Romero X R. Conselheiro Galvão' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.87055933,-43.34197092]} icon={camera}><Popup><span className='title-content'>Estr. Do Portela X R. Firmino Fragoso - FIXA -  Zona Norte - Madureira</span><img src='http://187.111.99.18:9004/?CODE=000462' alt='Estr. Do Portela X R. Firmino Fragoso - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.982722,-43.410896]} icon={camera}><Popup><span className='title-content'>Rua Salvador Allende X Próx. Olof Palme -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000464' alt='Rua Salvador Allende X Próx. Olof Palme' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.011278,-43.453834]} icon={camera}><Popup><span className='title-content'>Av. das Américas X Av. Guignard -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000465' alt='Av. das Américas X Av. Guignard' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.020528,-43.490238]} icon={camera}><Popup><span className='title-content'>Av. das Américas X Shopping Recreio -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000466' alt='Av. das Américas X Shopping Recreio' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.999444,-43.408248]} icon={camera}><Popup><span className='title-content'>Av. das Américas X Av. Cândido Portinari (Alt. Do Ribalta) -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000467' alt='Av. das Américas X Av. Cândido Portinari (Alt. Do Ribalta)' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00379364,-43.36613244]} icon={camera}><Popup><span className='title-content'>Av. Ayrton Senna X Cidade da Arte -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000468' alt='Av. Ayrton Senna X Cidade da Arte' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.972964,-43.403281]} icon={camera}><Popup><span className='title-content'>AV.SALVADOR ALLENDE X R.ABRAHÃO JABOUR -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000471' alt='AV.SALVADOR ALLENDE X R.ABRAHÃO JABOUR' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.020222,-43.501439]} icon={camera}><Popup><span className='title-content'>Av. das Américas X Altura Do Colégio Notre Dame -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000472' alt='Av. das Américas X Altura Do Colégio Notre Dame' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00040874,-43.38869271]} icon={camera}><Popup><span className='title-content'>AV. DAS AMÉRICAS x AV JEAN POUL X  R. PROF. ALFREDO COLOMBO -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000473' alt='AV. DAS AMÉRICAS x AV JEAN POUL X  R. PROF. ALFREDO COLOMBO' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.022384,-43.447999]} icon={camera}><Popup><span className='title-content'>Av. Lucio Costa X Av. Do Contorno  - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000474' alt='Av. Lucio Costa X Av. Do Contorno  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.022315,-43.458213]} icon={camera}><Popup><span className='title-content'>Av. Alfredo Baltazar da Silveira X R. Glaucio Gil - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000475' alt='Av. Alfredo Baltazar da Silveira X R. Glaucio Gil - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.024902,-43.457215]} icon={camera}><Popup><span className='title-content'>Av. Lucio Costa X R. Glaucio Gil  - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000476' alt='Av. Lucio Costa X R. Glaucio Gil  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00588786,-43.30417465]} icon={camera}><Popup><span className='title-content'>Estr. da Barra da Tijuca -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000480' alt='Estr. da Barra da Tijuca' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.012498,-43.29918299]} icon={camera}><Popup><span className='title-content'>Av. Min. Ivan Lins X Altura da Ponte da Joatinga -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000482' alt='Av. Min. Ivan Lins X Altura da Ponte da Joatinga' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.03024991,-43.47844879]} icon={camera}><Popup><span className='title-content'>Estrada Do Pontal Em Frente Ao N.º 6870 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000483' alt='Estrada Do Pontal Em Frente Ao N.º 6870 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00637287,-43.43713414]} icon={camera}><Popup><span className='title-content'>Av. das Américas X Av. Salvador Allende -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000484' alt='Av. das Américas X Av. Salvador Allende' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.013876,-43.46303]} icon={camera}><Popup><span className='title-content'>Av. das Américas X Estr. Benvindo de Novais -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000485' alt='Av. das Américas X Estr. Benvindo de Novais' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.031018,-43.471851]} icon={camera}><Popup><span className='title-content'>Av. Gilka Machado X Estr. Do Pontal - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000487' alt='Av. Gilka Machado X Estr. Do Pontal - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.978317,-43.416542]} icon={camera}><Popup><span className='title-content'>Rua Olof Palm X Abraão Jabour -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000488' alt='Rua Olof Palm X Abraão Jabour' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.003849,-43.425511]} icon={camera}><Popup><span className='title-content'>Av. das Américas X Pedra de Itaúna -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000489' alt='Av. das Américas X Pedra de Itaúna' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.981034,-43.409686]} icon={camera}><Popup><span className='title-content'>Av. Salvador Allende (Rio Centro) -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000490' alt='Av. Salvador Allende (Rio Centro)' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94144,-43.34115]} icon={camera}><Popup><span className='title-content'>Estr. de Jacarepaguá X R. Tirol -  Zona Oeste - Freguesia</span><img src='http://187.111.99.18:9004/?CODE=000493' alt='Estr. de Jacarepaguá X R. Tirol' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.939781912924700,-43.33670107250610]} icon={camera}><Popup><span className='title-content'>R. Tirol X R. Cmte Rubens Silva - FIXA -  Zona Oeste - Freguesia</span><img src='http://187.111.99.18:9004/?CODE=000495' alt='R. Tirol X R. Cmte Rubens Silva - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.960245,-43.389772]} icon={camera}><Popup><span className='title-content'>Est. Cel. Pedro Corrêa X Est. dos Bandeirantes -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000497' alt='Est. Cel. Pedro Corrêa X Est. dos Bandeirantes' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913834,-43.597480]} icon={camera}><Popup><span className='title-content'>Av. Cesário de Mello X R. Adolfo Lemos -  Zona Oeste - Inhoaiba</span><img src='http://187.111.99.18:9004/?CODE=000498' alt='Av. Cesário de Mello X R. Adolfo Lemos' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910571,-43.583460]} icon={camera}><Popup><span className='title-content'>Av. Cesário de Mello X Rua Morango -  Zona Oeste - Inhoaiba</span><img src='http://187.111.99.18:9004/?CODE=000500' alt='Av. Cesário de Mello X Rua Morango' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.923601,-43.648603]} icon={camera}><Popup><span className='title-content'>Av. Cesario de Mello X R. Antares -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=000502' alt='Av. Cesario de Mello X R. Antares' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.921718,-43.371212]} icon={camera}><Popup><span className='title-content'>Av. Nelson Cardoso X R. Bacairis -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=000503' alt='Av. Nelson Cardoso X R. Bacairis' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.920986,-43.371674]} icon={camera}><Popup><span className='title-content'>R. Bacairis X R. Apiacás - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=000504' alt='R. Bacairis X R. Apiacás - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.918555,-43.376051]} icon={camera}><Popup><span className='title-content'>Estr. Do Tindiba X R. Caviana -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=000505' alt='Estr. Do Tindiba X R. Caviana' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.928862,-43.354207]} icon={camera}><Popup><span className='title-content'>Av. Geremário Dantas X Estr. Do Tindiba -  Zona Oeste - Pechincha</span><img src='http://187.111.99.18:9004/?CODE=000507' alt='Av. Geremário Dantas X Estr. Do Tindiba' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.960586,-43.39178]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes X Av. Salvador Allende -  Zona Oeste - Curicica</span><img src='http://187.111.99.18:9004/?CODE=000510' alt='Estr. dos Bandeirantes X Av. Salvador Allende' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.927073,-43.360709]} icon={camera}><Popup><span className='title-content'>Estr. Do Tindiba X R. Lopo Saraiva -  Zona Oeste - Pechincha</span><img src='http://187.111.99.18:9004/?CODE=000511' alt='Estr. Do Tindiba X R. Lopo Saraiva' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93819,-43.349368]} icon={camera}><Popup><span className='title-content'>Av. Geremário Dantas X Sob Linha Amarela -  Zona Oeste - Pechincha</span><img src='http://187.111.99.18:9004/?CODE=000513' alt='Av. Geremário Dantas X Sob Linha Amarela' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93978,-43.343768]} icon={camera}><Popup><span className='title-content'>Av. Geremário Dantas X Estr. dos Três Rios (Pça. Profª Camisão) -  Zona Oeste - Pechincha</span><img src='http://187.111.99.18:9004/?CODE=000514' alt='Av. Geremário Dantas X Estr. dos Três Rios (Pça. Profª Camisão)' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.936381,-43.333275]} icon={camera}><Popup><span className='title-content'>Estr. dos Três Rios X Estr. Do Bananal -  Zona Oeste - Freguesia</span><img src='http://187.111.99.18:9004/?CODE=000515' alt='Estr. dos Três Rios X Estr. Do Bananal' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91701478,-43.63368435]} icon={camera}><Popup><span className='title-content'>Av. Cesário de Melo x Estada Santa Eugênia -  Zona Oeste - Paciencia</span><img src='http://187.111.99.18:9004/?CODE=000516' alt='Av. Cesário de Melo x Estada Santa Eugênia' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915809,-43.628979]} icon={camera}><Popup><span className='title-content'>Av. Cesário de Mello X Viaduto de Paciência -  Zona Oeste - Paciencia</span><img src='http://187.111.99.18:9004/?CODE=000517' alt='Av. Cesário de Mello X Viaduto de Paciência' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.932706,-43.330454]} icon={camera}><Popup><span className='title-content'>Estr. Do Pau Ferro X Estr. de Ganumbi -  Zona Oeste - Freguesia</span><img src='http://187.111.99.18:9004/?CODE=000520' alt='Estr. Do Pau Ferro X Estr. de Ganumbi' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94752956,-43.33967490]} icon={camera}><Popup><span className='title-content'>Estr. Tenente Coronel Muniz de Aragão X Estr. de Jacarepaguá - FIXA -  Zona Oeste - Anil</span><img src='http://187.111.99.18:9004/?CODE=000523' alt='Estr. Tenente Coronel Muniz de Aragão X Estr. de Jacarepaguá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94305,-43.331355]} icon={camera}><Popup><span className='title-content'>Estr. Do Bananal X R. Ituverava - FIXA -  Zona Oeste - Freguesia</span><img src='http://187.111.99.18:9004/?CODE=000524' alt='Estr. Do Bananal X R. Ituverava - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.955084,-43.337384]} icon={camera}><Popup><span className='title-content'>Estr. de Jacarepaguá X Estr.Do Engenho Dágua - FIXA -  Zona Oeste - Freguesia</span><img src='http://187.111.99.18:9004/?CODE=000525' alt='Estr. de Jacarepaguá X Estr.Do Engenho Dágua - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.916678,-43.377478]} icon={camera}><Popup><span className='title-content'>Estr. Do Tindiba X Pça Jauru -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=000526' alt='Estr. Do Tindiba X Pça Jauru' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914672,-43.380836]} icon={camera}><Popup><span className='title-content'>Estr. Do Tindiba X Estr. Meringuava -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=000527' alt='Estr. Do Tindiba X Estr. Meringuava' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914204,-43.375713]} icon={camera}><Popup><span className='title-content'>Estr. Do Cafundá X Estr. Meringuava - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=000528' alt='Estr. Do Cafundá X Estr. Meringuava - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.951250,-43.650340]} icon={camera}><Popup><span className='title-content'>Estr. da Pedra X Rua. General Alexandre Barreto -  Zona Oeste - Paciencia</span><img src='http://187.111.99.18:9004/?CODE=000530' alt='Estr. da Pedra X Rua. General Alexandre Barreto' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90299450,-43.26851963]} icon={camera}><Popup><span className='title-content'>Buraco Do Padre -  Zona Norte - Engenho Novo</span><img src='http://187.111.99.18:9004/?CODE=000532' alt='Buraco Do Padre' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92365833,-43.36903261]} icon={camera}><Popup><span className='title-content'>R. André Rocha X R. Mal. José Beviláqua - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=000533' alt='R. André Rocha X R. Mal. José Beviláqua - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.977804,-43.417239]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes X Olof Palm  - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000534' alt='Estr. dos Bandeirantes X Olof Palm  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.963194,-43.403485]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes X Estr. da Curicica -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000535' alt='Estr. dos Bandeirantes X Estr. da Curicica' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93764629,-43.33728808]} icon={camera}><Popup><span className='title-content'>Estr. dos Três Rios X R. Cmte Rubens Silva - FIXA -  Zona Oeste - Freguesia</span><img src='http://187.111.99.18:9004/?CODE=000536' alt='Estr. dos Três Rios X R. Cmte Rubens Silva - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01200056,-43.44374712]} icon={camera}><Popup><span className='title-content'>Avenida Alfredo Baltazar da Silveira x Rua Odilon Duarte Braga  - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000537' alt='Avenida Alfredo Baltazar da Silveira x Rua Odilon Duarte Braga  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.875839,-43.470186]} icon={camera}><Popup><span className='title-content'>Av. de Santa Cruz X Viaduto de Bangu -  Zona Oeste - Bangu</span><img src='http://187.111.99.18:9004/?CODE=000543' alt='Av. de Santa Cruz X Viaduto de Bangu' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.873594,-43.464871]} icon={camera}><Popup><span className='title-content'>R. Min. Ary Franco X R. Sul América - FIXA -  Zona Oeste - Bangu</span><img src='http://187.111.99.18:9004/?CODE=000544' alt='R. Min. Ary Franco X R. Sul América - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.877114,-43.445767]} icon={camera}><Popup><span className='title-content'>Av. de Santa Cruz X R. Francisco Real -  Zona Oeste - Padre Miguel</span><img src='http://187.111.99.18:9004/?CODE=000545' alt='Av. de Santa Cruz X R. Francisco Real' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.862069,-43.411317]} icon={camera}><Popup><span className='title-content'>Av. Brasil X Estr. da Equitação - FIXA -  Zona Oeste - Magalhaes Bastos</span><img src='http://187.111.99.18:9004/?CODE=000547' alt='Av. Brasil X Estr. da Equitação - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.858247,-43.501137]} icon={camera}><Popup><span className='title-content'>Av. Brasil X Restaurante Aldeias -  Zona Oeste - Bangu</span><img src='http://187.111.99.18:9004/?CODE=000548' alt='Av. Brasil X Restaurante Aldeias' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.876301,-43.430233]} icon={camera}><Popup><span className='title-content'>R. Bernardo de Vasconcelos X Praça Do Canhão - FIXA -  Zona Norte - Realengo</span><img src='http://187.111.99.18:9004/?CODE=000550' alt='R. Bernardo de Vasconcelos X Praça Do Canhão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914775,-43.175097]} icon={camera}><Popup><span className='title-content'>Rua Teixeira de Freitas -  Centro da Cidade - Gloria</span><img src='http://187.111.99.18:9004/?CODE=000552' alt='Rua Teixeira de Freitas' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.879715,-43.463489]} icon={camera}><Popup><span className='title-content'>R. Francisco Real X R. Silva Cardoso - FIXA -  Zona Oeste - Bangu</span><img src='http://187.111.99.18:9004/?CODE=000553' alt='R. Francisco Real X R. Silva Cardoso - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.880188,-43.468802]} icon={camera}><Popup><span className='title-content'>R. da Feira X R. dos Açudes -  Zona Oeste - Bangu</span><img src='http://187.111.99.18:9004/?CODE=000554' alt='R. da Feira X R. dos Açudes' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.875532,-43.463503]} icon={camera}><Popup><span className='title-content'>Av. de Santa Cruz X R. Silva Cardoso -  Zona Oeste - Bangu</span><img src='http://187.111.99.18:9004/?CODE=000555' alt='Av. de Santa Cruz X R. Silva Cardoso' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.877974,-43.441604]} icon={camera}><Popup><span className='title-content'>Av. de Santa Cruz X Estr. Do Realengo - FIXA -  Zona Oeste - Padre Miguel</span><img src='http://187.111.99.18:9004/?CODE=000557' alt='Av. de Santa Cruz X Estr. Do Realengo - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.878107,-43.434836]} icon={camera}><Popup><span className='title-content'>Av. Santa Cruz X R. Gal. Sezefredo - FIXA -  Zona Norte - Realengo</span><img src='http://187.111.99.18:9004/?CODE=000561' alt='Av. Santa Cruz X R. Gal. Sezefredo - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.901756,-43.558879]} icon={camera}><Popup><span className='title-content'>R. Campo Grande X Alt. Estação Ferroviária de Campo Grande -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=000564' alt='R. Campo Grande X Alt. Estação Ferroviária de Campo Grande' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.896556,-43.558388]} icon={camera}><Popup><span className='title-content'>Estr. da Caroba X Estr. Rio-São Paulo -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=000566' alt='Estr. da Caroba X Estr. Rio-São Paulo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906044,-43.559783]} icon={camera}><Popup><span className='title-content'>Av. Cesário de Melo X Alt. Do Calçadão de Campo Grande -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=000567' alt='Av. Cesário de Melo X Alt. Do Calçadão de Campo Grande' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.904878,-43.556736]} icon={camera}><Popup><span className='title-content'>Av. Cesário de Melo X R. Aurélio de Figueiredo -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=000568' alt='Av. Cesário de Melo X R. Aurélio de Figueiredo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.907062,-43.574599]} icon={camera}><Popup><span className='title-content'>Av. Cesário de Melo X Av. Farroupilha -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=000569' alt='Av. Cesário de Melo X Av. Farroupilha' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.897446,-43.54827]} icon={camera}><Popup><span className='title-content'>Estr. da Caroba X Av. Cesário de Melo -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=000570' alt='Estr. da Caroba X Av. Cesário de Melo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.902388,-43.549064]} icon={camera}><Popup><span className='title-content'>Av. Cesário de Melo X R. Artur Rios -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=000571' alt='Av. Cesário de Melo X R. Artur Rios' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.894372,-43.560072]} icon={camera}><Popup><span className='title-content'>Estr. Rio Do A X Estr. Rio São Paulo -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=000572' alt='Estr. Rio Do A X Estr. Rio São Paulo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.899808,-43.567003]} icon={camera}><Popup><span className='title-content'>Estr. Rio Do A X R. André Vesálio -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=000573' alt='Estr. Rio Do A X R. André Vesálio' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90389,-43.559139]} icon={camera}><Popup><span className='title-content'>R. Xavier Marques X R. Cel. Agostinho -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=000574' alt='R. Xavier Marques X R. Cel. Agostinho' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914183,-43.554338]} icon={camera}><Popup><span className='title-content'>R. Olinda Ellis X Alt. Centro Esportivo Miécimo da Silva - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=000576' alt='R. Olinda Ellis X Alt. Centro Esportivo Miécimo da Silva - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914464,-43.549955]} icon={camera}><Popup><span className='title-content'>Estr. da Cachamorra X R. Olinda Ellis - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=000577' alt='Estr. da Cachamorra X R. Olinda Ellis - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.920631,-43.56299]} icon={camera}><Popup><span className='title-content'>Estr. Do Monteiro (Entre Estr. da Cambota E Estr. Iaraquã) - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=000578' alt='Estr. Do Monteiro (Entre Estr. da Cambota E Estr. Iaraquã) - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.894273,-43.584931]} icon={camera}><Popup><span className='title-content'>Estr. Do Campinho X Estr. Santa Maria - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=000580' alt='Estr. Do Campinho X Estr. Santa Maria - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.868979,-43.596368]} icon={camera}><Popup><span className='title-content'>Av. Brasil X Estr. Rio-São Paulo -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=000583' alt='Av. Brasil X Estr. Rio-São Paulo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.863522,-43.56431]} icon={camera}><Popup><span className='title-content'>Av. Brasil X Estr. Do Pedregoso -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=000585' alt='Av. Brasil X Estr. Do Pedregoso' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.886432,-43.604469]} icon={camera}><Popup><span className='title-content'>Estr. Do Campinho X Estr. Do Encanamento -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=000587' alt='Estr. Do Campinho X Estr. Do Encanamento' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.935591,-43.666942]} icon={camera}><Popup><span className='title-content'>R. Felipe Cardoso X Av. Cesário de Melo (Pça. Santa Cruz) -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=000588' alt='R. Felipe Cardoso X Av. Cesário de Melo (Pça. Santa Cruz)' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.919718,-43.681970]} icon={camera}><Popup><span className='title-content'>R. Felipe Cardoso X Av. Isabel -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=000589' alt='R. Felipe Cardoso X Av. Isabel' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.921800,-43.679875]} icon={camera}><Popup><span className='title-content'>R. Felipe Cardoso X Alt. Beco Macaé -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=000590' alt='R. Felipe Cardoso X Alt. Beco Macaé' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.927110,-43.678165]} icon={camera}><Popup><span className='title-content'>R. Felipe Cardoso X Av. Engº Gastão Rangel -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=000591' alt='R. Felipe Cardoso X Av. Engº Gastão Rangel' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914262,-43.686219]} icon={camera}><Popup><span className='title-content'>Rua Senador Camará, 207 -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=000594' alt='Rua Senador Camará, 207' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915512,-43.684849]} icon={camera}><Popup><span className='title-content'>R. D. João Vi X R. Senador Câmara -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=000595' alt='R. D. João Vi X R. Senador Câmara' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.881187,-43.632492]} icon={camera}><Popup><span className='title-content'>Av. Brasil X Estr. Do Campinho -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=000596' alt='Av. Brasil X Estr. Do Campinho' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911703,-43.236397]} icon={camera}><Popup><span className='title-content'>Uerj -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000600' alt='Uerj' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.909278,-43.226288]} icon={camera}><Popup><span className='title-content'>Bartolomeu de Gusmão - Acesso Ao Maracanã -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000601' alt='Bartolomeu de Gusmão - Acesso Ao Maracanã' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.924532,-43.224376]} icon={camera}><Popup><span className='title-content'>Conde de Bonfim X Alzira Brandão - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000602' alt='Conde de Bonfim X Alzira Brandão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.013758,-43.297972]} icon={camera}><Popup><span className='title-content'>Hotel Ibis -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000607' alt='Hotel Ibis' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97373590,-43.40020534]} icon={camera}><Popup><span className='title-content'>Av. Embaixador Abelardo Bueno - Em Frente 73 -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000610' alt='Av. Embaixador Abelardo Bueno - Em Frente 73' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.901878,-43.182273]} icon={camera}><Popup><span className='title-content'>Iperj -  Centro da Cidade - Uruguaiana</span><img src='http://187.111.99.18:9004/?CODE=000611' alt='Iperj' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.987883,-43.194503]} icon={camera}><Popup><span className='title-content'>Av. Vieira Souto x R. Francisco Otaviano -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=000612' alt='Av. Vieira Souto x R. Francisco Otaviano' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.989201,-43.191494]} icon={camera}><Popup><span className='title-content'>Posto 7 -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=000613' alt='Posto 7' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.902772,-43.191193]} icon={camera}><Popup><span className='title-content'>R. Marcilio Dias X R. Bento Ribeiro -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000614' alt='R. Marcilio Dias X R. Bento Ribeiro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91195441,-43.20319196]} icon={camera}><Popup><span className='title-content'>Terraço do COR - 01 -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=000632' alt='Terraço do COR - 01' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91198900,-43.20329120]} icon={camera}><Popup><span className='title-content'>Terraço do COR - 02 -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=000633' alt='Terraço do COR - 02' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91212488,-43.20342799]} icon={camera}><Popup><span className='title-content'>Terraço do COR - 03 -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=000634' alt='Terraço do COR - 03' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91201741,-43.20339580]} icon={camera}><Popup><span className='title-content'>Terraço do COR - 04 -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=000635' alt='Terraço do COR - 04' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.84057995,-43.36928373]} icon={camera}><Popup><span className='title-content'>Av. Brasil X R. Leocádio Figueiredo - Guadalupe Shopping -  Zona Norte - Guadalupe</span><img src='http://187.111.99.18:9004/?CODE=000636' alt='Av. Brasil X R. Leocádio Figueiredo - Guadalupe Shopping' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91209884,-43.20398023]} icon={camera}><Popup><span className='title-content'>Terraço do COR - 05 -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=000637' alt='Terraço do COR - 05' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911742,-43.204353]} icon={camera}><Popup><span className='title-content'>Terraço do Cass - 06 -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=000638' alt='Terraço do Cass - 06' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91209884,-43.20398023]} icon={camera}><Popup><span className='title-content'>Terraço do COR - 07 -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=000639' alt='Terraço do COR - 07' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97285673,-43.36323016]} icon={camera}><Popup><span className='title-content'>Poste 1 - Ponte Nova BRT Barra da Tijuca -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000640' alt='Poste 1 - Ponte Nova BRT Barra da Tijuca' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99906423,-43.36621528]} icon={camera}><Popup><span className='title-content'>Cidade das Artes 1 -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000648' alt='Cidade das Artes 1' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.998798,-43.365000]} icon={camera}><Popup><span className='title-content'>Cidade das Artes - Av. das Américas alt. Carrefour -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000649' alt='Cidade das Artes - Av. das Américas alt. Carrefour' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01150157,-43.32520277]} icon={camera}><Popup><span className='title-content'>Av. Lúcio Costa 3100 -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000654' alt='Av. Lúcio Costa 3100' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90157752,-43.22631662]} icon={camera}><Popup><span className='title-content'>Tunel da Expressa - Cam 01 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000659' alt='Tunel da Expressa - Cam 01' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95832941,-43.20437615]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - NORTE 01 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000660' alt='Túnel Rebouças - NORTE 01' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95717850,-43.20429569]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - NORTE 02 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000661' alt='Túnel Rebouças - NORTE 02' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95383439,-43.20382362]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - NORTE 03 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000662' alt='Túnel Rebouças - NORTE 03' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95329844,-43.20376997]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - NORTE 04 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000663' alt='Túnel Rebouças - NORTE 04' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95114472,-43.20346957]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - NORTE 05 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000664' alt='Túnel Rebouças - NORTE 05' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94994929,-43.20333009]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - NORTE 010 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000665' alt='Túnel Rebouças - NORTE 010' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94882795,-43.20312088]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - NORTE 011 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000666' alt='Túnel Rebouças - NORTE 011' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94824999,-43.20308065]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - NORTE 012 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000667' alt='Túnel Rebouças - NORTE 012' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94657042,-43.20278560]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - NORTE 013 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000668' alt='Túnel Rebouças - NORTE 013' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94463396,-43.20219015]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - NORTE 014 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000669' alt='Túnel Rebouças - NORTE 014' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94415972,-43.20244764]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - NORTE 015 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000670' alt='Túnel Rebouças - NORTE 015' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94174897,-43.20225989]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - NORTE 016 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000671' alt='Túnel Rebouças - NORTE 016' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94047442,-43.20203458]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - NORTE 017 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000672' alt='Túnel Rebouças - NORTE 017' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95940291,-43.20454]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - EXTERNA 01 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000680' alt='Túnel Rebouças - EXTERNA 01' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95940914,-43.20465]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - EXTERNA 02 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000681' alt='Túnel Rebouças - EXTERNA 02' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93478448,-43.20986798]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - SUL 10 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000682' alt='Túnel Rebouças - SUL 10' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93545637,-43.20917328]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - SUL 12 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000683' alt='Túnel Rebouças - SUL 12' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93578737,-43.20887824]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - SUL 13 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000684' alt='Túnel Rebouças - SUL 13' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93607144,-43.20815404]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - SUL 15 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000685' alt='Túnel Rebouças - SUL 15' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93607144,-43.20841154]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - SUL 17 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000686' alt='Túnel Rebouças - SUL 17' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93680755,-43.20734938]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - SUL 18 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000687' alt='Túnel Rebouças - SUL 18' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93713360,-43.20658763]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - SUL 19 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000688' alt='Túnel Rebouças - SUL 19' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94754729,-43.20348164]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - SUL 21 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000690' alt='Túnel Rebouças - SUL 21' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95018023,-43.20370694]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - SUL 22 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000691' alt='Túnel Rebouças - SUL 22' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95489271,-43.20432921]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - SUL 23 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000692' alt='Túnel Rebouças - SUL 23' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95684878,-43.20460280]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - SUL 24 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000693' alt='Túnel Rebouças - SUL 24' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95777742,-43.20463499]} icon={camera}><Popup><span className='title-content'>Túnel Rebouças - SUL 25 -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000694' alt='Túnel Rebouças - SUL 25' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.87624785203340,-43.40474476386260]} icon={camera}><Popup><span className='title-content'>BRT TRANSOLÍMPICA X ETR. MARECHAL MALLET -  Zona Norte - Deodoro</span><img src='http://187.111.99.18:9004/?CODE=000704' alt='BRT TRANSOLÍMPICA X ETR. MARECHAL MALLET' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.872966,-43.408237]} icon={camera}><Popup><span className='title-content'>BRT TRANSOLÍMPICA X R. ALMIRANTE MILÂNEZ -  Zona Oeste - Abolicao</span><img src='http://187.111.99.18:9004/?CODE=000705' alt='BRT TRANSOLÍMPICA X R. ALMIRANTE MILÂNEZ' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.872911,-43.412860]} icon={camera}><Popup><span className='title-content'>R. ENGENHEIRO TRAJANO DE MEDEIROS X R. CARINHANHA -  Zona Norte - Padre Miguel</span><img src='http://187.111.99.18:9004/?CODE=000706' alt='R. ENGENHEIRO TRAJANO DE MEDEIROS X R. CARINHANHA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.867998,-43.406686]} icon={camera}><Popup><span className='title-content'>AV. DUQUE DE CAXIAS X TEN. NEPOMUCENO -  Zona Norte - Duque de Caxias</span><img src='http://187.111.99.18:9004/?CODE=000708' alt='AV. DUQUE DE CAXIAS X TEN. NEPOMUCENO' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.862207,-43.394428]} icon={camera}><Popup><span className='title-content'>AV. DUQUE DE CAXIAS X AV. GAL. GOMES CARNEIRO -  Zona Norte - Duque de Caxias</span><img src='http://187.111.99.18:9004/?CODE=000713' alt='AV. DUQUE DE CAXIAS X AV. GAL. GOMES CARNEIRO' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.859060,-43.405754]} icon={camera}><Popup><span className='title-content'>Av. Brasil x R. Gericinó -  Zona Norte - Bangu</span><img src='http://187.111.99.18:9004/?CODE=000715' alt='Av. Brasil x R. Gericinó' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.855068,-43.385337]} icon={camera}><Popup><span className='title-content'>AV. DUQUE DE CAXIAS X ETR. SÃO PEDRO DE ALCANTARA -  Zona Norte - Duque de Caxias</span><img src='http://187.111.99.18:9004/?CODE=000717' alt='AV. DUQUE DE CAXIAS X ETR. SÃO PEDRO DE ALCANTARA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.850972,433.94688]} icon={camera}><Popup><span className='title-content'>ETR. MARECHAL ALENCASTRO X AV. BRASIL -  Zona Norte - Deodoro</span><img src='http://187.111.99.18:9004/?CODE=000718' alt='ETR. MARECHAL ALENCASTRO X AV. BRASIL' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.853243,-43.39135099]} icon={camera}><Popup><span className='title-content'>ETR. MARECHAL ALENCASTRO  X AV. NAZARE -  Zona Norte - Ricardo de Albuquerque</span><img src='http://187.111.99.18:9004/?CODE=000722' alt='ETR. MARECHAL ALENCASTRO  X AV. NAZARE' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.843844,-43.375250]} icon={camera}><Popup><span className='title-content'>AV. BRASIL X R. MARCOS DE MACEDO -  Zona Norte - Guadalupe</span><img src='http://187.111.99.18:9004/?CODE=000724' alt='AV. BRASIL X R. MARCOS DE MACEDO' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.83179189,-43.35313141]} icon={camera}><Popup><span className='title-content'>AV. BRASIL, ALTURA FAZENDA BOTAFOGO -  Zona Oeste - Colegio</span><img src='http://187.111.99.18:9004/?CODE=000726' alt='AV. BRASIL, ALTURA FAZENDA BOTAFOGO' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.827076,-43.274333]} icon={camera}><Popup><span className='title-content'>AV. BRASIL X AV. LOBO JÚNIOR -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=000732' alt='AV. BRASIL X AV. LOBO JÚNIOR' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.853136,-43.314891]} icon={camera}><Popup><span className='title-content'>AV. P. MARTIN LUTHER KING JR X LARGO DE VICENTE DE CARVALHO -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=000737' alt='AV. P. MARTIN LUTHER KING JR X LARGO DE VICENTE DE CARVALHO' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.847473,-43.305032]} icon={camera}><Popup><span className='title-content'>AV. VICENTE DE CARVALHO X R. SOLDADO SERVINO MENGAROA -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=000738' alt='AV. VICENTE DE CARVALHO X R. SOLDADO SERVINO MENGAROA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.845037,-43.249251]} icon={camera}><Popup><span className='title-content'>Av. Brasil x Altura do Viaduto da Ilha -  Zona Norte - Mare</span><img src='http://187.111.99.18:9004/?CODE=000741' alt='Av. Brasil x Altura do Viaduto da Ilha' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.856913,-43.234541]} icon={camera}><Popup><span className='title-content'>RUA 5 x ALTURA DO CENPES (ILHA DO FUNDÃO) -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=000744' alt='RUA 5 x ALTURA DO CENPES (ILHA DO FUNDÃO)' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.864625,-43.228961]} icon={camera}><Popup><span className='title-content'>AV. HORÁCIO DE MACEDO X PRAÇA GIULIO MASSARANI -  Zona Norte - Honorio Gurgel</span><img src='http://187.111.99.18:9004/?CODE=000745' alt='AV. HORÁCIO DE MACEDO X PRAÇA GIULIO MASSARANI' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89507572,-43.29804835]} icon={camera}><Popup><span className='title-content'>R. GOIÁS X R. GUINEZA - FIXA -  Zona Norte - Engenho de Dentro</span><img src='http://187.111.99.18:9004/?CODE=000747' alt='R. GOIÁS X R. GUINEZA - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.893577,-43.29989799]} icon={camera}><Popup><span className='title-content'>AV. LINHA AMARELA, ENTRADA 3 -  Zona Norte - Engenho de Dentro</span><img src='http://187.111.99.18:9004/?CODE=000748' alt='AV. LINHA AMARELA, ENTRADA 3' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.890699,-43.294331]} icon={camera}><Popup><span className='title-content'>R. DAS OFICINAS X R. JOSÉ DOS REIS -  Zona Norte - Engenho de Dentro</span><img src='http://187.111.99.18:9004/?CODE=000751' alt='R. DAS OFICINAS X R. JOSÉ DOS REIS' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.871755,-43.25825800]} icon={camera}><Popup><span className='title-content'>AV. DOS DEMOCRÁTICOS X  AV. NOVO RIO -  Zona Norte - Manguinhos</span><img src='http://187.111.99.18:9004/?CODE=000756' alt='AV. DOS DEMOCRÁTICOS X  AV. NOVO RIO' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.923262,-43.231088]} icon={camera}><Popup><span className='title-content'>R. CONDE DE BONFIM 305 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000757' alt='R. CONDE DE BONFIM 305 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.921331,-43.235703]} icon={camera}><Popup><span className='title-content'>AV. MARACANA X PRAÇA LUIS LA SAIGNE -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000758' alt='AV. MARACANA X PRAÇA LUIS LA SAIGNE' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908938,-43.238636]} icon={camera}><Popup><span className='title-content'>R. SÃO FRANCISCO XAVIER X R. 8 DE DEZEMBRO - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000759' alt='R. SÃO FRANCISCO XAVIER X R. 8 DE DEZEMBRO - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.905137,-43.244102]} icon={camera}><Popup><span className='title-content'>AV. MARECHAL RONDON X R. SOUSA DANTAS -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000760' alt='AV. MARECHAL RONDON X R. SOUSA DANTAS' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.901240,-43.249144]} icon={camera}><Popup><span className='title-content'>R. 24 DE MAIO N161 -  Zona Norte - Riachuelo</span><img src='http://187.111.99.18:9004/?CODE=000761' alt='R. 24 DE MAIO N161' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.894948,-43.239928]} icon={camera}><Popup><span className='title-content'>DESCIDA DO VIADUTO ANA NERI X R. SEN. BERNARDO MONTEIRO -  Zona Norte - Benfica</span><img src='http://187.111.99.18:9004/?CODE=000764' alt='DESCIDA DO VIADUTO ANA NERI X R. SEN. BERNARDO MONTEIRO' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.890345,-43.234190]} icon={camera}><Popup><span className='title-content'>R. PREFEITO OLIMPIO DE MELO X R. CHIBATA -  Zona Norte - Benfica</span><img src='http://187.111.99.18:9004/?CODE=000765' alt='R. PREFEITO OLIMPIO DE MELO X R. CHIBATA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88797118,-43.22537744]} icon={camera}><Popup><span className='title-content'>Rua Bela 909 - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000766' alt='Rua Bela 909 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.886307,-43.224766]} icon={camera}><Popup><span className='title-content'>AV. BRASIL X R. FRANCO DE ALMEIDA - FIXA -  Zona Norte - Caju</span><img src='http://187.111.99.18:9004/?CODE=000767' alt='AV. BRASIL X R. FRANCO DE ALMEIDA - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88652,-43.22519]} icon={camera}><Popup><span className='title-content'>AV. BRASIL X R. FRANCO DE ALMEIDA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000768' alt='AV. BRASIL X R. FRANCO DE ALMEIDA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.889535,-43.215804]} icon={camera}><Popup><span className='title-content'>AV. RIO DE JANEIRO, ALTURA PORTÃO CAIS DO PORTO -  Zona Norte  - Caju</span><img src='http://187.111.99.18:9004/?CODE=000769' alt='AV. RIO DE JANEIRO, ALTURA PORTÃO CAIS DO PORTO' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913313,-43.225239]} icon={camera}><Popup><span className='title-content'>R. GENERAL CANABARRO X R. MATA MACHADO -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=000770' alt='R. GENERAL CANABARRO X R. MATA MACHADO' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910868,-43.226114]} icon={camera}><Popup><span className='title-content'>AV. RADIAL OESTE X VIADUTO ODUVALDO COZZI -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000771' alt='AV. RADIAL OESTE X VIADUTO ODUVALDO COZZI' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908976,-43.222637]} icon={camera}><Popup><span className='title-content'>R. GENERAL HERCULANO GOMES - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000773' alt='R. GENERAL HERCULANO GOMES - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908126,-43.221771]} icon={camera}><Popup><span className='title-content'>QUINTA DA BOA VISTA 2 -FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000774' alt='QUINTA DA BOA VISTA 2 -FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.904731,-43.220328]} icon={camera}><Popup><span className='title-content'>QUINTA DA BOA VISTA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000775' alt='QUINTA DA BOA VISTA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.899081,-43.220322]} icon={camera}><Popup><span className='title-content'>CAMPO DE SÃO CRISTÓVÃO X COLÉGIO PEDRO II -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000776' alt='CAMPO DE SÃO CRISTÓVÃO X COLÉGIO PEDRO II' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.905536,-43.224640]} icon={camera}><Popup><span className='title-content'>QUINTA DA BOA VISTA ENTRADA DO MUSEU -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000777' alt='QUINTA DA BOA VISTA ENTRADA DO MUSEU' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.897165,-43.212520]} icon={camera}><Popup><span className='title-content'>AV. FRANCISCO BICALHO ALTURA RODOVIARIA NOVO RIO -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000779' alt='AV. FRANCISCO BICALHO ALTURA RODOVIARIA NOVO RIO' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.897120,-43.206470]} icon={camera}><Popup><span className='title-content'>Av. Rodrigues Alves x R. Cordeiro da Graça -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=000782' alt='Av. Rodrigues Alves x R. Cordeiro da Graça' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.896,-43.18160399]} icon={camera}><Popup><span className='title-content'>AV. RIO BRANCO X AV. RODRIGUES ALVES (PRAÇA MAUÁ) -  Centro da Cidade - Saude</span><img src='http://187.111.99.18:9004/?CODE=000787' alt='AV. RIO BRANCO X AV. RODRIGUES ALVES (PRAÇA MAUÁ)' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911480,-43.209339]} icon={camera}><Popup><span className='title-content'>AV. PAULO DE FRONTIN -  Centro da Cidade - Praca da Bandeira</span><img src='http://187.111.99.18:9004/?CODE=000788' alt='AV. PAULO DE FRONTIN' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91384364,-43.20440066]} icon={camera}><Popup><span className='title-content'>AV. SALVADOR DE SÁ X RUA ESTÁCIO  DE SÁ  X FREI CANECA -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=000789' alt='AV. SALVADOR DE SÁ X RUA ESTÁCIO  DE SÁ  X FREI CANECA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913196,-43.202951]} icon={camera}><Popup><span className='title-content'>AV. SALVADOR DE SÁ X R. FREI CANECA (LARGO DO ESTÁCIO) -  Centro da Cidade - Estacio</span><img src='http://187.111.99.18:9004/?CODE=000790' alt='AV. SALVADOR DE SÁ X R. FREI CANECA (LARGO DO ESTÁCIO)' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.912047,-43.197545]} icon={camera}><Popup><span className='title-content'>AV. SALVADOR DE SÁ X TRAV. PEDREGAIS -  Centro da Cidade - Estacio</span><img src='http://187.111.99.18:9004/?CODE=000793' alt='AV. SALVADOR DE SÁ X TRAV. PEDREGAIS' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.912310,-43.195245]} icon={camera}><Popup><span className='title-content'>ENTRADA DO TÚNEL DO CATUMBI -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000794' alt='ENTRADA DO TÚNEL DO CATUMBI' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911706,-43.195338]} icon={camera}><Popup><span className='title-content'>AV. SALVADOR DE SÁ, ALTURA DO BPCHQ -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=000795' alt='AV. SALVADOR DE SÁ, ALTURA DO BPCHQ' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906894,-43.197262]} icon={camera}><Popup><span className='title-content'>AV. PRESIDENTE VARGAS, ALTURA DO SAMBÓDROMO -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000797' alt='AV. PRESIDENTE VARGAS, ALTURA DO SAMBÓDROMO' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.903302,-43.199097]} icon={camera}><Popup><span className='title-content'>VIADUTO SÃO PEDRO / SÃO PAULO X R. DO PINTO -  Centro da Cidade - Santa Teresa</span><img src='http://187.111.99.18:9004/?CODE=000798' alt='VIADUTO SÃO PEDRO / SÃO PAULO X R. DO PINTO' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.898772,-43.201872]} icon={camera}><Popup><span className='title-content'>PRAÇA SANTO CRISTO -  Centro da Cidade - Santo Cristo</span><img src='http://187.111.99.18:9004/?CODE=000799' alt='PRAÇA SANTO CRISTO' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910439,-43.188245]} icon={camera}><Popup><span className='title-content'>R. DO SENADO X R. VINTE DE ABRIL -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000800' alt='R. DO SENADO X R. VINTE DE ABRIL' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.912710,-43.184501]} icon={camera}><Popup><span className='title-content'>AV. MEM DE S X R. DOS INVÁLIDOS - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000801' alt='AV. MEM DE S X R. DOS INVÁLIDOS - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906172,-43.187269]} icon={camera}><Popup><span className='title-content'>PRAÇA DA REPUBLICA X R. BUENOS AIRES -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000802' alt='PRAÇA DA REPUBLICA X R. BUENOS AIRES' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906749,-43.183574]} icon={camera}><Popup><span className='title-content'>R. DA CONSTITUIÇÃO X PRAÇA TIRADENTES -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000803' alt='R. DA CONSTITUIÇÃO X PRAÇA TIRADENTES' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.904554,-43.183216]} icon={camera}><Popup><span className='title-content'>AV. PASSOS X R. BUENOS AIRES -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000804' alt='AV. PASSOS X R. BUENOS AIRES' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.901978,-43.184661]} icon={camera}><Popup><span className='title-content'>AV. PASSOS X AV. MARECHAL FLORIANO -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000806' alt='AV. PASSOS X AV. MARECHAL FLORIANO' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.901040,-43.182102]} icon={camera}><Popup><span className='title-content'>R. ACRE X AV. MARECHAL  FLORIANO X R. URUGUAIANA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000807' alt='R. ACRE X AV. MARECHAL  FLORIANO X R. URUGUAIANA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.900416,-43.181051]} icon={camera}><Popup><span className='title-content'>AV. MARECHAL FLORIANO (LARGO DE SANTA RITA) -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000808' alt='AV. MARECHAL FLORIANO (LARGO DE SANTA RITA)' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.899088,-43.17717700]} icon={camera}><Popup><span className='title-content'>R. PRIMEIRO DE MARÇO X PRAÇA BARÃO DE LADÁRIO -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000810' alt='R. PRIMEIRO DE MARÇO X PRAÇA BARÃO DE LADÁRIO' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.909237,-43.180223]} icon={camera}><Popup><span className='title-content'>AV. REPÚBLICA DO PARAGUAI X AV. REPÚBLICA DO CHILE -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000812' alt='AV. REPÚBLICA DO PARAGUAI X AV. REPÚBLICA DO CHILE' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908622,-43.178492]} icon={camera}><Popup><span className='title-content'>AV. REPÚBLICA DO CHILE X R. SENADOR DANTAS -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000813' alt='AV. REPÚBLICA DO CHILE X R. SENADOR DANTAS' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906245,-43.173754]} icon={camera}><Popup><span className='title-content'>AV. NILO PEÇANHA X AV. PRESIDENTE ANTÔNIO CARLOS -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000815' alt='AV. NILO PEÇANHA X AV. PRESIDENTE ANTÔNIO CARLOS' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.902289,-43.177587]} icon={camera}><Popup><span className='title-content'>R. BUENOS AIRES X R. DA QUITANDA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000818' alt='R. BUENOS AIRES X R. DA QUITANDA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910569,-43.173752]} icon={camera}><Popup><span className='title-content'>AV. CALÓGERAS X R. SANTA LUZIA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000820' alt='AV. CALÓGERAS X R. SANTA LUZIA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.909656,-43.172181]} icon={camera}><Popup><span className='title-content'>R. SANTA LUZIA X AV. PRESIDENTE ANTÔNIO CARLOS -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000821' alt='R. SANTA LUZIA X AV. PRESIDENTE ANTÔNIO CARLOS' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911354,-43.171541]} icon={camera}><Popup><span className='title-content'>AV. PRESIDENTE ANTÔNIO CARLOS X AV. BEIRA MAR -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000822' alt='AV. PRESIDENTE ANTÔNIO CARLOS X AV. BEIRA MAR' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.938009,-43.177722]} icon={camera}><Popup><span className='title-content'>R. DO CATETE X R. CORRA DUTRA - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000833' alt='R. DO CATETE X R. CORRA DUTRA - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.941040,-43.178764]} icon={camera}><Popup><span className='title-content'>R. MARQUÊS DE ABRANTES X ALTURA DA P.DE BOTAFOGO - FIXA -  Zona Sul - Flamengo</span><img src='http://187.111.99.18:9004/?CODE=000834' alt='R. MARQUÊS DE ABRANTES X ALTURA DA P.DE BOTAFOGO - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94961070,-43.18063271]} icon={camera}><Popup><span className='title-content'>Av. das Nações Unidas X  Botafogo - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000835' alt='Av. das Nações Unidas X  Botafogo - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.953376,-43.171395]} icon={camera}><Popup><span className='title-content'>AV. PASTEUR X AV. PORTUGAL -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=000836' alt='AV. PASTEUR X AV. PORTUGAL' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.961006,-43.175648]} icon={camera}><Popup><span className='title-content'>AV. PRINCESA ISABEL X SAÍDA DO TÚNEL NOVO -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000837' alt='AV. PRINCESA ISABEL X SAÍDA DO TÚNEL NOVO' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97030516,-43.18587461]} icon={camera}><Popup><span className='title-content'>AV. NOSSA Sra DE  COPACABANA X R. FIG. MAGALHÃES - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=000838' alt='AV. NOSSA Sra DE  COPACABANA X R. FIG. MAGALHÃES - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.960786,-43.207992]} icon={camera}><Popup><span className='title-content'>R. JARDIM BOTNICO X R. MARIA ANGLICA -  Zona Sul - Jardim Botanico</span><img src='http://187.111.99.18:9004/?CODE=000839' alt='R. JARDIM BOTNICO X R. MARIA ANGLICA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96302,-43.207585]} icon={camera}><Popup><span className='title-content'>AV. BORGES DE MEDEIROS X R. MARIA ANGÉLICA - FIXA -  Zona Sul - Jardim Botanico</span><img src='http://187.111.99.18:9004/?CODE=000840' alt='AV. BORGES DE MEDEIROS X R. MARIA ANGÉLICA - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.964217,-43.216124]} icon={camera}><Popup><span className='title-content'>AV. LINEU DE P. MACHADO X R. BATISTA DA COSTA - FIXA -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000842' alt='AV. LINEU DE P. MACHADO X R. BATISTA DA COSTA - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.973291,-43.225274]} icon={camera}><Popup><span className='title-content'>JOQUEI - FIXA -  Zona Sul - Gavea</span><img src='http://187.111.99.18:9004/?CODE=000845' alt='JOQUEI - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.970270,-43.217357]} icon={camera}><Popup><span className='title-content'>AV. BORGES DE MEDEIROS X PARQUE DOS PATINS - FIXA -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=000846' alt='AV. BORGES DE MEDEIROS X PARQUE DOS PATINS - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984085,-43.209187]} icon={camera}><Popup><span className='title-content'>R. GARCIA D´ÁVILA (ESTAÇÃO DO METRÔ) -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=000856' alt='R. GARCIA D´ÁVILA (ESTAÇÃO DO METRÔ)' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984817,-43.215916]} icon={camera}><Popup><span className='title-content'>R. PRUDENTE DE MORAIS X AV. BORGES DE MEDEIROS - FIXA -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=000859' alt='R. PRUDENTE DE MORAIS X AV. BORGES DE MEDEIROS - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984936,-43.219755]} icon={camera}><Popup><span className='title-content'>R. GENERAL SAN MARTIN X R. CARLOS GÓIS - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=000862' alt='R. GENERAL SAN MARTIN X R. CARLOS GÓIS - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.985380,-43.222897]} icon={camera}><Popup><span className='title-content'>R. GENERAL SAN MARTIN X PRAIA ANTERO DE QUENTAL - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=000863' alt='R. GENERAL SAN MARTIN X PRAIA ANTERO DE QUENTAL - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.013308,-43.297607]} icon={camera}><Popup><span className='title-content'>R. SARGENTO JOÃO DE FARIA X AV. MINISTRO IVAN LINS - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000869' alt='R. SARGENTO JOÃO DE FARIA X AV. MINISTRO IVAN LINS - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.011972,-43.304979]} icon={camera}><Popup><span className='title-content'>AV. OLEGÁRIO MACIEL X AV. GILBERTO AMADO - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000870' alt='AV. OLEGÁRIO MACIEL X AV. GILBERTO AMADO - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.015203,-43.305800]} icon={camera}><Popup><span className='title-content'>AV. DO PEP X AV. OLEGRIO MACIEL - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000872' alt='AV. DO PEP X AV. OLEGRIO MACIEL - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01142234,-43.30971037]} icon={camera}><Popup><span className='title-content'>AV. ÉRICO VERÍSSIMO X RUA FERNANDO DE MATTOS - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000873' alt='AV. ÉRICO VERÍSSIMO X RUA FERNANDO DE MATTOS - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.015858,-43.413433]} icon={camera}><Popup><span className='title-content'>AV. LÚCIO COSTA X ILHA 5 -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000885' alt='AV. LÚCIO COSTA X ILHA 5' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.009377,-43.440528]} icon={camera}><Popup><span className='title-content'>AV. DAS AMÉRICAS, ALTURA DO BARRA WORLD -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000887' alt='AV. DAS AMÉRICAS, ALTURA DO BARRA WORLD' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.028236,-43.466651]} icon={camera}><Popup><span className='title-content'>AV. LUCIO COSTA X RUA ALBERT SABINN - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=000891' alt='AV. LUCIO COSTA X RUA ALBERT SABINN - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.018612,-43.508016]} icon={camera}><Popup><span className='title-content'>AV. DAS AMERICAS, ALTURA DO Nº 19.400 -  Zona Oeste - Recreio</span><img src='http://187.111.99.18:9004/?CODE=000894' alt='AV. DAS AMERICAS, ALTURA DO Nº 19.400' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.015903,-43.517289]} icon={camera}><Popup><span className='title-content'>AV. DAS AMÉRICAS, SAÍDA DO T. DA GROTA FUNDA - SENTIDO GUARATIBA -  Zona Oeste - Guaratiba</span><img src='http://187.111.99.18:9004/?CODE=000895' alt='AV. DAS AMÉRICAS, SAÍDA DO T. DA GROTA FUNDA - SENTIDO GUARATIBA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.972078,-43.41415799]} icon={camera}><Popup><span className='title-content'>AV. DOS BANDEIRANTES, E/F À GLAXO -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=000901' alt='AV. DOS BANDEIRANTES, E/F À GLAXO' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.968051,-43.41329100]} icon={camera}><Popup><span className='title-content'>AV. DOS BANDEIRANTES, ALTURA DO Nº 7800 -  Zona Oeste - Recreio</span><img src='http://187.111.99.18:9004/?CODE=000902' alt='AV. DOS BANDEIRANTES, ALTURA DO Nº 7800' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90821537,-43.20998279]} icon={camera}><Popup><span className='title-content'>FRANCISCO BICALHO - RUA IDALINA SENRA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000916' alt='FRANCISCO BICALHO - RUA IDALINA SENRA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.985861,-43.19793099]} icon={camera}><Popup><span className='title-content'>PCA. GENERAL OSORIO (ED. XAVIER) -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=000917' alt='PCA. GENERAL OSORIO (ED. XAVIER)' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89761368,-43.19569831]} icon={camera}><Popup><span className='title-content'>RIVADAVIA CORREA X LIVRAMENTO - Pn - 19 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000918' alt='RIVADAVIA CORREA X LIVRAMENTO - Pn - 19' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8984,-43.20731000]} icon={camera}><Popup><span className='title-content'>VIA TRILHOS ALTURA DA RODOVIARIA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000919' alt='VIA TRILHOS ALTURA DA RODOVIARIA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89804,-43.21223]} icon={camera}><Popup><span className='title-content'>AV. BRASIL - RUA SÃO CRISTOVAO - Pn - 21 -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=000920' alt='AV. BRASIL - RUA SÃO CRISTOVAO - Pn - 21' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90027,-43.18098]} icon={camera}><Popup><span className='title-content'>AV. MARECHAL FLORIANO X LARGO DA SANTA RITA - Pn - 23 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000922' alt='AV. MARECHAL FLORIANO X LARGO DA SANTA RITA - Pn - 23' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89579,-43.19801]} icon={camera}><Popup><span className='title-content'>Cidade Do Samba - Pn - 24 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000923' alt='Cidade Do Samba - Pn - 24' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90026,-43.20765]} icon={camera}><Popup><span className='title-content'>R. Equador X Cidade de Lima X Comte. Garcia - Pn - 25 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000924' alt='R. Equador X Cidade de Lima X Comte. Garcia - Pn - 25' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89839,-43.21107]} icon={camera}><Popup><span className='title-content'>Perimetral - Pn - 26 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000925' alt='Perimetral - Pn - 26' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90417,-43.19665]} icon={camera}><Popup><span className='title-content'>R. Senador Pompeu X Rua da América - Pn - 29 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000926' alt='R. Senador Pompeu X Rua da América - Pn - 29' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90342,-43.18856]} icon={camera}><Popup><span className='title-content'>Av. Marechal Floriano X R. Visconde da Gávea - Pn - 32 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000927' alt='Av. Marechal Floriano X R. Visconde da Gávea - Pn - 32' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90034,-43.20502]} icon={camera}><Popup><span className='title-content'>Praça Marechal Hermes - Pn - 33 -  Centro da Cidade - Santo Cristo</span><img src='http://187.111.99.18:9004/?CODE=000928' alt='Praça Marechal Hermes - Pn - 33' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90109,-43.19271]} icon={camera}><Popup><span className='title-content'>R. Bento Ribeiro X R. Cel. Aldomaro Costa - Pn - 35 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000929' alt='R. Bento Ribeiro X R. Cel. Aldomaro Costa - Pn - 35' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88875,-43.21873]} icon={camera}><Popup><span className='title-content'>Av. Brasil Altura Do Cajú - Pn - 36 -  Zona Norte - Caju</span><img src='http://187.111.99.18:9004/?CODE=000930' alt='Av. Brasil Altura Do Cajú - Pn - 36' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90098,-43.18220]} icon={camera}><Popup><span className='title-content'>Av. Marechal Floriano X R. Acre - Pn - 37 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000931' alt='Av. Marechal Floriano X R. Acre - Pn - 37' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90200,-43.18477]} icon={camera}><Popup><span className='title-content'>Av. Marechal Floriano X R. Camerino - Pn - 38 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000932' alt='Av. Marechal Floriano X R. Camerino - Pn - 38' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89365,-43.19103]} icon={camera}><Popup><span className='title-content'>Av. Binário Do Porto X R. Silvino Montenegro - Pn - 39 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000933' alt='Av. Binário Do Porto X R. Silvino Montenegro - Pn - 39' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90183,-43.21039]} icon={camera}><Popup><span className='title-content'>Av. Francisco Bicalho X Av. Pedro II - Pn - 40 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000934' alt='Av. Francisco Bicalho X Av. Pedro II - Pn - 40' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.897724,-43.20610399]} icon={camera}><Popup><span className='title-content'>Av. Rodrigues Alves X R. Cordeiro da Graça - Pn - 41 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000935' alt='Av. Rodrigues Alves X R. Cordeiro da Graça - Pn - 41' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89682,-43.18381]} icon={camera}><Popup><span className='title-content'>Av. Venezuela X R. Edgard Gordilho - Pn - 42 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000936' alt='Av. Venezuela X R. Edgard Gordilho - Pn - 42' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90033,-43.20655]} icon={camera}><Popup><span className='title-content'>Praça Marechal Hermes X Via D1 - Pn - 45 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000938' alt='Praça Marechal Hermes X Via D1 - Pn - 45' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90451,-43.19035]} icon={camera}><Popup><span className='title-content'>Praça Duque de Caxias X Praça Cristiano Otoni - Pn - 46 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000939' alt='Praça Duque de Caxias X Praça Cristiano Otoni - Pn - 46' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89638,-43.20121]} icon={camera}><Popup><span className='title-content'>R. Equador X Rua Do Santo Cristo - Pn - 47 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000940' alt='R. Equador X Rua Do Santo Cristo - Pn - 47' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89592,-43.21467]} icon={camera}><Popup><span className='title-content'>Vd. Gasômetro Sentido Centro - Pn - 48 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000941' alt='Vd. Gasômetro Sentido Centro - Pn - 48' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89471,-43.21501]} icon={camera}><Popup><span className='title-content'>Av. Brasil X Eduardo Luiz Lopes - Pn - 49 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000942' alt='Av. Brasil X Eduardo Luiz Lopes - Pn - 49' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89734,-43.18238]} icon={camera}><Popup><span className='title-content'>Av. Venezuela X R. Sacadura Cabral - Pn - 50 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000943' alt='Av. Venezuela X R. Sacadura Cabral - Pn - 50' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89757,-43.18415]} icon={camera}><Popup><span className='title-content'>R. Edgar Gordilho X Sacadura Cabral - Pn - 55 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000946' alt='R. Edgar Gordilho X Sacadura Cabral - Pn - 55' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89540,-43.19901]} icon={camera}><Popup><span className='title-content'>R. da Gamboa X Binário Do Porto - Pn - 57 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000948' alt='R. da Gamboa X Binário Do Porto - Pn - 57' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89612,-43.19186]} icon={camera}><Popup><span className='title-content'>Rua Pedro Ernesto X Rua Sacadura Cabral - Pn - 60 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000950' alt='Rua Pedro Ernesto X Rua Sacadura Cabral - Pn - 60' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89612,-43.19186]} icon={camera}><Popup><span className='title-content'>TUNEL DA SAÚDE -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000951' alt='TUNEL DA SAÚDE' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89612,-43.19186]} icon={camera}><Popup><span className='title-content'>RUA SACADURA CABRAL X RUA SOUZA E SILVA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000952' alt='RUA SACADURA CABRAL X RUA SOUZA E SILVA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.892940,-43.214198]} icon={camera}><Popup><span className='title-content'>AV. RIO DE JANEIRO (PORTAO 24) -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000953' alt='AV. RIO DE JANEIRO (PORTAO 24)' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90925463,-43.20995206]} icon={camera}><Popup><span className='title-content'>FRANCISCO BICALHO X LINHA FÉRREA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000954' alt='FRANCISCO BICALHO X LINHA FÉRREA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90796569,-43.20925833]} icon={camera}><Popup><span className='title-content'>FRANCISCO BICALHO X PEDRO ALVES  - Pn - 06 . -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000955' alt='FRANCISCO BICALHO X PEDRO ALVES  - Pn - 06 .' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90598914,-43.19792868]} icon={camera}><Popup><span className='title-content'>31 DE MARCO X PRESIDENTE VARGAS  - Pn - 06 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000956' alt='31 DE MARCO X PRESIDENTE VARGAS  - Pn - 06' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89835198,-43.20210220]} icon={camera}><Popup><span className='title-content'>AV. PROF. PEREIRA REIS X AV. CIDADE DE LIMA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000957' alt='AV. PROF. PEREIRA REIS X AV. CIDADE DE LIMA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.903650,-43.172492]} icon={camera}><Popup><span className='title-content'>Tunel da Expressa - Cam37 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000959' alt='Tunel da Expressa - Cam37' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90227625,-43.17366680]} icon={camera}><Popup><span className='title-content'>Tunel da Expressa - Cam42 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000960' alt='Tunel da Expressa - Cam42' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90092718,-43.17466399]} icon={camera}><Popup><span className='title-content'>Tunel da Expressa - Cam43 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000961' alt='Tunel da Expressa - Cam43' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89929667,-43.17625153]} icon={camera}><Popup><span className='title-content'>Tunel da Expressa - Cam48 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000962' alt='Tunel da Expressa - Cam48' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89812055,-43.17896593]} icon={camera}><Popup><span className='title-content'>Tunel da Expressa - Cam51 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000963' alt='Tunel da Expressa - Cam51' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89675664,-43.18350423]} icon={camera}><Popup><span className='title-content'>Tunel da Expressa - Cam30 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000964' alt='Tunel da Expressa - Cam30' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89735953,-43.18156231]} icon={camera}><Popup><span className='title-content'>Tunel da Expressa - Cam27 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000965' alt='Tunel da Expressa - Cam27' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.898882,-43.210943]} icon={camera}><Popup><span className='title-content'>Viaduto do Gasometro sentido Centro cabine PMERJ -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000975' alt='Viaduto do Gasometro sentido Centro cabine PMERJ' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89457,-43.18647]} icon={camera}><Popup><span className='title-content'>Av. Rodrigues Alves X Barão de Tefé - Pn - 02 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000977' alt='Av. Rodrigues Alves X Barão de Tefé - Pn - 02' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90247893,-43.19131448]} icon={camera}><Popup><span className='title-content'>RUA BENTO RIBEIRO X SENADOR POMPEU - Pn - 03 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000978' alt='RUA BENTO RIBEIRO X SENADOR POMPEU - Pn - 03' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89721778,-43.18067428]} icon={camera}><Popup><span className='title-content'>Praça Mauá - Pn - 04 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000979' alt='Praça Mauá - Pn - 04' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90508,-43.20864]} icon={camera}><Popup><span className='title-content'>Rotatória Luiz Mendes de Morais - Pn - 05 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000980' alt='Rotatória Luiz Mendes de Morais - Pn - 05' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90676,-43.20943]} icon={camera}><Popup><span className='title-content'>FRANCISCO BICALHO X VIA D1 - Pn - 06 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000981' alt='FRANCISCO BICALHO X VIA D1 - Pn - 06' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90583,-43.20954]} icon={camera}><Popup><span className='title-content'>Av. Francisco Bicalho II - Pn - 07 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000982' alt='Av. Francisco Bicalho II - Pn - 07' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89927,-43.20939]} icon={camera}><Popup><span className='title-content'>Rodoviária - Pn - 08 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000983' alt='Rodoviária - Pn - 08' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89210,-43.21512]} icon={camera}><Popup><span className='title-content'>RIO DE JANEIRO X ALMIRANTE MARIATH -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000984' alt='RIO DE JANEIRO X ALMIRANTE MARIATH' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90316,-43.19910]} icon={camera}><Popup><span className='title-content'>31 de Março X Rua da América - Pn- 11 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000985' alt='31 de Março X Rua da América - Pn- 11' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89954,-43.20518]} icon={camera}><Popup><span className='title-content'>Av. Cidade de Lima X Cordeiro da Graça - Pn - 13 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000986' alt='Av. Cidade de Lima X Cordeiro da Graça - Pn - 13' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89403883,-43.19628769]} icon={camera}><Popup><span className='title-content'>BINARIO - PROXIMO A CIDADE DO SAMBA- Pn - 14 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000987' alt='BINARIO - PROXIMO A CIDADE DO SAMBA- Pn - 14' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89967,-43.20155]} icon={camera}><Popup><span className='title-content'>PRAÇA DO SANTO CRISTO - Pn - 15 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000988' alt='PRAÇA DO SANTO CRISTO - Pn - 15' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90055,-43.20994]} icon={camera}><Popup><span className='title-content'>Av. Francisco Bicalho (Rampa de Acesso Ao Gasômetro) - Pn - 18 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000989' alt='Av. Francisco Bicalho (Rampa de Acesso Ao Gasômetro) - Pn - 18' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89602,-43.20319]} icon={camera}><Popup><span className='title-content'>Av. Rodrigues Alves Com Pereira Reis - Pn - 16 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=000990' alt='Av. Rodrigues Alves Com Pereira Reis - Pn - 16' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98468306,-43.18958726]} icon={camera}><Popup><span className='title-content'>Av. Atlântica X R. Rainha Elisabeth -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001000' alt='Av. Atlântica X R. Rainha Elisabeth' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97781347,-43.19295061]} icon={camera}><Popup><span className='title-content'>Rua Barata Ribeiro X R. Professor Gastão Bahiana -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001002' alt='Rua Barata Ribeiro X R. Professor Gastão Bahiana' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.901803,-43.210542]} icon={camera}><Popup><span className='title-content'>Av. Francisco Bicalho X Av. Pedro II -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001003' alt='Av. Francisco Bicalho X Av. Pedro II' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99847950,-43.25618078]} icon={camera}><Popup><span className='title-content'>Av. Niemeyer Prox. Hotel Nacional -FIXA -  Zona Sul - Sao Conrado</span><img src='http://187.111.99.18:9004/?CODE=001004' alt='Av. Niemeyer Prox. Hotel Nacional -FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98678318,-43.20296134]} icon={camera}><Popup><span className='title-content'>Av. Vieira Souto X R. Vinícius de Moraes - FIXA -  Zona Sul - Ipanema</span><img src='http://187.111.99.18:9004/?CODE=001006' alt='Av. Vieira Souto X R. Vinícius de Moraes - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90951799,-43.17603413]} icon={camera}><Popup><span className='title-content'>Av. Rio Branco X R. Evaristo da Veiga -  Centro da Cidade - Carioca</span><img src='http://187.111.99.18:9004/?CODE=001008' alt='Av. Rio Branco X R. Evaristo da Veiga' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.935702157835200,-43.20841556392890]} icon={camera}><Popup><span className='title-content'>Av. Rodrigues Alves, alça de descida do Gasômetro -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001009' alt='Av. Rodrigues Alves, alça de descida do Gasômetro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89844828,-43.20974736]} icon={camera}><Popup><span className='title-content'>Av. Rodrigues Alves, alça de descida do Gasômetro -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001009' alt='Av. Rodrigues Alves, alça de descida do Gasômetro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.935129079279400,-43.20916121803500]} icon={camera}><Popup><span className='title-content'>Av. Francisco Bicalho, 46 - frente a Rodoviária -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001010' alt='Av. Francisco Bicalho, 46 - frente a Rodoviária' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89888,-43.210048]} icon={camera}><Popup><span className='title-content'>Av. Francisco Bicalho, 46 - frente a Rodoviária -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001010' alt='Av. Francisco Bicalho, 46 - frente a Rodoviária' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89232086,-43.29481819]} icon={camera}><Popup><span className='title-content'>R. JOS DOS REIS X R. GENERAL CLARINDO -  Zona Norte - Engenho de Dentro</span><img src='http://187.111.99.18:9004/?CODE=001011' alt='R. JOS DOS REIS X R. GENERAL CLARINDO' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89051043,-43.29425698]} icon={camera}><Popup><span className='title-content'>R. DAS OFICINAS X R. JOSÉ DOS REIS -  Zona Norte - Engenho de Dentro</span><img src='http://187.111.99.18:9004/?CODE=001012' alt='R. DAS OFICINAS X R. JOSÉ DOS REIS' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89147164,-43.29162305]} icon={camera}><Popup><span className='title-content'>R. DAS OFICINAS X R. HENRIQUE SCHEID -  Zona Norte - Engenho de Dentro</span><img src='http://187.111.99.18:9004/?CODE=001013' alt='R. DAS OFICINAS X R. HENRIQUE SCHEID' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.895631,-43.291151]} icon={camera}><Popup><span className='title-content'>R. ARQUIAS CORDEIRO X R. DR. PADILHA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001014' alt='R. ARQUIAS CORDEIRO X R. DR. PADILHA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.896753,-43.286711]} icon={camera}><Popup><span className='title-content'>R. Arquias X R. Piaui -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001015' alt='R. Arquias X R. Piaui' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89312702,-43.19642512]} icon={camera}><Popup><span className='title-content'>Av. Rodrigues Alves x R. Rivadávia Correa -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001016' alt='Av. Rodrigues Alves x R. Rivadávia Correa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.973572,-43.38812300]} icon={camera}><Popup><span className='title-content'>AV. EMBAIXADOR ABERLADO BUENO, PRÓX. AO PARQUE AQUÁTICO MARIA LENK -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001017' alt='AV. EMBAIXADOR ABERLADO BUENO, PRÓX. AO PARQUE AQUÁTICO MARIA LENK' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.973124,-43.38819000]} icon={camera}><Popup><span className='title-content'>AV. ABELARDO BUENO, ALTURA DO Nº 523 -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001018' alt='AV. ABELARDO BUENO, ALTURA DO Nº 523' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99722394,-43.36567915]} icon={camera}><Popup><span className='title-content'>Descida do Megulhão x Sentido Barra -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001019' alt='Descida do Megulhão x Sentido Barra' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99743134,-43.36581862]} icon={camera}><Popup><span className='title-content'>Mergulhão Barra -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001020' alt='Mergulhão Barra' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98528646,-43.18908585]} icon={camera}><Popup><span className='title-content'>Drummond 02 -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001021' alt='Drummond 02' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.977439,-43.192835]} icon={camera}><Popup><span className='title-content'>Rock in Rio -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001022' alt='Rock in Rio' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9699206,-43.1821008]} icon={camera}><Popup><span className='title-content'>Painel RIR -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001023' alt='Painel RIR' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.010381672502500,-43.288474138605400]} icon={camera}><Popup><span className='title-content'>TÚNEL DO JOA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001025' alt='TÚNEL DO JOA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89941568,-43.27842867]} icon={camera}><Popup><span className='title-content'>R. Aristides Caire X R. Santa Fé - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001026' alt='R. Aristides Caire X R. Santa Fé - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89946262,-43.27859966]} icon={camera}><Popup><span className='title-content'>R. Aristides Caire X R. Santa Fé - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001027' alt='R. Aristides Caire X R. Santa Fé - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89960593,-43.27806389]} icon={camera}><Popup><span className='title-content'>R. Aristides Caire X R. Santa Fé - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001028' alt='R. Aristides Caire X R. Santa Fé - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89967450,-43.27816514]} icon={camera}><Popup><span className='title-content'>R. Aristides Caire X R. Santa Fé - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001029' alt='R. Aristides Caire X R. Santa Fé - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90227805,-43.27763871]} icon={camera}><Popup><span className='title-content'>R. 24 de Maio X R. Cônego Tobias - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001030' alt='R. 24 de Maio X R. Cônego Tobias - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.902367,-43.277573]} icon={camera}><Popup><span className='title-content'>R. 24 de Maio X R. Cônego Tobias - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001031' alt='R. 24 de Maio X R. Cônego Tobias - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90162576,-43.28052342]} icon={camera}><Popup><span className='title-content'>Rua Ana Barbosa nº12 - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001032' alt='Rua Ana Barbosa nº12 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90179872,-43.28070045]} icon={camera}><Popup><span className='title-content'>Rua Ana Barbosa nº12 - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001033' alt='Rua Ana Barbosa nº12 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90053367,-43.28194500]} icon={camera}><Popup><span className='title-content'>Rua Medina nº165 - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001034' alt='Rua Medina nº165 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90062756,-43.28182161]} icon={camera}><Popup><span className='title-content'>Rua Medina nº165 - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001035' alt='Rua Medina nº165 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89919158,-43.28047196]} icon={camera}><Popup><span className='title-content'>R. Arquias Cordeiro x R. Coração de Maria - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001037' alt='R. Arquias Cordeiro x R. Coração de Maria - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90104722,-43.28030749]} icon={camera}><Popup><span className='title-content'>R. SILVA RABELO 39  - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001038' alt='R. SILVA RABELO 39  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90087673,-43.28048183]} icon={camera}><Popup><span className='title-content'>R. SILVA RABELO 39  - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001039' alt='R. SILVA RABELO 39  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90035459,-43.27960348]} icon={camera}><Popup><span className='title-content'>Av. Amaro Cavalcanti - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001040' alt='Av. Amaro Cavalcanti - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90047319,-43.27970540]} icon={camera}><Popup><span className='title-content'>R. Constança Barbosa x Av. Cavalcanti  - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001041' alt='R. Constança Barbosa x Av. Cavalcanti  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.901962,-43.279594]} icon={camera}><Popup><span className='title-content'>R. Dias da Cruz, 69 - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001042' alt='R. Dias da Cruz, 69 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90196755,-43.27952225]} icon={camera}><Popup><span className='title-content'>R. Dias da Cruz, 69 - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001043' alt='R. Dias da Cruz, 69 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90291088,-43.28215593]} icon={camera}><Popup><span className='title-content'>R. Dias da Cruz, 163 - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001044' alt='R. Dias da Cruz, 163 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9028170,-43.2819950]} icon={camera}><Popup><span className='title-content'>R. Dias da Cruz, 163 - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001045' alt='R. Dias da Cruz, 163 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90165462,-43.27796656]} icon={camera}><Popup><span className='title-content'>R. Arquias Cordeiro 346  - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001046' alt='R. Arquias Cordeiro 346  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90176457,-43.27785793]} icon={camera}><Popup><span className='title-content'>R. Arquias Cordeiro 346  - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001047' alt='R. Arquias Cordeiro 346  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90249500,-43.27522924]} icon={camera}><Popup><span className='title-content'>R. Padre André Moreira 58 - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001048' alt='R. Padre André Moreira 58 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90231340,-43.27552294]} icon={camera}><Popup><span className='title-content'>Terminal Americo Ayres - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001049' alt='Terminal Americo Ayres - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90185542,-43.27634267]} icon={camera}><Popup><span className='title-content'>R. Carolina Meier, 26 - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001050' alt='R. Carolina Meier, 26 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90175597,-43.27628366]} icon={camera}><Popup><span className='title-content'>R. Carolina Meier, 26 - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001051' alt='R. Carolina Meier, 26 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90211073,-43.27869533]} icon={camera}><Popup><span className='title-content'>R. Dias da Cruz, 19 - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001052' alt='R. Dias da Cruz, 19 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90199213,-43.27867388]} icon={camera}><Popup><span className='title-content'>R. Dias da Cruz, 19 - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001053' alt='R. Dias da Cruz, 19 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.901713,-43.275514]} icon={camera}><Popup><span className='title-content'>Terminal Américo Ayres - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001054' alt='Terminal Américo Ayres - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90179144,-43.27518341]} icon={camera}><Popup><span className='title-content'>Terminal Américo Ayres - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001055' alt='Terminal Américo Ayres - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90126856,-43.27836554]} icon={camera}><Popup><span className='title-content'>Hospital Salgado Filho  - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001056' alt='Hospital Salgado Filho  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.901128,-43.278867]} icon={camera}><Popup><span className='title-content'>Av. Amaro Cavalcanti nº135  - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001057' alt='Av. Amaro Cavalcanti nº135  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90106314,-43.27890857]} icon={camera}><Popup><span className='title-content'>Av. Amaro Cavalcanti nº135  - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001058' alt='Av. Amaro Cavalcanti nº135  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90018106,-43.27859743]} icon={camera}><Popup><span className='title-content'>Praça Jardim so Méier  - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001059' alt='Praça Jardim so Méier  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.87068846,-43.34182943]} icon={camera}><Popup><span className='title-content'>Estr. Do Portela 247 - FIXA -  Zona Norte - Madureira</span><img src='http://187.111.99.18:9004/?CODE=001060' alt='Estr. Do Portela 247 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90891670,-43.22255183]} icon={camera}><Popup><span className='title-content'>R. GENERAL HERCULANO GOMES - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001061' alt='R. GENERAL HERCULANO GOMES - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90807723,-43.22174629]} icon={camera}><Popup><span className='title-content'>QUINTA DA BOA VISTA 2 - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=001062' alt='QUINTA DA BOA VISTA 2 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95514142,-43.33728140]} icon={camera}><Popup><span className='title-content'>Estr. de Jacarepaguá X Estr.Do Engenho Dágua - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=001064' alt='Estr. de Jacarepaguá X Estr.Do Engenho Dágua - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94757464,-43.33976878]} icon={camera}><Popup><span className='title-content'>Estr. T. Coronel M. de Aragão X Estr. de Jacarepaguá - FIXA -  Zona Oeste - Anil</span><img src='http://187.111.99.18:9004/?CODE=001065' alt='Estr. T. Coronel M. de Aragão X Estr. de Jacarepaguá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93959419,-43.33679093]} icon={camera}><Popup><span className='title-content'>R. Tirol X R. Cmte Rubens Silva - FIXA -  Zona Oeste - Freguesia</span><img src='http://187.111.99.18:9004/?CODE=001068' alt='R. Tirol X R. Cmte Rubens Silva - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93733752,-43.33727132]} icon={camera}><Popup><span className='title-content'>Estr. dos Três Rios X R. Cmte Rubens Silva - FIXA -  Zona Oeste - Freguesia</span><img src='http://187.111.99.18:9004/?CODE=001069' alt='Estr. dos Três Rios X R. Cmte Rubens Silva - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98547633,-43.22201199]} icon={camera}><Popup><span className='title-content'>Av. Gal. San Martin X R. João Lira - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=001071' alt='Av. Gal. San Martin X R. João Lira - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96416266,-43.21623665]} icon={camera}><Popup><span className='title-content'>AV. LINEU DE P. MACHADO X R. JOSÉ JOAQUIM SEABRA - FIXA -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=001073' alt='AV. LINEU DE P. MACHADO X R. JOSÉ JOAQUIM SEABRA - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97307692,-43.22498498]} icon={camera}><Popup><span className='title-content'>JOQUEI - FIXA -  Zona Sul - Gavea</span><img src='http://187.111.99.18:9004/?CODE=001074' alt='JOQUEI - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98553803,-43.22384113]} icon={camera}><Popup><span className='title-content'>R. GENERAL SAN MARTIN X PRAIA A. DE QUENTAL - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=001075' alt='R. GENERAL SAN MARTIN X PRAIA A. DE QUENTAL - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93451957,-43.24304072]} icon={camera}><Popup><span className='title-content'>Rua Conde de Bonfim X R. Radmaker - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001076' alt='Rua Conde de Bonfim X R. Radmaker - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93880518,-43.24760535]} icon={camera}><Popup><span className='title-content'>R. Conde de Bonfim X R. Basileia - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001077' alt='R. Conde de Bonfim X R. Basileia - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93431949,-43.24317483]} icon={camera}><Popup><span className='title-content'>Rua Conde de Bonfim X R. Radmaker - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001078' alt='Rua Conde de Bonfim X R. Radmaker - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90187305,-43.27958729]} icon={camera}><Popup><span className='title-content'>R. Dias da Cruz, 69 - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001079' alt='R. Dias da Cruz, 69 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91420400,-43.37502635]} icon={camera}><Popup><span className='title-content'>Estr. Do Cafundá X Estr. Meringuava - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=001080' alt='Estr. Do Cafundá X Estr. Meringuava - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.87836939,-43.44057403]} icon={camera}><Popup><span className='title-content'>Av. de Santa Cruz X Estr. Do Realengo - FIXA -  Zona Norte - Padre Miguel</span><img src='http://187.111.99.18:9004/?CODE=001082' alt='Av. de Santa Cruz X Estr. Do Realengo - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88795511,-43.22529027]} icon={camera}><Popup><span className='title-content'>Rua Bela 909 - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=001083' alt='Rua Bela 909 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96300703,-43.20745826]} icon={camera}><Popup><span className='title-content'>AV. BORGES DE MEDEIROS X R. MARIA ANGÉLICA - FIXA -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=001086' alt='AV. BORGES DE MEDEIROS X R. MARIA ANGÉLICA - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98477934,-43.21605078]} icon={camera}><Popup><span className='title-content'>R. PRUDENTE DE MORAIS X AV. BORGES DE MEDEIROS - FIXA -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=001087' alt='R. PRUDENTE DE MORAIS X AV. BORGES DE MEDEIROS - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86517791,-43.42731398]} icon={camera}><Popup><span className='title-content'>Av. Brasil X Altura Estr. Do Engenho Novo - FIXA -  Zona Norte - Realengo</span><img src='http://187.111.99.18:9004/?CODE=001090' alt='Av. Brasil X Altura Estr. Do Engenho Novo - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.84713155,-43.32467703]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Jr. X Av. Monsenhor Felix - FIXA -  Zona Norte - Iraja</span><img src='http://187.111.99.18:9004/?CODE=001091' alt='Av. Pastor Martin Luther King Jr. X Av. Monsenhor Felix - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.87858753,-43.35753357]} icon={camera}><Popup><span className='title-content'>Estr. Intendente Magalhães X R. Henrique de Melo - FIXA -  Zona Norte - Campinho</span><img src='http://187.111.99.18:9004/?CODE=001092' alt='Estr. Intendente Magalhães X R. Henrique de Melo - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88304032,-43.34249566]} icon={camera}><Popup><span className='title-content'>R. Candido Benicio X Estrada Intendente Magalhães - FIXA -  Zona Norte - Campinho</span><img src='http://187.111.99.18:9004/?CODE=001093' alt='R. Candido Benicio X Estrada Intendente Magalhães - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.839921,-43.281957]} icon={camera}><Popup><span className='title-content'>Av. Braz de Pina X R CMTE. CONCEIÇÃO - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=001097' alt='Av. Braz de Pina X R CMTE. CONCEIÇÃO - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.87788953,-43.43480649]} icon={camera}><Popup><span className='title-content'>Av. Santa Cruz X R. Gal. Sezefredo - FIXA -  Zona Norte - Realengo</span><img src='http://187.111.99.18:9004/?CODE=001099' alt='Av. Santa Cruz X R. Gal. Sezefredo - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91428182,-43.55418511]} icon={camera}><Popup><span className='title-content'>R. Olinda Ellis X Alt. Centro Esportivo Miécimo da Silva - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001101' alt='R. Olinda Ellis X Alt. Centro Esportivo Miécimo da Silva - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89411486,-43.58504097]} icon={camera}><Popup><span className='title-content'>Estr. Do Campinho X Estr. Santa Maria - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001107' alt='Estr. Do Campinho X Estr. Santa Maria - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92460734,-43.22441556]} icon={camera}><Popup><span className='title-content'>Conde de Bonfim X Alzira Brandão - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001109' alt='Conde de Bonfim X Alzira Brandão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.925230,-43.225606]} icon={camera}><Popup><span className='title-content'>R. Conde de Bonfim X R. dos Araújos - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001110' alt='R. Conde de Bonfim X R. dos Araújos - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88663036,-43.28821450]} icon={camera}><Popup><span className='title-content'>Av. D. Helder Câmara x R. Henrique Scheid - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001111' alt='Av. D. Helder Câmara x R. Henrique Scheid - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89730765,-43.28563647]} icon={camera}><Popup><span className='title-content'>R. Amaro Cavalcanti X Viaduto de Todos Os Santos - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001112' alt='R. Amaro Cavalcanti X Viaduto de Todos Os Santos - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.895392,-43.298735]} icon={camera}><Popup><span className='title-content'>R. GOIÁS X R. GUINEZA - FIXA -  Zona Norte - Piedade</span><img src='http://187.111.99.18:9004/?CODE=001113' alt='R. GOIÁS X R. GUINEZA - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90447470,-43.22063443]} icon={camera}><Popup><span className='title-content'>PORTÃO DA RÓTULA DA QUINTA DA BOA VISTA - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=001114' alt='PORTÃO DA RÓTULA DA QUINTA DA BOA VISTA - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90489720,-43.22047886]} icon={camera}><Popup><span className='title-content'>PORTÃO DA RÓTULA DA QUINTA DA BOA VISTA - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=001115' alt='PORTÃO DA RÓTULA DA QUINTA DA BOA VISTA - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97714671,-43.23073507]} icon={camera}><Popup><span className='title-content'>Rua Marquês de São Vicente X R. Vice-Gov. Rubens Berardo - FIXA -  Zona Sul - Gavea</span><img src='http://187.111.99.18:9004/?CODE=001117' alt='Rua Marquês de São Vicente X R. Vice-Gov. Rubens Berardo - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91369517,-43.23550774]} icon={camera}><Popup><span className='title-content'>Boulevard 28 de Setembro  - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001118' alt='Boulevard 28 de Setembro  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91362722,-43.23541453]} icon={camera}><Popup><span className='title-content'>Boulevard 28 de Setembro  - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001119' alt='Boulevard 28 de Setembro  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91340611,-43.23527841]} icon={camera}><Popup><span className='title-content'>Rua São Francisco Xavier 478 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001120' alt='Rua São Francisco Xavier 478 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91353458,-43.23533340]} icon={camera}><Popup><span className='title-content'>R. São Francisco Xavier, 478 - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001121' alt='R. São Francisco Xavier, 478 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88206900807290,-43.32496442014880]} icon={camera}><Popup><span className='title-content'>Av. D. Helder Câmara X R. Cer. Daltro - FIXA -  Zona Norte - Cascadura</span><img src='http://187.111.99.18:9004/?CODE=001122' alt='Av. D. Helder Câmara X R. Cer. Daltro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.87626146,-43.42953026]} icon={camera}><Popup><span className='title-content'>R. Bernardo de Vasconcelos X Praça Do Canhão - FIXA -  Zona Norte - Realengo</span><img src='http://187.111.99.18:9004/?CODE=001123' alt='R. Bernardo de Vasconcelos X Praça Do Canhão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90878481,-43.23869500]} icon={camera}><Popup><span className='title-content'>R. SÃO FRANCISCO XAVIER X R. 8 DE DEZEMBRO - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001124' alt='R. SÃO FRANCISCO XAVIER X R. 8 DE DEZEMBRO - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98494587,-43.21941167]} icon={camera}><Popup><span className='title-content'>R. GENERAL SAN MARTIN X R. CARLOS GÓIS - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=001127' alt='R. GENERAL SAN MARTIN X R. CARLOS GÓIS - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88209850,-43.33209376]} icon={camera}><Popup><span className='title-content'>Av. Ernani Cardoso X R. Padre Telêmaco - FIXA -  Zona Norte - Campinho</span><img src='http://187.111.99.18:9004/?CODE=001128' alt='Av. Ernani Cardoso X R. Padre Telêmaco - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92372071,-43.36910034]} icon={camera}><Popup><span className='title-content'>R. André Rocha X R. Mal. José Beviláqua -FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=001129' alt='R. André Rocha X R. Mal. José Beviláqua -FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91128841,-43.19353875]} icon={camera}><Popup><span className='title-content'>R. Santana X R. Frei Caneca - FIXA -  Centro da Cidade - Praca Onze</span><img src='http://187.111.99.18:9004/?CODE=001130' alt='R. Santana X R. Frei Caneca - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91105458,-43.19264235]} icon={camera}><Popup><span className='title-content'>R. Mem de Sá X R. de Santana - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001132' alt='R. Mem de Sá X R. de Santana - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.962791,-43.206331]} icon={camera}><Popup><span className='title-content'>AV. BORGES DE MEDEIROS Nº3709 - FIXA -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=001133' alt='AV. BORGES DE MEDEIROS Nº3709 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96290707,-43.20630820]} icon={camera}><Popup><span className='title-content'>AV. BORGES DE MEDEIROS Nº3709 - FIXA -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=001134' alt='AV. BORGES DE MEDEIROS Nº3709 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00015987,-43.36986556]} icon={camera}><Popup><span className='title-content'>Av. das Américas x Av. Ayrton Senna - Cebolão - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001135' alt='Av. das Américas x Av. Ayrton Senna - Cebolão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94362303,-43.18365921]} icon={camera}><Popup><span className='title-content'>R. Muniz Barreto X R. Marques de Olinda - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001138' alt='R. Muniz Barreto X R. Marques de Olinda - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94362550,-43.18380405]} icon={camera}><Popup><span className='title-content'>R. Muniz Barreto X R. Marques de Olinda - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001139' alt='R. Muniz Barreto X R. Marques de Olinda - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96923966,-43.18086438]} icon={camera}><Popup><span className='title-content'>Av. Atlântica X R. Rep. Do Peru - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001140' alt='Av. Atlântica X R. Rep. Do Peru - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97015701,-43.21741533]} icon={camera}><Popup><span className='title-content'>AV. BORGES DE MEDEIROS X PARQUE DOS PATINS - FIXA -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=001141' alt='AV. BORGES DE MEDEIROS X PARQUE DOS PATINS - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96323339,-43.20447550]} icon={camera}><Popup><span className='title-content'>Av. Borges de Medeiros X Av. Epitácio Pessoa - FIXA -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=001142' alt='Av. Borges de Medeiros X Av. Epitácio Pessoa - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96335440,-43.20430920]} icon={camera}><Popup><span className='title-content'>Av. Borges de Medeiros X Av. Epitácio Pessoa - FIXA -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=001143' alt='Av. Borges de Medeiros X Av. Epitácio Pessoa - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99225659,-43.25182778]} icon={camera}><Popup><span className='title-content'>Auto Estr. Lagoa-Barra X Est. da Gávea - FIXA -  Zona Sul - Sao Conrado</span><img src='http://187.111.99.18:9004/?CODE=001144' alt='Auto Estr. Lagoa-Barra X Est. da Gávea - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99240733,-43.25190403]} icon={camera}><Popup><span className='title-content'>Auto Estr. Lagoa-Barra X Est. da Gávea - FIXA -  Zona Sul - Sao Conrado</span><img src='http://187.111.99.18:9004/?CODE=001145' alt='Auto Estr. Lagoa-Barra X Est. da Gávea - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.84178054,-43.27451741]} icon={camera}><Popup><span className='title-content'>R. Ibiapina X R. Monsenhor Alves - Brt - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=001146' alt='R. Ibiapina X R. Monsenhor Alves - Brt - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.84186379,-43.27420118]} icon={camera}><Popup><span className='title-content'>R. Ibiapina X R. Monsenhor Alves - Brt - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=001147' alt='R. Ibiapina X R. Monsenhor Alves - Brt - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00771057,-43.30250129]} icon={camera}><Popup><span className='title-content'>Estr. da Barra da Tijuca 506 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001148' alt='Estr. da Barra da Tijuca 506 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00763403,-43.30255091]} icon={camera}><Popup><span className='title-content'>Estr. da Barra da Tijuca 506 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001149' alt='Estr. da Barra da Tijuca 506 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00414375,-43.30451097]} icon={camera}><Popup><span className='title-content'>Estr. da Barra da Tijuca x Hotel Serramar  Fixa -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001150' alt='Estr. da Barra da Tijuca x Hotel Serramar  Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00402524,-43.30453511]} icon={camera}><Popup><span className='title-content'>Estr. da Barra da Tijuca x Hotel Serramar  Fixa -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001151' alt='Estr. da Barra da Tijuca x Hotel Serramar  Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91270999,-43.18437761]} icon={camera}><Popup><span className='title-content'>AV. MEM DE SÁ X R. DOS INVÁLIDOS - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001152' alt='AV. MEM DE SÁ X R. DOS INVÁLIDOS - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90830653,-43.18628424]} icon={camera}><Popup><span className='title-content'>R. Visconde Do Rio Branco X R. Dos Inválidos - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001154' alt='R. Visconde Do Rio Branco X R. Dos Inválidos - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92339046,-43.23149569]} icon={camera}><Popup><span className='title-content'>R. CONDE DE BONFIM Nº 305 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001155' alt='R. CONDE DE BONFIM Nº 305 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91414525,-43.17467879]} icon={camera}><Popup><span className='title-content'>Av. Rio Branco, 4700 - Glória - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001156' alt='Av. Rio Branco, 4700 - Glória - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91405137,-43.17467677]} icon={camera}><Popup><span className='title-content'>Av. Rio Branco, 4700 - Glória - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001157' alt='Av. Rio Branco, 4700 - Glória - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9145149,-43.1761714]} icon={camera}><Popup><span className='title-content'>Av. Augusto Severo  Nº 1746 - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001158' alt='Av. Augusto Severo  Nº 1746 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91460013,-43.17578516]} icon={camera}><Popup><span className='title-content'>Av. Augusto Severo Nº 1746 - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001159' alt='Av. Augusto Severo Nº 1746 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.87984191,-43.34176420]} icon={camera}><Popup><span className='title-content'>R. Domingos Lopes X R. Maria Lopes - FIXA -  Zona Norte - Madureira</span><img src='http://187.111.99.18:9004/?CODE=001160' alt='R. Domingos Lopes X R. Maria Lopes - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914249,-43.17755]} icon={camera}><Popup><span className='title-content'>RUA TEIXEIRA DE FREITAS 59 - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001161' alt='RUA TEIXEIRA DE FREITAS 59 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91426505,-43.17776860]} icon={camera}><Popup><span className='title-content'>RUA TEIXEIRA DE FREITAS 59 - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001162' alt='RUA TEIXEIRA DE FREITAS 59 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95587343,-43.17793315]} icon={camera}><Popup><span className='title-content'>AV. LAOURO SODRÉ X R. GENERAL GOIS MONTEIRO - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001163' alt='AV. LAOURO SODRÉ X R. GENERAL GOIS MONTEIRO - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95574006,-43.17859833]} icon={camera}><Popup><span className='title-content'>AV. LAOURO SODRÉ X R. GENERAL GOIS MONTEIRO - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001164' alt='AV. LAOURO SODRÉ X R. GENERAL GOIS MONTEIRO - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91185324,-43.18236632]} icon={camera}><Popup><span className='title-content'>R. DO LAVRADIO, 151 - CENTRO - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001167' alt='R. DO LAVRADIO, 151 - CENTRO - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91196071,-43.18202836]} icon={camera}><Popup><span className='title-content'>R. DO LAVRADIO, 151 - CENTRO - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001168' alt='R. DO LAVRADIO, 151 - CENTRO - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9110065,-43.1851347]} icon={camera}><Popup><span className='title-content'>Rua dos Inválidos, 99 - Centro - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001169' alt='Rua dos Inválidos, 99 - Centro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91114856,-43.18508843]} icon={camera}><Popup><span className='title-content'>Rua dos Inválidos, 99 - Centro - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001170' alt='Rua dos Inválidos, 99 - Centro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914749,-43.206623]} icon={camera}><Popup><span className='title-content'>Rua  Estácio de Sá, 165 - Estácio - FIXA -  Centro da Cidade - Estacio</span><img src='http://187.111.99.18:9004/?CODE=001171' alt='Rua  Estácio de Sá, 165 - Estácio - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91464770,-43.20683221]} icon={camera}><Popup><span className='title-content'>Rua  Estácio de Sá, 165 - Estácio - FIXA -  Centro da Cidade - Estacio</span><img src='http://187.111.99.18:9004/?CODE=001172' alt='Rua  Estácio de Sá, 165 - Estácio - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97183921,-43.18459313]} icon={camera}><Popup><span className='title-content'>Av. Atlântica X R. Figueiredo de Magalhães - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001173' alt='Av. Atlântica X R. Figueiredo de Magalhães - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97176698,-43.18451937]} icon={camera}><Popup><span className='title-content'>Av. Atlântica X R. Figueiredo de Magalhães - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001174' alt='Av. Atlântica X R. Figueiredo de Magalhães - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96514728,-43.17355044]} icon={camera}><Popup><span className='title-content'>Av. Atlântica X Av. Princesa Isabel - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001175' alt='Av. Atlântica X Av. Princesa Isabel - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96506146,-43.17342706]} icon={camera}><Popup><span className='title-content'>Av. Atlântica X Av. Princesa Isabel - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001176' alt='Av. Atlântica X Av. Princesa Isabel - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96364467,-43.16979344]} icon={camera}><Popup><span className='title-content'>Av. Atlântica X R. Anchieta - FIXA -  Zona Sul - Leme</span><img src='http://187.111.99.18:9004/?CODE=001177' alt='Av. Atlântica X R. Anchieta - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96356008,-43.16962312]} icon={camera}><Popup><span className='title-content'>Av. Atlântica X R. Anchieta -FIXA -  Zona Sul - Leme</span><img src='http://187.111.99.18:9004/?CODE=001178' alt='Av. Atlântica X R. Anchieta -FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97751308,-43.19272234]} icon={camera}><Popup><span className='title-content'>R. Miguel Lemos X R. Barata Ribeiro - Fixa -  Zona Sul - Cantagalo</span><img src='http://187.111.99.18:9004/?CODE=001179' alt='R. Miguel Lemos X R. Barata Ribeiro - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97742665,-43.19270625]} icon={camera}><Popup><span className='title-content'>R. Miguel Lemos X R. Barata Ribeiro - Fixa -  Zona Sul - Cantagalo</span><img src='http://187.111.99.18:9004/?CODE=001180' alt='R. Miguel Lemos X R. Barata Ribeiro - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95904536,-43.19118395]} icon={camera}><Popup><span className='title-content'>R. Real Grandeza  X R. Anibal Reis - Fixa -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001181' alt='R. Real Grandeza  X R. Anibal Reis - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95917316,-43.19112025]} icon={camera}><Popup><span className='title-content'>R. Real Grandeza  X R. Anibal Reis - Fixa -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001182' alt='R. Real Grandeza  X R. Anibal Reis - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97828036,-43.18848729]} icon={camera}><Popup><span className='title-content'>Av. Atlântica X R. Miguel Lemos - Fixa -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001184' alt='Av. Atlântica X R. Miguel Lemos - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97841618,-43.18870589]} icon={camera}><Popup><span className='title-content'>Av. Atlântica X R. Miguel Lemos - Fixa -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001185' alt='Av. Atlântica X R. Miguel Lemos - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97839395,-43.18842829]} icon={camera}><Popup><span className='title-content'>Av. Atlântica X R. Miguel Lemos - Fixa -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001186' alt='Av. Atlântica X R. Miguel Lemos - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984869,-43.189226]} icon={camera}><Popup><span className='title-content'>Av. Atlântica, 185 - Copacabana - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001187' alt='Av. Atlântica, 185 - Copacabana - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98495295,-43.18933328]} icon={camera}><Popup><span className='title-content'>Av. Atlântica, 185 - Copacabana - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001188' alt='Av. Atlântica, 185 - Copacabana - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98585917,-43.18872308]} icon={camera}><Popup><span className='title-content'>Av. Atlântica, 185 - Copacabana - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001189' alt='Av. Atlântica, 185 - Copacabana - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98606288,-43.18865200]} icon={camera}><Popup><span className='title-content'>AV. ATLÂNTICA 185 - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001190' alt='AV. ATLÂNTICA 185 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984932,-43.188939]} icon={camera}><Popup><span className='title-content'>AV. ATLÂNTICA 185 - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001191' alt='AV. ATLÂNTICA 185 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98503570,-43.18889340]} icon={camera}><Popup><span className='title-content'>AV. ATLÂNTICA 185 - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001192' alt='AV. ATLÂNTICA 185 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98510731,-43.18899532]} icon={camera}><Popup><span className='title-content'>AV. ATLÂNTICA 185 - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001193' alt='AV. ATLÂNTICA 185 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98426572,-43.18918705]} icon={camera}><Popup><span className='title-content'>AV. ATLÂNTICA 185 - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001194' alt='AV. ATLÂNTICA 185 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98410892,-43.18925009]} icon={camera}><Popup><span className='title-content'>AV. ATLÂNTICA 175 - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001195' alt='AV. ATLÂNTICA 175 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98519621,-43.18883975]} icon={camera}><Popup><span className='title-content'>AV. ATLÂNTICA 175 - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001196' alt='AV. ATLÂNTICA 175 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98383,-43.189629]} icon={camera}><Popup><span className='title-content'>AV ATLANTICA X  R. JULIO DE CASTILHO  - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001197' alt='AV ATLANTICA X  R. JULIO DE CASTILHO  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98404976,-43.18953512]} icon={camera}><Popup><span className='title-content'>AV ATLANTICA X  R. JULIO DE CASTILHO  - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001198' alt='AV ATLANTICA X  R. JULIO DE CASTILHO  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.986276,-43.188942]} icon={camera}><Popup><span className='title-content'>Av. Atlântica, 4240  - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001199' alt='Av. Atlântica, 4240  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98621673,-43.18881325]} icon={camera}><Popup><span className='title-content'>Av. Atlântica, 4240  - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001200' alt='Av. Atlântica, 4240  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.983351,-43.189877]} icon={camera}><Popup><span className='title-content'>Av. Atlântica - Copacabana  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001201' alt='Av. Atlântica - Copacabana  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98351891,-43.18966510]} icon={camera}><Popup><span className='title-content'>Av. Atlântica - Copacabana  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001202' alt='Av. Atlântica - Copacabana  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.981655,-43.189716]} icon={camera}><Popup><span className='title-content'>Avenida Atlântica QC 41  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001203' alt='Avenida Atlântica QC 41  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98120064,-43.18969454]} icon={camera}><Popup><span className='title-content'>Avenida Atlântica QC 41  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001204' alt='Avenida Atlântica QC 41  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98350867,-43.18939034]} icon={camera}><Popup><span className='title-content'>Avenida Atlântica, 3958, em frente à, R. Julio  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001205' alt='Avenida Atlântica, 3958, em frente à, R. Julio  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98350867,-43.18949227]} icon={camera}><Popup><span className='title-content'>Avenida Atlântica, 3958, em frente à, R. Julio  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001206' alt='Avenida Atlântica, 3958, em frente à, R. Julio  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98331113,-43.18935279]} icon={camera}><Popup><span className='title-content'>Avenida Atlântica, 3958, em frente à, R. Julio  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001207' alt='Avenida Atlântica, 3958, em frente à, R. Julio  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98359757,-43.18944667]} icon={camera}><Popup><span className='title-content'>Avenida Atlântica, 3958, em frente à, R. Julio  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001208' alt='Avenida Atlântica, 3958, em frente à, R. Julio  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.980605,-43.189594]} icon={camera}><Popup><span className='title-content'>Copacabana Prox. Posto 5  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001209' alt='Copacabana Prox. Posto 5  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98075439,-43.18962618]} icon={camera}><Popup><span className='title-content'>Copacabana Prox. Posto 5  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001210' alt='Copacabana Prox. Posto 5  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.982754,-43.18955]} icon={camera}><Popup><span className='title-content'>Av Atlântica 3888 - Praia -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001211' alt='Av Atlântica 3888 - Praia -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98289721,-43.18944539]} icon={camera}><Popup><span className='title-content'>Av Atlântica 3888 - Praia -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001212' alt='Av Atlântica 3888 - Praia -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98269226,-43.18946416]} icon={camera}><Popup><span className='title-content'>Av Atlântica 3888 - Praia -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001213' alt='Av Atlântica 3888 - Praia -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98255892,-43.18960900]} icon={camera}><Popup><span className='title-content'>Av Atlântica 3888 - Praia -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001214' alt='Av Atlântica 3888 - Praia -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95953612,-43.19104971]} icon={camera}><Popup><span className='title-content'>R. Real Grandeza, 384 - Botafogo  -  FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001215' alt='R. Real Grandeza, 384 - Botafogo  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95974357,-43.19091560]} icon={camera}><Popup><span className='title-content'>R. Real Grandeza, 384 - Botafogo  -  FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001216' alt='R. Real Grandeza, 384 - Botafogo  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96069380,-43.19053003]} icon={camera}><Popup><span className='title-content'>R. Real Grandeza x Saída do Túnel Alaor Prata -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001217' alt='R. Real Grandeza x Saída do Túnel Alaor Prata -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96084259,-43.19058837]} icon={camera}><Popup><span className='title-content'>R. Real Grandeza x Saída do Túnel Alaor Prata -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001218' alt='R. Real Grandeza x Saída do Túnel Alaor Prata -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96284882,-43.16709380]} icon={camera}><Popup><span className='title-content'>Av. Atlântica x Ponta do Leme -FIXA -  Zona Sul - Leme</span><img src='http://187.111.99.18:9004/?CODE=001219' alt='Av. Atlântica x Ponta do Leme -FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96283956,-43.16700998]} icon={camera}><Popup><span className='title-content'>Av. Atlântica x Ponta do Leme -FIXA -  Zona Sul - Leme</span><img src='http://187.111.99.18:9004/?CODE=001220' alt='Av. Atlântica x Ponta do Leme -FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96790369,-43.18778206]} icon={camera}><Popup><span className='title-content'>R. Tonelero X R. Figueiredo Magalhaes - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001221' alt='R. Tonelero X R. Figueiredo Magalhaes - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96783763,-43.18792221]} icon={camera}><Popup><span className='title-content'>R. Tonelero X R. Figueiredo Magalhães - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001222' alt='R. Tonelero X R. Figueiredo Magalhães - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96920080,-43.18674173]} icon={camera}><Popup><span className='title-content'>R. Barata Ribeiro, 450 -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001223' alt='R. Barata Ribeiro, 450' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97024033,-43.18610193]} icon={camera}><Popup><span className='title-content'>AV. NOSSA Sra DE  COPACABANA X R. FIG. MAGALHÃES - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001226' alt='AV. NOSSA Sra DE  COPACABANA X R. FIG. MAGALHÃES - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97015081,-43.18597184]} icon={camera}><Popup><span className='title-content'>AV. NOSSA Sra DE  COPACABANA X R. FIG. MAGALHÃES - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001227' alt='AV. NOSSA Sra DE  COPACABANA X R. FIG. MAGALHÃES - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97034652,-43.18601744]} icon={camera}><Popup><span className='title-content'>AV. NOSSA Sra DE  COPACABANA X R. FIG. MAGALHÃES - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001228' alt='AV. NOSSA Sra DE  COPACABANA X R. FIG. MAGALHÃES - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96309393,-43.16783074]} icon={camera}><Popup><span className='title-content'>Av. Atlântica x Ponta do Leme -FIXA -  Zona Sul - Leme</span><img src='http://187.111.99.18:9004/?CODE=001229' alt='Av. Atlântica x Ponta do Leme -FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96313901,-43.16794473]} icon={camera}><Popup><span className='title-content'>Av. Atlântica x Ponta do Leme -FIXA -  Zona Sul - Leme</span><img src='http://187.111.99.18:9004/?CODE=001230' alt='Av. Atlântica x Ponta do Leme -FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.962640,-43.165506]} icon={camera}><Popup><span className='title-content'>Av. Atlântica x Ponta do Leme - FIXA -  Zona Sul - Leme</span><img src='http://187.111.99.18:9004/?CODE=001231' alt='Av. Atlântica x Ponta do Leme - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.962636,-43.165424]} icon={camera}><Popup><span className='title-content'>Av. Atlântica x Ponta do Leme - FIXA -  Zona Sul - Leme</span><img src='http://187.111.99.18:9004/?CODE=001232' alt='Av. Atlântica x Ponta do Leme - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.962656,-43.164759]} icon={camera}><Popup><span className='title-content'>Av. Atlântica x Ponta do Leme - FIXA -  Zona Sul - Leme</span><img src='http://187.111.99.18:9004/?CODE=001233' alt='Av. Atlântica x Ponta do Leme - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.962674,-43.164681]} icon={camera}><Popup><span className='title-content'>Av. Atlântica x Ponta do Leme - FIXA -  Zona Sul - Leme</span><img src='http://187.111.99.18:9004/?CODE=001234' alt='Av. Atlântica x Ponta do Leme - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.976981,-43.188538]} icon={camera}><Popup><span className='title-content'>Av. Atlântica x Hotel Verdinho - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001235' alt='Av. Atlântica x Hotel Verdinho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97710200,-43.18861578]} icon={camera}><Popup><span className='title-content'>Av. Atlântica x Hotel Verdinho - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001236' alt='Av. Atlântica x Hotel Verdinho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97595116,-43.18773186]} icon={camera}><Popup><span className='title-content'>Av. Atlântica x R Bolivar - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001238' alt='Av. Atlântica x R Bolivar - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97568323,-43.18740261]} icon={camera}><Popup><span className='title-content'>Av. Atlântica x R Barão de Ipanema - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001239' alt='Av. Atlântica x R Barão de Ipanema - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97560976,-43.18735165]} icon={camera}><Popup><span className='title-content'>Av. Atlântica x Rua Barão de Ipanema - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001240' alt='Av. Atlântica x Rua Barão de Ipanema - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97712103,-43.18817421]} icon={camera}><Popup><span className='title-content'>Av. Atlântica x  Quiosque Flor do Caribe - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001241' alt='Av. Atlântica x  Quiosque Flor do Caribe - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97701978,-43.18827882]} icon={camera}><Popup><span className='title-content'>Av. Atlântica x  Quiosque Flor do Caribe - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001242' alt='Av. Atlântica x  Quiosque Flor do Caribe - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97705806,-43.18814001]} icon={camera}><Popup><span className='title-content'>Av. Atlântica x  Quiosque Flor do Caribe - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001243' alt='Av. Atlântica x  Quiosque Flor do Caribe - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97703213,-43.18796031]} icon={camera}><Popup><span className='title-content'>Av. Atlântica x  Quiosque Flor do Caribe - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001244' alt='Av. Atlântica x  Quiosque Flor do Caribe - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97513563,-43.18705326]} icon={camera}><Popup><span className='title-content'>Av. Atlântica x Constante Ramos - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001245' alt='Av. Atlântica x Constante Ramos - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97506278,-43.18700900]} icon={camera}><Popup><span className='title-content'>Av. Atlântica x Constante Ramos - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001246' alt='Av. Atlântica x Constante Ramos - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97478003,-43.18756019]} icon={camera}><Popup><span className='title-content'>R. Constante Ramos N 19  - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001247' alt='R. Constante Ramos N 19  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97483621,-43.18746967]} icon={camera}><Popup><span className='title-content'>R. Constante Ramos N 19  - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001248' alt='R. Constante Ramos N 19  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97386942,-43.18634314]} icon={camera}><Popup><span className='title-content'>Av. Atlântica Prox Posto BR - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001249' alt='Av. Atlântica Prox Posto BR - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97383670,-43.18646518]} icon={camera}><Popup><span className='title-content'>Av. Atlântica Prox Posto BR - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001250' alt='Av. Atlântica Prox Posto BR - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95663056,-43.17772349]} icon={camera}><Popup><span className='title-content'>Av. Lauro Sodré, 20    -  FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001251' alt='Av. Lauro Sodré, 20    -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95665526,-43.17775567]} icon={camera}><Popup><span className='title-content'>Av. Lauro Sodré    -  FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001252' alt='Av. Lauro Sodré    -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95730728,-43.17764302]} icon={camera}><Popup><span className='title-content'>Av. Lauro Sodré    -  FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001253' alt='Av. Lauro Sodré    -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95781111,-43.17752500]} icon={camera}><Popup><span className='title-content'>Av. Lauro Sodré    -  FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001254' alt='Av. Lauro Sodré    -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95823097,-43.17743381]} icon={camera}><Popup><span className='title-content'>Av. Lauro Sodré   - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001255' alt='Av. Lauro Sodré   - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95836433,-43.17737480]} icon={camera}><Popup><span className='title-content'>Av. Lauro Sodré  - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001256' alt='Av. Lauro Sodré  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95466506,-43.17851519]} icon={camera}><Popup><span className='title-content'>Av. Lauro Sodré,  191 - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001261' alt='Av. Lauro Sodré,  191 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95382532,-43.17879950]} icon={camera}><Popup><span className='title-content'>Av. Lauro Sodré - Botafogo  - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001262' alt='Av. Lauro Sodré - Botafogo  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95511457,-43.17838644]} icon={camera}><Popup><span className='title-content'>Av. Lauro Sodré - Botafogo  - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001263' alt='Av. Lauro Sodré - Botafogo  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95447735,-43.17860102]} icon={camera}><Popup><span className='title-content'>Av. Lauro Sodré - Botafogo  - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001264' alt='Av. Lauro Sodré - Botafogo  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01793098,-43.45072470]} icon={camera}><Popup><span className='title-content'>AV. ALFREDO BALTAZAR DA SILVEIRA X AV. PEDRO MOURA  - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001266' alt='AV. ALFREDO BALTAZAR DA SILVEIRA X AV. PEDRO MOURA  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01808157,-43.45049403]} icon={camera}><Popup><span className='title-content'>AV. ALFREDO BALTAZAR DA SILVEIRA X AV. PEDRO MOURA  - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001267' alt='AV. ALFREDO BALTAZAR DA SILVEIRA X AV. PEDRO MOURA  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.02245848,-43.44758880]} icon={camera}><Popup><span className='title-content'>Av. Lucio Costa x Av. do Contorno (Final do Eco Orla) - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001269' alt='Av. Lucio Costa x Av. do Contorno (Final do Eco Orla) - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.02232147,-43.44743189]} icon={camera}><Popup><span className='title-content'>Av. Lucio Costa x Av. do Contorno (Final do Eco Orla) - FIXA -  Zona Oeste  -  Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001270' alt='Av. Lucio Costa x Av. do Contorno (Final do Eco Orla) - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.02253377,-43.44789860]} icon={camera}><Popup><span className='title-content'>Av. Lucio Costa x Av. do Contorno (Final do Eco Orla) - FIXA -  Zona Oeste  -  Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001271' alt='Av. Lucio Costa x Av. do Contorno (Final do Eco Orla) - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98580409,-43.18936023]} icon={camera}><Popup><span className='title-content'>HOTEL FAIRMONT -  COPACABANA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001274' alt='HOTEL FAIRMONT -  COPACABANA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97744870,-43.18912]} icon={camera}><Popup><span className='title-content'>HOTEL OTHON -  COPACABANA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001275' alt='HOTEL OTHON -  COPACABANA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96912374,-43.18181141]} icon={camera}><Popup><span className='title-content'>HOTEL ARENA  -  COPACABANA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001276' alt='HOTEL ARENA  -  COPACABANA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97968338,-43.18909635]} icon={camera}><Popup><span className='title-content'>Av. Atlântica, 3530 -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001278' alt='Av. Atlântica, 3530' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97975129,-43.18937127]} icon={camera}><Popup><span className='title-content'>Av. Atlântica, 3530  - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001279' alt='Av. Atlântica, 3530  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.979862,-43.189414]} icon={camera}><Popup><span className='title-content'>Av. Atlântica, 3530  - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001280' alt='Av. Atlântica, 3530  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98038817,-43.18924483]} icon={camera}><Popup><span className='title-content'>Copacabana Prox. Posto 5  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001282' alt='Copacabana Prox. Posto 5  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.980503,-43.189273]} icon={camera}><Popup><span className='title-content'>Copacabana Prox. Posto 5  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001283' alt='Copacabana Prox. Posto 5  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97376836,-43.18573163]} icon={camera}><Popup><span className='title-content'>R. Santa Clara, S/N - Copacabana - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001284' alt='R. Santa Clara, S/N - Copacabana - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97321520,-43.18599985]} icon={camera}><Popup><span className='title-content'>R. Santa Clara, S/N - Copacabana - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001285' alt='R. Santa Clara, S/N - Copacabana - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97334361,-43.18569944]} icon={camera}><Popup><span className='title-content'>R. Santa Clara, S/N - Copacabana - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001286' alt='R. Santa Clara, S/N - Copacabana - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97347696,-43.18581746]} icon={camera}><Popup><span className='title-content'>R. Santa Clara, S/N - Copacabana - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001287' alt='R. Santa Clara, S/N - Copacabana - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01206166,-43.44379741]} icon={camera}><Popup><span className='title-content'>Avenida Alfredo Baltazar da Silveira x Rua Odilon Duarte Braga - FIXA -  Zona Oeste  -  Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001289' alt='Avenida Alfredo Baltazar da Silveira x Rua Odilon Duarte Braga - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.02499642,-43.45724986]} icon={camera}><Popup><span className='title-content'>Av. Lucio Costa x R. Glaucio Gil -FIXA -  Zona Oeste  -  Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001294' alt='Av. Lucio Costa x R. Glaucio Gil -FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96670265,-43.18886955]} icon={camera}><Popup><span className='title-content'>R. Joseph Bloch, 30 - Copacabana  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001296' alt='R. Joseph Bloch, 30 - Copacabana  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96655324,-43.18903584]} icon={camera}><Popup><span className='title-content'>R. Joseph Bloch, 30 - Copacabana  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001297' alt='R. Joseph Bloch, 30 - Copacabana  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.966237,-43.189397]} icon={camera}><Popup><span className='title-content'>Rua Figueiredo Magalhães x Rua Ministro Alfredo Valadão  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001298' alt='Rua Figueiredo Magalhães x Rua Ministro Alfredo Valadão  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96634813,-43.18940236]} icon={camera}><Popup><span className='title-content'>Rua Figueiredo Magalhães x Rua Ministro Alfredo Valadão  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001299' alt='Rua Figueiredo Magalhães x Rua Ministro Alfredo Valadão  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.02218910,-43.45812381]} icon={camera}><Popup><span className='title-content'>Av. Alfredo Baltazar da Silveira X R. Glaucio Gil - FIXA -  Zona Oeste  -  Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001301' alt='Av. Alfredo Baltazar da Silveira X R. Glaucio Gil - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96465710,-43.19073872]} icon={camera}><Popup><span className='title-content'>Praça Vereador Rocha Leão, 50 -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001303' alt='Praça Vereador Rocha Leão, 50 -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9639799,-43.1908386]} icon={camera}><Popup><span className='title-content'>Praça Vereador Rocha Leão, N 88 -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001304' alt='Praça Vereador Rocha Leão, N 88 -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96411820,-43.19091906]} icon={camera}><Popup><span className='title-content'>Praça Vereador Rocha Leão, N 88 -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001305' alt='Praça Vereador Rocha Leão, N 88 -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.013640,-43.446577]} icon={camera}><Popup><span className='title-content'>AV. GENARO DE CARVALHO X  R. JOAQUIM JOSE COUTO - FIXA -  Zona Oeste  -  Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001306' alt='AV. GENARO DE CARVALHO X  R. JOAQUIM JOSE COUTO - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01355606,-43.44689081]} icon={camera}><Popup><span className='title-content'>AV. GENARO DE CARVALHO X  R. JOAQUIM JOSE COUTO - FIXA -  Zona Oeste  -  Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001307' alt='AV. GENARO DE CARVALHO X  R. JOAQUIM JOSE COUTO - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.02828043,-43.46676365]} icon={camera}><Popup><span className='title-content'>AV. LUCIO COSTA X RUA ALBERT SABIN - FIXA -  Zona Oeste  -  Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001309' alt='AV. LUCIO COSTA X RUA ALBERT SABIN - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.03093407,-43.47178059]} icon={camera}><Popup><span className='title-content'>Av. Gilka Machado X Estr. Do Pontal - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=001311' alt='Av. Gilka Machado X Estr. Do Pontal - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.03019931,-43.47825567]} icon={camera}><Popup><span className='title-content'>Estrada Do Pontal Em Frente Ao N.º 6870 - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=001312' alt='Estrada Do Pontal Em Frente Ao N.º 6870 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97278057,-43.18596029]} icon={camera}><Popup><span className='title-content'>R. Santa Clara, 8 - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001314' alt='R. Santa Clara, 8 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97265957,-43.18607227]} icon={camera}><Popup><span className='title-content'>R. Santa Clara, 8 - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001315' alt='R. Santa Clara, 8 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96956564,-43.18166099]} icon={camera}><Popup><span className='title-content'>Av. Atlântica  N 15- FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001316' alt='Av. Atlântica  N 15- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96946624,-43.18164624]} icon={camera}><Popup><span className='title-content'>Av. Atlântica  N 15- FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001317' alt='Av. Atlântica  N 15- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96978234,-43.18191379]} icon={camera}><Popup><span className='title-content'>Av. Atlântica  N 18- FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001318' alt='Av. Atlântica  N 18- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96970146,-43.18197682]} icon={camera}><Popup><span className='title-content'>Av. Atlântica  N 18- FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001319' alt='Av. Atlântica  N 18- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97012746,-43.18260513]} icon={camera}><Popup><span className='title-content'>Av. Atlântica  N 18- FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001320' alt='Av. Atlântica  N 18- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97022191,-43.18252869]} icon={camera}><Popup><span className='title-content'>Av. Atlântica  N 18- FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001321' alt='Av. Atlântica  N 18- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97052690,-43.18271510]} icon={camera}><Popup><span className='title-content'>Av. Atlântica N 109 - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001326' alt='Av. Atlântica N 109 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97068866,-43.18292968]} icon={camera}><Popup><span className='title-content'>Av. Atlântica N 109 - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001327' alt='Av. Atlântica N 109 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97072940,-43.18288073]} icon={camera}><Popup><span className='title-content'>Av. Atlântica N 109 - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001328' alt='Av. Atlântica N 109 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97067692,-43.18271577]} icon={camera}><Popup><span className='title-content'>Av. Atlântica N 109 - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001329' alt='Av. Atlântica N 109 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97206826,-43.18452808]} icon={camera}><Popup><span className='title-content'>Av. Atlântica, N 110  - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001330' alt='Av. Atlântica, N 110  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97211148,-43.18447176]} icon={camera}><Popup><span className='title-content'>Av. Atlântica, N 110  - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001331' alt='Av. Atlântica, N 110  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97216210,-43.18452741]} icon={camera}><Popup><span className='title-content'>Av. Atlântica, N 110  - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001332' alt='Av. Atlântica, N 110  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97222754,-43.18459380]} icon={camera}><Popup><span className='title-content'>Av. Atlântica, N 110  - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001333' alt='Av. Atlântica, N 110  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96421378,-43.19142882]} icon={camera}><Popup><span className='title-content'>Rua Henrique Oswald, 70  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001334' alt='Rua Henrique Oswald, 70  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96428910,-43.19130276]} icon={camera}><Popup><span className='title-content'>Rua Henrique Oswald, 70  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001335' alt='Rua Henrique Oswald, 70  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94960206,-43.18092373]} icon={camera}><Popup><span className='title-content'>Av. das Nações Unidas X  Botafogo - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001337' alt='Av. das Nações Unidas X  Botafogo - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94985646,-43.18090093]} icon={camera}><Popup><span className='title-content'>Av. das Nações Unidas X  Botafogo - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001338' alt='Av. das Nações Unidas X  Botafogo - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94982805,-43.18052206]} icon={camera}><Popup><span className='title-content'>Av. das Nações Unidas X  Botafogo - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001339' alt='Av. das Nações Unidas X  Botafogo - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97645617,-43.19373622]} icon={camera}><Popup><span className='title-content'>Praça Eugênio Jardim  - Fixa -  Zona Sul - Cantagalo</span><img src='http://187.111.99.18:9004/?CODE=001341' alt='Praça Eugênio Jardim  - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97659631,-43.19378383]} icon={camera}><Popup><span className='title-content'>Praça Eugênio Jardim  - Fixa -  Zona Sul - Cantagalo</span><img src='http://187.111.99.18:9004/?CODE=001342' alt='Praça Eugênio Jardim  - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97674518,-43.19449397]} icon={camera}><Popup><span className='title-content'>Av. Henrique Dodsworth, 54 - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001343' alt='Av. Henrique Dodsworth, 54 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.976518,-43.194384]} icon={camera}><Popup><span className='title-content'>Av. Henrique Dodsworth, 54  - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001344' alt='Av. Henrique Dodsworth, 54  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.976664,-43.195109]} icon={camera}><Popup><span className='title-content'>Av. Henrique Dodsworth, 64 -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001345' alt='Av. Henrique Dodsworth, 64 -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97687143,-43.19543488]} icon={camera}><Popup><span className='title-content'>Av. Henrique Dodsworth, 64  - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001346' alt='Av. Henrique Dodsworth, 64  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.976828,-43.19634]} icon={camera}><Popup><span className='title-content'>Av. Henrique Dodsworth, 64-142 - Copacabana - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001347' alt='Av. Henrique Dodsworth, 64-142 - Copacabana - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97693665,-43.19659212]} icon={camera}><Popup><span className='title-content'>Av. Henrique Dodsworth, 64-142 - Copacabana - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001348' alt='Av. Henrique Dodsworth, 64-142 - Copacabana - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97813058,-43.19061036]} icon={camera}><Popup><span className='title-content'>Av. Nossa Sra. de Copacabana, 1033  - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001349' alt='Av. Nossa Sra. de Copacabana, 1033  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97796266,-43.19050844]} icon={camera}><Popup><span className='title-content'>Av. Nossa Sra. de Copacabana, 1033  - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001350' alt='Av. Nossa Sra. de Copacabana, 1033  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95041245,-43.18002797]} icon={camera}><Popup><span className='title-content'>Av. das Nações Unidas  - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=001351' alt='Av. das Nações Unidas  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95062486,-43.17995823]} icon={camera}><Popup><span className='title-content'>Av. das Nações Unidas - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=001352' alt='Av. das Nações Unidas - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98041286,-43.18907317]} icon={camera}><Popup><span className='title-content'>Copacabana Prox. Posto 5  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001353' alt='Copacabana Prox. Posto 5  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98054127,-43.18910536]} icon={camera}><Popup><span className='title-content'>Copacabana Prox. Posto 5  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001354' alt='Copacabana Prox. Posto 5  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.931000,-43.17796]} icon={camera}><Popup><span className='title-content'>R. Do Catete  X R. das Laranjeiras - FIXA -  Zona Sul - Largo do Machado</span><img src='http://187.111.99.18:9004/?CODE=001355' alt='R. Do Catete  X R. das Laranjeiras - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96538092,-43.17349567]} icon={camera}><Popup><span className='title-content'>Av. Atlântica 250  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001357' alt='Av. Atlântica 250  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.965426,-43.173609]} icon={camera}><Popup><span className='title-content'>Av. Atlântica 250  - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001358' alt='Av. Atlântica 250  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.965798,-43.174200]} icon={camera}><Popup><span className='title-content'>Av. Atlântica 195  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001359' alt='Av. Atlântica 195  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96574243,-43.17408801]} icon={camera}><Popup><span className='title-content'>Av. Atlântica 195  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001360' alt='Av. Atlântica 195  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97653707,-43.19780227]} icon={camera}><Popup><span className='title-content'>Av. Henrique Dodsworth, 193 - Copacabana   -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001361' alt='Av. Henrique Dodsworth, 193 - Copacabana   -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97644324,-43.19814559]} icon={camera}><Popup><span className='title-content'>Av. Henrique Dodsworth, 193 - Copacabana   -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001362' alt='Av. Henrique Dodsworth, 193 - Copacabana   -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97662,-43.197809]} icon={camera}><Popup><span className='title-content'>Praça Benedito Cerqueira, S/N - Lagoa   -  FIXA -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=001363' alt='Praça Benedito Cerqueira, S/N - Lagoa   -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97666568,-43.19758771]} icon={camera}><Popup><span className='title-content'>Praça Benedito Cerqueira, S/N - Lagoa   -  FIXA -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=001364' alt='Praça Benedito Cerqueira, S/N - Lagoa   -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96174077,-43.17527541]} icon={camera}><Popup><span className='title-content'>Av. Princesa Isabel Prox Augusto Rio copa - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001365' alt='Av. Princesa Isabel Prox Augusto Rio copa - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96177349,-43.17537532]} icon={camera}><Popup><span className='title-content'>Av. Princesa Isabel Prox Augusto Rio copa - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001366' alt='Av. Princesa Isabel Prox Augusto Rio copa - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96297005,-43.17471013]} icon={camera}><Popup><span className='title-content'>Av. Princesa Isabel - Copacabana- FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001367' alt='Av. Princesa Isabel - Copacabana- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96289349,-43.17454250]} icon={camera}><Popup><span className='title-content'>Av. Princesa Isabel - Copacabana- FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001368' alt='Av. Princesa Isabel - Copacabana- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96304846,-43.17461894]} icon={camera}><Popup><span className='title-content'>Av. Princesa Isabel - Copacabana- FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001369' alt='Av. Princesa Isabel - Copacabana- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.963009566680600,-43.17449153959750]} icon={camera}><Popup><span className='title-content'>Av. Princesa Isabel - Copacabana- FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001370' alt='Av. Princesa Isabel - Copacabana- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.963867771679500,-43.174211248755400]} icon={camera}><Popup><span className='title-content'>Av. Nossa Sra. de Copacabana, 68 - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001371' alt='Av. Nossa Sra. de Copacabana, 68 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96381158,-43.17406976]} icon={camera}><Popup><span className='title-content'>Av. Nossa Sra. de Copacabana, 68 - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001372' alt='Av. Nossa Sra. de Copacabana, 68 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96402212,-43.17374588]} icon={camera}><Popup><span className='title-content'>Av. Nossa Sra. de Copacabana, Av Princesa Isabel  - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001373' alt='Av. Nossa Sra. de Copacabana, Av Princesa Isabel  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96388814,-43.17378544]} icon={camera}><Popup><span className='title-content'>Av. Nossa Sra. de Copacabana X Av Princesa Isabel FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001374' alt='Av. Nossa Sra. de Copacabana X Av Princesa Isabel FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00927730,-43.44044451]} icon={camera}><Popup><span className='title-content'>Av. Alfredo Balthazar da Silveira, 419 -  FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=001375' alt='Av. Alfredo Balthazar da Silveira, 419 -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00940075,-43.44059203]} icon={camera}><Popup><span className='title-content'>Av. Alfredo Balthazar da Silveira, 419 -  FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=001376' alt='Av. Alfredo Balthazar da Silveira, 419 -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.010987,-43.301528]} icon={camera}><Popup><span className='title-content'>R. Deodato de Moraes X Av Min Ivan Lins. FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001381' alt='R. Deodato de Moraes X Av Min Ivan Lins. FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01104131,-43.30143680]} icon={camera}><Popup><span className='title-content'>R. Deodato de Moraes X Av Min Ivan Lins. FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001382' alt='R. Deodato de Moraes X Av Min Ivan Lins. FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01332281,-43.29736560]} icon={camera}><Popup><span className='title-content'>R. SARGENTO JOÃO DE FARIA X AV. MINISTRO IVAN LINS - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001383' alt='R. SARGENTO JOÃO DE FARIA X AV. MINISTRO IVAN LINS - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9603695,-43.35732]} icon={camera}><Popup><span className='title-content'>Av. Ayrton Senna, 5850 - Em Frente ao Espeço Hall - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001384' alt='Av. Ayrton Senna, 5850 - Em Frente ao Espeço Hall - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96049669,-43.35732938]} icon={camera}><Popup><span className='title-content'>Av. Ayrton Senna, 5850 - Em Frente ao Espeço Hall - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001385' alt='Av. Ayrton Senna, 5850 - Em Frente ao Espeço Hall - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90204711,-43.28024646]} icon={camera}><Popup><span className='title-content'>R. Dias da Cruz X  R. Ana Barbosa - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001386' alt='R. Dias da Cruz X  R. Ana Barbosa - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.0074709,-43.3068961]} icon={camera}><Popup><span className='title-content'>Av. Armando Lombardi, 205 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001387' alt='Av. Armando Lombardi, 205 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00737084,-43.30676043]} icon={camera}><Popup><span className='title-content'>Av. Armando Lombardi, 205 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001388' alt='Av. Armando Lombardi, 205 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90702635,-43.21124587]} icon={camera}><Popup><span className='title-content'>R. Francisco Eugênio - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001389' alt='R. Francisco Eugênio - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90699671,-43.21102191]} icon={camera}><Popup><span className='title-content'>R. Francisco Eugênio - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001390' alt='R. Francisco Eugênio - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00312090,-43.30464303]} icon={camera}><Popup><span className='title-content'>Estrada da Barra da Tijuca - Itanhangá  - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001391' alt='Estrada da Barra da Tijuca - Itanhangá  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00306782,-43.30464772]} icon={camera}><Popup><span className='title-content'>Estrada da Barra da Tijuca - Itanhangá  - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001392' alt='Estrada da Barra da Tijuca - Itanhangá  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96050733,-43.20505749]} icon={camera}><Popup><span className='title-content'>R. Jardim Botanico X R. Prof. Saldanha - FIXA -  Zona Sul - Jardim Botanico</span><img src='http://187.111.99.18:9004/?CODE=001393' alt='R. Jardim Botanico X R. Prof. Saldanha - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.964362,-43.203722]} icon={camera}><Popup><span className='title-content'>R. Carvalho Azevedo, 368 - FIXA -  Zona Sul - Jardim Botanico</span><img src='http://187.111.99.18:9004/?CODE=001394' alt='R. Carvalho Azevedo, 368 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96439040,-43.20382928]} icon={camera}><Popup><span className='title-content'>R. Carvalho Azevedo, 368 - FIXA -  Zona Sul - Jardim Botanico</span><img src='http://187.111.99.18:9004/?CODE=001395' alt='R. Carvalho Azevedo, 368 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93870280,-43.17378083]} icon={camera}><Popup><span className='title-content'>Praia Do Flamengo X Av. Oswaldo Cruz - FIXA -  Zona Sul - Flamengo</span><img src='http://187.111.99.18:9004/?CODE=001396' alt='Praia Do Flamengo X Av. Oswaldo Cruz - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94092884,-43.17873851]} icon={camera}><Popup><span className='title-content'>R. MARQUÊS DE ABRANTES X ALTURA DA P.DE BOTAFOGO - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001397' alt='R. MARQUÊS DE ABRANTES X ALTURA DA P.DE BOTAFOGO - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93810162,-43.17777027]} icon={camera}><Popup><span className='title-content'>R. DO CATETE X R. CORRA DUTRA - FIXA -  Zona Sul - Flamengo</span><img src='http://187.111.99.18:9004/?CODE=001398' alt='R. DO CATETE X R. CORRA DUTRA - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.82687611,-43.27504950]} icon={camera}><Popup><span className='title-content'>AV. BRASIL X AV. LOBO JUNIOR - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=001399' alt='AV. BRASIL X AV. LOBO JUNIOR - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94824397,-43.18201422]} icon={camera}><Popup><span className='title-content'>AV. DAS NAÇÕES UNIDAS X ALT. BOTAFOGO PRAIA SHOPPING - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001400' alt='AV. DAS NAÇÕES UNIDAS X ALT. BOTAFOGO PRAIA SHOPPING - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.85220167,-43.31612186]} icon={camera}><Popup><span className='title-content'>R. MARIO  CARVALHO X AV. PST M. LUTHER KING JR - BRT -FIXA -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=001401' alt='R. MARIO  CARVALHO X AV. PST M. LUTHER KING JR - BRT -FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.85228200,-43.31603335]} icon={camera}><Popup><span className='title-content'>R. MARIO  CARVALHO X AV. PST M. LUTHER KING JR - BRT -FIXA -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=001402' alt='R. MARIO  CARVALHO X AV. PST M. LUTHER KING JR - BRT -FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.977686,-43.222394]} icon={camera}><Popup><span className='title-content'>Praça Nossa Senhora Auxiliadora 93  - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=001403' alt='Praça Nossa Senhora Auxiliadora 93  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97770575,-43.22249592]} icon={camera}><Popup><span className='title-content'>Praça Nossa Senhora Auxiliadora 93  - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=001404' alt='Praça Nossa Senhora Auxiliadora 93  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97763908,-43.22219685]} icon={camera}><Popup><span className='title-content'>Praça Nossa Senhora Auxiliadora 93  - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=001405' alt='Praça Nossa Senhora Auxiliadora 93  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.978169,-43.224816]} icon={camera}><Popup><span className='title-content'>Av. Bartolomeu Mitre, 1099 - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=001406' alt='Av. Bartolomeu Mitre, 1099 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97805787,-43.22485623]} icon={camera}><Popup><span className='title-content'>Av. Bartolomeu Mitre, 1099 - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=001407' alt='Av. Bartolomeu Mitre, 1099 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97835790,-43.22478515]} icon={camera}><Popup><span className='title-content'>Av. Bartolomeu Mitre, 1099 - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=001408' alt='Av. Bartolomeu Mitre, 1099 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97812702,-43.22457862]} icon={camera}><Popup><span className='title-content'>Av. Bartolomeu Mitre, 1099 - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=001409' alt='Av. Bartolomeu Mitre, 1099 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97887277,-43.22896537]} icon={camera}><Popup><span className='title-content'>PRAÇA SIBELIUS  - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=001410' alt='PRAÇA SIBELIUS  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97886042,-43.22883663]} icon={camera}><Popup><span className='title-content'>PRAÇA SIBELIUS  - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=001411' alt='PRAÇA SIBELIUS  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.839037,-43.311611]} icon={camera}><Popup><span className='title-content'>Av. Brás de Pina X R. Pe. Roser X Av. Meriti (Largo Do Bicão) - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=001412' alt='Av. Brás de Pina X R. Pe. Roser X Av. Meriti (Largo Do Bicão) - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.87761719,-43.33638936]} icon={camera}><Popup><span className='title-content'>Vd. Pref. Negrão de Lima X Estação Brt Madureira -FIXA -  Zona Norte - Madureira</span><img src='http://187.111.99.18:9004/?CODE=001413' alt='Vd. Pref. Negrão de Lima X Estação Brt Madureira -FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.990757,-43.229449]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER 54 - FIXA -  Zona Sul - Niemeyer</span><img src='http://187.111.99.18:9004/?CODE=001414' alt='AV. NIEMEYER 54 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.990761,-43.229560]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER 54 - FIXA -  Zona Sul - Niemeyer</span><img src='http://187.111.99.18:9004/?CODE=001415' alt='AV. NIEMEYER 54 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.990624,-43.230661]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER 88 - FIXA -  Zona Sul - Niemeyer</span><img src='http://187.111.99.18:9004/?CODE=001416' alt='AV. NIEMEYER 88 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.990576,-43.230766]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER 88 - FIXA -  Zona Sul - Niemeyer</span><img src='http://187.111.99.18:9004/?CODE=001417' alt='AV. NIEMEYER 88 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.990870,-43.231765]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER 683 - FIXA -  Zona Sul - Niemeyer</span><img src='http://187.111.99.18:9004/?CODE=001418' alt='AV. NIEMEYER 683 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.990873,-43.231876]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER 683 - FIXA -  Zona Sul - Niemeyer</span><img src='http://187.111.99.18:9004/?CODE=001419' alt='AV. NIEMEYER 683 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.991043,-43.232612]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER 126 - FIXA -  Zona Sul - NIEMEYER</span><img src='http://187.111.99.18:9004/?CODE=001420' alt='AV. NIEMEYER 126 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.991020,-43.232505]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER 126 - FIXA -  Zona Sul - NIEMEYER</span><img src='http://187.111.99.18:9004/?CODE=001421' alt='AV. NIEMEYER 126 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.993002,-43.233977]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER 170B - FIXA -  Zona Sul - Niemeyer</span><img src='http://187.111.99.18:9004/?CODE=001422' alt='AV. NIEMEYER 170B - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.993075,-43.233964]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER 170B - FIXA -  Zona Sul - Niemeyer</span><img src='http://187.111.99.18:9004/?CODE=001423' alt='AV. NIEMEYER 170B - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.994778,-43.234833]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER  204B - FIXA -  Zona Sul - Niemeyer</span><img src='http://187.111.99.18:9004/?CODE=001424' alt='AV. NIEMEYER  204B - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99480022,-43.23466871]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER  204B - FIXA -  Zona Sul - Niemeyer</span><img src='http://187.111.99.18:9004/?CODE=001425' alt='AV. NIEMEYER  204B - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.995806,-43.235542]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER  226 - FIXA -  Zona Sul - Niemeyer</span><img src='http://187.111.99.18:9004/?CODE=001426' alt='AV. NIEMEYER  226 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99587760,-43.23556681]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER  226 - FIXA -  Zona Sul - Niemeyer</span><img src='http://187.111.99.18:9004/?CODE=001427' alt='AV. NIEMEYER  226 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99671382,-43.23660219]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER  359 - FIXA -  Zona Sul - Niemeyer</span><img src='http://187.111.99.18:9004/?CODE=001428' alt='AV. NIEMEYER  359 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.996670,-43.236511]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER  359 - FIXA -  Zona Sul - Niemeyer</span><img src='http://187.111.99.18:9004/?CODE=001429' alt='AV. NIEMEYER  359 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.997696,-43.239254]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER  318 - FIXA -  Zona Sul - Niemeyer</span><img src='http://187.111.99.18:9004/?CODE=001430' alt='AV. NIEMEYER  318 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99772069,-43.23932507]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER  318 - FIXA -  Zona Sul - Niemeyer</span><img src='http://187.111.99.18:9004/?CODE=001431' alt='AV. NIEMEYER  318 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.000616,-43.245274]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER  - FIXA -  Zona Sul - NIEMEYER</span><img src='http://187.111.99.18:9004/?CODE=001432' alt='AV. NIEMEYER  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.000618,-43.245103]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER  - FIXA -  Zona Sul - Niemeyer</span><img src='http://187.111.99.18:9004/?CODE=001433' alt='AV. NIEMEYER  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99972974,-43.24086523]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER 376  - FIXA -  Zona Sul - Niemeyer</span><img src='http://187.111.99.18:9004/?CODE=001434' alt='AV. NIEMEYER 376  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99983282,-43.24096380]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER 376  - FIXA -  Zona Sul - NIEMEYER</span><img src='http://187.111.99.18:9004/?CODE=001435' alt='AV. NIEMEYER 376  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00013721,-43.24185602]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER 400 - FIXA -  Zona Sul - NIEMEYER</span><img src='http://187.111.99.18:9004/?CODE=001436' alt='AV. NIEMEYER 400 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.000065,-43.241909]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER 400 - FIXA -  Zona Sul - NIEMEYER</span><img src='http://187.111.99.18:9004/?CODE=001437' alt='AV. NIEMEYER 400 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.000046,-43.243214]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER 749 - FIXA -  Zona Sul - NIEMEYER</span><img src='http://187.111.99.18:9004/?CODE=001438' alt='AV. NIEMEYER 749 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00003674,-43.24312146]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER 749 - FIXA -  Zona Sul - NIEMEYER</span><img src='http://187.111.99.18:9004/?CODE=001439' alt='AV. NIEMEYER 749 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.000633,-43.243912]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER 418 - FIXA -  Zona Sul - NIEMEYER</span><img src='http://187.111.99.18:9004/?CODE=001440' alt='AV. NIEMEYER 418 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00057806,-43.24380873]} icon={camera}><Popup><span className='title-content'>AV. NIEMEYER 418 - FIXA -  Zona Sul - NIEMEYER</span><img src='http://187.111.99.18:9004/?CODE=001441' alt='AV. NIEMEYER 418 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.999400,-43.247810]} icon={camera}><Popup><span className='title-content'>Av. Niemeyer, 393 - São Conrado - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=001445' alt='Av. Niemeyer, 393 - São Conrado - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.998808,-43.251640]} icon={camera}><Popup><span className='title-content'>Av. Niemeyer, 546-548 - São Conrado - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=001446' alt='Av. Niemeyer, 546-548 - São Conrado - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.998980,-43.251640]} icon={camera}><Popup><span className='title-content'>Av. Niemeyer, 546-548 - São Conrado - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=001447' alt='Av. Niemeyer, 546-548 - São Conrado - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.998695,-43.253143]} icon={camera}><Popup><span className='title-content'>Av. Niemeyer Parque Natural Municipal - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=001448' alt='Av. Niemeyer Parque Natural Municipal - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.998795,-43.253143]} icon={camera}><Popup><span className='title-content'>Av. Niemeyer Parque Natural Municipal - FIXA -  Zona Sul - Leblon</span><img src='http://187.111.99.18:9004/?CODE=001449' alt='Av. Niemeyer Parque Natural Municipal - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.998474567237000,-43.25606813197700]} icon={camera}><Popup><span className='title-content'>Av. Niemeyer Prox. Hotel Nacional - FIXA -  Zona Sul - São Conrado</span><img src='http://187.111.99.18:9004/?CODE=001450' alt='Av. Niemeyer Prox. Hotel Nacional - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.85461937,-43.25063933]} icon={camera}><Popup><span className='title-content'>Portão do BRT - R. Barreiros, 21 - Ramos - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=001451' alt='Portão do BRT - R. Barreiros, 21 - Ramos - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.854565,-43.250870]} icon={camera}><Popup><span className='title-content'>Portão do BRT - R. Barreiros, 21 - Ramos - FIXA -  Zona Norte - Ramos</span><img src='http://187.111.99.18:9004/?CODE=001452' alt='Portão do BRT - R. Barreiros, 21 - Ramos - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915894,-43.608132]} icon={camera}><Popup><span className='title-content'>Portão do BRT - Av. Cesário de Mello, 8121 - Cosmos - FIXA -  Zona Norte - Cosmos</span><img src='http://187.111.99.18:9004/?CODE=001453' alt='Portão do BRT - Av. Cesário de Mello, 8121 - Cosmos - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915796,-43.607889]} icon={camera}><Popup><span className='title-content'>Portão do BRT - Av. Cesário de Mello, 8121 - Cosmos - FIXA -  Zona Norte - Cosmos</span><img src='http://187.111.99.18:9004/?CODE=001454' alt='Portão do BRT - Av. Cesário de Mello, 8121 - Cosmos - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.965863,-43.391308]} icon={camera}><Popup><span className='title-content'>Portão do BRT - R. Leonardo Vilas Boas, 3  - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=001455' alt='Portão do BRT - R. Leonardo Vilas Boas, 3  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.965762,-43.391120]} icon={camera}><Popup><span className='title-content'>Portão do BRT - R. Leonardo Vilas Boas, 3  - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=001456' alt='Portão do BRT - R. Leonardo Vilas Boas, 3  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91260317,-43.21603446]} icon={camera}><Popup><span className='title-content'>R. Mariz E Barros X R. Felisberto de Menezes - FIXA -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=001457' alt='R. Mariz E Barros X R. Felisberto de Menezes - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91366011,-43.17875469]} icon={camera}><Popup><span className='title-content'>Lapa - FIXA -  Centro da Cidade - Lapa</span><img src='http://187.111.99.18:9004/?CODE=001458' alt='Lapa - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.007048,-43.311872]} icon={camera}><Popup><span className='title-content'>Av. Armando Lombardi 669 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001459' alt='Av. Armando Lombardi 669 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.007046,-43.311794]} icon={camera}><Popup><span className='title-content'>Av. Armando Lombardi 669 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001460' alt='Av. Armando Lombardi 669 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00716749,-43.30986177]} icon={camera}><Popup><span className='title-content'>Av. Armando Lombardi 505 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001461' alt='Av. Armando Lombardi 505 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00706291,-43.30989176]} icon={camera}><Popup><span className='title-content'>Av. Armando Lombardi 505 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001462' alt='Av. Armando Lombardi 505 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911278,-43.203594]} icon={camera}><Popup><span className='title-content'>ALOJAMENTO DA OBRA  01 - FIXA -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=001463' alt='ALOJAMENTO DA OBRA  01 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911211,-43.203622]} icon={camera}><Popup><span className='title-content'>ALOJAMENTO DA OBRA  02 - FIXA -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=001464' alt='ALOJAMENTO DA OBRA  02 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.011331,-43.309703]} icon={camera}><Popup><span className='title-content'>AV. ÉRICO VERÍSSIMO X RUA FERNANDO DE MATTOS - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001465' alt='AV. ÉRICO VERÍSSIMO X RUA FERNANDO DE MATTOS - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01186769,-43.30502996]} icon={camera}><Popup><span className='title-content'>AV. OLEGÁRIO MACIEL X AV. GILBERTO AMADO -FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001466' alt='AV. OLEGÁRIO MACIEL X AV. GILBERTO AMADO -FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92106381,-43.37164986]} icon={camera}><Popup><span className='title-content'>R. Bacairis X R. Apiacás - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=001467' alt='R. Bacairis X R. Apiacás - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911074,-43.206143]} icon={camera}><Popup><span className='title-content'>Prefeitura CASS - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001468' alt='Prefeitura CASS - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911094,-43.206296]} icon={camera}><Popup><span className='title-content'>Prefeitura CASS - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001469' alt='Prefeitura CASS - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01519744,-43.30585096]} icon={camera}><Popup><span className='title-content'>AV. DO PEP X AV. OLEGRIO MACIEL - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001470' alt='AV. DO PEP X AV. OLEGRIO MACIEL - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01520176,-43.30573897]} icon={camera}><Popup><span className='title-content'>AV. DO PEP X AV. OLEGRIO MACIEL - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001471' alt='AV. DO PEP X AV. OLEGRIO MACIEL - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01521904,-43.30577317]} icon={camera}><Popup><span className='title-content'>AV. DO PEP X AV. OLEGRIO MACIEL - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001472' alt='AV. DO PEP X AV. OLEGRIO MACIEL - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92079958,-43.22287825]} icon={camera}><Popup><span className='title-content'>São Francisco Xavier X Heitor Beltrão - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001473' alt='São Francisco Xavier X Heitor Beltrão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.925900,-43.176602]} icon={camera}><Popup><span className='title-content'>R. Do Catete X R. Silveira Martins - FIXA -  Zona Sul - Catete</span><img src='http://187.111.99.18:9004/?CODE=001474' alt='R. Do Catete X R. Silveira Martins - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.926000,-43.176602]} icon={camera}><Popup><span className='title-content'>R. Do Catete X R. Silveira Martins - FIXA -  Zona Sul - Catete</span><img src='http://187.111.99.18:9004/?CODE=001475' alt='R. Do Catete X R. Silveira Martins - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96680,-43.219492]} icon={camera}><Popup><span className='title-content'>R. Jardim Botânico X R. Pacheco Leão - FIXA -  Zona Sul - Jardim Botanico</span><img src='http://187.111.99.18:9004/?CODE=001476' alt='R. Jardim Botânico X R. Pacheco Leão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96710,-43.219492]} icon={camera}><Popup><span className='title-content'>R. Jardim Botânico X R. Pacheco Leão - FIXA -  Zona Sul - Jardim Botanico</span><img src='http://187.111.99.18:9004/?CODE=001477' alt='R. Jardim Botânico X R. Pacheco Leão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.950600,-43.181605]} icon={camera}><Popup><span className='title-content'>R. Voluntários da Pátria X R. Praia de Botafogo - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001478' alt='R. Voluntários da Pátria X R. Praia de Botafogo - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.950705,-43.181605]} icon={camera}><Popup><span className='title-content'>R. Voluntários da Pátria X R. Praia de Botafogo - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001479' alt='R. Voluntários da Pátria X R. Praia de Botafogo - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90483590,-43.19033958]} icon={camera}><Popup><span className='title-content'>Av. Pres.Vargas X Pça. da República - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001482' alt='Av. Pres.Vargas X Pça. da República - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90476487,-43.19036305]} icon={camera}><Popup><span className='title-content'>Av. Pres.Vargas X Pça. da República - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001483' alt='Av. Pres.Vargas X Pça. da República - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95004930,-43.19102923]} icon={camera}><Popup><span className='title-content'>R. São Clemente X R. Sorocaba - fixa -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001484' alt='R. São Clemente X R. Sorocaba - fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95020985,-43.19097089]} icon={camera}><Popup><span className='title-content'>R. São Clemente X R. Sorocaba - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001485' alt='R. São Clemente X R. Sorocaba - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92836436,-43.23973167]} icon={camera}><Popup><span className='title-content'>Av. Maracanã X R. José Higino - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001486' alt='Av. Maracanã X R. José Higino - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92849281,-43.23979336]} icon={camera}><Popup><span className='title-content'>Av. Maracanã X R. José Higino - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001487' alt='Av. Maracanã X R. José Higino - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.837986,-43.284893]} icon={camera}><Popup><span className='title-content'>Av. Braz de Pina, 404  - FIXA -  Zona Norte - Braz de Pina</span><img src='http://187.111.99.18:9004/?CODE=001488' alt='Av. Braz de Pina, 404  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.838076,-43.284813]} icon={camera}><Popup><span className='title-content'>Av. Braz de Pina, 404  - FIXA -  Zona Norte - Braz de Pina</span><img src='http://187.111.99.18:9004/?CODE=001489' alt='Av. Braz de Pina, 404  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92417793,-43.23622356]} icon={camera}><Popup><span className='title-content'>R. Pereira Nunes X R. Barão de Mesquita - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001490' alt='R. Pereira Nunes X R. Barão de Mesquita - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92416064,-43.23629799]} icon={camera}><Popup><span className='title-content'>R. Pereira Nunes X R. Barão de Mesquita - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001491' alt='R. Pereira Nunes X R. Barão de Mesquita - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91709064,-43.26272777]} icon={camera}><Popup><span className='title-content'>Grajaú-Jacarepaguá (Subida Grajaú) - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=001492' alt='Grajaú-Jacarepaguá (Subida Grajaú) - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91698688,-43.26288870]} icon={camera}><Popup><span className='title-content'>Grajaú-Jacarepaguá (Subida Grajaú) - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=001493' alt='Grajaú-Jacarepaguá (Subida Grajaú) - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89550498,-43.29105444]} icon={camera}><Popup><span className='title-content'>R. ARQUIAS CORDEIRO X R. DR. PADILHA - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001494' alt='R. ARQUIAS CORDEIRO X R. DR. PADILHA - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89553339,-43.29120062]} icon={camera}><Popup><span className='title-content'>R. ARQUIAS CORDEIRO X R. DR. PADILHA - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001495' alt='R. ARQUIAS CORDEIRO X R. DR. PADILHA - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91086464,-43.21397592]} icon={camera}><Popup><span className='title-content'>Praça da Bandeira -FIXA -  Centro da Cidade - Praca da Bandeira</span><img src='http://187.111.99.18:9004/?CODE=001496' alt='Praça da Bandeira -FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91088317,-43.21347837]} icon={camera}><Popup><span className='title-content'>Praça da Bandeira -FIXA -  Centro da Cidade - Praca da Bandeira</span><img src='http://187.111.99.18:9004/?CODE=001497' alt='Praça da Bandeira -FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91683558,-43.25997292]} icon={camera}><Popup><span className='title-content'>R. Visconde de Santa Isabel X R. Alexandre Calaza - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=001498' alt='R. Visconde de Santa Isabel X R. Alexandre Calaza - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91696776,-43.25990721]} icon={camera}><Popup><span className='title-content'>R. Visconde de Santa Isabel X R. Alexandre Calaza - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=001499' alt='R. Visconde de Santa Isabel X R. Alexandre Calaza - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.919797,-43.233887]} icon={camera}><Popup><span className='title-content'>Av. Maracanã X R. Major Ávila - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001500' alt='Av. Maracanã X R. Major Ávila - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.919462,-43.234025]} icon={camera}><Popup><span className='title-content'>Av. Maracanã X R. Major Ávila - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001501' alt='Av. Maracanã X R. Major Ávila - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.951155,-43.175334]} icon={camera}><Popup><span className='title-content'>Av. Pasteur X R. Venceslau - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001502' alt='Av. Pasteur X R. Venceslau - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.951551,-43.175261]} icon={camera}><Popup><span className='title-content'>Av. Pasteur X R. Venceslau - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001503' alt='Av. Pasteur X R. Venceslau - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89892410,-43.22031261]} icon={camera}><Popup><span className='title-content'>CAMPO DE SÃO CRISTÓVÃO X COLÉGIO PEDRO II - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=001504' alt='CAMPO DE SÃO CRISTÓVÃO X COLÉGIO PEDRO II - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.899081,-43.220322]} icon={camera}><Popup><span className='title-content'>CAMPO DE SÃO CRISTÓVÃO X COLÉGIO PEDRO II - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=001505' alt='CAMPO DE SÃO CRISTÓVÃO X COLÉGIO PEDRO II - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95443216,-43.19304187]} icon={camera}><Popup><span className='title-content'>R. Voluntários da Pátria X R. Real Grandeza - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001506' alt='R. Voluntários da Pátria X R. Real Grandeza - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95434572,-43.19297012]} icon={camera}><Popup><span className='title-content'>R. Voluntários da Pátria X R. Real Grandeza - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001507' alt='R. Voluntários da Pátria X R. Real Grandeza - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.907555,-43.219223]} icon={camera}><Popup><span className='title-content'>R. Francisco Eugênio, 329 - São Cristóvão - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=001508' alt='R. Francisco Eugênio, 329 - São Cristóvão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90747840,-43.21917874]} icon={camera}><Popup><span className='title-content'>R. Francisco Eugênio, 329 - São Cristóvão - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=001509' alt='R. Francisco Eugênio, 329 - São Cristóvão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908592,-43.220478]} icon={camera}><Popup><span className='title-content'>R. José Eugênio, 18 - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=001510' alt='R. José Eugênio, 18 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908674,-43.220609]} icon={camera}><Popup><span className='title-content'>R. José Eugênio, 18 - São Cristóvão - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=001511' alt='R. José Eugênio, 18 - São Cristóvão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.893799,-43.585431]} icon={camera}><Popup><span className='title-content'>Estr. do Campinho, 2226 -  FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001512' alt='Estr. do Campinho, 2226 -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.893672,-43.585578]} icon={camera}><Popup><span className='title-content'>Estr. do Campinho, 2226 -  FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=001513' alt='Estr. do Campinho, 2226 -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.850391,-43.309430]} icon={camera}><Popup><span className='title-content'>Praça Aquidauana, 1-908 - Vila Cosmos - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=001514' alt='Praça Aquidauana, 1-908 - Vila Cosmos - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.850490,-43.309430]} icon={camera}><Popup><span className='title-content'>Praça Aquidauana, 1-908 - Vila Cosmos - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=001515' alt='Praça Aquidauana, 1-908 - Vila Cosmos - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.836601,-43.308891]} icon={camera}><Popup><span className='title-content'>Av. Meriti, 2114 - Brás de Pina - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=001516' alt='Av. Meriti, 2114 - Brás de Pina - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.836701,-43.308891]} icon={camera}><Popup><span className='title-content'>Av. Meriti, 2114 - Brás de Pina - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=001517' alt='Av. Meriti, 2114 - Brás de Pina - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.833729,-43.309377]} icon={camera}><Popup><span className='title-content'>R. Eng. Francelino Mota, 627-489 Brás de Pina - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=001518' alt='R. Eng. Francelino Mota, 627-489 Brás de Pina - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.833929,-43.309377]} icon={camera}><Popup><span className='title-content'>R. Eng. Francelino Mota, 627-489 Brás de Pina - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=001519' alt='R. Eng. Francelino Mota, 627-489 Brás de Pina - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.836220,-43.307471]} icon={camera}><Popup><span className='title-content'>Estr. do Quitungo, 1919 - Brás de Pina - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=001520' alt='Estr. do Quitungo, 1919 - Brás de Pina - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.836320,-43.307471]} icon={camera}><Popup><span className='title-content'>Estr. do Quitungo, 1919 - Brás de Pina - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=001521' alt='Estr. do Quitungo, 1919 - Brás de Pina - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.896959,-43.351512]} icon={camera}><Popup><span className='title-content'>R. Cândido Benício, 1757 - Campinho - FIXA -  Zona Norte - Campinho</span><img src='http://187.111.99.18:9004/?CODE=001522' alt='R. Cândido Benício, 1757 - Campinho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.897100,-43.351512]} icon={camera}><Popup><span className='title-content'>R. Cândido Benício, 1757 - Campinho - FIXA -  Zona Norte - Campinho</span><img src='http://187.111.99.18:9004/?CODE=001523' alt='R. Cândido Benício, 1757 - Campinho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.885262,-43.227570]} icon={camera}><Popup><span className='title-content'>R. Bela, 1281 - São Cristóvão - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=001524' alt='R. Bela, 1281 - São Cristóvão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.885242,-43.227827]} icon={camera}><Popup><span className='title-content'>R. Bela, 1281 - São Cristóvão - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=001525' alt='R. Bela, 1281 - São Cristóvão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.895882,-43.220764]} icon={camera}><Popup><span className='title-content'>Campo São Cristóvão, 13 - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=001526' alt='Campo São Cristóvão, 13 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.895926,-43.220700]} icon={camera}><Popup><span className='title-content'>Campo São Cristóvão, 13 - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=001527' alt='Campo São Cristóvão, 13 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.904440,-43.210224]} icon={camera}><Popup><span className='title-content'>Av. Francisco Bicalho, 2042 - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=001528' alt='Av. Francisco Bicalho, 2042 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.904432,-43.210369]} icon={camera}><Popup><span className='title-content'>Av. Francisco Bicalho, 2042 - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=001529' alt='Av. Francisco Bicalho, 2042 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.919879,-43.215250]} icon={camera}><Popup><span className='title-content'>R. Haddock Lobo 282 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001530' alt='R. Haddock Lobo 282 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.919783,-43.215367]} icon={camera}><Popup><span className='title-content'>R. Haddock Lobo 282 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001531' alt='R. Haddock Lobo 282 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.920927,-43.225786]} icon={camera}><Popup><span className='title-content'>Av. Heitor Beltrão, S/N - Tijuca - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001532' alt='Av. Heitor Beltrão, S/N - Tijuca - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.920903,-43.225935]} icon={camera}><Popup><span className='title-content'>Av. Heitor Beltrão, S/N - Tijuca - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001533' alt='Av. Heitor Beltrão, S/N - Tijuca - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.912101,-43.221899]} icon={camera}><Popup><span className='title-content'>R. Morais e Silva, 83 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001534' alt='R. Morais e Silva, 83 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911939,-43.222126]} icon={camera}><Popup><span className='title-content'>R. Morais e Silva, 83 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001535' alt='R. Morais e Silva, 83 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.979591,-43.202832]} icon={camera}><Popup><span className='title-content'>R. Epitacio Pessoa X R. Vinicius de Morais - FIXA -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=001538' alt='R. Epitacio Pessoa X R. Vinicius de Morais - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.979458,-43.202361]} icon={camera}><Popup><span className='title-content'>R. Epitacio Pessoa X R. Vinicius de Morais - FIXA -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=001539' alt='R. Epitacio Pessoa X R. Vinicius de Morais - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.921426104913500,-43.23576737209010]} icon={camera}><Popup><span className='title-content'>AV. MARACANA X PRAÇA LUIS LA SAIGNE - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001540' alt='AV. MARACANA X PRAÇA LUIS LA SAIGNE - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92120994,-43.23562119]} icon={camera}><Popup><span className='title-content'>AV. MARACAN X PRAÇA LUIS LA SAIGNE - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001541' alt='AV. MARACAN X PRAÇA LUIS LA SAIGNE - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.916062,-43.229935]} icon={camera}><Popup><span className='title-content'>R. São Francisco Xavier, 312 - FIXA -  Zona Norte - Sao Francisco Xavier</span><img src='http://187.111.99.18:9004/?CODE=001542' alt='R. São Francisco Xavier, 312 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915994,-43.229819]} icon={camera}><Popup><span className='title-content'>R. São Francisco Xavier, 312 - FIXA -  Zona Norte - Sao Francisco Xavier</span><img src='http://187.111.99.18:9004/?CODE=001543' alt='R. São Francisco Xavier, 312 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.897675,-43.283768]} icon={camera}><Popup><span className='title-content'>Av. Amaro Cavalcanti, 693 - FIXA -  Centro da Cidade - Engenho Novo</span><img src='http://187.111.99.18:9004/?CODE=001544' alt='Av. Amaro Cavalcanti, 693 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.897610,-43.284090]} icon={camera}><Popup><span className='title-content'>Av. Amaro Cavalcanti, 693 - FIXA -  Zona Norte - Engenho Novo</span><img src='http://187.111.99.18:9004/?CODE=001545' alt='Av. Amaro Cavalcanti, 693 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.902372,-43.265198]} icon={camera}><Popup><span className='title-content'>R. Manuel Miranda, 57 - Engenho Novo - FIXA -  Centro da Cidade - Engenho Novo</span><img src='http://187.111.99.18:9004/?CODE=001546' alt='R. Manuel Miranda, 57 - Engenho Novo - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.902400,-43.265316]} icon={camera}><Popup><span className='title-content'>R. Manuel Miranda, 57 - Engenho Novo - FIXA -  Centro da Cidade - Engenho Novo</span><img src='http://187.111.99.18:9004/?CODE=001547' alt='R. Manuel Miranda, 57 - Engenho Novo - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.884915,-43.277962]} icon={camera}><Popup><span className='title-content'>Av. Dom Hélder Câmara - FIXA -  Zona Norte - Cachambi</span><img src='http://187.111.99.18:9004/?CODE=001548' alt='Av. Dom Hélder Câmara - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88689,-43.28782]} icon={camera}><Popup><span className='title-content'>Av. Dom Hélder Câmara, 5861 - FIXA -  Zona Norte - Cachambi</span><img src='http://187.111.99.18:9004/?CODE=001549' alt='Av. Dom Hélder Câmara, 5861 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.996567,-43.260566]} icon={camera}><Popup><span className='title-content'>Autoestrada Eng. Fernando Mac Dowell  - FIXA -  Zona Oeste - Sao Conrado</span><img src='http://187.111.99.18:9004/?CODE=001550' alt='Autoestrada Eng. Fernando Mac Dowell  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.996394,-43.260180]} icon={camera}><Popup><span className='title-content'>Autoestrada Eng. Fernando Mac Dowell   - FIXA -  Zona Oeste - Sao Conrado</span><img src='http://187.111.99.18:9004/?CODE=001551' alt='Autoestrada Eng. Fernando Mac Dowell   - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.920731,-43.56299]} icon={camera}><Popup><span className='title-content'>Estr. Do Monteiro (Entre Estr. da Cambota E Estr. Iaraquã) - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001552' alt='Estr. Do Monteiro (Entre Estr. da Cambota E Estr. Iaraquã) - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913975,-43.231078]} icon={camera}><Popup><span className='title-content'>R. Isidro de Figueiredo, 93 - Maracanã  - FIXA -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=001553' alt='R. Isidro de Figueiredo, 93 - Maracanã  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914050,-43.231078]} icon={camera}><Popup><span className='title-content'>R. Isidro de Figueiredo, 93 - Maracanã  - FIXA -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=001554' alt='R. Isidro de Figueiredo, 93 - Maracanã  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.862169,-43.411317]} icon={camera}><Popup><span className='title-content'>Av. Brasil X Estr. da Equitação - FIXA -  Zona Oeste - Magalhães Bastos</span><img src='http://187.111.99.18:9004/?CODE=001555' alt='Av. Brasil X Estr. da Equitação - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.909763,-43.204178]} icon={camera}><Popup><span className='title-content'>Av. Pres. Vargas, 3107 - FIXA -  Zona Oeste - Magalhaes Bastos</span><img src='http://187.111.99.18:9004/?CODE=001556' alt='Av. Pres. Vargas, 3107 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.909710,-43.204042]} icon={camera}><Popup><span className='title-content'>Av. Pres. Vargas, 3107 - FIXA -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=001557' alt='Av. Pres. Vargas, 3107 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.829038,-43.277315]} icon={camera}><Popup><span className='title-content'>R. Cuba, 364 - FIXA -  Zona Oeste - Magalhaes Bastos</span><img src='http://187.111.99.18:9004/?CODE=001558' alt='R. Cuba, 364 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906517,-43.185974]} icon={camera}><Popup><span className='title-content'>R. República do Líbano, 67-31 - Centro - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001559' alt='R. República do Líbano, 67-31 - Centro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.886781,-43.226187]} icon={camera}><Popup><span className='title-content'>Rua Bela  - São Cristóvão - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=001560' alt='Rua Bela  - São Cristóvão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.898263,-43.276429]} icon={camera}><Popup><span className='title-content'>R. Rio Grande do Sul, 26 - Méier - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001561' alt='R. Rio Grande do Sul, 26 - Méier - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.992821,-43.366264]} icon={camera}><Popup><span className='title-content'>Av. Ayrton Senna, 2001 - Jacarepaguá - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=001563' alt='Av. Ayrton Senna, 2001 - Jacarepaguá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.949332,-43.183939]} icon={camera}><Popup><span className='title-content'>R. São Clemente, 47 - Botafogo - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001564' alt='R. São Clemente, 47 - Botafogo - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.971893,-43.191684]} icon={camera}><Popup><span className='title-content'>Rua Pompeu Loureiro, 21 - Copacabana  - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001565' alt='Rua Pompeu Loureiro, 21 - Copacabana  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915247,-43.251244]} icon={camera}><Popup><span className='title-content'>R. Barão de São Francisco, 444 - Vila Isabel - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=001566' alt='R. Barão de São Francisco, 444 - Vila Isabel - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.872738,-43.462313]} icon={camera}><Popup><span className='title-content'>R. Falcão Padilha, 264 - Bangu - FIXA -  Zona Oeste - Bangu</span><img src='http://187.111.99.18:9004/?CODE=001567' alt='R. Falcão Padilha, 264 - Bangu - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.981579,-43.213477]} icon={camera}><Popup><span className='title-content'>Av. Epitácio Pessoa, 532 - FIXA -  Zona Sul - Lagoa</span><img src='http://187.111.99.18:9004/?CODE=001568' alt='Av. Epitácio Pessoa, 532 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.861075,-43.371214]} icon={camera}><Popup><span className='title-content'>R. João Vicente - Mal. Hermes - FIXA -  Zona Norte - Marechal Hermes</span><img src='http://187.111.99.18:9004/?CODE=001569' alt='R. João Vicente - Mal. Hermes - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.861175,-43.371214]} icon={camera}><Popup><span className='title-content'>R. João Vicente - Mal. Hermes - FIXA -  Zona Norte - Marechal Hermes</span><img src='http://187.111.99.18:9004/?CODE=001570' alt='R. João Vicente - Mal. Hermes - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.997074,-43.365981]} icon={camera}><Popup><span className='title-content'>Av. Ayrton Senna, 2000 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001571' alt='Av. Ayrton Senna, 2000 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9969612,-43.3658624]} icon={camera}><Popup><span className='title-content'>Av. Ayrton Senna, 2000 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001572' alt='Av. Ayrton Senna, 2000 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.996678,-43.365629]} icon={camera}><Popup><span className='title-content'>Av. Ayrton Senna, 2000 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001573' alt='Av. Ayrton Senna, 2000 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.996665,-43.365818]} icon={camera}><Popup><span className='title-content'>Av. Ayrton Senna, 2000 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001574' alt='Av. Ayrton Senna, 2000 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90634047,-43.21024614]} icon={camera}><Popup><span className='title-content'>Av. Francisco Bicalho - IML - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001575' alt='Av. Francisco Bicalho - IML - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90611491,-43.21022]} icon={camera}><Popup><span className='title-content'>Av. Francisco Bicalho - IML - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001576' alt='Av. Francisco Bicalho - IML - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90745190,-43.19798789]} icon={camera}><Popup><span className='title-content'>Av. Pres. Vargas - Centro, Rio de Janeiro - RJ - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001577' alt='Av. Pres. Vargas - Centro, Rio de Janeiro - RJ - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.907436,-43.197520]} icon={camera}><Popup><span className='title-content'>Av. Presidente  Vargas - Centro - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001578' alt='Av. Presidente  Vargas - Centro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90737626,-43.19790286]} icon={camera}><Popup><span className='title-content'>Av. Presidente Vargas - Centro - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001579' alt='Av. Presidente Vargas - Centro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.907389,-43.197549]} icon={camera}><Popup><span className='title-content'>Av. Pres. Vargas - Centro - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001580' alt='Av. Pres. Vargas - Centro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.907179,-43.196736]} icon={camera}><Popup><span className='title-content'>Av. Pres. Vargas - Cidade Nova - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001581' alt='Av. Pres. Vargas - Cidade Nova - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906700,-43.196613]} icon={camera}><Popup><span className='title-content'>Av. Pres. Vargas - Centro - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001582' alt='Av. Pres. Vargas - Centro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90047450,-43.17716349]} icon={camera}><Popup><span className='title-content'>Av. Pres.Vargas X R. 1º Março - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001583' alt='Av. Pres.Vargas X R. 1º Março - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90117130,-43.17903539]} icon={camera}><Popup><span className='title-content'>Av. Pres.Vargas X Av. Rio Branco - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001584' alt='Av. Pres.Vargas X Av. Rio Branco - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.909256,-43.203505]} icon={camera}><Popup><span className='title-content'>Av. Pres. Vargas - Cidade Nova - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001585' alt='Av. Pres. Vargas - Cidade Nova - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908610,-43.201793]} icon={camera}><Popup><span className='title-content'>Av. Pres. Vargas, 2863 - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001586' alt='Av. Pres. Vargas, 2863 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90650880,-43.19450859]} icon={camera}><Popup><span className='title-content'>Av. Pres. Vargas, 2135 - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001587' alt='Av. Pres. Vargas, 2135 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.909230,-43.203407]} icon={camera}><Popup><span className='title-content'>Av. Pres. Vargas - Cidade Nova - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001588' alt='Av. Pres. Vargas - Cidade Nova - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908570,-43.201632]} icon={camera}><Popup><span className='title-content'>Av. Pres. Vargas, 2863 - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001589' alt='Av. Pres. Vargas, 2863 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90708820,-43.19642169]} icon={camera}><Popup><span className='title-content'>Av. Pres. Vargas - Cidade Nova - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001590' alt='Av. Pres. Vargas - Cidade Nova - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906670,-43.194290]} icon={camera}><Popup><span className='title-content'>Av. Pres. Vargas, 2135 - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001591' alt='Av. Pres. Vargas, 2135 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90669090,-43.19675409]} icon={camera}><Popup><span className='title-content'>Av. Presidente Vargas 2014 - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001593' alt='Av. Presidente Vargas 2014 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911676,-43.195227]} icon={camera}><Popup><span className='title-content'>AV. SALVADOR DE SÁ, ALTURA DO BPCHQ - FIXA -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=001594' alt='AV. SALVADOR DE SÁ, ALTURA DO BPCHQ - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911806,-43.195233]} icon={camera}><Popup><span className='title-content'>AV. SALVADOR DE SÁ, ALTURA DO BPCHQ - FIXA -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=001595' alt='AV. SALVADOR DE SÁ, ALTURA DO BPCHQ - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911447,-43.195545]} icon={camera}><Popup><span className='title-content'>Centro, Rio de Janeiro - RJ - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001596' alt='Centro, Rio de Janeiro - RJ - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911511,-43.195651]} icon={camera}><Popup><span className='title-content'>Centro, Rio de Janeiro - RJ - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001597' alt='Centro, Rio de Janeiro - RJ - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908870,-43.196781]} icon={camera}><Popup><span className='title-content'>Sub do Viaduto Sao Sebastiao - FIXA -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=001598' alt='Sub do Viaduto Sao Sebastiao - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.909005,-43.196809]} icon={camera}><Popup><span className='title-content'>Sub do Viaduto Sao Sebastiao - FIXA -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=001599' alt='Sub do Viaduto Sao Sebastiao - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908402,-43.196919]} icon={camera}><Popup><span className='title-content'>Viaduto São Sebastião 1214 - FIXA -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=001600' alt='Viaduto São Sebastião 1214 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908283,-43.196967]} icon={camera}><Popup><span className='title-content'>Santa Teresa  - FIXA -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=001601' alt='Santa Teresa  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906115,-43.192650]} icon={camera}><Popup><span className='title-content'>Av. Pres. Vargas, 1733 -  FIXA -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=001602' alt='Av. Pres. Vargas, 1733 -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906054,-43.192677]} icon={camera}><Popup><span className='title-content'>Av. Pres. Vargas, 1733 - FIXA -  Centro da Cidade - Cidade Nova</span><img src='http://187.111.99.18:9004/?CODE=001603' alt='Av. Pres. Vargas, 1733 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906653,-43.196331]} icon={camera}><Popup><span className='title-content'>Av. Pres. Vargas, 4-177  - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001604' alt='Av. Pres. Vargas, 4-177  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906779,-43.196588]} icon={camera}><Popup><span className='title-content'>Monumento Zumbi  - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001605' alt='Monumento Zumbi  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910820,-43.184071]} icon={camera}><Popup><span className='title-content'>Av. Gomes Freire - Centro - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001606' alt='Av. Gomes Freire - Centro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911472,-43.183563]} icon={camera}><Popup><span className='title-content'>Av. Gomes Freire, 615- FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001607' alt='Av. Gomes Freire, 615- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911989,-43.183258]} icon={camera}><Popup><span className='title-content'>R. do Rezende - Centro - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001608' alt='R. do Rezende - Centro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.912689,-43.183125]} icon={camera}><Popup><span className='title-content'>Av. Gomes Freire, 758 - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001609' alt='Av. Gomes Freire, 758 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.912844,-43.182896]} icon={camera}><Popup><span className='title-content'>Praça João Pessoa - Centro - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001610' alt='Praça João Pessoa - Centro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.912874,-43.182766]} icon={camera}><Popup><span className='title-content'>Praça João Pessoa - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001611' alt='Praça João Pessoa - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914557,-43.195153]} icon={camera}><Popup><span className='title-content'>R. Dr. Lagden, N.30 - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001612' alt='R. Dr. Lagden, N.30 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914635,-43.195109]} icon={camera}><Popup><span className='title-content'>R. Dr. Lagden, N.30 - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001613' alt='R. Dr. Lagden, N.30 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913710,-43.181538]} icon={camera}><Popup><span className='title-content'>R. do Lavradio, 206d - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001614' alt='R. do Lavradio, 206d - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913227,-43.181606]} icon={camera}><Popup><span className='title-content'>R. Riachuelo, 89 - Centro - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001615' alt='R. Riachuelo, 89 - Centro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91701262,-43.17634714]} icon={camera}><Popup><span className='title-content'>Avenida Augusto Severo, 272c - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001616' alt='Avenida Augusto Severo, 272c - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.916106,-43.176529]} icon={camera}><Popup><span className='title-content'>Av. Augusto Severo - Glória - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001617' alt='Av. Augusto Severo - Glória - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915371,-43.176527]} icon={camera}><Popup><span className='title-content'>Av. Augusto Severo, 130-202 - Glória- FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001618' alt='Av. Augusto Severo, 130-202 - Glória- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.917214,-43.176803]} icon={camera}><Popup><span className='title-content'>Rua da Lapa X Rua Augusto Severo - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001619' alt='Rua da Lapa X Rua Augusto Severo - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914498,-43.178182]} icon={camera}><Popup><span className='title-content'>Rua do Passeio, 70 - Centro - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001621' alt='Rua do Passeio, 70 - Centro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915299,-43.177856]} icon={camera}><Popup><span className='title-content'>Rua da Lapa, 1 - Centro - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001622' alt='Rua da Lapa, 1 - Centro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.916222,-43.177466]} icon={camera}><Popup><span className='title-content'>Rua da Lapa, 193b - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001623' alt='Rua da Lapa, 193b - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.901430,-43.179173]} icon={camera}><Popup><span className='title-content'>Av. Pres. Vargas,x Rio Branco - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001624' alt='Av. Pres. Vargas,x Rio Branco - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914101,-43.182758]} icon={camera}><Popup><span className='title-content'>R. Riachuelo - Centro - FIXA -  Centro da Cidade - Lapa</span><img src='http://187.111.99.18:9004/?CODE=001625' alt='R. Riachuelo - Centro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914207,-43.182463]} icon={camera}><Popup><span className='title-content'>R. Riachuelo x R. Francisco Muratóri - Centro -  Centro da Cidade - Lapa</span><img src='http://187.111.99.18:9004/?CODE=001626' alt='R. Riachuelo x R. Francisco Muratóri - Centro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913334,-43.179936]} icon={camera}><Popup><span className='title-content'>Av. Mem de Sá, 1565 - FIXA -  Centro da Cidade - Lapa</span><img src='http://187.111.99.18:9004/?CODE=001627' alt='Av. Mem de Sá, 1565 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913170,-43.179832]} icon={camera}><Popup><span className='title-content'>Lapa - FIXA -  Centro da Cidade - Lapa</span><img src='http://187.111.99.18:9004/?CODE=001628' alt='Lapa - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914572,-43.175205]} icon={camera}><Popup><span className='title-content'>R. Teixeira de Freitas, 1240 -  FIXA -  Centro da Cidade - Lapa</span><img src='http://187.111.99.18:9004/?CODE=001629' alt='R. Teixeira de Freitas, 1240 -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.923471,-43.230543]} icon={camera}><Popup><span className='title-content'>Av. Gabriela Prado Maia Ribeiro, 289b - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001630' alt='Av. Gabriela Prado Maia Ribeiro, 289b - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.923405,-43.230503]} icon={camera}><Popup><span className='title-content'>Av. Gabriela Prado Maia Ribeiro, 289b - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001631' alt='Av. Gabriela Prado Maia Ribeiro, 289b - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.923123,-43.236016]} icon={camera}><Popup><span className='title-content'>Av. Maracanã, 970 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001632' alt='Av. Maracanã, 970 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.923046,-43.236094]} icon={camera}><Popup><span className='title-content'>Av. Maracanã, 970 - Tijuca - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001633' alt='Av. Maracanã, 970 - Tijuca - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914529,-43.550027]} icon={camera}><Popup><span className='title-content'>Estr. da Cachamorra X R. Olinda Ellis- FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001634' alt='Estr. da Cachamorra X R. Olinda Ellis- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.887457,-43.224228]} icon={camera}><Popup><span className='title-content'>Av. Brasil, 418 - Caju - FIXA -  Zona Norte - Caju</span><img src='http://187.111.99.18:9004/?CODE=001635' alt='Av. Brasil, 418 - Caju - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.887506,-43.224199]} icon={camera}><Popup><span className='title-content'>Av. Brasil, 418 - Caju - FIXA -  Zona Norte - Caju</span><img src='http://187.111.99.18:9004/?CODE=001636' alt='Av. Brasil, 418 - Caju - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.886872,-43.280339]} icon={camera}><Popup><span className='title-content'>Av. Dom Helder Camara x R. Pedras Altas X R. Silva Mourão  - FIXA -  Zona Norte - Cachambi</span><img src='http://187.111.99.18:9004/?CODE=001637' alt='Av. Dom Helder Camara x R. Pedras Altas X R. Silva Mourão  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.006472,-43.309784]} icon={camera}><Popup><span className='title-content'>Av. Armando Lombardi, 400 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001638' alt='Av. Armando Lombardi, 400 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.006517,-43.311260]} icon={camera}><Popup><span className='title-content'>Av. Armando Lombardi, 675 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001639' alt='Av. Armando Lombardi, 675 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.006262,-43.313202]} icon={camera}><Popup><span className='title-content'>Avenida Armando Lombardi, N 800 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001640' alt='Avenida Armando Lombardi, N 800 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.925137,-43.232460]} icon={camera}><Popup><span className='title-content'>Rua Conde de Bonfim - Praça Saenz Peña, 204 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001641' alt='Rua Conde de Bonfim - Praça Saenz Peña, 204 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.923836,-43.236111]} icon={camera}><Popup><span className='title-content'>R. Pereira Nunes - Tijuca, Rio de Janeiro - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001642' alt='R. Pereira Nunes - Tijuca, Rio de Janeiro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914959,-43.187317]} icon={camera}><Popup><span className='title-content'>R. Riachuelo x R. Monte Alegre -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001643' alt='R. Riachuelo x R. Monte Alegre' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.006261,-43.312110]} icon={camera}><Popup><span className='title-content'>Av. Armando Lombardi, 704 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001644' alt='Av. Armando Lombardi, 704 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.955652,-43.196360]} icon={camera}><Popup><span className='title-content'>RUA VOLUNTÁRIOS DA PÁTRIA X RUA CAP. SL -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001645' alt='RUA VOLUNTÁRIOS DA PÁTRIA X RUA CAP. SL' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.953112,-43.189045]} icon={camera}><Popup><span className='title-content'>RUA VOLUNTÁRIOS DA PÁTRIA E/F 190 - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001646' alt='RUA VOLUNTÁRIOS DA PÁTRIA E/F 190 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.952577,-43.196284]} icon={camera}><Popup><span className='title-content'>R. São Clemente, 466 - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001647' alt='R. São Clemente, 466 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.949766,-43.189650]} icon={camera}><Popup><span className='title-content'>Rua Dona Mariana, N 02 - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001648' alt='Rua Dona Mariana, N 02 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.949806,-43.189875]} icon={camera}><Popup><span className='title-content'>R. São Clemente X Dona Mariana - FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001649' alt='R. São Clemente X Dona Mariana - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.895512,-43.558826]} icon={camera}><Popup><span className='title-content'>Estr. das Capoeiras, 39 - Campo Grande - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001650' alt='Estr. das Capoeiras, 39 - Campo Grande - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.885149,-43.557064]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha, 5 -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001651' alt='Estr. do Mendanha, 5' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.884380,-43.556973]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha, 555 -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001652' alt='Estr. do Mendanha, 555' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.883682,-43.556782]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha, 651 - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001653' alt='Estr. do Mendanha, 651 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.931502,-43.177558]} icon={camera}><Popup><span className='title-content'>R. do Catete, 347-317 -  Zona Sul - Gloria</span><img src='http://187.111.99.18:9004/?CODE=001654' alt='R. do Catete, 347-317' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.952754,-43.188029]} icon={camera}><Popup><span className='title-content'>R. Voluntários da Pátria, 187 - Botafogo -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001655' alt='R. Voluntários da Pátria, 187 - Botafogo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.932673,-43.177654]} icon={camera}><Popup><span className='title-content'>Praça José de Alencar - Flamengo -  Zona Sul - Flamengo</span><img src='http://187.111.99.18:9004/?CODE=001656' alt='Praça José de Alencar - Flamengo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913204,-43.227261]} icon={camera}><Popup><span className='title-content'>Av. Maracanã,  nº  160 -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=001657' alt='Av. Maracanã,  nº  160' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914047,-43.227993]} icon={camera}><Popup><span className='title-content'>Av. Maracanã, nº 196 - FIXA -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=001658' alt='Av. Maracanã, nº 196 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914846,-43.229710]} icon={camera}><Popup><span className='title-content'>R. Prof. Euríco Rabêlo, 51 - FIXA -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=001659' alt='R. Prof. Euríco Rabêlo, 51 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913187,-43.232361]} icon={camera}><Popup><span className='title-content'>R. Prof. Euríco Rabêlo, 177 - FIXA -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=001660' alt='R. Prof. Euríco Rabêlo, 177 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910481,-43.232814]} icon={camera}><Popup><span className='title-content'>Av. Pres. Castelo Branco, 13 - FIXA -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=001661' alt='Av. Pres. Castelo Branco, 13 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910549,-43.228401]} icon={camera}><Popup><span className='title-content'>Av. Radial Oeste, 6007 -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=001662' alt='Av. Radial Oeste, 6007' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910937,-43.226156]} icon={camera}><Popup><span className='title-content'>Av. Radial Oeste, 2088-2092 -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=001663' alt='Av. Radial Oeste, 2088-2092' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.926931,-43.235722]} icon={camera}><Popup><span className='title-content'>Rua Conde de Bonfim nº 482 - Tijuca -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001664' alt='Rua Conde de Bonfim nº 482 - Tijuca' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.880774,-43.289579]} icon={camera}><Popup><span className='title-content'>Viaduto Cristóvão Colombo, 159 -  Zona Norte - Abolicao</span><img src='http://187.111.99.18:9004/?CODE=001665' alt='Viaduto Cristóvão Colombo, 159' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.882782,-43.292053]} icon={camera}><Popup><span className='title-content'>Av. Dom Hélder Câmara, 6444 -  Zona Norte - Abolicao</span><img src='http://187.111.99.18:9004/?CODE=001666' alt='Av. Dom Hélder Câmara, 6444' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.894555,-43.295494]} icon={camera}><Popup><span className='title-content'>Galpão do Engenhão - R. José dos Reis -  Centro da Cidade - Engenho de Dentro</span><img src='http://187.111.99.18:9004/?CODE=001667' alt='Galpão do Engenhão - R. José dos Reis' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.893032,-43.288075]} icon={camera}><Popup><span className='title-content'>R. Dona Teresa, 161 -  Centro da Cidade - Engenho de Dentro</span><img src='http://187.111.99.18:9004/?CODE=001668' alt='R. Dona Teresa, 161' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.897682,-43.284035]} icon={camera}><Popup><span className='title-content'>Av. Amaro Cavalcanti, 693 -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001669' alt='Av. Amaro Cavalcanti, 693' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89004,-43.29436]} icon={camera}><Popup><span className='title-content'>R. da Abolição, 058 -  Zona Norte - Abolicao</span><img src='http://187.111.99.18:9004/?CODE=001670' alt='R. da Abolição, 058' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.855104,-43.252029]} icon={camera}><Popup><span className='title-content'>Av. Teixeira de Castro - BRT - Santa Luzia -  Zona Norte - Ramos</span><img src='http://187.111.99.18:9004/?CODE=001671' alt='Av. Teixeira de Castro - BRT - Santa Luzia' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.841656,-43.320068]} icon={camera}><Popup><span className='title-content'>Estrada Padre Roser, 750 - Irajá -  Zona Norte - Iraja</span><img src='http://187.111.99.18:9004/?CODE=001672' alt='Estrada Padre Roser, 750 - Irajá' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.841467,-43.317192]} icon={camera}><Popup><span className='title-content'>Av. Padre Rose 430 - Vila da Penha -  Zona Norte - Iraja</span><img src='http://187.111.99.18:9004/?CODE=001673' alt='Av. Padre Rose 430 - Vila da Penha' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.893061,-43.675072]} icon={camera}><Popup><span className='title-content'>Av. Brasil, 2385 -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=001674' alt='Av. Brasil, 2385' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910355,-43.595242]} icon={camera}><Popup><span className='title-content'>Rua Inhoaíba, Rio de Janeiro - RJ, 23059-550 -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=001675' alt='Rua Inhoaíba, Rio de Janeiro - RJ, 23059-550' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.922482,-43.647499]} icon={camera}><Popup><span className='title-content'>Av. Cesário de Melo x Av. do Canal - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=001676' alt='Av. Cesário de Melo x Av. do Canal - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86966767618130,-43.55448323925200]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha 142  - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=001677' alt='Estr. do Mendanha 142  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908756,-43.550877]} icon={camera}><Popup><span className='title-content'>Est. do Cabuçu, 747 - Campo Grande -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=001678' alt='Est. do Cabuçu, 747 - Campo Grande' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.912664234873800,-43.544249463789]} icon={camera}><Popup><span className='title-content'>Est. do Cabuçu, 2219 - Campo Grande -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=001679' alt='Est. do Cabuçu, 2219 - Campo Grande' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.940070,-43.249187]} icon={camera}><Popup><span className='title-content'>Rua Conde De Bonfim, 1037 - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001681' alt='Rua Conde De Bonfim, 1037 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.941822,-43.252515]} icon={camera}><Popup><span className='title-content'>Rua Conde de Bonfim  1179 - Tijuca - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001682' alt='Rua Conde de Bonfim  1179 - Tijuca - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.946315,-43.255448]} icon={camera}><Popup><span className='title-content'>Rua Conde de Bonfim, 1339 - Tijuca - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001683' alt='Rua Conde de Bonfim, 1339 - Tijuca - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.946937,-43.256866]} icon={camera}><Popup><span className='title-content'>Rua Conde Bonfim 1590  - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001684' alt='Rua Conde Bonfim 1590  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.946085,-43.258206]} icon={camera}><Popup><span className='title-content'>R. Mal. Pilsudski, 94 - Alto da Boa Vista  - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001685' alt='R. Mal. Pilsudski, 94 - Alto da Boa Vista  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.946781,-43.259014]} icon={camera}><Popup><span className='title-content'>R. Mal. Pilsudski, 68 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001686' alt='R. Mal. Pilsudski, 68 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.948078,-43.259529]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Q59 Rio de Janeiro  - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001687' alt='Alto da Boa Vista, Q59 Rio de Janeiro  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.949480,-43.260452]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 637 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001688' alt='Av. Édison Passos, 637 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.949137,-43.261353]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 742 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001689' alt='Av. Édison Passos, 742 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.950155,-43.262013]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 872 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001690' alt='Av. Édison Passos, 872 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.951439,-43.261532]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 982-1044 - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001691' alt='Av. Édison Passos, 982-1044 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.951614,-43.260078]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 1237-1199 - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001692' alt='Av. Édison Passos, 1237-1199 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.951066,-43.258805]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro  - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001693' alt='Alto da Boa Vista, Rio de Janeiro  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.950853,-43.257698]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro  - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001694' alt='Alto da Boa Vista, Rio de Janeiro  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.951593,-43.259190]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro  - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001695' alt='Alto da Boa Vista, Rio de Janeiro  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910649,-43.228401]} icon={camera}><Popup><span className='title-content'>Av. Radial Oeste, 6007 - Maracanã - FIXA -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=001696' alt='Av. Radial Oeste, 6007 - Maracanã - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911037,-43.226156]} icon={camera}><Popup><span className='title-content'>Av. Radial Oeste, 2088-2092 - Maracanã - FIXA -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=001697' alt='Av. Radial Oeste, 2088-2092 - Maracanã - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.952940,-43.261063]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro  - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001698' alt='Alto da Boa Vista, Rio de Janeiro  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.953747,-43.262329]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 1980 - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001699' alt='Av. Édison Passos, 1980 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.954586,-43.264549]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001700' alt='Av. Édison Passos - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.955542,-43.265244]} icon={camera}><Popup><span className='title-content'>Estr. Velha da Tijuca, 1251 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001701' alt='Estr. Velha da Tijuca, 1251 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.956146,-43.265518]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro  - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001702' alt='Alto da Boa Vista, Rio de Janeiro  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95693210,-43.26768413]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 2799 - Alto da Boa VistaFIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001703' alt='Av. Édison Passos, 2799 - Alto da Boa VistaFIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.957306,-43.268509]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro - RJ - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001704' alt='Alto da Boa Vista, Rio de Janeiro - RJ - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.957254,-43.267586]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista - RJ - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001705' alt='Alto da Boa Vista - RJ - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.957304,-43.265045]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro - RJ - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001706' alt='Alto da Boa Vista, Rio de Janeiro - RJ - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91425046879850,-43.25168054907370]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa - Entrada Vila Isabel - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=001708' alt='Túnel Noel Rosa - Entrada Vila Isabel - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914304816252300,-43.25163762372950]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa - Entrada Vila Isabel - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=001709' alt='Túnel Noel Rosa - Entrada Vila Isabel - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913649669787800,-43.25194580901430]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa  - saída Vila Isabel - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=001710' alt='Túnel Noel Rosa  - saída Vila Isabel - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913716163714400,-43.251942273645400]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa - Saída Vila Isabel - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=001711' alt='Túnel Noel Rosa - Saída Vila Isabel - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.946111,-43.258687]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 97 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001714' alt='Av. Édison Passos, 97 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.946228,-43.258804]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 194-256 - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001715' alt='Av. Édison Passos, 194-256 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94731939,-43.25925217]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 346-398 - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001716' alt='Av. Édison Passos, 346-398 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.947475,-43.259279]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 565-515 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001717' alt='Av. Édison Passos, 565-515 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94925764,-43.26003008]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 603- FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001718' alt='Av. Édison Passos, 603- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.949477,-43.260683]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 667 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001719' alt='Av. Édison Passos, 667 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8437861,-43.4010439]} icon={camera}><Popup><span className='title-content'>R. Aripua, 316 - Ricardo de Albuquerque - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001720' alt='R. Aripua, 316 - Ricardo de Albuquerque - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.949345,-43.261769]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 800 -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001721' alt='Av. Édison Passos, 800' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.949247,-43.261025]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 711 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001722' alt='Av. Édison Passos, 711 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.950587,-43.261900]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 934 - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001723' alt='Av. Édison Passos, 934 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.949955,-43.261717]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 603- FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001724' alt='Av. Édison Passos, 603- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.951153,-43.261803]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 1011 - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001725' alt='Av. Édison Passos, 1011 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8363638,-43.4007509]} icon={camera}><Popup><span className='title-content'>R. Aroeiras, 261 - Ricardo de Albuquerque - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001726' alt='R. Aroeiras, 261 - Ricardo de Albuquerque - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8268803,-43.400622]} icon={camera}><Popup><span className='title-content'>Av. Nazaré, 2319-2125 - Anchieta - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001727' alt='Av. Nazaré, 2319-2125 - Anchieta - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.951528,-43.260449]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 1271 - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001728' alt='Av. Édison Passos, 1271 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.951311,-43.259854]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 583 - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001729' alt='Av. Édison Passos, 583 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.951255,-43.259331]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 1240-1410 - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001730' alt='Av. Édison Passos, 1240-1410 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.951360,-43.259822]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001731' alt='Alto da Boa Vista, Rio de Janeiro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.950746,-43.257729]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001732' alt='Alto da Boa Vista' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.951032,-43.258427]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 1240-1410 - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001733' alt='Av. Édison Passos, 1240-1410 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.951172,-43.258405]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro - RJ - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001734' alt='Alto da Boa Vista, Rio de Janeiro - RJ - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.951749,-43.259494]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 1596-1708 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001735' alt='Av. Édison Passos, 1596-1708 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.952617,-43.260783]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 1710-1874 - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001736' alt='Av. Édison Passos, 1710-1874 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.953212,-43.261497]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 1875 - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001737' alt='Av. Édison Passos, 1875 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.953563,-43.261949]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 1919 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001738' alt='Av. Édison Passos, 1919 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.954388,-43.262788]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 2029 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001739' alt='Av. Édison Passos, 2029 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.954463,-43.264193]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 2261 -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001740' alt='Av. Édison Passos, 2261' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.954949,-43.264892]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 2272 - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001741' alt='Av. Édison Passos, 2272 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.955400,-43.265319]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 2395 -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001742' alt='Av. Édison Passos, 2395' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.955851,-43.264505]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 2477 - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001743' alt='Av. Édison Passos, 2477 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.955763,-43.264591]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista  - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001744' alt='Alto da Boa Vista  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.956076,-43.265811]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001745' alt='Alto da Boa Vista, Rio de Janeiro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.956153,-43.266385]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001746' alt='Alto da Boa Vista, Rio de Janeiro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.956529,-43.267163]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001747' alt='Alto da Boa Vista, Rio de Janeiro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.956651,-43.267671]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001748' alt='Alto da Boa Vista, Rio de Janeiro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.957040,-43.268156]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001749' alt='Alto da Boa Vista, Rio de Janeiro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.957128,-43.268289]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001750' alt='Alto da Boa Vista, Rio de Janeiro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.957010,-43.267601]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001751' alt='Alto da Boa Vista, Rio de Janeiro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.956896,-43.266799]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 3132 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001753' alt='Av. Édison Passos, 3132 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.956703,-43.265910]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001754' alt='Alto da Boa Vista, Rio de Janeiro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.957585,-43.264546]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001756' alt='Alto da Boa Vista, Rio de Janeiro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.957991022026300,-43.264270906745904]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 3123 - Alto da Boa Vista -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001757' alt='Av. Édison Passos, 3123 - Alto da Boa Vista' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.957707,-43.264287]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 3127 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001758' alt='Av. Édison Passos, 3127 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.958160,-43.265293]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro - RJ - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001759' alt='Alto da Boa Vista, Rio de Janeiro - RJ - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.958390234873400,-43.26501683432380]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro  - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001760' alt='Alto da Boa Vista, Rio de Janeiro  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.958377,-43.265240]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro  - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001761' alt='Alto da Boa Vista, Rio de Janeiro  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.958189,-43.265197]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro  - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001762' alt='Alto da Boa Vista, Rio de Janeiro  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.957939,-43.266824]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro  - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001763' alt='Alto da Boa Vista, Rio de Janeiro  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.957979,-43.266878]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro  - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001764' alt='Alto da Boa Vista, Rio de Janeiro  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.958060,-43.266845]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro  - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001765' alt='Alto da Boa Vista, Rio de Janeiro  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.958669,-43.267636]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001766' alt='Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.958553,-43.267638]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 4794 - Alto da Boa Vista  - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001767' alt='Av. Édison Passos, 4794 - Alto da Boa Vista  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.959189,-43.267996]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 4114 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001768' alt='Av. Édison Passos, 4114 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.959186,-43.267990]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 4150 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001769' alt='Av. Édison Passos, 4150 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.959349,-43.268420]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 4200 - Alto da Boa Vista  - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001770' alt='Av. Édison Passos, 4200 - Alto da Boa Vista  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95964727,-43.26929764]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 4200 - Alto da Boa Vista  - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001771' alt='Av. Édison Passos, 4200 - Alto da Boa Vista  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95999363,-43.26974274]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 4211 - Alto da Boa Vista -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001772' alt='Av. Édison Passos, 4211 - Alto da Boa Vista' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96044798,-43.27023367]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 4272 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001773' alt='Av. Édison Passos, 4272 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96040846,-43.27018003]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 4272 - Alto da Boa Vista -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001774' alt='Av. Édison Passos, 4272 - Alto da Boa Vista' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96018739,-43.27125237]} icon={camera}><Popup><span className='title-content'>Estr. Velha da Tijuca, 2400-2424 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001775' alt='Estr. Velha da Tijuca, 2400-2424 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96039210,-43.27202794]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 4271 - Alto da Boa Vista  - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001776' alt='Av. Édison Passos, 4271 - Alto da Boa Vista  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96034921,-43.27170348]} icon={camera}><Popup><span className='title-content'>Estr. Velha da Tijuca, 2424 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001777' alt='Estr. Velha da Tijuca, 2424 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96033003,-43.27205116]} icon={camera}><Popup><span className='title-content'>Estr. Velha da Tijuca, 4446 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001778' alt='Estr. Velha da Tijuca, 4446 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96031651,-43.27273408]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 4490 - Alto da Boa Vista  - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001779' alt='Av. Édison Passos, 4490 - Alto da Boa Vista  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96047842,-43.27282894]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 4490 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001780' alt='Av. Édison Passos, 4490 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96099419,-43.27310820]} icon={camera}><Popup><span className='title-content'>Praça Afonso Viseu, 27 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001781' alt='Praça Afonso Viseu, 27 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96097443,-43.27295800]} icon={camera}><Popup><span className='title-content'>Praça Afonso Viseu, 27 - Alto da Boa Vista  - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001782' alt='Praça Afonso Viseu, 27 - Alto da Boa Vista  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96140364,-43.27338554]} icon={camera}><Popup><span className='title-content'>Praça Afonso Viseu - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001783' alt='Praça Afonso Viseu - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96200947,-43.27432450]} icon={camera}><Popup><span className='title-content'>R. Boa Vista, 42 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001784' alt='R. Boa Vista, 42 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96211984,-43.27433736]} icon={camera}><Popup><span className='title-content'>R. Boa Vista - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001785' alt='R. Boa Vista - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.962435,-43.274715]} icon={camera}><Popup><span className='title-content'>R. Boa Vista, 65 - Alto da Boa Vista  - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001786' alt='R. Boa Vista, 65 - Alto da Boa Vista  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.962852,-43.275106]} icon={camera}><Popup><span className='title-content'>R. Boa Vista, 52 - Alto da Boa Vista  - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001787' alt='R. Boa Vista, 52 - Alto da Boa Vista  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96314255,-43.27548860]} icon={camera}><Popup><span className='title-content'>R. Boa Vista, 111-71 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001788' alt='R. Boa Vista, 111-71 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.963734,-43.275644]} icon={camera}><Popup><span className='title-content'>R. Boa Vista, 111-71 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001789' alt='R. Boa Vista, 111-71 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.964243,-43.276656]} icon={camera}><Popup><span className='title-content'>R. Ferreira de Almeida, 2 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001790' alt='R. Ferreira de Almeida, 2 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.964201,-43.276549]} icon={camera}><Popup><span className='title-content'>R. Boa Vista, 107 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001791' alt='R. Boa Vista, 107 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.964546,-43.276978]} icon={camera}><Popup><span className='title-content'>R. Boa Vista, 134 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa vista</span><img src='http://187.111.99.18:9004/?CODE=001792' alt='R. Boa Vista, 134 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.964900,-43.277067]} icon={camera}><Popup><span className='title-content'>R. Boa Vista, 122-158 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001793' alt='R. Boa Vista, 122-158 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.965379,-43.277390]} icon={camera}><Popup><span className='title-content'>R. Boa Vista, 158 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001794' alt='R. Boa Vista, 158 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.965599,-43.277341]} icon={camera}><Popup><span className='title-content'>Alto da Boa Vista, Rio de Janeiro - RJ - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001795' alt='Alto da Boa Vista, Rio de Janeiro - RJ - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.966032,-43.277474]} icon={camera}><Popup><span className='title-content'>R. Boa Vista, 242 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001796' alt='R. Boa Vista, 242 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.966979,-43.278064]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 199-169 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001797' alt='Estr. das Furnas, 199-169 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.968224,-43.279265]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 490 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001798' alt='Estr. das Furnas, 490 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.968607,-43.279630]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 572 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001799' alt='Estr. das Furnas, 572 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.968837,-43.279005]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 574 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001800' alt='Estr. das Furnas, 574 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.967892,-43.279073]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 361 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001801' alt='Estr. das Furnas, 361 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.969597,-43.279630]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 580 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001802' alt='Estr. das Furnas, 580 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.968776,-43.278942]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 572 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001803' alt='Estr. das Furnas, 572 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.969580,-43.279580]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 572 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001804' alt='Estr. das Furnas, 572 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.969591,-43.2796]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 580 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001805' alt='Estr. das Furnas, 580 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.969645,-43.280419]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 601 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001806' alt='Estr. das Furnas, 601 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.969667,-43.280513]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 601 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001807' alt='Estr. das Furnas, 601 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.969876,-43.280771]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 602-680 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001808' alt='Estr. das Furnas, 602-680 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.970419,-43.281203]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 775-681 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001809' alt='Estr. das Furnas, 775-681 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.971743,-43.283226]} icon={camera}><Popup><span className='title-content'>Rua Anael Rosa - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001810' alt='Rua Anael Rosa - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97178913,-43.28336276]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 1042 - casa - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001811' alt='Estr. das Furnas, 1042 - casa - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.972149,-43.283660]} icon={camera}><Popup><span className='title-content'>R. Boa Vista, 1173-1041 - Alto da Boa Vista  - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001812' alt='R. Boa Vista, 1173-1041 - Alto da Boa Vista  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.973194,-43.284709]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 1280 - Itanhangá  - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001813' alt='Estr. das Furnas, 1280 - Itanhangá  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.973507,-43.285040]} icon={camera}><Popup><span className='title-content'>R. Boa Vista, 1299-1209 - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001814' alt='R. Boa Vista, 1299-1209 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.974012,-43.285632]} icon={camera}><Popup><span className='title-content'>R. Boa Vista, 1302-1456 - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001815' alt='R. Boa Vista, 1302-1456 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.974220,-43.285824]} icon={camera}><Popup><span className='title-content'>R. Boa Vista, 1302-1456 - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001816' alt='R. Boa Vista, 1302-1456 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.974418,-43.286016]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 1440 - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001817' alt='Estr. das Furnas, 1440 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.977760,-43.291279]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 1800 - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001818' alt='Estr. das Furnas, 1800 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.977928,-43.291448]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 0 - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001819' alt='Estr. das Furnas, 0 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.976791,-43.290361]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 1837-1651 - Itanhangá  - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001820' alt='Estr. das Furnas, 1837-1651 - Itanhangá  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.977092,-43.290909]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 1850 - Itanhangá  - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001821' alt='Estr. das Furnas, 1850 - Itanhangá  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97058523,-43.28190318]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 801-777 - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001822' alt='Estr. das Furnas, 801-777 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.970722,-43.282553]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 915-803 - Alto da Boa Vista  - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001823' alt='Estr. das Furnas, 915-803 - Alto da Boa Vista  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.970847898012000,-43.282728305166000]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 866 - Alto da Boa Vista  - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001824' alt='Estr. das Furnas, 866 - Alto da Boa Vista  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.970872,-43.282745]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 915-803 - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001825' alt='Estr. das Furnas, 915-803 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.972550,-43.284019]} icon={camera}><Popup><span className='title-content'>Rua Francisco Rosalino 5 - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001826' alt='Rua Francisco Rosalino 5 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.972906,-43.284371]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 1142 - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001827' alt='Estr. das Furnas, 1142 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.974736,-43.286911]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 1400-1510 - Itanhangá  - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001828' alt='Estr. das Furnas, 1400-1510 - Itanhangá  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.974945,-43.287164]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 440 - Itanhangá  - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001829' alt='Estr. das Furnas, 440 - Itanhangá  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.975392,-43.287517]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 1563-1511 - Itanhangá - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001830' alt='Estr. das Furnas, 1563-1511 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.979266,-43.292101]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 2110-2250 - Itanhangá  - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001831' alt='Estr. das Furnas, 2110-2250 - Itanhangá  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.979525,-43.292667]} icon={camera}><Popup><span className='title-content'>Estr. Cap. Campos, 29 - Alto da Boa Vista  - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001832' alt='Estr. Cap. Campos, 29 - Alto da Boa Vista  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.979569,-43.292702]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 327-277 - Itanhangá  - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001833' alt='Estr. das Furnas, 327-277 - Itanhangá  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.980220,-43.293823]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 2456 - Itanhangá - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001834' alt='Estr. das Furnas, 2456 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.980050,-43.293176]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 168-260 - Itanhangá - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001835' alt='Estr. das Furnas, 168-260 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.980293,-43.292235]} icon={camera}><Popup><span className='title-content'>R. Agrícola, 2255-2251 - Itanhangá -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001836' alt='R. Agrícola, 2255-2251 - Itanhangá' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.980334,-43.292619]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 2-142 - Itanhangá  - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001837' alt='Estr. das Furnas, 2-142 - Itanhangá  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.980298,-43.292961]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 2258-2408 - Itanhangá  - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001838' alt='Estr. das Furnas, 2258-2408 - Itanhangá  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98028650,-43.29387211]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 143 - Itanhangá -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001839' alt='Estr. das Furnas, 143 - Itanhangá' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.981196,-43.294341]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 2922 - Itanhanga - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001840' alt='Estr. das Furnas, 2922 - Itanhanga - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98130648,-43.29449188]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 2922 - Itanhanga - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001841' alt='Estr. das Furnas, 2922 - Itanhanga - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.981529,-43.294776]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 2991 - Alto da Boa Vista - FIXA -  Zona Norte - Alto da Boa Vista</span><img src='http://187.111.99.18:9004/?CODE=001842' alt='Estr. das Furnas, 2991 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.981574,-43.294955]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3000 -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001843' alt='Estr. das Furnas, 3000' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.981991,-43.295239]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3001 - Itanhangá  - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001844' alt='Estr. das Furnas, 3001 - Itanhangá  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.982214,-43.295484]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3006 - Itanhangá  - FIXA -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001845' alt='Estr. das Furnas, 3006 - Itanhangá  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.983369,-43.298161]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3063-3055 - Itanhangá,  - FIXA -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001846' alt='Estr. das Furnas, 3063-3055 - Itanhangá,  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.982477,-43.298571]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 2455  - FIXA -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001847' alt='Estr. das Furnas, 2455  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.983379,-43.298229]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3063-3055 - Itanhangá - FIXA -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001848' alt='Estr. das Furnas, 3063-3055 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.981906,-43.299167]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3081-3075 - Itanhangá - FIXA -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001849' alt='Estr. das Furnas, 3081-3075 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.981895,-43.299481]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3081-3075 - Itanhangá - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001850' alt='Estr. das Furnas, 3081-3075 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.981734,-43.299944]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3081-3075 - Itanhangá - FIXA -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001851' alt='Estr. das Furnas, 3081-3075 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.981187,-43.300635]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3800 - Itanhangá - FIXA -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001852' alt='Estr. das Furnas, 3800 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.981653,-43.300375]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3805 - Itanhangá - FIXA -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001853' alt='Estr. das Furnas, 3805 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.981389,-43.300399]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3800 - Itanhangá - FIXA -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001854' alt='Estr. das Furnas, 3800 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.981690,-43.300300]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3805 - Itanhangá - FIXA -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001855' alt='Estr. das Furnas, 3805 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.982331,-43.299878]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3997-4055 - Itanhangá - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001856' alt='Estr. das Furnas, 3997-4055 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.982348,-43.299869]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3997-4055 - Itanhangá  - FIXA -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001857' alt='Estr. das Furnas, 3997-4055 - Itanhangá  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.982778,-43.299675]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3929-3995 - Itanhangá -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001858' alt='Estr. das Furnas, 3929-3995 - Itanhangá' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.983565,-43.299584]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3982-4004 - Itanhangá - FIXA -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001859' alt='Estr. das Furnas, 3982-4004 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984217,-43.300005]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3950 - Itanhangá - FIXA -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001860' alt='Estr. das Furnas, 3950 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984728,-43.300290]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3951 - Itanhangá - FIXA -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001861' alt='Estr. das Furnas, 3951 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984901,-43.300383]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 4087 - Itanhanga - FIXA -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001862' alt='Estr. das Furnas, 4087 - Itanhanga - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984921,-43.300318]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 775-681 - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001863' alt='Estr. das Furnas, 775-681 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984921,-43.300318]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 1837-1651 - Itanhangá - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001864' alt='Estr. das Furnas, 1837-1651 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.985661,-43.300264]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 4234-4438 - Itanhangá - FIXA -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001865' alt='Estr. das Furnas, 4234-4438 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.986022,-43.300499]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 4234-4438 - Itanhangá - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001866' alt='Estr. das Furnas, 4234-4438 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.986324,-43.301322]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3700 - Itanhangá - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001867' alt='Estr. das Furnas, 3700 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.956902,-43.267726]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 2799-2791 - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001868' alt='Av. Édison Passos, 2799-2791 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.982523,-43.295625]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3040 - Itanhangá - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001869' alt='Estr. das Furnas, 3040 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.983033,-43.295737]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3042-3044 - Itanhangá - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001870' alt='Estr. das Furnas, 3042-3044 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.983725,-43.295932]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3046 - Itanhangá - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001871' alt='Estr. das Furnas, 3046 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.983721,-43.295952]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3046 - Itanhangá - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001872' alt='Estr. das Furnas, 3046 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984007,-43.296605]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3050 - Itanhangá - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001873' alt='Estr. das Furnas, 3050 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984096,-43.297036]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3052 - Itanhangá - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001874' alt='Estr. das Furnas, 3052 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.951577,-43.260438]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 1175 - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001875' alt='Av. Édison Passos, 1175 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.960576,-43.269863]} icon={camera}><Popup><span className='title-content'>Av. Édison Passos, 4271-4211 - Alto da Boa Vista - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001876' alt='Av. Édison Passos, 4271-4211 - Alto da Boa Vista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.966088,-43.277469]} icon={camera}><Popup><span className='title-content'>R. Boa Vista, 242 - Alto da Boa Vista, Rio de Janeiro - RJ, 24342-270 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001877' alt='R. Boa Vista, 242 - Alto da Boa Vista, Rio de Janeiro - RJ, 24342-270 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.987156,-43.302019]} icon={camera}><Popup><span className='title-content'>R. Dom Rosalvo Costa Rêgo, 90 - FIXA -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001878' alt='R. Dom Rosalvo Costa Rêgo, 90 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.838045,-43.3997706]} icon={camera}><Popup><span className='title-content'>R. Aroeiras, 134 - Ricardo de Albuquerque - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001880' alt='R. Aroeiras, 134 - Ricardo de Albuquerque - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8349801,-43.3996392]} icon={camera}><Popup><span className='title-content'>Rua Capitão Mário Barbedo, 460 - Parque Anchieta - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001881' alt='Rua Capitão Mário Barbedo, 460 - Parque Anchieta - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8259421,-43.4013715]} icon={camera}><Popup><span className='title-content'>Av. Nazaré, 2330 - Anchieta - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001882' alt='Av. Nazaré, 2330 - Anchieta - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.919014,-43.262755]} icon={camera}><Popup><span className='title-content'>R. José do Patrocínio, 320-390 - Grajau - FIXA -  Zona Norte - Grajau</span><img src='http://187.111.99.18:9004/?CODE=001883' alt='R. José do Patrocínio, 320-390 - Grajau - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.918881,-43.262847]} icon={camera}><Popup><span className='title-content'>R. José do Patrocínio, 318 - Grajau - FIXA -  Zona Norte - Grajau</span><img src='http://187.111.99.18:9004/?CODE=001884' alt='R. José do Patrocínio, 318 - Grajau - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90015,-43.278465]} icon={camera}><Popup><span className='title-content'>Praca Jardim do Méier   - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001885' alt='Praca Jardim do Méier   - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913473,-43.215065]} icon={camera}><Popup><span className='title-content'>R. Barão de Iguatemi, 370 - Praca da Bandeira - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001886' alt='R. Barão de Iguatemi, 370 - Praca da Bandeira - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91354,-43.215151]} icon={camera}><Popup><span className='title-content'>R. Barão de Iguatemi, 364 - Praça da Bandeira - FIXA -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001887' alt='R. Barão de Iguatemi, 364 - Praça da Bandeira - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914344,-43.216228]} icon={camera}><Popup><span className='title-content'>R. Vicente Licínio, 140 -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001888' alt='R. Vicente Licínio, 140' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.881948,-43.556315]} icon={camera}><Popup><span className='title-content'>R. Solânea, 299 - Campo Grande - FIXA -  Zona Norte - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001889' alt='R. Solânea, 299 - Campo Grande - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.880277,-43.555245]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha, 1105 - Campo Grande - FIXA -  Zona Norte - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001890' alt='Estr. do Mendanha, 1105 - Campo Grande - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.879001,-43.554758]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha, 1149 - Campo Grande - FIXA -  Zona Norte - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001891' alt='Estr. do Mendanha, 1149 - Campo Grande - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.878112,-43.554586]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha - Campo Grande - FIXA -  Zona Norte - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001892' alt='Estr. do Mendanha - Campo Grande - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8765962,-43.5546055]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha, 1448b - Campo Grande - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001893' alt='Estr. do Mendanha, 1448b - Campo Grande - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8754749,-43.5548017]} icon={camera}><Popup><span className='title-content'>Estrada do Pedregoso - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001894' alt='Estrada do Pedregoso - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8750096,-43.5544452]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha, 1532-1666 - Campo Grande - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001895' alt='Estr. do Mendanha, 1532-1666 - Campo Grande - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8744188,-43.5537439]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha, 1966-1986 - Campo Grande - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001896' alt='Estr. do Mendanha, 1966-1986 - Campo Grande - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.871624,-43.554288]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha, 2132 - Campo Grande - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001898' alt='Estr. do Mendanha, 2132 - Campo Grande - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.869131,-43.553993]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha, 2488 - Campo Grande - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001899' alt='Estr. do Mendanha, 2488 - Campo Grande - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.867893,-43.553756]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha, 2696 - Campo Grande - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001900' alt='Estr. do Mendanha, 2696 - Campo Grande - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.872356,-43.553490]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha, 2123 - Campo Grande - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001901' alt='Estr. do Mendanha, 2123 - Campo Grande - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.866407,-43.551514]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha, 3050 - Campo Grande - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001902' alt='Estr. do Mendanha, 3050 - Campo Grande - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.864806,-43.549214]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha, 3376 - Campo Grande - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001903' alt='Estr. do Mendanha, 3376 - Campo Grande - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.863843,-43.547585]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha, 3500 - Campo Grande - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001904' alt='Estr. do Mendanha, 3500 - Campo Grande - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.885709,-43.569153]} icon={camera}><Popup><span className='title-content'>Rod. Luiz Henrique Rezende Novaes , 1674 - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001905' alt='Rod. Luiz Henrique Rezende Novaes , 1674 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.884315,-43.569696]} icon={camera}><Popup><span className='title-content'>Rod. Luiz Henrique Rezende Novaes , 1762 - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001906' alt='Rod. Luiz Henrique Rezende Novaes , 1762 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.883704,-43.570395]} icon={camera}><Popup><span className='title-content'>Rod. Luiz Henrique Rezende Novaes , 1776 - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001907' alt='Rod. Luiz Henrique Rezende Novaes , 1776 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.882571,-43.571383]} icon={camera}><Popup><span className='title-content'>Estr. Rio São Paulo,  1912 - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001908' alt='Estr. Rio São Paulo,  1912 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.882089,-43.572254]} icon={camera}><Popup><span className='title-content'>Rod. Luiz Henrique Rezende Novaes, 1992 - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001909' alt='Rod. Luiz Henrique Rezende Novaes, 1992 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9875407,-43.3020504]} icon={camera}><Popup><span className='title-content'>Estrada da Barra da Tijuca, 79 - Itanhangá - FIXA -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001910' alt='Estrada da Barra da Tijuca, 79 - Itanhangá - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9871554,-43.3019302]} icon={camera}><Popup><span className='title-content'>R. Dom Rosalvo Costa Rêgo, 194 - Itanhangá -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001911' alt='R. Dom Rosalvo Costa Rêgo, 194 - Itanhangá' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.986242,-43.3019888]} icon={camera}><Popup><span className='title-content'>Estrada da Barra da Tijuca, 3068 - FIXA -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001912' alt='Estrada da Barra da Tijuca, 3068 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.900199,-43.275442]} icon={camera}><Popup><span className='title-content'>R. Castro Alves, 1 - Méier, Rio de Janeiro -  Zona Norte - Meier</span><img src='http://187.111.99.18:9004/?CODE=001913' alt='R. Castro Alves, 1 - Méier, Rio de Janeiro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.889992,-43.566186]} icon={camera}><Popup><span className='title-content'>Estrada Rio São Paulo, 1115 - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001914' alt='Estrada Rio São Paulo, 1115 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.890614,-43.565622]} icon={camera}><Popup><span className='title-content'>Estrada Rio São Paulo - Campo Grande - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001915' alt='Estrada Rio São Paulo - Campo Grande - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.891212,-43.564705]} icon={camera}><Popup><span className='title-content'>Estrada Rio São Paulo 883 - Campo Grande - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001916' alt='Estrada Rio São Paulo 883 - Campo Grande - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.891957,-43.563626]} icon={camera}><Popup><span className='title-content'>Rod. Luiz Henrique Rezende Novaes, 745 - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001917' alt='Rod. Luiz Henrique Rezende Novaes, 745 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.862775,-43.544143]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha, 4017 - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001918' alt='Estr. do Mendanha, 4017 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.862548,-43.543053]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha, 3600 - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001919' alt='Estr. do Mendanha, 3600 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8625515,-43.5420935]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha, 4517 - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001920' alt='Estr. do Mendanha, 4517 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8618516,-43.5414545]} icon={camera}><Popup><span className='title-content'>Estr. do Mendanha, 4240 - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001921' alt='Estr. do Mendanha, 4240 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99474334,-43.23434829]} icon={camera}><Popup><span className='title-content'>Av. Niemeyer, 209 - São Conrado -  Zona Sul - Niemeyer</span><img src='http://187.111.99.18:9004/?CODE=001922' alt='Av. Niemeyer, 209 - São Conrado' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9986335,-43.2529932]} icon={camera}><Popup><span className='title-content'>Parque Natural Municipal Penhasco Dois Irmão - Niemeyer -  Zona Sul - Niemeyer</span><img src='http://187.111.99.18:9004/?CODE=001923' alt='Parque Natural Municipal Penhasco Dois Irmão - Niemeyer' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.895785,-43.2374382]} icon={camera}><Popup><span className='title-content'>R. Dr. Rodrigues de Santana, 3a -  Zona Norte - Benfica</span><img src='http://187.111.99.18:9004/?CODE=001924' alt='R. Dr. Rodrigues de Santana, 3a' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8979917,-43.236923]} icon={camera}><Popup><span className='title-content'>R. São Luiz Gonzaga, 1667 -  Zona Norte - Benfica</span><img src='http://187.111.99.18:9004/?CODE=001925' alt='R. São Luiz Gonzaga, 1667' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96408239,-43.17878411]} icon={camera}><Popup><span className='title-content'>R. Duvivier, 107 - Copacabana -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001926' alt='R. Duvivier, 107 - Copacabana' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9641108,-43.178764]} icon={camera}><Popup><span className='title-content'>R. Duvivier, 107 - Copacabana -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001927' alt='R. Duvivier, 107 - Copacabana -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97752295,-43.19287925]} icon={camera}><Popup><span className='title-content'>Rua Miguel Lemos X Rua Barata Ribeiro - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001930' alt='Rua Miguel Lemos X Rua Barata Ribeiro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.983690,-43.297808]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 3063-3055  - FIXA -  Zona Oeste - Itanhanga</span><img src='http://187.111.99.18:9004/?CODE=001931' alt='Estr. das Furnas, 3063-3055  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8962144,-43.2386555]} icon={camera}><Popup><span className='title-content'>R. Dr. Rodrigues de Santana, 69-15 - FIXA -  Zona Norte - Benfica</span><img src='http://187.111.99.18:9004/?CODE=001937' alt='R. Dr. Rodrigues de Santana, 69-15 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8971883,-43.238429]} icon={camera}><Popup><span className='title-content'>R. Gen. Gustavo Cordeiro de Farias, 571-455 - FIXA -  Zona Norte - Benfica</span><img src='http://187.111.99.18:9004/?CODE=001938' alt='R. Gen. Gustavo Cordeiro de Farias, 571-455 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.897392,-43.2381424]} icon={camera}><Popup><span className='title-content'>R. Gen. Gustavo Cordeiro de Farias, 571- FIXA -  Zona Norte - Benfica</span><img src='http://187.111.99.18:9004/?CODE=001939' alt='R. Gen. Gustavo Cordeiro de Farias, 571- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91482747,-43.22964428]} icon={camera}><Popup><span className='title-content'>R. Prof. Euríco Rabêlo, 51 -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=001941' alt='R. Prof. Euríco Rabêlo, 51' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9125257,-43.2259951]} icon={camera}><Popup><span className='title-content'>Bloco L - R. Mata Machado - Maracanã -  Zona Norte - Maracana</span><img src='http://187.111.99.18:9004/?CODE=001942' alt='Bloco L - R. Mata Machado - Maracanã' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8938907,-43.5610079]} icon={camera}><Popup><span className='title-content'>Estrada Rio São Paulo 405-301 - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001943' alt='Estrada Rio São Paulo 405-301 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8880079,-43.5680954]} icon={camera}><Popup><span className='title-content'>Estrada Rio São Paulo 1456 Campo Grande Rod - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=001944' alt='Estrada Rio São Paulo 1456 Campo Grande Rod - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96739690499460,-43.189882836805296]} icon={camera}><Popup><span className='title-content'>Praça Edmundo Bitencourt  07 - FIXA -  Zona Sul - Praça Bitencourt</span><img src='http://187.111.99.18:9004/?CODE=001945' alt='Praça Edmundo Bitencourt  07 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.967326,-43.189954]} icon={camera}><Popup><span className='title-content'>Praça Edmundo Bitencourt  08 - FIXA -  Zona Sul - Praça Bitencourt</span><img src='http://187.111.99.18:9004/?CODE=001946' alt='Praça Edmundo Bitencourt  08 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.967464,-43.189948]} icon={camera}><Popup><span className='title-content'>Praça Edmundo Bitencourt  09 - FIXA -  Zona Sul - Praça Bitencourt</span><img src='http://187.111.99.18:9004/?CODE=001947' alt='Praça Edmundo Bitencourt  09 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.966955,-43.190128]} icon={camera}><Popup><span className='title-content'>Praça Edmundo Bitencourt  10 - FIXA -  Zona Sul - Praça Bitencourt</span><img src='http://187.111.99.18:9004/?CODE=001948' alt='Praça Edmundo Bitencourt  10 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.967160,-43.190216]} icon={camera}><Popup><span className='title-content'>Praça Edmundo Bitencourt  11 - FIXA -  Zona Sul - Praça Bitencourt</span><img src='http://187.111.99.18:9004/?CODE=001949' alt='Praça Edmundo Bitencourt  11 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.967185,-43.190187]} icon={camera}><Popup><span className='title-content'>Praça Edmundo Bitencourt  12 - FIXA -  Zona Sul - Praça Bitencourt</span><img src='http://187.111.99.18:9004/?CODE=001950' alt='Praça Edmundo Bitencourt  12 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.967219,-43.190167]} icon={camera}><Popup><span className='title-content'>Praça Edmundo Bitencourt  13 - FIXA -  Zona Sul - Praça Bitencourt</span><img src='http://187.111.99.18:9004/?CODE=001951' alt='Praça Edmundo Bitencourt  13 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.966770,-43.190487]} icon={camera}><Popup><span className='title-content'>Praça Edmundo Bitencourt  04- FIXA -  Zona Sul - Praça Bitencourt</span><img src='http://187.111.99.18:9004/?CODE=001952' alt='Praça Edmundo Bitencourt  04- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.966785,-43.190523]} icon={camera}><Popup><span className='title-content'>Praça Edmundo Bitencourt  05 - FIXA -  Zona Sul - Praça Bitencourt</span><img src='http://187.111.99.18:9004/?CODE=001953' alt='Praça Edmundo Bitencourt  05 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.966819,-43.190537]} icon={camera}><Popup><span className='title-content'>Praça Edmundo Bitencourt  06 - FIXA -  Zona Sul - Praça Bitencourt</span><img src='http://187.111.99.18:9004/?CODE=001954' alt='Praça Edmundo Bitencourt  06 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.966596,-43.190245]} icon={camera}><Popup><span className='title-content'>Praça Edmundo Bitencourt  01- FIXA -  Zona Sul - Praça Bitencourt</span><img src='http://187.111.99.18:9004/?CODE=001955' alt='Praça Edmundo Bitencourt  01- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96657635,-43.19017310]} icon={camera}><Popup><span className='title-content'>Praça Edmundo Bitencourt  02- FIXA -  Zona Sul - Praça Bitencourt</span><img src='http://187.111.99.18:9004/?CODE=001956' alt='Praça Edmundo Bitencourt  02- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.966585,-43.190212]} icon={camera}><Popup><span className='title-content'>Praça Edmundo Bitencourt  03- FIXA -  Zona Sul - Praça Bitencourt</span><img src='http://187.111.99.18:9004/?CODE=001957' alt='Praça Edmundo Bitencourt  03- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.967146,-43.189564]} icon={camera}><Popup><span className='title-content'>Praça Edmundo Bitencourt  14 - FIXA -  Zona Sul - Praça Bitencourt</span><img src='http://187.111.99.18:9004/?CODE=001958' alt='Praça Edmundo Bitencourt  14 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.967197,-43.189566]} icon={camera}><Popup><span className='title-content'>Praça Edmundo Bitencourt  15 - FIXA -  Zona Sul - Praça Bitencourt</span><img src='http://187.111.99.18:9004/?CODE=001959' alt='Praça Edmundo Bitencourt  15 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.967207,-43.189602]} icon={camera}><Popup><span className='title-content'>Praça Edmundo Bitencourt  16 - FIXA -  Zona Sul - Praça Bitencourt</span><img src='http://187.111.99.18:9004/?CODE=001960' alt='Praça Edmundo Bitencourt  16 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.967234,-43.189651]} icon={camera}><Popup><span className='title-content'>Praça Edmundo Bitencourt  17 - FIXA -  Zona Sul - Praça Bitencourt</span><img src='http://187.111.99.18:9004/?CODE=001961' alt='Praça Edmundo Bitencourt  17 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90632793,-43.17619575]} icon={camera}><Popup><span className='title-content'>R. México X Av. Nilo Peçanha - FIXA -  Centro da Cidade - Carioca</span><img src='http://187.111.99.18:9004/?CODE=001962' alt='R. México X Av. Nilo Peçanha - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9061647,-43.1767343]} icon={camera}><Popup><span className='title-content'>R. São José, 90/1808 - Centro - FIXA -  Centro da Cidade - Carioca</span><img src='http://187.111.99.18:9004/?CODE=001963' alt='R. São José, 90/1808 - Centro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.968524,-43.1836488]} icon={camera}><Popup><span className='title-content'>Rua Hilário de Gouvêia 493 - Copacabana -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001964' alt='Rua Hilário de Gouvêia 493 - Copacabana' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9690314,-43.1845505]} icon={camera}><Popup><span className='title-content'>Av. Nossa Sra. de Copacabana  X Rua Siqueira Campos -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=001965' alt='Av. Nossa Sra. de Copacabana  X Rua Siqueira Campos' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914468,-43.178160]} icon={camera}><Popup><span className='title-content'>Rua do Passeio, 70 - Centro -  Zona Sul - Centro</span><img src='http://187.111.99.18:9004/?CODE=001966' alt='Rua do Passeio, 70 - Centro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91466560,-43.17620090]} icon={camera}><Popup><span className='title-content'>Av. Augusto Severo N1755 -  Zona Sul - Centro</span><img src='http://187.111.99.18:9004/?CODE=001967' alt='Av. Augusto Severo N1755' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91710350,-43.17633739]} icon={camera}><Popup><span className='title-content'>Avenida Augusto Severo, 272c -  Zona Sul - Gloria</span><img src='http://187.111.99.18:9004/?CODE=001968' alt='Avenida Augusto Severo, 272c' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.03338719,-43.49205107]} icon={camera}><Popup><span className='title-content'>Estr. do Pontal, 1100 - Recreio dos Bandeirantes - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=001970' alt='Estr. do Pontal, 1100 - Recreio dos Bandeirantes - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.032262,-43.492225]} icon={camera}><Popup><span className='title-content'>Estr. Ver. Alceu de Carvalho, 126 -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=001971' alt='Estr. Ver. Alceu de Carvalho, 126' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95274741,-43.19648248]} icon={camera}><Popup><span className='title-content'>R. São Clemente, 466 -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=001972' alt='R. São Clemente, 466' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.029094,-43.492394]} icon={camera}><Popup><span className='title-content'>Estr. Ver. Alceu de Carvalho, 226-564 -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=001973' alt='Estr. Ver. Alceu de Carvalho, 226-564' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.931026,-43.179298]} icon={camera}><Popup><span className='title-content'>Rua Ministro Tavares de Lira, 17-1 -  Zona Sul - Catete</span><img src='http://187.111.99.18:9004/?CODE=001974' alt='Rua Ministro Tavares de Lira, 17-1' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.02558292,-43.49253740]} icon={camera}><Popup><span className='title-content'>Estr. Ver. Alceu de Carvalho, 902 - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=001975' alt='Estr. Ver. Alceu de Carvalho, 902 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.02335157,-43.49266860]} icon={camera}><Popup><span className='title-content'>Av. Teotônio Viléla, 842-930 -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=001976' alt='Av. Teotônio Viléla, 842-930' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01833375,-43.49286515]} icon={camera}><Popup><span className='title-content'>Estr. Ver. Alceu de Carvalho, 555 - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=001977' alt='Estr. Ver. Alceu de Carvalho, 555 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.0163343,-43.4929727]} icon={camera}><Popup><span className='title-content'>Estr. Ver. Alceu de Carvalho , S/N - FIXA -  Zona Oeste - Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=001978' alt='Estr. Ver. Alceu de Carvalho , S/N - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.012254,-43.4930573]} icon={camera}><Popup><span className='title-content'>Estr. Ver. Alceu de Carvalho, S/N - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=001979' alt='Estr. Ver. Alceu de Carvalho, S/N - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.0100552,-43.4931783]} icon={camera}><Popup><span className='title-content'>Estr. Ver. Alceu de Carvalho, 376- FIXA -  Zona Oeste - Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=001980' alt='Estr. Ver. Alceu de Carvalho, 376- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00687639,-43.49341895]} icon={camera}><Popup><span className='title-content'>Estr. Ver. Alceu de Carvalho - 2865 - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=001981' alt='Estr. Ver. Alceu de Carvalho - 2865 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00406296,-43.49352683]} icon={camera}><Popup><span className='title-content'>Estr. Ver. Alceu de Carvalho - 2879 - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=001982' alt='Estr. Ver. Alceu de Carvalho - 2879 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99937841,-43.49383073]} icon={camera}><Popup><span className='title-content'>Estr. Ver. Alceu de Carvalho, S/N - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=001983' alt='Estr. Ver. Alceu de Carvalho, S/N - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.0016034,-43.493632]} icon={camera}><Popup><span className='title-content'>Estr. Ver. Alceu de Carvalho, S/N - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=001984' alt='Estr. Ver. Alceu de Carvalho, S/N - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9974885,-43.4947743]} icon={camera}><Popup><span className='title-content'>Estr. Ver. Alcel de Carvalho, 2-222 - Vargem Grande - FIXA -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=001985' alt='Estr. Ver. Alcel de Carvalho, 2-222 - Vargem Grande - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9958392,-43.495055]} icon={camera}><Popup><span className='title-content'>Estr. Ver. Alceu de Carvalho, 51 - FIXA -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=001986' alt='Estr. Ver. Alceu de Carvalho, 51 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9937735,-43.4948334]} icon={camera}><Popup><span className='title-content'>Estr. do Rio Morto, 68 - FIXA -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=001987' alt='Estr. do Rio Morto, 68 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9889983,-43.4919595]} icon={camera}><Popup><span className='title-content'>Estr. do Rio Morto, 410 - FIXA -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=001988' alt='Estr. do Rio Morto, 410 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.986815,-43.489588]} icon={camera}><Popup><span className='title-content'>Estr. do Rio Morto, 3 - FIXA -  Zona Oeste -  Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=001989' alt='Estr. do Rio Morto, 3 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.987349,-43.488830]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 22289 - FIXA -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=001990' alt='Estr. dos Bandeirantes, 22289 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.988026,-43.487614]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 22310  - FIXA -  Zona Oeste -  Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=001991' alt='Estr. dos Bandeirantes, 22310  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.988061,-43.485957]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 22331 - FIXA -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=001992' alt='Estr. dos Bandeirantes, 22331 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.933642,-43.240203]} icon={camera}><Popup><span className='title-content'>R. Uruguai, 453 - Andaraí -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001993' alt='R. Uruguai, 453 - Andaraí' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.933836,-43.238285]} icon={camera}><Popup><span className='title-content'>Rua Itacuruçá, 99 - Tijuca -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001994' alt='Rua Itacuruçá, 99 - Tijuca' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.931481,-43.234430]} icon={camera}><Popup><span className='title-content'>Praça Gabriel Soares, 409 - Tijuca -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001995' alt='Praça Gabriel Soares, 409 - Tijuca' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9145919,-43.189465]} icon={camera}><Popup><span className='title-content'>R. Costa Bastos x Riachuelo 36 -  Centro da Cidade - Santa Teresa</span><img src='http://187.111.99.18:9004/?CODE=001996' alt='R. Costa Bastos x Riachuelo 36' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9143509,-43.1840155]} icon={camera}><Popup><span className='title-content'>R. dos Invalidos, 224 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=001997' alt='R. dos Invalidos, 224' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9289714,-43.2339482]} icon={camera}><Popup><span className='title-content'>R. Henry Ford, 301 - Tijuca -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=001998' alt='R. Henry Ford, 301 - Tijuca' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8260986,-43.3488001]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 11009 - FIXA -  Zona Norte - Coelho Neto</span><img src='http://187.111.99.18:9004/?CODE=001999' alt='Av. Pastor Martin Luther King Júnior, 11009 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.012314,-43.458156]} icon={camera}><Popup><span className='title-content'>GLAUCIO GIL  - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002000' alt='GLAUCIO GIL  - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.012462,-43.458615]} icon={camera}><Popup><span className='title-content'>GLAUCIO GIL  - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002001' alt='GLAUCIO GIL  - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.012420,-43.458470]} icon={camera}><Popup><span className='title-content'>GLAUCIO GIL  - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002002' alt='GLAUCIO GIL  - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.012393,-43.458908]} icon={camera}><Popup><span className='title-content'>GLAUCIO GIL  - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002003' alt='GLAUCIO GIL  - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.012114,-43.458210]} icon={camera}><Popup><span className='title-content'>GLAUCIO GIL  - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002004' alt='GLAUCIO GIL  - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.012223,-43.458760]} icon={camera}><Popup><span className='title-content'>GLAUCIO GIL  - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002005' alt='GLAUCIO GIL  - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.855054,-43.252503]} icon={camera}><Popup><span className='title-content'>Santa Luzia - BRT - FIXA -  Zona Norte - Ramos</span><img src='http://187.111.99.18:9004/?CODE=002015' alt='Santa Luzia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.854711,-43.253977]} icon={camera}><Popup><span className='title-content'>Santa Luzia - BRT - FIXA -  Zona Norte - Ramos</span><img src='http://187.111.99.18:9004/?CODE=002016' alt='Santa Luzia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.854904,-43.253251]} icon={camera}><Popup><span className='title-content'>Santa Luzia - BRT - FIXA -  Zona Norte - Ramos</span><img src='http://187.111.99.18:9004/?CODE=002017' alt='Santa Luzia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.847100,-43.243876]} icon={camera}><Popup><span className='title-content'>Maré - BRT - FIXA -  Zona Norte - Mare</span><img src='http://187.111.99.18:9004/?CODE=002018' alt='Maré - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.847137,-43.244025]} icon={camera}><Popup><span className='title-content'>Maré - BRT - FIXA -  Zona Norte - Mare</span><img src='http://187.111.99.18:9004/?CODE=002019' alt='Maré - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.846966,-43.243391]} icon={camera}><Popup><span className='title-content'>Maré - BRT - FIXA -  Zona Norte - Mare</span><img src='http://187.111.99.18:9004/?CODE=002020' alt='Maré - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90037933,-43.17676935]} icon={camera}><Popup><span className='title-content'>PIRA OLÍMPICA 2 -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=002021' alt='PIRA OLÍMPICA 2' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.857470,-43.258072]} icon={camera}><Popup><span className='title-content'>Cardoso de Moraes - Viúva Garcia - BRT - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=002022' alt='Cardoso de Moraes - Viúva Garcia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.857512,-43.257790]} icon={camera}><Popup><span className='title-content'>Cardoso de Moraes - Viúva Garcia - BRT - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=002023' alt='Cardoso de Moraes - Viúva Garcia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.857440,-43.258357]} icon={camera}><Popup><span className='title-content'>Cardoso de Moraes - Viúva Garcia - BRT - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=002024' alt='Cardoso de Moraes - Viúva Garcia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.841316,-43.276501]} icon={camera}><Popup><span className='title-content'>Penha I Parador - BRT - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=002025' alt='Penha I Parador - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.84142691243470,-43.2773511276474]} icon={camera}><Popup><span className='title-content'>Penha I Parador - BRT - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=002026' alt='Penha I Parador - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.841666,-43.277165]} icon={camera}><Popup><span className='title-content'>Penha I Parador - BRT - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=002027' alt='Penha I Parador - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.841913,-43.277341]} icon={camera}><Popup><span className='title-content'>Penha I - BRT - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=002028' alt='Penha I - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.841229,-43.276407]} icon={camera}><Popup><span className='title-content'>Penha I - BRT - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=002029' alt='Penha I - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.842146,-43.277616]} icon={camera}><Popup><span className='title-content'>Penha I - BRT - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=002030' alt='Penha I - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.842020,-43.277129]} icon={camera}><Popup><span className='title-content'>Penha II - BRT - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=002031' alt='Penha II - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.841944,-43.277021]} icon={camera}><Popup><span className='title-content'>Penha II - BRT - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=002032' alt='Penha II - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.842029,-43.276621]} icon={camera}><Popup><span className='title-content'>Penha II - BRT - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=002033' alt='Penha II - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.842129,-43.276621]} icon={camera}><Popup><span className='title-content'>Penha II - BRT - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=002034' alt='Penha II - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.842229,-43.276621]} icon={camera}><Popup><span className='title-content'>Penha II - BRT - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=002035' alt='Penha II - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.842329,-43.276621]} icon={camera}><Popup><span className='title-content'>Penha II - BRT - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=002036' alt='Penha II - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.839275,-43.283045]} icon={camera}><Popup><span className='title-content'>Pastor José Santos - BRT - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=002037' alt='Pastor José Santos - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.838975,-43.283571]} icon={camera}><Popup><span className='title-content'>Pastor José Santos - BRT - FIXA -  Zona Norte - Penha Circular</span><img src='http://187.111.99.18:9004/?CODE=002038' alt='Pastor José Santos - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.839119,-43.283395]} icon={camera}><Popup><span className='title-content'>Pastor José Santos - BRT - FIXA -  Zona Norte - Penha Circular</span><img src='http://187.111.99.18:9004/?CODE=002039' alt='Pastor José Santos - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.837720,-43.289513]} icon={camera}><Popup><span className='title-content'>Guapore - BRT - FIXA -  Zona Norte - Penha Circular</span><img src='http://187.111.99.18:9004/?CODE=002040' alt='Guapore - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.837739,-43.289829]} icon={camera}><Popup><span className='title-content'>Guapore - BRT - FIXA -  Zona Norte - Penha Circular</span><img src='http://187.111.99.18:9004/?CODE=002041' alt='Guapore - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.837683,-43.290059]} icon={camera}><Popup><span className='title-content'>Guapore - BRT - FIXA -  Zona Norte - Penha Circular</span><img src='http://187.111.99.18:9004/?CODE=002042' alt='Guapore - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.838994,-43.295294]} icon={camera}><Popup><span className='title-content'>Praca do Carmo - BRT - FIXA -  Zona Norte - Penha Circular</span><img src='http://187.111.99.18:9004/?CODE=002043' alt='Praca do Carmo - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.838843,-43.295112]} icon={camera}><Popup><span className='title-content'>Praca do Carmo - BRT - FIXA -  Zona Norte - Penha Circular</span><img src='http://187.111.99.18:9004/?CODE=002044' alt='Praca do Carmo - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.838619,-43.294788]} icon={camera}><Popup><span className='title-content'>Praca do Carmo - BRT - FIXA -  Zona Norte - Penha Circular</span><img src='http://187.111.99.18:9004/?CODE=002045' alt='Praca do Carmo - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.841317,-43.298487]} icon={camera}><Popup><span className='title-content'>Pedro Taques - BRT - FIXA -  Zona Norte - Penha Circular</span><img src='http://187.111.99.18:9004/?CODE=002046' alt='Pedro Taques - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.841507,-43.298748]} icon={camera}><Popup><span className='title-content'>Pedro Taques - BRT - FIXA -  Zona Norte - Penha Circular</span><img src='http://187.111.99.18:9004/?CODE=002047' alt='Pedro Taques - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.841654,-43.299033]} icon={camera}><Popup><span className='title-content'>Pedro Taques - BRT - FIXA -  Zona Norte - Penha Circular</span><img src='http://187.111.99.18:9004/?CODE=002048' alt='Pedro Taques - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.849765,-43.307977]} icon={camera}><Popup><span className='title-content'>Vila Kosmos - Nossa Senhora do Carmo - BRT - FIXA -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=002049' alt='Vila Kosmos - Nossa Senhora do Carmo - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.849503,-43.307684]} icon={camera}><Popup><span className='title-content'>Vila Kosmos - Nossa Senhora do Carmo - BRT - FIXA -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=002050' alt='Vila Kosmos - Nossa Senhora do Carmo - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.850009,-43.308189]} icon={camera}><Popup><span className='title-content'>Vila Kosmos - Nossa Senhora do Carmo - BRT - FIXA -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=002051' alt='Vila Kosmos - Nossa Senhora do Carmo - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.852357,-43.312151]} icon={camera}><Popup><span className='title-content'>Vicente de Carvalho - BRT - FIXA -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=002052' alt='Vicente de Carvalho - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.852457,-43.312151]} icon={camera}><Popup><span className='title-content'>Vicente de Carvalho - BRT - FIXA -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=002053' alt='Vicente de Carvalho - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.852257,-43.312151]} icon={camera}><Popup><span className='title-content'>Vicente de Carvalho - BRT - FIXA -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=002054' alt='Vicente de Carvalho - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.852357,-43.312151]} icon={camera}><Popup><span className='title-content'>Vicente de Carvalho - BRT - FIXA -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=002055' alt='Vicente de Carvalho - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.852557,-43.312151]} icon={camera}><Popup><span className='title-content'>Vicente de Carvalho - BRT - FIXA -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=002056' alt='Vicente de Carvalho - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.852657,-43.312151]} icon={camera}><Popup><span className='title-content'>Vicente de Carvalho - BRT - FIXA -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=002057' alt='Vicente de Carvalho - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.85316210079740,-43.320542736280400]} icon={camera}><Popup><span className='title-content'>Marambaia - BRT - FIXA -  Zona Norte - Vaz Lobo</span><img src='http://187.111.99.18:9004/?CODE=002058' alt='Marambaia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.853046550216500,-43.32028150793450]} icon={camera}><Popup><span className='title-content'>Marambaia - BRT - FIXA -  Zona Norte - Vaz Lobo</span><img src='http://187.111.99.18:9004/?CODE=002059' alt='Marambaia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.85287051,-43.32007242]} icon={camera}><Popup><span className='title-content'>Marambaia - BRT - FIXA -  Zona Norte - Vaz Lobo</span><img src='http://187.111.99.18:9004/?CODE=002060' alt='Marambaia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.85646674311060,-43.32815793987270]} icon={camera}><Popup><span className='title-content'>Vaz Lobo - BRT - FIXA -  Zona Norte - Vaz Lobo</span><img src='http://187.111.99.18:9004/?CODE=002061' alt='Vaz Lobo - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.856586161776800,-43.32841881101030]} icon={camera}><Popup><span className='title-content'>Vaz Lobo - BRT - FIXA -  Zona Norte - Vaz Lobo</span><img src='http://187.111.99.18:9004/?CODE=002062' alt='Vaz Lobo - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.85645305,-43.3278757]} icon={camera}><Popup><span className='title-content'>Vaz Lobo - BRT - FIXA -  Zona Norte - Vaz Lobo</span><img src='http://187.111.99.18:9004/?CODE=002063' alt='Vaz Lobo - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93662697,-43.37175191]} icon={camera}><Popup><span className='title-content'>Santa Efigenia - BRT - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=002067' alt='Santa Efigenia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93639206,-43.37167409]} icon={camera}><Popup><span className='title-content'>Santa Efigenia - BRT - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=002068' alt='Santa Efigenia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93695341,-43.37187016]} icon={camera}><Popup><span className='title-content'>Santa Efigenia - BRT - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=002069' alt='Santa Efigenia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94043702,-43.37245835]} icon={camera}><Popup><span className='title-content'>Divina Providencia - BRT - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=002073' alt='Divina Providencia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94099835,-43.37257718]} icon={camera}><Popup><span className='title-content'>Divina Providencia - BRT - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=002074' alt='Divina Providencia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9406659,-43.37255207]} icon={camera}><Popup><span className='title-content'>Divina Providencia - BRT - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=002075' alt='Divina Providencia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93540177298810,-43.37173581674590]} icon={camera}><Popup><span className='title-content'>Merck - BRT - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=002076' alt='Merck - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.935240965964000,-43.37186184463100]} icon={camera}><Popup><span className='title-content'>Merck - BRT - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=002077' alt='Merck - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93499704646390,-43.3720149992868]} icon={camera}><Popup><span className='title-content'>Merck - BRT - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=002078' alt='Merck - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91495772,-43.36098833]} icon={camera}><Popup><span className='title-content'>Tanque - BRT - FIXA -  Zona Oeste - Tanque</span><img src='http://187.111.99.18:9004/?CODE=002082' alt='Tanque - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91621819,-43.36170727]} icon={camera}><Popup><span className='title-content'>Tanque - BRT - FIXA -  Zona Oeste - Tanque</span><img src='http://187.111.99.18:9004/?CODE=002083' alt='Tanque - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91604757,-43.36126164]} icon={camera}><Popup><span className='title-content'>Tanque - BRT - FIXA -  Zona Oeste - Tanque</span><img src='http://187.111.99.18:9004/?CODE=002084' alt='Tanque - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.87785946,-43.33643649]} icon={camera}><Popup><span className='title-content'>Madureira-Manaceia - BRT - FIXA -  Zona Norte - Madureira</span><img src='http://187.111.99.18:9004/?CODE=002088' alt='Madureira-Manaceia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8772777,-43.33607881]} icon={camera}><Popup><span className='title-content'>Madureira-Manaceia - BRT - FIXA -  Zona Norte - Madureira</span><img src='http://187.111.99.18:9004/?CODE=002089' alt='Madureira-Manaceia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.87736000,-43.33614306]} icon={camera}><Popup><span className='title-content'>Madureira-Manaceia - BRT - FIXA -  Zona Norte - Madureira</span><img src='http://187.111.99.18:9004/?CODE=002090' alt='Madureira-Manaceia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97328147,-43.38353337]} icon={camera}><Popup><span className='title-content'>Rio II - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002094' alt='Rio II - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97327615,-43.38280649]} icon={camera}><Popup><span className='title-content'>Rio II - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002095' alt='Rio II - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96796082,-43.39065165]} icon={camera}><Popup><span className='title-content'>Pedro Correia - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002100' alt='Pedro Correia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96739961,-43.39052093]} icon={camera}><Popup><span className='title-content'>Pedro Correia - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002101' alt='Pedro Correia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96773789,-43.3906047]} icon={camera}><Popup><span className='title-content'>Pedro Correia - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002102' alt='Pedro Correia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97340355,-43.37663464]} icon={camera}><Popup><span className='title-content'>Rede Sarah - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002103' alt='Rede Sarah - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97337407,-43.37634622]} icon={camera}><Popup><span className='title-content'>Rede Sarah - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002104' alt='Rede Sarah - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97338726,-43.37693089]} icon={camera}><Popup><span className='title-content'>Rede Sarah - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002105' alt='Rede Sarah - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97346954,-43.36564073]} icon={camera}><Popup><span className='title-content'>Centro Metropolitano - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002106' alt='Centro Metropolitano - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97341395,-43.36530881]} icon={camera}><Popup><span className='title-content'>Centro Metropolitano - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002107' alt='Centro Metropolitano - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97340953,-43.36593626]} icon={camera}><Popup><span className='title-content'>Centro Metropolitano - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002108' alt='Centro Metropolitano - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95983347,-43.38837513]} icon={camera}><Popup><span className='title-content'>Curicica - BRT - FIXA -  Zona Oeste - Vila Militar</span><img src='http://187.111.99.18:9004/?CODE=002109' alt='Curicica - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95992509,-43.38871839]} icon={camera}><Popup><span className='title-content'>Curicica - BRT - FIXA -  Zona Oeste - Vila Militar</span><img src='http://187.111.99.18:9004/?CODE=002110' alt='Curicica - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95996552,-43.38896455]} icon={camera}><Popup><span className='title-content'>Curicica - BRT - FIXA -  Zona Oeste - Vila Militar</span><img src='http://187.111.99.18:9004/?CODE=002111' alt='Curicica - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95873944,-43.3837118]} icon={camera}><Popup><span className='title-content'>Praca do Bandolim - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002112' alt='Praca do Bandolim - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95860952,-43.3833512]} icon={camera}><Popup><span className='title-content'>Praca do Bandolim - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002113' alt='Praca do Bandolim - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95859639,-43.38309386]} icon={camera}><Popup><span className='title-content'>Praca do Bandolim - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002114' alt='Praca do Bandolim - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95529134,-43.37732539]} icon={camera}><Popup><span className='title-content'>Arroio Pavuna - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002115' alt='Arroio Pavuna - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95512988,-43.37708085]} icon={camera}><Popup><span className='title-content'>Arroio Pavuna - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002116' alt='Arroio Pavuna - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95551506,-43.37757996]} icon={camera}><Popup><span className='title-content'>Arroio Pavuna - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002117' alt='Arroio Pavuna - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95233839,-43.37461184]} icon={camera}><Popup><span className='title-content'>Vila Sape - IV Centenario - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002118' alt='Vila Sape - IV Centenario - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95198238,-43.37435957]} icon={camera}><Popup><span className='title-content'>Vila Sape - IV Centenario - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002119' alt='Vila Sape - IV Centenario - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95249963,-43.3747636]} icon={camera}><Popup><span className='title-content'>Vila Sape - IV Centenario - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002120' alt='Vila Sape - IV Centenario - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94821246,-43.37238414]} icon={camera}><Popup><span className='title-content'>Recanto das Palmeiras - Jardim Sao Luiz - BRT - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=002121' alt='Recanto das Palmeiras - Jardim Sao Luiz - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94803135,-43.37229189]} icon={camera}><Popup><span className='title-content'>Recanto das Palmeiras - Jardim Sao Luiz - BRT - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=002122' alt='Recanto das Palmeiras - Jardim Sao Luiz - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94859265,-43.3724939]} icon={camera}><Popup><span className='title-content'>Recanto das Palmeiras - Jardim Sao Luiz - BRT - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=002123' alt='Recanto das Palmeiras - Jardim Sao Luiz - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92950909,-43.37340305]} icon={camera}><Popup><span className='title-content'>Andre Rocha - BRT - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=002124' alt='Andre Rocha - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.929740,-43.373328]} icon={camera}><Popup><span className='title-content'>Andre Rocha - BRT - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=002125' alt='Andre Rocha - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.929251,-43.373532]} icon={camera}><Popup><span className='title-content'>Andre Rocha - BRT - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=002126' alt='Andre Rocha - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92376045,-43.37428072]} icon={camera}><Popup><span className='title-content'>Taquara - BRT - FIXA -  Zona Oeste - Tanque</span><img src='http://187.111.99.18:9004/?CODE=002127' alt='Taquara - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92391855,-43.37426194]} icon={camera}><Popup><span className='title-content'>Taquara - BRT - FIXA -  Zona Oeste - Tanque</span><img src='http://187.111.99.18:9004/?CODE=002128' alt='Taquara - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92395,-43.37399]} icon={camera}><Popup><span className='title-content'>Taquara - BRT - FIXA -  Zona Oeste - Tanque</span><img src='http://187.111.99.18:9004/?CODE=002129' alt='Taquara - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92020054,-43.36821121]} icon={camera}><Popup><span className='title-content'>Aracy Cabral - BRT - FIXA -  Zona Oeste - Tanque</span><img src='http://187.111.99.18:9004/?CODE=002133' alt='Aracy Cabral - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.919997963467700,-43.36798054002470]} icon={camera}><Popup><span className='title-content'>Aracy Cabral - BRT - FIXA -  Zona Oeste - Tanque</span><img src='http://187.111.99.18:9004/?CODE=002134' alt='Aracy Cabral - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90449587,-43.35689819]} icon={camera}><Popup><span className='title-content'>Ipase - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002136' alt='Ipase - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9050023,-43.35715962]} icon={camera}><Popup><span className='title-content'>Ipase - BRT - FIXA -  Zona Oeste - Tanque</span><img src='http://187.111.99.18:9004/?CODE=002137' alt='Ipase - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9047268,-43.35704472]} icon={camera}><Popup><span className='title-content'>Ipase - BRT - FIXA -  Zona Oeste - Tanque</span><img src='http://187.111.99.18:9004/?CODE=002138' alt='Ipase - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89722951,-43.35178841]} icon={camera}><Popup><span className='title-content'>Praca Seca - BRT - FIXA -  Zona Oeste - Praca Seca</span><img src='http://187.111.99.18:9004/?CODE=002139' alt='Praca Seca - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89830847,-43.35267965]} icon={camera}><Popup><span className='title-content'>Praca Seca - BRT - FIXA -  Zona Oeste - Praca Seca</span><img src='http://187.111.99.18:9004/?CODE=002140' alt='Praca Seca - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89771793,-43.35224021]} icon={camera}><Popup><span className='title-content'>Praca Seca - BRT - FIXA -  Zona Oeste - Praca Seca</span><img src='http://187.111.99.18:9004/?CODE=002141' alt='Praca Seca - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89268233,-43.34844923]} icon={camera}><Popup><span className='title-content'>Capitao Menezes - BRT - FIXA -  Zona Oeste - Praca Seca</span><img src='http://187.111.99.18:9004/?CODE=002145' alt='Capitao Menezes - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89319981,-43.34875819]} icon={camera}><Popup><span className='title-content'>Capitao Menezes - BRT - FIXA -  Zona Oeste - Praca Seca</span><img src='http://187.111.99.18:9004/?CODE=002146' alt='Capitao Menezes - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89295582,-43.34859234]} icon={camera}><Popup><span className='title-content'>Capitao Menezes - BRT - FIXA -  Zona Oeste - Praca Seca</span><img src='http://187.111.99.18:9004/?CODE=002147' alt='Capitao Menezes - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88820104,-43.34575175]} icon={camera}><Popup><span className='title-content'>Pinto Teles - BRT - FIXA -  Zona Norte - Campinho</span><img src='http://187.111.99.18:9004/?CODE=002148' alt='Pinto Teles - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88872955,-43.34608448]} icon={camera}><Popup><span className='title-content'>Pinto Teles - BRT - FIXA -  Zona Oeste - Campinho</span><img src='http://187.111.99.18:9004/?CODE=002149' alt='Pinto Teles - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88846097,-43.34597015]} icon={camera}><Popup><span className='title-content'>Pinto Teles - BRT - FIXA -  Zona Oeste - Penha</span><img src='http://187.111.99.18:9004/?CODE=002150' alt='Pinto Teles - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88249078,-43.34188378]} icon={camera}><Popup><span className='title-content'>Campinho - BRT - FIXA -  Zona Norte - Campinho</span><img src='http://187.111.99.18:9004/?CODE=002151' alt='Campinho - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8819292,-43.3417911]} icon={camera}><Popup><span className='title-content'>Campinho - BRT - FIXA -  Zona Norte - Campinho</span><img src='http://187.111.99.18:9004/?CODE=002152' alt='Campinho - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88195638,-43.34193057]} icon={camera}><Popup><span className='title-content'>Campinho - BRT - FIXA -  Zona Norte - Campinho</span><img src='http://187.111.99.18:9004/?CODE=002153' alt='Campinho - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.84238043,-43.27282892]} icon={camera}><Popup><span className='title-content'>Ibiapina - BRT - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=002156' alt='Ibiapina - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8423759,-43.27246268]} icon={camera}><Popup><span className='title-content'>Ibiapina - BRT - FIXA -  Zona Norte - Olaria</span><img src='http://187.111.99.18:9004/?CODE=002157' alt='Ibiapina - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.84256548,-43.27224424]} icon={camera}><Popup><span className='title-content'>Ibiapina - BRT - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=002158' alt='Ibiapina - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.84880418,-43.26525872]} icon={camera}><Popup><span className='title-content'>Olaria - Cacique de Ramos - BRT - FIXA -  Zona Norte - Olaria</span><img src='http://187.111.99.18:9004/?CODE=002159' alt='Olaria - Cacique de Ramos - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.84841593,-43.26560581]} icon={camera}><Popup><span className='title-content'>Olaria - Cacique de Ramos - BRT - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=002160' alt='Olaria - Cacique de Ramos - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.84861779,-43.2654647]} icon={camera}><Popup><span className='title-content'>Olaria - Cacique de Ramos - BRT - FIXA -  Zona Norte - Penha</span><img src='http://187.111.99.18:9004/?CODE=002161' alt='Olaria - Cacique de Ramos - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98427211,-43.36567944]} icon={camera}><Popup><span className='title-content'>Via Parque - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002165' alt='Via Parque - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98367355,-43.36566043]} icon={camera}><Popup><span className='title-content'>Via Parque - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002166' alt='Via Parque - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98397341,-43.36564722]} icon={camera}><Popup><span className='title-content'>Via Parque - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002167' alt='Via Parque - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98934218,-43.36579346]} icon={camera}><Popup><span className='title-content'>Aeroporto de Jacarepagua - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002168' alt='Aeroporto de Jacarepagua - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98872603,-43.36579347]} icon={camera}><Popup><span className='title-content'>Aeroporto de Jacarepagua - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002169' alt='Aeroporto de Jacarepagua - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9890178,-43.36577965]} icon={camera}><Popup><span className='title-content'>Aeroporto de Jacarepagua - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002170' alt='Aeroporto de Jacarepagua - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99500177,-43.3658433]} icon={camera}><Popup><span className='title-content'>Lourenco Jorge - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002171' alt='Lourenco Jorge - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99465729,-43.36584562]} icon={camera}><Popup><span className='title-content'>Lourenco Jorge - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002172' alt='Lourenco Jorge - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99431524,-43.36584331]} icon={camera}><Popup><span className='title-content'>Lourenco Jorge - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002173' alt='Lourenco Jorge - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.81146072,-43.25074992]} icon={camera}><Popup><span className='title-content'>Galeão Tom Jobim 1 - BRT - FIXA -  Zona Norte - Galeao</span><img src='http://187.111.99.18:9004/?CODE=002174' alt='Galeão Tom Jobim 1 - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.81079571,-43.25201378]} icon={camera}><Popup><span className='title-content'>Galeão Tom Jobim 1 - BRT - FIXA -  Zona Norte - Galeao</span><img src='http://187.111.99.18:9004/?CODE=002175' alt='Galeão Tom Jobim 1 - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.81125714,-43.2514816]} icon={camera}><Popup><span className='title-content'>Galeão Tom Jobim 1 - BRT - FIXA -  Zona Norte - Galeao</span><img src='http://187.111.99.18:9004/?CODE=002176' alt='Galeão Tom Jobim 1 - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.81462743,-43.24586528]} icon={camera}><Popup><span className='title-content'>Galeão Tom Jobim 2 - BRT - FIXA -  Zona Norte - Galeao</span><img src='http://187.111.99.18:9004/?CODE=002177' alt='Galeão Tom Jobim 2 - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.81445227,-43.24640981]} icon={camera}><Popup><span className='title-content'>Galeão Tom Jobim 2 - BRT - FIXA -  Zona Norte - Galeao</span><img src='http://187.111.99.18:9004/?CODE=002178' alt='Galeão Tom Jobim 2 - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.81396243,-43.24685587]} icon={camera}><Popup><span className='title-content'>Galeão Tom Jobim 2 - BRT - FIXA -  Zona Norte - Galeao</span><img src='http://187.111.99.18:9004/?CODE=002179' alt='Galeão Tom Jobim 2 - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97325042,-43.39349294]} icon={camera}><Popup><span className='title-content'>Parque Olímpico - BRT - FIXA -  Zona Oeste - Cidade de Deus</span><img src='http://187.111.99.18:9004/?CODE=002181' alt='Parque Olímpico - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97325593,-43.39317208]} icon={camera}><Popup><span className='title-content'>Parque Olímpico - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002182' alt='Parque Olímpico - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97325592,-43.39378408]} icon={camera}><Popup><span className='title-content'>Parque Olímpico - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002183' alt='Parque Olímpico - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93338089,-43.66169345]} icon={camera}><Popup><span className='title-content'>Cesarao II - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002189' alt='Cesarao II - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.933539,-43.662207]} icon={camera}><Popup><span className='title-content'>Cesarao II - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002190' alt='Cesarao II - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.933434,-43.661933]} icon={camera}><Popup><span className='title-content'>Cesarao II - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002191' alt='Cesarao II - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.931727,-43.657266]} icon={camera}><Popup><span className='title-content'>Cesarao III - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002192' alt='Cesarao III - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.931620,-43.656978]} icon={camera}><Popup><span className='title-content'>Cesarao III - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002193' alt='Cesarao III - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.931866,-43.657472]} icon={camera}><Popup><span className='title-content'>Cesarao III - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002194' alt='Cesarao III - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.928380,-43.653787]} icon={camera}><Popup><span className='title-content'>Vila Paciencia - BRT - FIXA -  Zona Oeste - Paciencia</span><img src='http://187.111.99.18:9004/?CODE=002195' alt='Vila Paciencia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.928256,-43.653555]} icon={camera}><Popup><span className='title-content'>Vila Paciencia - BRT - FIXA -  Zona Oeste - Paciencia</span><img src='http://187.111.99.18:9004/?CODE=002196' alt='Vila Paciencia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.928573,-43.654012]} icon={camera}><Popup><span className='title-content'>Vila Paciencia - BRT - FIXA -  Zona Oeste - Paciencia</span><img src='http://187.111.99.18:9004/?CODE=002197' alt='Vila Paciencia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.925685,-43.650038]} icon={camera}><Popup><span className='title-content'>Tres Pontes - BRT - FIXA -  Zona Oeste - Paciencia</span><img src='http://187.111.99.18:9004/?CODE=002198' alt='Tres Pontes - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.925432,-43.649952]} icon={camera}><Popup><span className='title-content'>Tres Pontes - BRT - FIXA -  Zona Oeste - Paciencia</span><img src='http://187.111.99.18:9004/?CODE=002199' alt='Tres Pontes - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.925227,-43.649772]} icon={camera}><Popup><span className='title-content'>Tres Pontes - BRT - FIXA -  Zona Oeste - Paciencia</span><img src='http://187.111.99.18:9004/?CODE=002200' alt='Tres Pontes - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.920447,-43.643516]} icon={camera}><Popup><span className='title-content'>Cesarinho - BRT - FIXA -  Zona Oeste - Paciencia</span><img src='http://187.111.99.18:9004/?CODE=002201' alt='Cesarinho - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.920366,-43.643231]} icon={camera}><Popup><span className='title-content'>Cesarinho - BRT - FIXA -  Zona Oeste - Paciencia</span><img src='http://187.111.99.18:9004/?CODE=002202' alt='Cesarinho - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.920630,-43.643801]} icon={camera}><Popup><span className='title-content'>Cesarinho - BRT - FIXA -  Zona Oeste - Paciencia</span><img src='http://187.111.99.18:9004/?CODE=002203' alt='Cesarinho - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.918129,-43.638782]} icon={camera}><Popup><span className='title-content'>31 de Outubro - BRT - FIXA -  Zona Oeste - Paciencia</span><img src='http://187.111.99.18:9004/?CODE=002204' alt='31 de Outubro - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.918411,-43.639257]} icon={camera}><Popup><span className='title-content'>31 de Outubro - BRT - FIXA -  Zona Oeste - Paciencia</span><img src='http://187.111.99.18:9004/?CODE=002205' alt='31 de Outubro - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.918224,-43.639028]} icon={camera}><Popup><span className='title-content'>31 de Outubro - BRT - FIXA -  Zona Oeste - Paciencia</span><img src='http://187.111.99.18:9004/?CODE=002206' alt='31 de Outubro - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.916755,-43.632450]} icon={camera}><Popup><span className='title-content'>Santa Eugenia - BRT - FIXA -  Zona Oeste - Paciencia</span><img src='http://187.111.99.18:9004/?CODE=002207' alt='Santa Eugenia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.916878,-43.633078]} icon={camera}><Popup><span className='title-content'>Santa Eugenia - BRT - FIXA -  Zona Oeste - Paciencia</span><img src='http://187.111.99.18:9004/?CODE=002208' alt='Santa Eugenia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.916796,-43.632772]} icon={camera}><Popup><span className='title-content'>Santa Eugenia - BRT - FIXA -  Zona Oeste - Paciencia</span><img src='http://187.111.99.18:9004/?CODE=002209' alt='Santa Eugenia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915645,-43.627563]} icon={camera}><Popup><span className='title-content'>Julia Miguel - BRT - FIXA -  Zona Oeste - Cosmos</span><img src='http://187.111.99.18:9004/?CODE=002210' alt='Julia Miguel - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915786,-43.628286]} icon={camera}><Popup><span className='title-content'>Julia Miguel - BRT - FIXA -  Zona Oeste - Cosmos</span><img src='http://187.111.99.18:9004/?CODE=002211' alt='Julia Miguel - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915622,-43.627952]} icon={camera}><Popup><span className='title-content'>Julia Miguel - BRT - FIXA -  Zona Oeste - Cosmos</span><img src='http://187.111.99.18:9004/?CODE=002212' alt='Julia Miguel - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.916265,-43.622360]} icon={camera}><Popup><span className='title-content'>Parque Sao Paulo - BRT - FIXA -  Zona Oeste - Inhoaiba</span><img src='http://187.111.99.18:9004/?CODE=002213' alt='Parque Sao Paulo - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.916332,-43.622011]} icon={camera}><Popup><span className='title-content'>Parque Sao Paulo - BRT - FIXA -  Zona Oeste - Cosmos</span><img src='http://187.111.99.18:9004/?CODE=002214' alt='Parque Sao Paulo - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.916227,-43.622696]} icon={camera}><Popup><span className='title-content'>Parque Sao Paulo - BRT - FIXA -  Zona Oeste - Cosmos</span><img src='http://187.111.99.18:9004/?CODE=002215' alt='Parque Sao Paulo - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915123,-43.605826]} icon={camera}><Popup><span className='title-content'>Icurana - BRT - FIXA -  Zona Oeste - Inhoaiba</span><img src='http://187.111.99.18:9004/?CODE=002216' alt='Icurana - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915082,-43.605526]} icon={camera}><Popup><span className='title-content'>Icurana - BRT - FIXA -  Zona Oeste - Inhoaiba</span><img src='http://187.111.99.18:9004/?CODE=002217' alt='Icurana - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915293,-43.606135]} icon={camera}><Popup><span className='title-content'>Icurana - BRT - FIXA -  Zona Oeste - Inhoaiba</span><img src='http://187.111.99.18:9004/?CODE=002218' alt='Icurana - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914197,-43.601278]} icon={camera}><Popup><span className='title-content'>Vilar Carioca - BRT - FIXA -  Zona Oeste - Inhoaiba</span><img src='http://187.111.99.18:9004/?CODE=002219' alt='Vilar Carioca - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914219,-43.600925]} icon={camera}><Popup><span className='title-content'>Vilar Carioca - BRT - FIXA -  Zona Oeste - Inhoaiba</span><img src='http://187.111.99.18:9004/?CODE=002220' alt='Vilar Carioca - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914219,-43.601666]} icon={camera}><Popup><span className='title-content'>Vilar Carioca - BRT - FIXA -  Zona Oeste - Inhoaiba</span><img src='http://187.111.99.18:9004/?CODE=002221' alt='Vilar Carioca - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913315,-43.595605]} icon={camera}><Popup><span className='title-content'>Inhoaiba - BRT - FIXA -  Zona Oeste - Inhoaiba</span><img src='http://187.111.99.18:9004/?CODE=002222' alt='Inhoaiba - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913215,-43.595354]} icon={camera}><Popup><span className='title-content'>Inhoaiba - BRT - FIXA -  Zona Oeste - Inhoaiba</span><img src='http://187.111.99.18:9004/?CODE=002223' alt='Inhoaiba - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913497,-43.595962]} icon={camera}><Popup><span className='title-content'>Inhoaiba - BRT - FIXA -  Zona Oeste - Inhoaiba</span><img src='http://187.111.99.18:9004/?CODE=002224' alt='Inhoaiba - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00002808138600,-43.41219849597160]} icon={camera}><Popup><span className='title-content'>Golfe Olimpico - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002225' alt='Golfe Olimpico - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00005924811870,-43.41190637301630]} icon={camera}><Popup><span className='title-content'>Golfe Olimpico - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002226' alt='Golfe Olimpico - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.000023248113500,-43.412497066305104]} icon={camera}><Popup><span className='title-content'>Golfe Olimpico - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002227' alt='Golfe Olimpico - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911277,-43.589421]} icon={camera}><Popup><span className='title-content'>Ana Gonzaga - BRT - FIXA -  Zona Oeste - Inhoaiba</span><img src='http://187.111.99.18:9004/?CODE=002228' alt='Ana Gonzaga - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.911436,-43.590106]} icon={camera}><Popup><span className='title-content'>Ana Gonzaga - BRT - FIXA -  Zona Oeste - Inhoaiba</span><img src='http://187.111.99.18:9004/?CODE=002229' alt='Ana Gonzaga - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91078418,-43.58386923]} icon={camera}><Popup><span className='title-content'>Sao Jorge - BRT - FIXA -  Zona Oeste - Inhoaiba</span><img src='http://187.111.99.18:9004/?CODE=002231' alt='Sao Jorge - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91097794,-43.58449669]} icon={camera}><Popup><span className='title-content'>Sao Jorge - BRT - FIXA -  Zona Oeste - Inhoaiba</span><img src='http://187.111.99.18:9004/?CODE=002232' alt='Sao Jorge - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91085092,-43.58416815]} icon={camera}><Popup><span className='title-content'>Sao Jorge - BRT - FIXA -  Zona Oeste - Inhoaiba</span><img src='http://187.111.99.18:9004/?CODE=002233' alt='Sao Jorge - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90750444,-43.57576085]} icon={camera}><Popup><span className='title-content'>Pina Rangel - BRT - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=002234' alt='Pina Rangel - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90749032,-43.57544603]} icon={camera}><Popup><span className='title-content'>Pina Rangel - BRT - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=002235' alt='Pina Rangel - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90766646,-43.57611152]} icon={camera}><Popup><span className='title-content'>Pina Rangel - BRT - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=002236' alt='Pina Rangel - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90704999,-43.57126295]} icon={camera}><Popup><span className='title-content'>Parque Esperanca - BRT - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=002237' alt='Parque Esperanca - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90682098,-43.57206154]} icon={camera}><Popup><span className='title-content'>Parque Esperanca - BRT - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=002238' alt='Parque Esperanca - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90690245,-43.57163307]} icon={camera}><Popup><span className='title-content'>Parque Esperanca - BRT - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=002239' alt='Parque Esperanca - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.907631,-43.56397519]} icon={camera}><Popup><span className='title-content'>Candido Magalhaes - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002240' alt='Candido Magalhaes - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90370172,-43.56661271]} icon={camera}><Popup><span className='title-content'>Prefeito Alim Pedro - BRT - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=002243' alt='Prefeito Alim Pedro - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94039003,-43.66538212]} icon={camera}><Popup><span className='title-content'>Santa Verediana - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002246' alt='Santa Verediana - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94063659,-43.66488775]} icon={camera}><Popup><span className='title-content'>Santa Verediana - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002247' alt='Santa Verediana - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94049123,-43.66518157]} icon={camera}><Popup><span className='title-content'>Santa Verediana - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002248' alt='Santa Verediana - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92777928,-43.67731911]} icon={camera}><Popup><span className='title-content'>Gastao Rangel - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002249' alt='Gastao Rangel - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.927563,-43.677554]} icon={camera}><Popup><span className='title-content'>Gastao Rangel - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002250' alt='Gastao Rangel - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.927393,-43.677762]} icon={camera}><Popup><span className='title-content'>Gastao Rangel - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002251' alt='Gastao Rangel - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00001993,-43.35246438]} icon={camera}><Popup><span className='title-content'>Parque das Rosas - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002253' alt='Parque das Rosas - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.000094,-43.352628]} icon={camera}><Popup><span className='title-content'>Parque das Rosas - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002254' alt='Parque das Rosas - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.000125,-43.352172]} icon={camera}><Popup><span className='title-content'>Parque das Rosas - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002255' alt='Parque das Rosas - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.934581,-43.665326]} icon={camera}><Popup><span className='title-content'>Cesarão I - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002256' alt='Cesarão I - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.934437,-43.665154]} icon={camera}><Popup><span className='title-content'>Cesarão I - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002257' alt='Cesarão I - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.934843,-43.665477]} icon={camera}><Popup><span className='title-content'>Cesarão I - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002258' alt='Cesarão I - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915786,-43.615699]} icon={camera}><Popup><span className='title-content'>Cosmos - BRT - FIXA -  Zona Oeste - Cosmos</span><img src='http://187.111.99.18:9004/?CODE=002259' alt='Cosmos - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915669,-43.616059]} icon={camera}><Popup><span className='title-content'>Cosmos - BRT - FIXA -  Zona Oeste - Cosmos</span><img src='http://187.111.99.18:9004/?CODE=002260' alt='Cosmos - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915575,-43.616402]} icon={camera}><Popup><span className='title-content'>Cosmos - BRT - FIXA -  Zona Oeste - Cosmos</span><img src='http://187.111.99.18:9004/?CODE=002261' alt='Cosmos - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.021024,-43.496661]} icon={camera}><Popup><span className='title-content'>Recanto das Garças - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002262' alt='Recanto das Garças - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.021028,-43.496898]} icon={camera}><Popup><span className='title-content'>Recanto das Garças - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002263' alt='Recanto das Garças - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.021074,-43.496270]} icon={camera}><Popup><span className='title-content'>Recanto das Garças - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002264' alt='Recanto das Garças - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.018348,-43.508670]} icon={camera}><Popup><span className='title-content'>Dom Bosco - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002265' alt='Dom Bosco - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.018183,-43.509290]} icon={camera}><Popup><span className='title-content'>Dom Bosco - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002266' alt='Dom Bosco - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.018242,-43.508985]} icon={camera}><Popup><span className='title-content'>Dom Bosco - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002267' alt='Dom Bosco - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.005389,-43.557927]} icon={camera}><Popup><span className='title-content'>CTEx - BRT - FIXA -  Zona Oeste - Guaratiba</span><img src='http://187.111.99.18:9004/?CODE=002268' alt='CTEx - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.005191,-43.558726]} icon={camera}><Popup><span className='title-content'>CTEx - BRT - FIXA -  Zona Oeste - Guaratiba</span><img src='http://187.111.99.18:9004/?CODE=002269' alt='CTEx - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.005241,-43.558446]} icon={camera}><Popup><span className='title-content'>CTEx - BRT - FIXA -  Zona Oeste - Guaratiba</span><img src='http://187.111.99.18:9004/?CODE=002270' alt='CTEx - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99962805425660,-43.581978288360496]} icon={camera}><Popup><span className='title-content'>Embrapa - BRT - FIXA -  Zona Oeste - Guaratiba</span><img src='http://187.111.99.18:9004/?CODE=002271' alt='Embrapa - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.999500620098000,-43.58251600859830]} icon={camera}><Popup><span className='title-content'>Embrapa - BRT - FIXA -  Zona Oeste - Guaratiba</span><img src='http://187.111.99.18:9004/?CODE=002272' alt='Embrapa - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.999605552368100,-43.58223839996520]} icon={camera}><Popup><span className='title-content'>Embrapa - BRT - FIXA -  Zona Oeste - Guaratiba</span><img src='http://187.111.99.18:9004/?CODE=002273' alt='Embrapa - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01573476,-43.47177814]} icon={camera}><Popup><span className='title-content'>Nova Barra - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002277' alt='Nova Barra - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01556316,-43.4711079]} icon={camera}><Popup><span className='title-content'>Nova Barra - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002278' alt='Nova Barra - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01560567,-43.47145136]} icon={camera}><Popup><span className='title-content'>Nova Barra - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002279' alt='Nova Barra - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95087538,-43.65080841]} icon={camera}><Popup><span className='title-content'>Vendas de Varanda - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002280' alt='Vendas de Varanda - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95112556,-43.65057787]} icon={camera}><Popup><span className='title-content'>Vendas de Varanda - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002281' alt='Vendas de Varanda - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95072935,-43.65096291]} icon={camera}><Popup><span className='title-content'>Vendas de Varanda - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002282' alt='Vendas de Varanda - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93654645,-43.66693949]} icon={camera}><Popup><span className='title-content'>Curral Falso - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002283' alt='Curral Falso - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93630431,-43.66690327]} icon={camera}><Popup><span className='title-content'>Curral Falso - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002284' alt='Curral Falso - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93683264,-43.66692228]} icon={camera}><Popup><span className='title-content'>Curral Falso - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002285' alt='Curral Falso - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93085451,-43.67348761]} icon={camera}><Popup><span className='title-content'>Cajueiros - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002286' alt='Cajueiros - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93107124,-43.67322118]} icon={camera}><Popup><span className='title-content'>Cajueiros - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002287' alt='Cajueiros - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93066122,-43.6737271]} icon={camera}><Popup><span className='title-content'>Cajueiros - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002288' alt='Cajueiros - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00386122,-43.32272939]} icon={camera}><Popup><span className='title-content'>Bosque Marapendi - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002291' alt='Bosque Marapendi - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00361156,-43.32327725]} icon={camera}><Popup><span className='title-content'>Bosque Marapendi - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002292' alt='Bosque Marapendi - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00324512,-43.32421251]} icon={camera}><Popup><span className='title-content'>Bosque Marapendi - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002293' alt='Bosque Marapendi - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00141443,-43.32881397]} icon={camera}><Popup><span className='title-content'>Paulo Malta Rezende - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002297' alt='Paulo Malta Rezende - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00118559,-43.3293844]} icon={camera}><Popup><span className='title-content'>Paulo Malta Rezende - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002298' alt='Paulo Malta Rezende - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00130523,-43.32916194]} icon={camera}><Popup><span className='title-content'>Paulo Malta Rezende - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002299' alt='Paulo Malta Rezende - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00053706,-43.33567440]} icon={camera}><Popup><span className='title-content'>Afrânio Costa - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002300' alt='Afrânio Costa - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00055929,-43.33552018]} icon={camera}><Popup><span className='title-content'>Afrânio Costa - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002301' alt='Afrânio Costa - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00062225,-43.33478441]} icon={camera}><Popup><span className='title-content'>Afrânio Costa - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002302' alt='Afrânio Costa - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00027454,-43.34192265]} icon={camera}><Popup><span className='title-content'>Riviera - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002303' alt='Riviera - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00027002,-43.34231383]} icon={camera}><Popup><span className='title-content'>Riviera - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002304' alt='Riviera - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00028764,-43.34162933]} icon={camera}><Popup><span className='title-content'>Riviera - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002305' alt='Riviera - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00017993,-43.34645197]} icon={camera}><Popup><span className='title-content'>Ricardo Marinho - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002306' alt='Ricardo Marinho - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00023031,-43.34681056]} icon={camera}><Popup><span className='title-content'>Ricardo Marinho - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002307' alt='Ricardo Marinho - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00021272,-43.34622113]} icon={camera}><Popup><span className='title-content'>Ricardo Marinho - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002308' alt='Ricardo Marinho - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00001872,-43.36167924]} icon={camera}><Popup><span className='title-content'>Barra Shopping - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002309' alt='Barra Shopping - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00000117,-43.35935958]} icon={camera}><Popup><span className='title-content'>Barra Shopping - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002310' alt='Barra Shopping - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99999251,-43.36067059]} icon={camera}><Popup><span className='title-content'>Barra Shopping - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002311' alt='Barra Shopping - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00035279,-43.37776479]} icon={camera}><Popup><span className='title-content'>Bosque da Barra - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002315' alt='Bosque da Barra - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00035281,-43.37709932]} icon={camera}><Popup><span className='title-content'>Bosque da Barra - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002316' alt='Bosque da Barra - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00031967,-43.3774403]} icon={camera}><Popup><span className='title-content'>Bosque da Barra - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002317' alt='Bosque da Barra - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00044947,-43.38356396]} icon={camera}><Popup><span className='title-content'>Novo Leblon - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002318' alt='Novo Leblon - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00044949,-43.38285095]} icon={camera}><Popup><span className='title-content'>Novo Leblon - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002319' alt='Novo Leblon - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00041755,-43.38318928]} icon={camera}><Popup><span className='title-content'>Novo Leblon - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002320' alt='Novo Leblon - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00044932,-43.39006663]} icon={camera}><Popup><span className='title-content'>Américas Park - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002321' alt='Américas Park - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00047574,-43.38943918]} icon={camera}><Popup><span className='title-content'>Américas Park - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002322' alt='Américas Park - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00042668,-43.38978219]} icon={camera}><Popup><span className='title-content'>Américas Park - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002323' alt='Américas Park - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00022468,-43.39882242]} icon={camera}><Popup><span className='title-content'>Santa Monica Jardins - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002324' alt='Santa Monica Jardins - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00023789,-43.39813793]} icon={camera}><Popup><span className='title-content'>Santa Monica Jardins - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002325' alt='Santa Monica Jardins - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00022252,-43.39848265]} icon={camera}><Popup><span className='title-content'>Santa Monica Jardins - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002326' alt='Santa Monica Jardins - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99994751,-43.40689294]} icon={camera}><Popup><span className='title-content'>Rio Mar - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002327' alt='Rio Mar - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99997364,-43.40723597]} icon={camera}><Popup><span className='title-content'>Rio Mar - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002328' alt='Rio Mar - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00000006,-43.40656574]} icon={camera}><Popup><span className='title-content'>Rio Mar - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002329' alt='Rio Mar - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00101635,-43.41776003]} icon={camera}><Popup><span className='title-content'>Interlagos - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002330' alt='Interlagos - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00085794,-43.41711832]} icon={camera}><Popup><span className='title-content'>Interlagos - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002331' alt='Interlagos - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00088045,-43.41746037]} icon={camera}><Popup><span className='title-content'>Interlagos - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002332' alt='Interlagos - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00306252,-43.4243055]} icon={camera}><Popup><span className='title-content'>Pedra de Itauna - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002333' alt='Pedra de Itauna - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.0028381,-43.42372083]} icon={camera}><Popup><span className='title-content'>Pedra de Itauna - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002334' alt='Pedra de Itauna - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00291775,-43.42403134]} icon={camera}><Popup><span className='title-content'>Pedra de Itauna - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002335' alt='Pedra de Itauna - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00549625,-43.43193858]} icon={camera}><Popup><span className='title-content'>Pontões/Barrasul - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002336' alt='Pontões/Barrasul - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00545188,-43.4316353]} icon={camera}><Popup><span className='title-content'>Pontões/Barrasul - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002337' alt='Pontões/Barrasul - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00561029,-43.43223424]} icon={camera}><Popup><span className='title-content'>Pontões/Barrasul - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002338' alt='Pontões/Barrasul - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00794676285780,-43.44155487249140]} icon={camera}><Popup><span className='title-content'>Salvador Allende - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002339' alt='Salvador Allende - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00843517,-43.4433858]} icon={camera}><Popup><span className='title-content'>Salvador Allende - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002340' alt='Salvador Allende - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.0082844,-43.4426875]} icon={camera}><Popup><span className='title-content'>Salvador Allende - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002341' alt='Salvador Allende - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.008165774909500,-43.44238964909850]} icon={camera}><Popup><span className='title-content'>Salvador Allende - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002342' alt='Salvador Allende - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00809197,-43.44197403]} icon={camera}><Popup><span className='title-content'>Salvador Allende - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002343' alt='Salvador Allende - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00978007,-43.44864289]} icon={camera}><Popup><span className='title-content'>Gelson Fonseca - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002345' alt='Gelson Fonseca - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.0099136,-43.44893307]} icon={camera}><Popup><span className='title-content'>Gelson Fonseca - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002346' alt='Gelson Fonseca - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.0097288,-43.44829135]} icon={camera}><Popup><span className='title-content'>Gelson Fonseca - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002347' alt='Gelson Fonseca - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01090361,-43.45288318]} icon={camera}><Popup><span className='title-content'>Guignard - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002348' alt='Guignard - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01077161,-43.45225572]} icon={camera}><Popup><span className='title-content'>Guignard - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002349' alt='Guignard - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01077987,-43.45265355]} icon={camera}><Popup><span className='title-content'>Guignard - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002350' alt='Guignard - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.0143766,-43.46667524]} icon={camera}><Popup><span className='title-content'>Benvindo de Novaes - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002357' alt='Benvindo de Novaes - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01452039,-43.4669724]} icon={camera}><Popup><span className='title-content'>Benvindo de Novaes - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002358' alt='Benvindo de Novaes - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01705473,-43.47706168]} icon={camera}><Popup><span className='title-content'>Gilka Machado - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002360' alt='Gilka Machado - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01696232,-43.4766837]} icon={camera}><Popup><span className='title-content'>Gilka Machado - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002361' alt='Gilka Machado - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01716032,-43.47732542]} icon={camera}><Popup><span className='title-content'>Gilka Machado - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002362' alt='Gilka Machado - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01843611,-43.48259779]} icon={camera}><Popup><span className='title-content'>Guiomar Novais - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002363' alt='Guiomar Novais - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01857266,-43.48288696]} icon={camera}><Popup><span className='title-content'>Guiomar Novais - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002364' alt='Guiomar Novais - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01842747,-43.48225951]} icon={camera}><Popup><span className='title-content'>Guiomar Novais - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002365' alt='Guiomar Novais - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01964124,-43.48727521]} icon={camera}><Popup><span className='title-content'>Recreio Shopping - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002366' alt='Recreio Shopping - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01948341,-43.48649484]} icon={camera}><Popup><span className='title-content'>Recreio Shopping - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002367' alt='Recreio Shopping - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01986619,-43.48797792]} icon={camera}><Popup><span className='title-content'>Recreio Shopping - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002368' alt='Recreio Shopping - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.02061049,-43.5002661]} icon={camera}><Popup><span className='title-content'>Notre Dame - BRT - FIXA -  Zona Oeste - Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=002372' alt='Notre Dame - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.02072396,-43.49982825]} icon={camera}><Popup><span className='title-content'>Notre Dame - BRT - FIXA -  Zona Oeste - Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=002373' alt='Notre Dame - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.02057875,-43.5004557]} icon={camera}><Popup><span className='title-content'>Notre Dame - BRT - FIXA -  Zona Oeste - Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=002374' alt='Notre Dame - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01638744,-43.51568579]} icon={camera}><Popup><span className='title-content'>Pontal - BRT - FIXA -  Zona Oeste - Ilha de Guaratiba</span><img src='http://187.111.99.18:9004/?CODE=002375' alt='Pontal - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01623563,-43.51628473]} icon={camera}><Popup><span className='title-content'>Pontal - BRT - FIXA -  Zona Oeste - Ilha de Guaratiba</span><img src='http://187.111.99.18:9004/?CODE=002376' alt='Pontal - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01628452,-43.51601685]} icon={camera}><Popup><span className='title-content'>Pontal - BRT - FIXA -  Zona Oeste - Ilha de Guaratiba</span><img src='http://187.111.99.18:9004/?CODE=002377' alt='Pontal - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00927046,-43.54013044]} icon={camera}><Popup><span className='title-content'>Ilha de Guaratiba - BRT - FIXA -  Zona Oeste - Ilha de Guaratiba</span><img src='http://187.111.99.18:9004/?CODE=002378' alt='Ilha de Guaratiba - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.0094308,-43.53987271]} icon={camera}><Popup><span className='title-content'>Ilha de Guaratiba - BRT - FIXA -  Zona Oeste - Ilha de Guaratiba</span><img src='http://187.111.99.18:9004/?CODE=002379' alt='Ilha de Guaratiba - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.0096797,-43.53956003]} icon={camera}><Popup><span className='title-content'>Ilha de Guaratiba - BRT - FIXA -  Zona Oeste - Ilha de Guaratiba</span><img src='http://187.111.99.18:9004/?CODE=002380' alt='Ilha de Guaratiba - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99435165,-43.59617389]} icon={camera}><Popup><span className='title-content'>Mato Alto - BRT - FIXA -  Zona Oeste - Guaratiba</span><img src='http://187.111.99.18:9004/?CODE=002381' alt='Mato Alto - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99341425,-43.59728621]} icon={camera}><Popup><span className='title-content'>Mato Alto - BRT - FIXA -  Zona Oeste - Guaratiba</span><img src='http://187.111.99.18:9004/?CODE=002382' alt='Mato Alto - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99384162,-43.59674405]} icon={camera}><Popup><span className='title-content'>Mato Alto - BRT - FIXA -  Zona Oeste - Guaratiba</span><img src='http://187.111.99.18:9004/?CODE=002383' alt='Mato Alto - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96863034,-43.62168602]} icon={camera}><Popup><span className='title-content'>Magarça - BRT - FIXA -  Zona Oeste - Guaratiba</span><img src='http://187.111.99.18:9004/?CODE=002387' alt='Magarça - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96876238,-43.622342]} icon={camera}><Popup><span className='title-content'>Magarça - BRT - FIXA -  Zona Oeste - Guaratiba</span><img src='http://187.111.99.18:9004/?CODE=002388' alt='Magarça - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96864525,-43.62203359]} icon={camera}><Popup><span className='title-content'>Magarça - BRT - FIXA -  Zona Oeste - Guaratiba</span><img src='http://187.111.99.18:9004/?CODE=002389' alt='Magarça - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96515052,-43.64701979]} icon={camera}><Popup><span className='title-content'>Pingo Dagua - BRT - FIXA -  Zona Oeste - General Olimpio</span><img src='http://187.111.99.18:9004/?CODE=002391' alt='Pingo Dagua - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96467512,-43.6473977]} icon={camera}><Popup><span className='title-content'>Pingo Dagua - BRT - FIXA -  Zona Oeste - General Olimpio</span><img src='http://187.111.99.18:9004/?CODE=002392' alt='Pingo Dagua - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96485755,-43.64730071]} icon={camera}><Popup><span className='title-content'>Pingo Dagua - BRT - FIXA -  Zona Oeste - General Olimpio</span><img src='http://187.111.99.18:9004/?CODE=002393' alt='Pingo Dagua - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9222396,-43.67964339]} icon={camera}><Popup><span className='title-content'>General Olimpo - BRT -  FIXA -  Zona Oeste - General Olimpio</span><img src='http://187.111.99.18:9004/?CODE=002394' alt='General Olimpo - BRT -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92195666,-43.67970959]} icon={camera}><Popup><span className='title-content'>General Olimpo - BRT - FIXA -  Zona Oeste - General Olimpio</span><img src='http://187.111.99.18:9004/?CODE=002395' alt='General Olimpo - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92255416,-43.67953252]} icon={camera}><Popup><span className='title-content'>General Olimpo - BRT -  FIXA -  Zona Oeste - General Olimpio</span><img src='http://187.111.99.18:9004/?CODE=002396' alt='General Olimpo - BRT -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00387406,-43.43406238]} icon={camera}><Popup><span className='title-content'>Catedral do Recreio - BRT - FIXA -  Zona Oeste - Tapebuias</span><img src='http://187.111.99.18:9004/?CODE=002400' alt='Catedral do Recreio - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00362998,-43.4337458]} icon={camera}><Popup><span className='title-content'>Catedral do Recreio - BRT - FIXA -  Zona Oeste - Tapebuias</span><img src='http://187.111.99.18:9004/?CODE=002401' alt='Catedral do Recreio - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00403923,-43.43417361]} icon={camera}><Popup><span className='title-content'>Catedral do Recreio - BRT - FIXA -  Zona Oeste - Tapebuias</span><img src='http://187.111.99.18:9004/?CODE=002402' alt='Catedral do Recreio - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00016448,-43.42971181]} icon={camera}><Popup><span className='title-content'>Tapebuias - BRT -  FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002403' alt='Tapebuias - BRT -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99995991,-43.42949621]} icon={camera}><Popup><span className='title-content'>Tapebuias - BRT -  FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002404' alt='Tapebuias - BRT -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00039557,-43.42995253]} icon={camera}><Popup><span className='title-content'>Tapebuias - BRT -  FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002405' alt='Tapebuias - BRT -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98935172,-43.41732743]} icon={camera}><Popup><span className='title-content'>Ilha Pura - BRT - FIXA -  Zona Oeste - Camorim</span><img src='http://187.111.99.18:9004/?CODE=002406' alt='Ilha Pura - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98981433,-43.41792998]} icon={camera}><Popup><span className='title-content'>Ilha Pura - BRT - FIXA -  Zona Oeste - Camorim</span><img src='http://187.111.99.18:9004/?CODE=002407' alt='Ilha Pura - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98957907,-43.41763105]} icon={camera}><Popup><span className='title-content'>Ilha Pura - BRT - FIXA -  Zona Oeste - Camorim</span><img src='http://187.111.99.18:9004/?CODE=002408' alt='Ilha Pura - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98180178,-43.41019139]} icon={camera}><Popup><span className='title-content'>Olof Palme - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002409' alt='Olof Palme - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98147953,-43.40993679]} icon={camera}><Popup><span className='title-content'>Olof Palme - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002410' alt='Olof Palme - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98217191,-43.41045032]} icon={camera}><Popup><span className='title-content'>Olof Palme - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002411' alt='Olof Palme - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97669954,-43.40618889]} icon={camera}><Popup><span className='title-content'>Riocentro - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002412' alt='Riocentro - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97726681,-43.40664837]} icon={camera}><Popup><span className='title-content'>Riocentro - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002413' alt='Riocentro - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97694082,-43.40640604]} icon={camera}><Popup><span className='title-content'>Riocentro - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002414' alt='Riocentro - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97173719,-43.40157374]} icon={camera}><Popup><span className='title-content'>Morro do Outeiro - BRT - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=002415' alt='Morro do Outeiro - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97266153,-43.40124693]} icon={camera}><Popup><span className='title-content'>Morro do Outeiro - BRT - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=002416' alt='Morro do Outeiro - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97267256,-43.40022495]} icon={camera}><Popup><span className='title-content'>Morro do Outeiro - BRT - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=002417' alt='Morro do Outeiro - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96653011,-43.39678355]} icon={camera}><Popup><span className='title-content'>Minha Praia - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002420' alt='Minha Praia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9670253,-43.39723512]} icon={camera}><Popup><span className='title-content'>Minha Praia - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002421' alt='Minha Praia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96669204,-43.39690042]} icon={camera}><Popup><span className='title-content'>Minha Praia - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002422' alt='Minha Praia - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96310579,-43.39363021]} icon={camera}><Popup><span className='title-content'>Asa Branca - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002423' alt='Asa Branca - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96306548,-43.39356192]} icon={camera}><Popup><span className='title-content'>Asa Branca - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002424' alt='Asa Branca - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9634997,-43.39397693]} icon={camera}><Popup><span className='title-content'>Asa Branca - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002425' alt='Asa Branca - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8681799,-43.41306149]} icon={camera}><Popup><span className='title-content'>Magalhães Bastos - BRT - FIXA -  Zona Oeste - Deodoro</span><img src='http://187.111.99.18:9004/?CODE=002426' alt='Magalhães Bastos - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86803019,-43.41279524]} icon={camera}><Popup><span className='title-content'>Magalhães Bastos - BRT - FIXA -  Zona Oeste - Deodoro</span><img src='http://187.111.99.18:9004/?CODE=002427' alt='Magalhães Bastos - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86832751,-43.41340843]} icon={camera}><Popup><span className='title-content'>Magalhães Bastos - BRT - FIXA -  Zona Oeste - Deodoro</span><img src='http://187.111.99.18:9004/?CODE=002428' alt='Magalhães Bastos - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86224644,-43.40060053]} icon={camera}><Popup><span className='title-content'>Vila Militar - BRT - FIXA -  Zona Oeste - Deodoro</span><img src='http://187.111.99.18:9004/?CODE=002429' alt='Vila Militar - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86239487,-43.40088882]} icon={camera}><Popup><span className='title-content'>Vila Militar - BRT - FIXA -  Zona Oeste - Deodoro</span><img src='http://187.111.99.18:9004/?CODE=002430' alt='Vila Militar - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86210303,-43.40035407]} icon={camera}><Popup><span className='title-content'>Vila Militar - BRT - FIXA -  Zona Oeste - Deodoro</span><img src='http://187.111.99.18:9004/?CODE=002431' alt='Vila Militar - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.928239,-43.395888]} icon={camera}><Popup><span className='title-content'>Outeiro Santo - BRT -  FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002432' alt='Outeiro Santo - BRT -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.928209,-43.395845]} icon={camera}><Popup><span className='title-content'>Outeiro Santo - BRT -  FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002433' alt='Outeiro Santo - BRT -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.927676,-43.396061]} icon={camera}><Popup><span className='title-content'>Outeiro Santo - BRT -  FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002434' alt='Outeiro Santo - BRT -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.953284,-43.390734]} icon={camera}><Popup><span className='title-content'>Leila Diniz- BRT - FIXA -  Zona Oeste - Vila Militar</span><img src='http://187.111.99.18:9004/?CODE=002435' alt='Leila Diniz- BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.953103,-43.390691]} icon={camera}><Popup><span className='title-content'>Leila Diniz- BRT - FIXA -  Zona Oeste - Vila Militar</span><img src='http://187.111.99.18:9004/?CODE=002436' alt='Leila Diniz- BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.952899,-43.390386]} icon={camera}><Popup><span className='title-content'>Leila Diniz- BRT - FIXA -  Zona Oeste - Vila Militar</span><img src='http://187.111.99.18:9004/?CODE=002437' alt='Leila Diniz- BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.875771,-43.405322]} icon={camera}><Popup><span className='title-content'>PE. João Cribbin / Marechal Mallet - BRT - FIXA -  Zona Oeste - Vila Militar</span><img src='http://187.111.99.18:9004/?CODE=002438' alt='PE. João Cribbin / Marechal Mallet - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.87624,-43.40513]} icon={camera}><Popup><span className='title-content'>PE. João Cribbin / Marechal Mallet - BRT - FIXA -  Zona Oeste - Vila Militar</span><img src='http://187.111.99.18:9004/?CODE=002439' alt='PE. João Cribbin / Marechal Mallet - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.87601,-43.40523]} icon={camera}><Popup><span className='title-content'>PE. João Cribbin / Marechal Mallet - BRT - FIXA -  Zona Oeste - Vila Militar</span><img src='http://187.111.99.18:9004/?CODE=002440' alt='PE. João Cribbin / Marechal Mallet - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88587,-43.40056]} icon={camera}><Popup><span className='title-content'>Marechal Fontenelle - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002441' alt='Marechal Fontenelle - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.886568974057700,-43.40073802083200]} icon={camera}><Popup><span className='title-content'>Marechal Fontenelle - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002442' alt='Marechal Fontenelle - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88593,-43.40071]} icon={camera}><Popup><span className='title-content'>Marechal Fontenelle - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002443' alt='Marechal Fontenelle - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.887184,-43.400870]} icon={camera}><Popup><span className='title-content'>Marechal Fontenelle - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002444' alt='Marechal Fontenelle - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88640,-43.40089]} icon={camera}><Popup><span className='title-content'>Marechal Fontenelle - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002445' alt='Marechal Fontenelle - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88642,-43.40068]} icon={camera}><Popup><span className='title-content'>Marechal Fontenelle - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002446' alt='Marechal Fontenelle - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91570,-43.39805]} icon={camera}><Popup><span className='title-content'>Boiúna - BRT - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=002447' alt='Boiúna - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91590,-43.39795]} icon={camera}><Popup><span className='title-content'>Boiúna - BRT - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=002448' alt='Boiúna - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91543,-43.39823]} icon={camera}><Popup><span className='title-content'>Boiúna - BRT - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=002449' alt='Boiúna - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94128,-43.39462]} icon={camera}><Popup><span className='title-content'>Colônia / Museu Bispo do Rosário - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002450' alt='Colônia / Museu Bispo do Rosário - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94129,-43.39470]} icon={camera}><Popup><span className='title-content'>Colônia / Museu Bispo do Rosário - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002451' alt='Colônia / Museu Bispo do Rosário - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94073,-43.39461]} icon={camera}><Popup><span className='title-content'>Colônia / Museu Bispo do Rosário - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002452' alt='Colônia / Museu Bispo do Rosário - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94765,-43.39196]} icon={camera}><Popup><span className='title-content'>Ventura - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002453' alt='Ventura - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94802,-43.39161]} icon={camera}><Popup><span className='title-content'>Ventura - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002454' alt='Ventura - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94786,-43.39174]} icon={camera}><Popup><span className='title-content'>Ventura - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002455' alt='Ventura - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.916637245043800,-43.68369300248140]} icon={camera}><Popup><span className='title-content'>Santa Cruz - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002456' alt='Santa Cruz - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.917107876120200,-43.68353475214950]} icon={camera}><Popup><span className='title-content'>Santa Cruz - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002457' alt='Santa Cruz - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.917009056082200,-43.683623265047000]} icon={camera}><Popup><span className='title-content'>Santa Cruz - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002458' alt='Santa Cruz - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.917391983327900,-43.683434169311504]} icon={camera}><Popup><span className='title-content'>Santa Cruz - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002459' alt='Santa Cruz - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.917661267001200,-43.683334927577904]} icon={camera}><Popup><span className='title-content'>Santa Cruz - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002460' alt='Santa Cruz - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91672988908570,-43.683727871198600]} icon={camera}><Popup><span className='title-content'>Santa Cruz - BRT - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=002461' alt='Santa Cruz - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.976111096364100,-43.40580522518060]} icon={camera}><Popup><span className='title-content'>Riocentro - BRT - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=002462' alt='Riocentro - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.952256838222900,-43.3900426772461]} icon={camera}><Popup><span className='title-content'>Leila Diniz- BRT - FIXA -  Zona Oeste - Vila Militar</span><img src='http://187.111.99.18:9004/?CODE=002463' alt='Leila Diniz- BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.94041877091930,-43.39468510185240]} icon={camera}><Popup><span className='title-content'>Colônia / Museu Bispo do Rosário - BRT - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002464' alt='Colônia / Museu Bispo do Rosário - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.927310394017400,-43.39635067857360]} icon={camera}><Popup><span className='title-content'>Outeiro Santo - BRT -  FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=002465' alt='Outeiro Santo - BRT -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91516318242890,-43.398562593917800]} icon={camera}><Popup><span className='title-content'>Boiúna - BRT - FIXA -  Zona Oeste - Taquara</span><img src='http://187.111.99.18:9004/?CODE=002466' alt='Boiúna - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00826972,-43.43968878]} icon={camera}><Popup><span className='title-content'>Terminal Recreio - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002467' alt='Terminal Recreio - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.008361066868700,-43.44007501809810]} icon={camera}><Popup><span className='title-content'>Terminal Recreio - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002468' alt='Terminal Recreio - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00843759381530,-43.440383464036704]} icon={camera}><Popup><span className='title-content'>Terminal Recreio - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002469' alt='Terminal Recreio - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00850918611050,-43.440657045319504]} icon={camera}><Popup><span className='title-content'>Terminal Recreio - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002470' alt='Terminal Recreio - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00858077607250,-43.44098695170880]} icon={camera}><Popup><span className='title-content'>Terminal Recreio - BRT - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=002471' alt='Terminal Recreio - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88507925,-43.39941201]} icon={camera}><Popup><span className='title-content'>Terminal Sulacap - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002480' alt='Terminal Sulacap - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.884844496863000,-43.399366412446704]} icon={camera}><Popup><span className='title-content'>Terminal Sulacap - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002481' alt='Terminal Sulacap - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.884686340289300,-43.39933422349180]} icon={camera}><Popup><span className='title-content'>Terminal Sulacap - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002482' alt='Terminal Sulacap - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.884533132292100,-43.39930739790980]} icon={camera}><Popup><span className='title-content'>Terminal Sulacap - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002483' alt='Terminal Sulacap - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.884426872939700,-43.39931677773150]} icon={camera}><Popup><span className='title-content'>Terminal Sulacap - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002484' alt='Terminal Sulacap - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88417481803600,-43.39939187185240]} icon={camera}><Popup><span className='title-content'>Terminal Sulacap - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002485' alt='Terminal Sulacap - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.883818971136500,-43.399434785344200]} icon={camera}><Popup><span className='title-content'>Terminal Sulacap - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002486' alt='Terminal Sulacap - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88377696118370,-43.399845157979200]} icon={camera}><Popup><span className='title-content'>Terminal Sulacap - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002487' alt='Terminal Sulacap - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.883984532847900,-43.39988270092620]} icon={camera}><Popup><span className='title-content'>Terminal Sulacap - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002488' alt='Terminal Sulacap - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88412291,-43.39989879]} icon={camera}><Popup><span className='title-content'>Terminal Sulacap - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002489' alt='Terminal Sulacap - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.884226696199100,-43.39990415441800]} icon={camera}><Popup><span className='title-content'>Terminal Sulacap - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002490' alt='Terminal Sulacap - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88439966682220,-43.39992560767210]} icon={camera}><Popup><span className='title-content'>Terminal Sulacap - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002491' alt='Terminal Sulacap - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.884557810044500,-43.39995242209010]} icon={camera}><Popup><span className='title-content'>Terminal Sulacap - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002492' alt='Terminal Sulacap - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.88470113331870,-43.399973877672096]} icon={camera}><Popup><span className='title-content'>Terminal Sulacap - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002493' alt='Terminal Sulacap - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.884958123158100,-43.40001142092610]} icon={camera}><Popup><span className='title-content'>Terminal Sulacap - BRT - FIXA -  Zona Oeste - Jardim Sulacap</span><img src='http://187.111.99.18:9004/?CODE=002494' alt='Terminal Sulacap - BRT - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8274106,-43.347624]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 8888 - FIXA -  Zona Norte - Iraja</span><img src='http://187.111.99.18:9004/?CODE=003100' alt='Av. Pastor Martin Luther King Júnior, 8888 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.810935,-43.339436]} icon={camera}><Popup><span className='title-content'>R. Sussekind de Mendonça, 163 - Parque Colúmbia -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003101' alt='R. Sussekind de Mendonça, 163 - Parque Colúmbia' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.807938,-43.3447364]} icon={camera}><Popup><span className='title-content'>Av. Cel. Phidias Távora, 1095 - Pavuna -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003102' alt='Av. Cel. Phidias Távora, 1095 - Pavuna' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8047819,-43.3508921]} icon={camera}><Popup><span className='title-content'>R. Mercúrio, 1545 - Pavuna -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003103' alt='R. Mercúrio, 1545 - Pavuna' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.80550260,-43.35682072]} icon={camera}><Popup><span className='title-content'>R. Netuno 714 a 794 - Pavuna -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003104' alt='R. Netuno 714 a 794 - Pavuna' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.80749956,-43.35605595]} icon={camera}><Popup><span className='title-content'>R. Srg. Antônio Ernesto - Pavuna -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003105' alt='R. Srg. Antônio Ernesto - Pavuna' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8111681,-43.3528656]} icon={camera}><Popup><span className='title-content'>R. Herculano Pinheiro, 32 - Pavuna -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003106' alt='R. Herculano Pinheiro, 32 - Pavuna' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8076635,-43.3587199]} icon={camera}><Popup><span className='title-content'>Praça Ênio, 64 - FIXA -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003107' alt='Praça Ênio, 64 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915299,-43.177856]} icon={camera}><Popup><span className='title-content'>Rua da Lapa, 1 - Centro - FIXA -  Centro da Cidade - Centro</span><img src='http://187.111.99.18:9004/?CODE=003108' alt='Rua da Lapa, 1 - Centro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96076539,-43.39278821]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 293 -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003109' alt='Estr. dos Bandeirantes, 293' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.820197,-43.352603]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 11763 - FIXA -  Zona Norte - Iraja</span><img src='http://187.111.99.18:9004/?CODE=003110' alt='Av. Pastor Martin Luther King Júnior, 11763 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92942444,-43.23878652]} icon={camera}><Popup><span className='title-content'>R. José Higino, 244 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003111' alt='R. José Higino, 244 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92780455,-43.23630193]} icon={camera}><Popup><span className='title-content'>Rua Conde de Bonfim, 502 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003112' alt='Rua Conde de Bonfim, 502 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92811998,-43.23980091]} icon={camera}><Popup><span className='title-content'>Rua José Higino 178  - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003113' alt='Rua José Higino 178  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92702683,-43.23786808]} icon={camera}><Popup><span className='title-content'>R. Antônio Basílio, 623 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003114' alt='R. Antônio Basílio, 623 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92957837,-43.24094689]} icon={camera}><Popup><span className='title-content'>Av. Maracanã, 1281 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003115' alt='Av. Maracanã, 1281 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92680941,-43.24001454]} icon={camera}><Popup><span className='title-content'>R. José Higino, 110 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003116' alt='R. José Higino, 110 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92570411,-43.24021454]} icon={camera}><Popup><span className='title-content'>Rua Dois, 61 - Tijuca - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003117' alt='Rua Dois, 61 - Tijuca - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92764669,-43.24186772]} icon={camera}><Popup><span className='title-content'>R. Maria Amália, 201 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003118' alt='R. Maria Amália, 201 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92847128,-43.24379595]} icon={camera}><Popup><span className='title-content'>R. Uruguai, 260 -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003119' alt='R. Uruguai, 260' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.961670,-43.390449]} icon={camera}><Popup><span className='title-content'>Estr. Cel. Pedro Corrêa, 232 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003120' alt='Estr. Cel. Pedro Corrêa, 232 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.961770,-43.390449]} icon={camera}><Popup><span className='title-content'>Estr. Cel. Pedro Corrêa, 232 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003121' alt='Estr. Cel. Pedro Corrêa, 232 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96182402,-43.39699792]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 5837 -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003122' alt='Estr. dos Bandeirantes, 5837' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.965289,-43.390389]} icon={camera}><Popup><span className='title-content'>Estr. Cel. Pedro Corrêa, 22 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003124' alt='Estr. Cel. Pedro Corrêa, 22 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.965315,-43.390481]} icon={camera}><Popup><span className='title-content'>Estr. Cel. Pedro Corrêa, 22 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003125' alt='Estr. Cel. Pedro Corrêa, 22 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.823646,-43.350866]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 8348 - FIXA -  Zona Norte - Iraja</span><img src='http://187.111.99.18:9004/?CODE=003127' alt='Av. Pastor Martin Luther King Júnior, 8348 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.824659,-43.350029]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 11363 - FIXA -  Zona Norte - Iraja</span><img src='http://187.111.99.18:9004/?CODE=003128' alt='Av. Pastor Martin Luther King Júnior, 11363 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.020425,-43.492627]} icon={camera}><Popup><span className='title-content'>Estr. Ver. Alceu de Carvalho, 190 -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=003129' alt='Estr. Ver. Alceu de Carvalho, 190' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.019721,-43.492865]} icon={camera}><Popup><span className='title-content'>Estr. Ver. Alceu de Carvalho, 2222 - FIXA -  Zona Oeste - Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003130' alt='Estr. Ver. Alceu de Carvalho, 2222 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.014347,-43.493038]} icon={camera}><Popup><span className='title-content'>Estr. Ver. Alceu de Carvalho, 114 - FIXA -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=003131' alt='Estr. Ver. Alceu de Carvalho, 114 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.806976,-43.363851]} icon={camera}><Popup><span className='title-content'>R. Catão, 13 - Pavuna - FIXA -  Zona Oeste - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003132' alt='R. Catão, 13 - Pavuna - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.006362,-43.313202]} icon={camera}><Popup><span className='title-content'>Avenida Armando Lombardi, N 800 -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003133' alt='Avenida Armando Lombardi, N 800' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.006916,-43.313425]} icon={camera}><Popup><span className='title-content'>Av. Armando Lombardi, 435 -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003134' alt='Av. Armando Lombardi, 435' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00726501442670,-43.30978801262500]} icon={camera}><Popup><span className='title-content'>Av. Armando Lombardi 505 -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003135' alt='Av. Armando Lombardi 505' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.806369,-43.343610]} icon={camera}><Popup><span className='title-content'>Estrada Rio D´Ouro, S/N - FIXA -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003137' alt='Estrada Rio D´Ouro, S/N - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.968080,-43.390844]} icon={camera}><Popup><span className='title-content'>Estrada Cel. Pedro Corrêa 120-140 -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003138' alt='Estrada Cel. Pedro Corrêa 120-140' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.975875,-43.190084]} icon={camera}><Popup><span className='title-content'>Av. Nossa Sra. de Copacabana x Rua Bolívar -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=003139' alt='Av. Nossa Sra. de Copacabana x Rua Bolívar' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.974173,-43.400913]} icon={camera}><Popup><span className='title-content'>Av. Embaixador Abelardo Bueno, 5001 -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003140' alt='Av. Embaixador Abelardo Bueno, 5001' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00016974,-43.36926474]} icon={camera}><Popup><span className='title-content'>Av. das Américas x Av. Ayrton Senna - Cebolão -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003141' alt='Av. das Américas x Av. Ayrton Senna - Cebolão' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.968276,-43.394788]} icon={camera}><Popup><span className='title-content'>R. Francisco de Paula, 71 -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003142' alt='R. Francisco de Paula, 71' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.970815,-43.397451]} icon={camera}><Popup><span className='title-content'>R. Francisco de Paula, 5 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003143' alt='R. Francisco de Paula, 5 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.033393,-43.492911]} icon={camera}><Popup><span className='title-content'>Estr. do Pontal x Av. Estado da Guanabara -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=003145' alt='Estr. do Pontal x Av. Estado da Guanabara' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96998211,-43.39979601]} icon={camera}><Popup><span className='title-content'>Barra da Tijuca, Rio de Janeiro - RJ -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003146' alt='Barra da Tijuca, Rio de Janeiro - RJ' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.961226,-43.190890]} icon={camera}><Popup><span className='title-content'>Túnel Velho sent. Copacabana - 1  -  FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=003147' alt='Túnel Velho sent. Copacabana - 1  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.961126,-43.190890]} icon={camera}><Popup><span className='title-content'>Túnel Velho sent. Copacabana - 2  -  FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=003148' alt='Túnel Velho sent. Copacabana - 2  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.961229,-43.190898]} icon={camera}><Popup><span className='title-content'>Túnel Velho sent. Copacabana - 3  -  FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=003149' alt='Túnel Velho sent. Copacabana - 3  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.961240,-43.190897]} icon={camera}><Popup><span className='title-content'>Túnel Velho sent. Copacabana - 4  -  FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=003150' alt='Túnel Velho sent. Copacabana - 4  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.961140,-43.190897]} icon={camera}><Popup><span className='title-content'>Túnel Velho sent. Copacabana - 5  -  FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=003151' alt='Túnel Velho sent. Copacabana - 5  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.962633,-43.191353]} icon={camera}><Popup><span className='title-content'>Túnel Velho sent. Copacabana - 6  -  FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=003152' alt='Túnel Velho sent. Copacabana - 6  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.962533,-43.191353]} icon={camera}><Popup><span className='title-content'>Túnel Velho sent. Copacabana - 7  -  FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=003153' alt='Túnel Velho sent. Copacabana - 7  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.962847,-43.191460]} icon={camera}><Popup><span className='title-content'>Túnel Velho sent. Copacabana - 8  -  FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=003154' alt='Túnel Velho sent. Copacabana - 8  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.963251,-43.191548]} icon={camera}><Popup><span className='title-content'>Túnel Velho sent. Copacabana - 9  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=003155' alt='Túnel Velho sent. Copacabana - 9  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.963151,-43.191548]} icon={camera}><Popup><span className='title-content'>Túnel Velho sent. Copacabana - 10  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=003156' alt='Túnel Velho sent. Copacabana - 10  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96283953817860,-43.191383612268400]} icon={camera}><Popup><span className='title-content'>Túnel Velho sent. Botafogo - 1  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=003157' alt='Túnel Velho sent. Botafogo - 1  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.962791182001700,-43.1913636588955]} icon={camera}><Popup><span className='title-content'>Túnel Velho sent. Botafogo - 2  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=003158' alt='Túnel Velho sent. Botafogo - 2  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96220281,-43.19116781]} icon={camera}><Popup><span className='title-content'>Túnel Velho sent. Botafogo - 3  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=003159' alt='Túnel Velho sent. Botafogo - 3  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96220181,-43.19116781]} icon={camera}><Popup><span className='title-content'>Túnel Velho sent. Botafogo - 4  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=003160' alt='Túnel Velho sent. Botafogo - 4  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96182065052230,-43.191058042565600]} icon={camera}><Popup><span className='title-content'>Túnel Velho sent. Botafogo - 5  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=003161' alt='Túnel Velho sent. Botafogo - 5  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.962072561240800,-43.19112778349180]} icon={camera}><Popup><span className='title-content'>Túnel Velho sent. Botafogo - 6  -  FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=003162' alt='Túnel Velho sent. Botafogo - 6  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.961207,-43.190805]} icon={camera}><Popup><span className='title-content'>Túnel Velho sent. Botafogo - 7  -  FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=003163' alt='Túnel Velho sent. Botafogo - 7  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.960992,-43.190731]} icon={camera}><Popup><span className='title-content'>Túnel Velho sent. Botafogo - 8  -  FIXA -  Zona Sul - Botafogo</span><img src='http://187.111.99.18:9004/?CODE=003164' alt='Túnel Velho sent. Botafogo - 8  -  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9726151,-43.4004343]} icon={camera}><Popup><span className='title-content'>Av. Embaixador Abelardo Bueno, 91 -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003165' alt='Av. Embaixador Abelardo Bueno, 91' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.970809,-43.400544]} icon={camera}><Popup><span className='title-content'>Av. Salvador Allende X Av. Embaixador Abelardo Bueno -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003166' alt='Av. Salvador Allende X Av. Embaixador Abelardo Bueno' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9732631,-43.3994164]} icon={camera}><Popup><span className='title-content'>Av. Embaixador Abelardo Bueno, nº 4801 -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003167' alt='Av. Embaixador Abelardo Bueno, nº 4801' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00063386,-43.36772650]} icon={camera}><Popup><span className='title-content'>Terminal Alvorada A -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003168' alt='Terminal Alvorada A' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.000664,-43.364520]} icon={camera}><Popup><span className='title-content'>Terminal Alvorada B -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003169' alt='Terminal Alvorada B' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.001799,-43.367594]} icon={camera}><Popup><span className='title-content'>Av. Ayrton Senna x Terminal Alvorada C -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003170' alt='Av. Ayrton Senna x Terminal Alvorada C' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.978638,-43.291767]} icon={camera}><Popup><span className='title-content'>Estr. das Furnas, 2082 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003171' alt='Estr. das Furnas, 2082 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.979958,-43.409981]} icon={camera}><Popup><span className='title-content'>Lagune Barra Hotel - Av. Salvador Allende, 6.555 -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003172' alt='Lagune Barra Hotel - Av. Salvador Allende, 6.555' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.975514,-43.405065]} icon={camera}><Popup><span className='title-content'>Av. Salvador Allende, 531 -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003173' alt='Av. Salvador Allende, 531' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.967469,-43.397707]} icon={camera}><Popup><span className='title-content'>Av. Salvador Allende, 2 -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=003174' alt='Av. Salvador Allende, 2' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.963908,-43.394301]} icon={camera}><Popup><span className='title-content'>Av. Salvador Allende 4700 -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=003175' alt='Av. Salvador Allende 4700' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.974649,-43.414683]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 8613 -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=003176' alt='Estr. dos Bandeirantes, 8613' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.989308,-43.416947]} icon={camera}><Popup><span className='title-content'>Av. Salvador Allende, 31087 -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=003177' alt='Av. Salvador Allende, 31087' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.003092,-43.433462]} icon={camera}><Popup><span className='title-content'>Av. Salvador Allende Recreio dos Bandeirantes -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=003178' alt='Av. Salvador Allende Recreio dos Bandeirantes' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.000213,-43.430007]} icon={camera}><Popup><span className='title-content'>Av. Salvador Allende -  Zona Oeste - Recreio dos Bandeirantes</span><img src='http://187.111.99.18:9004/?CODE=003179' alt='Av. Salvador Allende' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.997562,-43.426382]} icon={camera}><Popup><span className='title-content'>Av. Salvador Allende - Barra da Tijuca -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003180' alt='Av. Salvador Allende - Barra da Tijuca' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00655950,-43.31274066]} icon={camera}><Popup><span className='title-content'>Avenida Armando Lombardi -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003181' alt='Avenida Armando Lombardi' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.014115,-43.461273]} icon={camera}><Popup><span className='title-content'>Av. Glaucio Gil, 1125  - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003183' alt='Av. Glaucio Gil, 1125  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01419646,-43.46147953]} icon={camera}><Popup><span className='title-content'>Av. Glaucio Gil, 1125  - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003184' alt='Av. Glaucio Gil, 1125  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.014862,-43.460986]} icon={camera}><Popup><span className='title-content'>Av. Glaucio Gil, 1078  - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003185' alt='Av. Glaucio Gil, 1078  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01491631,-43.46115229]} icon={camera}><Popup><span className='title-content'>Av. Glaucio Gil, 1078  - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003186' alt='Av. Glaucio Gil, 1078  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.016037,-43.460605]} icon={camera}><Popup><span className='title-content'>Av. Glaucio Gil, 984 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003187' alt='Av. Glaucio Gil, 984 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01608143,-43.46075788]} icon={camera}><Popup><span className='title-content'>Av. Glaucio Gil, 984 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003188' alt='Av. Glaucio Gil, 984 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.016661,-43.460269]} icon={camera}><Popup><span className='title-content'>Av. Glaucio Gil, 810 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003189' alt='Av. Glaucio Gil, 810 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.016739,-43.460459]} icon={camera}><Popup><span className='title-content'>Av. Glaucio Gil, 810 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003190' alt='Av. Glaucio Gil, 810 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.018084,-43.459916]} icon={camera}><Popup><span className='title-content'>Av. Glaucio Gil, 770 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003191' alt='Av. Glaucio Gil, 770 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.018014,-43.459753]} icon={camera}><Popup><span className='title-content'>Av. Glaucio Gil, 770 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003192' alt='Av. Glaucio Gil, 770 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.018904,-43.459443]} icon={camera}><Popup><span className='title-content'>Av. Glaucio Gil, 680 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003193' alt='Av. Glaucio Gil, 680 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.018927,-43.459577]} icon={camera}><Popup><span className='title-content'>Av. Glaucio Gil, 680 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003194' alt='Av. Glaucio Gil, 680 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.010714,-43.461486]} icon={camera}><Popup><span className='title-content'>Estrada Benvindo de Novaes, 2467 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003195' alt='Estrada Benvindo de Novaes, 2467 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.019561,-43.459146]} icon={camera}><Popup><span className='title-content'>Av. Glaucio Gil, 595 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003196' alt='Av. Glaucio Gil, 595 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.019627,-43.459291]} icon={camera}><Popup><span className='title-content'>Av. Glaucio Gil, 595 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003197' alt='Av. Glaucio Gil, 595 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.008713,-43.459854]} icon={camera}><Popup><span className='title-content'>Estrada Benvindo de Novaes, 2223 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003198' alt='Estrada Benvindo de Novaes, 2223 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.020665,-43.458750]} icon={camera}><Popup><span className='title-content'>Av. Glaucio Gil, 480 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003199' alt='Av. Glaucio Gil, 480 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.020683,-43.458901]} icon={camera}><Popup><span className='title-content'>Av. Glaucio Gil, 480 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003200' alt='Av. Glaucio Gil, 480 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.021396,-43.458461]} icon={camera}><Popup><span className='title-content'>Av. Glaucio Gil, 374 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003201' alt='Av. Glaucio Gil, 374 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.021422,-43.458615]} icon={camera}><Popup><span className='title-content'>Av. Glaucio Gil, 374 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003202' alt='Av. Glaucio Gil, 374 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.022701,-43.458038]} icon={camera}><Popup><span className='title-content'>Av. Glaucio Gil, 201 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003203' alt='Av. Glaucio Gil, 201 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.02266150,-43.45786633]} icon={camera}><Popup><span className='title-content'>Av. Glaucio Gil, 201 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003204' alt='Av. Glaucio Gil, 201 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.868133,-43.585724]} icon={camera}><Popup><span className='title-content'>Estr. Rio São Paulo, 5799 - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=003206' alt='Estr. Rio São Paulo, 5799 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.00115450,-43.41825536]} icon={camera}><Popup><span className='title-content'>Av. das Américas - Prox BRT Interlagos -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003207' alt='Av. das Américas - Prox BRT Interlagos' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99969,-43.411535]} icon={camera}><Popup><span className='title-content'>Av. das Américas, 9818 - alt. BRT Golfe Olimpico -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003208' alt='Av. das Américas, 9818 - alt. BRT Golfe Olimpico' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.868432,-43.584167]} icon={camera}><Popup><span className='title-content'>Rod. Luiz Henrique Rezende Novaes, 3941 - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=003209' alt='Rod. Luiz Henrique Rezende Novaes, 3941 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.872218,-43.580674]} icon={camera}><Popup><span className='title-content'>Rod. Luiz Henrique Rezende Novaes, 3270 - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=003210' alt='Rod. Luiz Henrique Rezende Novaes, 3270 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98816808,-43.36605988]} icon={camera}><Popup><span className='title-content'>Av. Ayrton Senna, 2547 -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003211' alt='Av. Ayrton Senna, 2547' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97971915,-43.36540114]} icon={camera}><Popup><span className='title-content'>Av. Ayrton Senna, 3437 -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003212' alt='Av. Ayrton Senna, 3437' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9162132,-43.2298273]} icon={camera}><Popup><span className='title-content'>Av. Maracanã x São Francisco Xavier -  Zona Norte - Sao Francisco Xavier</span><img src='http://187.111.99.18:9004/?CODE=003213' alt='Av. Maracanã x São Francisco Xavier' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.023417,-43.457761]} icon={camera}><Popup><span className='title-content'>R. Demóstenes Madureira de Pinho  - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003214' alt='R. Demóstenes Madureira de Pinho  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.023517,-43.457761]} icon={camera}><Popup><span className='title-content'>R. Demóstenes Madureira de Pinho  - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003215' alt='R. Demóstenes Madureira de Pinho  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.916274,-43.228525]} icon={camera}><Popup><span className='title-content'>São Francisco Xavier x Mata Machado -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003216' alt='São Francisco Xavier x Mata Machado' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.024175,-43.457486]} icon={camera}><Popup><span className='title-content'>Rua Joaquim Cardozo, 90   - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003217' alt='Rua Joaquim Cardozo, 90   - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.024275,-43.457486]} icon={camera}><Popup><span className='title-content'>Rua Joaquim Cardozo, 90   - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003218' alt='Rua Joaquim Cardozo, 90   - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915896,-43.230606]} icon={camera}><Popup><span className='title-content'>São Francisco Xavier x Visconde de Itamarati -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003219' alt='São Francisco Xavier x Visconde de Itamarati' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913812,-43.233708]} icon={camera}><Popup><span className='title-content'>R. São Francisco Xavier X R. Conselheiro Olegário -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003220' alt='R. São Francisco Xavier X R. Conselheiro Olegário' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914593,-43.233123]} icon={camera}><Popup><span className='title-content'>R. São Francisco Xavier X R. Artur Menezes -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003221' alt='R. São Francisco Xavier X R. Artur Menezes' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.915653,-43.232198]} icon={camera}><Popup><span className='title-content'>R. Isidro de Figueiredo -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003222' alt='R. Isidro de Figueiredo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.878867,-43.573553]} icon={camera}><Popup><span className='title-content'>Rod. Luiz Henrique Rezende Novaes x  R. Vicente Francisco dos Santos - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=003223' alt='Rod. Luiz Henrique Rezende Novaes x  R. Vicente Francisco dos Santos - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.875719,-43.575257]} icon={camera}><Popup><span className='title-content'>Rod. Luiz Henrique Rezende Novaes, 2595 - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=003224' alt='Rod. Luiz Henrique Rezende Novaes, 2595 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.881164,-43.573490]} icon={camera}><Popup><span className='title-content'>Estr. Rio São Paulo, 2172 - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=003225' alt='Estr. Rio São Paulo, 2172 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96971948,-43.38046758]} icon={camera}><Popup><span className='title-content'>Av. Canal Arroio Pavuna, 2127 -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003226' alt='Av. Canal Arroio Pavuna, 2127' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97107634,-43.39274418]} icon={camera}><Popup><span className='title-content'>Rua Aroazes,  730 -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003227' alt='Rua Aroazes,  730' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.897686,-43.556483]} icon={camera}><Popup><span className='title-content'>Estrada da Caroba, 917 - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=003228' alt='Estrada da Caroba, 917 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.898398,-43.555017]} icon={camera}><Popup><span className='title-content'>Estrada da Caroba x R. Lucília - FIXA -  Zona Oeste - Campo Grande</span><img src='http://187.111.99.18:9004/?CODE=003229' alt='Estrada da Caroba x R. Lucília - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.961361,-43.381074]} icon={camera}><Popup><span className='title-content'>Av. Canal Arroio Pavuna x Pedro Pereira Pinto -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003230' alt='Av. Canal Arroio Pavuna x Pedro Pereira Pinto' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.973026,-43.400432]} icon={camera}><Popup><span className='title-content'>Av. Embaixador Abelardo Bueno, 95 -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003231' alt='Av. Embaixador Abelardo Bueno, 95' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.973004,-43.401038]} icon={camera}><Popup><span className='title-content'>Av. Embaixador Abelardo Bueno, 3300 -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003232' alt='Av. Embaixador Abelardo Bueno, 3300' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.0012253,-43.4536353]} icon={camera}><Popup><span className='title-content'>Estrada Benvindo de Novaes, 1180 -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003234' alt='Estrada Benvindo de Novaes, 1180' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.806353,-43.364915]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior x Praça Copérnico - FIXA -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003235' alt='Av. Pastor Martin Luther King Júnior x Praça Copérnico - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99706317,-43.45029918]} icon={camera}><Popup><span className='title-content'>Estrada Benvindo de Novaes, 520 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003236' alt='Estrada Benvindo de Novaes, 520 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99431859,-43.44806894]} icon={camera}><Popup><span className='title-content'>Estrada Benvindo de Novaes, 880 -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003237' alt='Estrada Benvindo de Novaes, 880' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.804975,-43.363106]} icon={camera}><Popup><span className='title-content'>Avenida Sargento de Milícias, 2113 -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003238' alt='Avenida Sargento de Milícias, 2113' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.805157,-43.363934]} icon={camera}><Popup><span className='title-content'>Avenida Sargento de Milícias, 23 -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003239' alt='Avenida Sargento de Milícias, 23' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.804293,-43.364624]} icon={camera}><Popup><span className='title-content'>R. Prof. Lindolfo Gomes, 126 -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003240' alt='R. Prof. Lindolfo Gomes, 126' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99264709,-43.44712635]} icon={camera}><Popup><span className='title-content'>Estr. Dos Bandeirantes x Estr. Benvindo de Novaes -  Zona Oeste -  Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003241' alt='Estr. Dos Bandeirantes x Estr. Benvindo de Novaes' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99412850,-43.44790195]} icon={camera}><Popup><span className='title-content'>Estrada Benvindo de Novaes, 880 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003242' alt='Estrada Benvindo de Novaes, 880 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99285195,-43.44890552]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 12877-12777 - FIXA -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003243' alt='Estr. dos Bandeirantes, 12877-12777 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.99282561,-43.44777903]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 8325 - FIXA -  Zona Oeste -  Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003244' alt='Estr. dos Bandeirantes, 8325 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.804690,-43.36153]} icon={camera}><Popup><span className='title-content'>R. Srg. Basileu da Costa x Av. Srg. de Milícias -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003245' alt='R. Srg. Basileu da Costa x Av. Srg. de Milícias' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8044862,-43.3600062]} icon={camera}><Popup><span className='title-content'>Av. Srg. de Milícias, 831 - FIXA -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003246' alt='Av. Srg. de Milícias, 831 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.805915,-43.3607577]} icon={camera}><Popup><span className='title-content'>R. Mercúrio, 459 - Pavuna - FIXA -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003247' alt='R. Mercúrio, 459 - Pavuna - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96396967,-43.19125114]} icon={camera}><Popup><span className='title-content'>Praça Vereador Rocha Leão - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=003248' alt='Praça Vereador Rocha Leão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9639351,-43.1913705]} icon={camera}><Popup><span className='title-content'>Praça Vereador Rocha Leão - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=003249' alt='Praça Vereador Rocha Leão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.963759,-43.191498]} icon={camera}><Popup><span className='title-content'>Praça Vereador Rocha Leão - FIXA -  Zona Sul - Copacabana</span><img src='http://187.111.99.18:9004/?CODE=003250' alt='Praça Vereador Rocha Leão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92347214,-43.23523738]} icon={camera}><Popup><span className='title-content'>R. Barão de Mesquita, Shopping Tijuca -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003251' alt='R. Barão de Mesquita, Shopping Tijuca' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92372365,-43.23477908]} icon={camera}><Popup><span className='title-content'>R. Gen. Roca, 932 - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003252' alt='R. Gen. Roca, 932 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92391641,-43.23449197]} icon={camera}><Popup><span className='title-content'>R. Gen. Roca, 894 -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003253' alt='R. Gen. Roca, 894' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.896795,-43.216514]} icon={camera}><Popup><span className='title-content'>R. Benedito Otoni x R. Santos Lima -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=003254' alt='R. Benedito Otoni x R. Santos Lima' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8995661,-43.2146122]} icon={camera}><Popup><span className='title-content'>R. Benedito Otoni, 46 - São Cristóvão -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=003255' alt='R. Benedito Otoni, 46 - São Cristóvão' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.901123,-43.216668]} icon={camera}><Popup><span className='title-content'>R. Figueira Lima x São Cristóvão -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=003256' alt='R. Figueira Lima x São Cristóvão' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.902981,-43.218469]} icon={camera}><Popup><span className='title-content'>R. Fonseca Téles  x São Cristóvão -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=003257' alt='R. Fonseca Téles  x São Cristóvão' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.903464,-43.215008]} icon={camera}><Popup><span className='title-content'>Av. Pedro II, 187 -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=003258' alt='Av. Pedro II, 187' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.902786,-43.213196]} icon={camera}><Popup><span className='title-content'>Av. Pedro II, 111 -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=003259' alt='Av. Pedro II, 111' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.907288,-43.182869]} icon={camera}><Popup><span className='title-content'>Pedro I  - Praça Tiradentes - FIXA -  Centro da Cidade - Carioca</span><img src='http://187.111.99.18:9004/?CODE=003260' alt='Pedro I  - Praça Tiradentes - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.906505,-43.182908]} icon={camera}><Popup><span className='title-content'>Pedro I  - Praça Tiradentes - FIXA -  Centro da Cidade - Carioca</span><img src='http://187.111.99.18:9004/?CODE=003261' alt='Pedro I  - Praça Tiradentes - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.922804,-43.172565]} icon={camera}><Popup><span className='title-content'>Balaustrada da Murada da Glória - FIXA -  Centro da Cidade - Gloria</span><img src='http://187.111.99.18:9004/?CODE=003262' alt='Balaustrada da Murada da Glória - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92261624,-43.17240272]} icon={camera}><Popup><span className='title-content'>Balaustrada da Murada da Glória - FIXA -  Centro da Cidade - Gloria</span><img src='http://187.111.99.18:9004/?CODE=003263' alt='Balaustrada da Murada da Glória - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92268047,-43.17242417]} icon={camera}><Popup><span className='title-content'>Balaustrada da Murada da Glória  - FIXA -  Centro da Cidade - Gloria</span><img src='http://187.111.99.18:9004/?CODE=003264' alt='Balaustrada da Murada da Glória  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.922646,-43.172481]} icon={camera}><Popup><span className='title-content'>Balaustrada da Murada da Glória - FIXA -  Centro da Cidade - Gloria</span><img src='http://187.111.99.18:9004/?CODE=003265' alt='Balaustrada da Murada da Glória - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90535,-43.22477]} icon={camera}><Popup><span className='title-content'>Pedro II - Quinta da Boavista - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=003266' alt='Pedro II - Quinta da Boavista - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90522,-43.18083]} icon={camera}><Popup><span className='title-content'>José Bonifácio - Largo de São Francisco de Paula - FIXA -  Centro da Cidade - Carioca</span><img src='http://187.111.99.18:9004/?CODE=003268' alt='José Bonifácio - Largo de São Francisco de Paula - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.870714,-43.343139]} icon={camera}><Popup><span className='title-content'>R. Clara Nunes, 10-170 - Portela - FIXA -  Zona Norte - Madureira</span><img src='http://187.111.99.18:9004/?CODE=003269' alt='R. Clara Nunes, 10-170 - Portela - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.92702683,-43.23786808]} icon={camera}><Popup><span className='title-content'>Rua Antônio Basílio x Rua Conde de Itaguaí - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003270' alt='Rua Antônio Basílio x Rua Conde de Itaguaí - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.898770,-43.219888]} icon={camera}><Popup><span className='title-content'>R. Campo de São Cristóvão, 87 - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=003271' alt='R. Campo de São Cristóvão, 87 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89878976,-43.21983301]} icon={camera}><Popup><span className='title-content'>R. Campo de São Cristóvão, 87 - FIXA -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=003272' alt='R. Campo de São Cristóvão, 87 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97806987,-43.19293536]} icon={camera}><Popup><span className='title-content'>Túnel Prefeito Sá Freire Alvim 01 - FIXA -  Zona Sul - Cantagalo</span><img src='http://187.111.99.18:9004/?CODE=003273' alt='Túnel Prefeito Sá Freire Alvim 01 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97857,-43.19303]} icon={camera}><Popup><span className='title-content'>Túnel Prefeito Sá Freire Alvim 02 - FIXA -  Zona Sul - Cantagalo</span><img src='http://187.111.99.18:9004/?CODE=003274' alt='Túnel Prefeito Sá Freire Alvim 02 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97923,-43.19306]} icon={camera}><Popup><span className='title-content'>Túnel Prefeito Sá Freire Alvim 03 - FIXA -  Zona Sul - Cantagalo</span><img src='http://187.111.99.18:9004/?CODE=003275' alt='Túnel Prefeito Sá Freire Alvim 03 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97950,-43.19302]} icon={camera}><Popup><span className='title-content'>Túnel Prefeito Sá Freire Alvim 04 - FIXA -  Zona Sul - Cantagalo</span><img src='http://187.111.99.18:9004/?CODE=003276' alt='Túnel Prefeito Sá Freire Alvim 04 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98016,-43.19286]} icon={camera}><Popup><span className='title-content'>Túnel Prefeito Sá Freire Alvim 05 - FIXA -  Zona Sul - Cantagalo</span><img src='http://187.111.99.18:9004/?CODE=003277' alt='Túnel Prefeito Sá Freire Alvim 05 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.980061,-43.19271]} icon={camera}><Popup><span className='title-content'>Túnel Prefeito Sá Freire Alvim 06 - FIXA -  Zona Sul - Cantagalo</span><img src='http://187.111.99.18:9004/?CODE=003278' alt='Túnel Prefeito Sá Freire Alvim 06 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98096,-43.19261]} icon={camera}><Popup><span className='title-content'>Túnel Prefeito Sá Freire Alvim 07 - FIXA -  Zona Sul - Cantagalo</span><img src='http://187.111.99.18:9004/?CODE=003279' alt='Túnel Prefeito Sá Freire Alvim 07 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.820537,-43.227394]} icon={camera}><Popup><span className='title-content'>Pr. Belo Jardim x Estr. do Galeão - FIXA -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003280' alt='Pr. Belo Jardim x Estr. do Galeão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.82036566,-43.22713689]} icon={camera}><Popup><span className='title-content'>Pr. Belo Jardim x Estr. do Galeão - FIXA -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003281' alt='Pr. Belo Jardim x Estr. do Galeão - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.82001445,-43.22697693]} icon={camera}><Popup><span className='title-content'>Estr. do Galeão x Av. Quatro - FIXA -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003282' alt='Estr. do Galeão x Av. Quatro - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.81779262,-43.22454742]} icon={camera}><Popup><span className='title-content'>Estrada do Galeão x R Cinquenta e Dois - Fixa -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003283' alt='Estrada do Galeão x R Cinquenta e Dois - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.81580995,-43.22240442]} icon={camera}><Popup><span className='title-content'>Estrada do Galeão Prox R. Rio de Janeiro -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003284' alt='Estrada do Galeão Prox R. Rio de Janeiro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.81300069,-43.21994918]} icon={camera}><Popup><span className='title-content'>Estrada do Galeão Prox R. Espumas -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003285' alt='Estrada do Galeão Prox R. Espumas' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.809671903988400,-43.215680409717504]} icon={camera}><Popup><span className='title-content'>Estrada do Galeão, 837 -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003286' alt='Estrada do Galeão, 837' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.806501,-43.214118]} icon={camera}><Popup><span className='title-content'>Estrada do Galeão, 3359 -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003287' alt='Estrada do Galeão, 3359' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.805456,-43.211027]} icon={camera}><Popup><span className='title-content'>Estrada do Galeão, 2940 - Chafariz da Ilha -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003288' alt='Estrada do Galeão, 2940 - Chafariz da Ilha' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89829678,-43.21954664]} icon={camera}><Popup><span className='title-content'>R.Campo de São Cristóvão x R.Figueira de Melo -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=003289' alt='R.Campo de São Cristóvão x R.Figueira de Melo' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89673643,-43.22126191]} icon={camera}><Popup><span className='title-content'>Praça Padre Cícero X  R. Campo de São Cristóvão -  Zona Norte - Sao Cristovao</span><img src='http://187.111.99.18:9004/?CODE=003290' alt='Praça Padre Cícero X  R. Campo de São Cristóvão' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.987592,-43.484395]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 21979 - FIXA -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003291' alt='Estr. dos Bandeirantes, 21979 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.987522,-43.484395]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 21979 - FIXA -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003292' alt='Estr. dos Bandeirantes, 21979 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.987968,-43.481604]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 21717 - FIXA -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003293' alt='Estr. dos Bandeirantes, 21717 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.987888,-43.481604]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 21717 - FIXA -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003294' alt='Estr. dos Bandeirantes, 21717 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.987626,-43.479585]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 21577 - FIXA -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003295' alt='Estr. dos Bandeirantes, 21577 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.987546,-43.479585]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 21577 - FIXA -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003296' alt='Estr. dos Bandeirantes, 21577 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.987170,-43.477760]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 21361 - FIXA -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003297' alt='Estr. dos Bandeirantes, 21361 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.987110,-43.477760]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 21361 - FIXA -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003298' alt='Estr. dos Bandeirantes, 21361 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.986483,-43.476327]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 21152 - FIXA -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003299' alt='Estr. dos Bandeirantes, 21152 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.986403,-43.476327]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 21152 - FIXA -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003300' alt='Estr. dos Bandeirantes, 21152 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.985117,-43.473939]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 20732 - FIXA -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003301' alt='Estr. dos Bandeirantes, 20732 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.985037,-43.473939]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 20732 - FIXA -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003302' alt='Estr. dos Bandeirantes, 20732 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.983681,-43.470621]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes,20265 - FIXA -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003303' alt='Estr. dos Bandeirantes,20265 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.983600,-43.470621]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes,20265 - FIXA -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003304' alt='Estr. dos Bandeirantes,20265 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.812899,-43.2076877]} icon={camera}><Popup><span className='title-content'>Rua Cambaúba, 27 - Jardim Guanabara - Fixa -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003305' alt='Rua Cambaúba, 27 - Jardim Guanabara - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-23.01274517,-43.31835682]} icon={camera}><Popup><span className='title-content'>Av. Sernambetiba X Praça Do O -  Zona Oeste - Barra da Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003306' alt='Av. Sernambetiba X Praça Do O' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8152141,-43.2064024]} icon={camera}><Popup><span className='title-content'>Rua Cambaúba, 1290 - FIXA -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003307' alt='Rua Cambaúba, 1290 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.978972,-43.230064]} icon={camera}><Popup><span className='title-content'>Av. Padre Leonel Franca - Terminal da PUC - FIXA -  Zona Sul - Gavea</span><img src='http://187.111.99.18:9004/?CODE=003308' alt='Av. Padre Leonel Franca - Terminal da PUC - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.979047,-43.230644]} icon={camera}><Popup><span className='title-content'>Av. Padre Leonel Franca - Terminal da PUC - FIXA -  Zona Sul - Gavea</span><img src='http://187.111.99.18:9004/?CODE=003309' alt='Av. Padre Leonel Franca - Terminal da PUC - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.979108,-43.231871]} icon={camera}><Popup><span className='title-content'>Av. Padre Leonel Franca - Terminal da PUC - FIXA -  Zona Sul - Gavea</span><img src='http://187.111.99.18:9004/?CODE=003310' alt='Av. Padre Leonel Franca - Terminal da PUC - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.979376,-43.231852]} icon={camera}><Popup><span className='title-content'>Av. Padre Leonel Franca - Terminal da PUC - FIXA -  Zona Sul - Gavea</span><img src='http://187.111.99.18:9004/?CODE=003311' alt='Av. Padre Leonel Franca - Terminal da PUC - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.979495,-43.231130]} icon={camera}><Popup><span className='title-content'>Av. Padre Leonel Franca - Terminal da PUC - FIXA -  Zona Sul - Gavea</span><img src='http://187.111.99.18:9004/?CODE=003312' alt='Av. Padre Leonel Franca - Terminal da PUC - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.979432,-43.230711]} icon={camera}><Popup><span className='title-content'>Av. Padre Leonel Franca - Terminal da PUC - FIXA -  Zona Sul - Gavea</span><img src='http://187.111.99.18:9004/?CODE=003313' alt='Av. Padre Leonel Franca - Terminal da PUC - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8173098,-43.2029786]} icon={camera}><Popup><span className='title-content'>Rua Cambaúba, 1664 -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003314' alt='Rua Cambaúba, 1664' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8191751,-43.2014486]} icon={camera}><Popup><span className='title-content'>Praça Jerusalém próximo ao Nº29 -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003315' alt='Praça Jerusalém próximo ao Nº29' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8185884,-43.1944742]} icon={camera}><Popup><span className='title-content'>Av. Alm. Alves Câmara Júnior, 589 - Praia da BICA -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003316' alt='Av. Alm. Alves Câmara Júnior, 589 - Praia da BICA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8183498,-43.1969481]} icon={camera}><Popup><span className='title-content'>Av. Alm. Alves Câmara Júnior, 302 - Praia da BICA -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003317' alt='Av. Alm. Alves Câmara Júnior, 302 - Praia da BICA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.918432,-43.232287]} icon={camera}><Popup><span className='title-content'>Av. Maracanã X R. Deputado Soares Filho - FIXA -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003318' alt='Av. Maracanã X R. Deputado Soares Filho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8194611,-43.1919038]} icon={camera}><Popup><span className='title-content'>R. Ipiru, 416 -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003319' alt='R. Ipiru, 416' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8187325,-43.1998025]} icon={camera}><Popup><span className='title-content'>Praia da Bica Próx. Av Francisco Alves - Fixa -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003320' alt='Praia da Bica Próx. Av Francisco Alves - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8091289,-43.2083119]} icon={camera}><Popup><span className='title-content'>Rua Cambaúba, 448 - Fixa -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003321' alt='Rua Cambaúba, 448 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8193511,-43.2020761]} icon={camera}><Popup><span className='title-content'>Praça Jerusalém Nº 241 -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003322' alt='Praça Jerusalém Nº 241' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.967560,-43.217717]} icon={camera}><Popup><span className='title-content'>R. Gen. Garzon, 1746 - FIXA -  Zona Sul - Jardim Botanico</span><img src='http://187.111.99.18:9004/?CODE=003323' alt='R. Gen. Garzon, 1746 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.816474,-43.204871]} icon={camera}><Popup><span className='title-content'>Rua Cambaúba , 1510 - FIXA -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003324' alt='Rua Cambaúba , 1510 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8138271,-43.2067662]} icon={camera}><Popup><span className='title-content'>Rua Cambaúba, 1135 - FIXA -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003325' alt='Rua Cambaúba, 1135 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.80585107865410,-43.36711843470570]} icon={camera}><Popup><span className='title-content'>Largo da Pavuna, 97 - FIXA -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003326' alt='Largo da Pavuna, 97 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8057282,-43.3699047]} icon={camera}><Popup><span className='title-content'>R. Maria Helena, 93  FIXA -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003327' alt='R. Maria Helena, 93  FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.808073,-43.196808]} icon={camera}><Popup><span className='title-content'>Estrada do Galeão X Rua Vistula -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003328' alt='Estrada do Galeão X Rua Vistula' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8102048403,-43.1952124429]} icon={camera}><Popup><span className='title-content'>Estrada do Galeão x R. Copenhague -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003329' alt='Estrada do Galeão x R. Copenhague' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8119983,-43.1915522]} icon={camera}><Popup><span className='title-content'>Estrada do Galeão x Estr. da Cacuia -  Zona Norte - Cacuia</span><img src='http://187.111.99.18:9004/?CODE=003330' alt='Estrada do Galeão x Estr. da Cacuia' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.808826,-43.341769]} icon={camera}><Popup><span className='title-content'>Parque Colúmbia x Av Cel. Phidias Távora- Fixa -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003331' alt='Parque Colúmbia x Av Cel. Phidias Távora- Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8165634,-43.1856707]} icon={camera}><Popup><span className='title-content'>Estr. do Rio Jequiá, 200 -  Zona Norte - Cacuia</span><img src='http://187.111.99.18:9004/?CODE=003332' alt='Estr. do Rio Jequiá, 200' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8160293,-43.1871324]} icon={camera}><Popup><span className='title-content'>Estrada do Galeão, 12 - Fixa -  Zona Norte - Cacuia</span><img src='http://187.111.99.18:9004/?CODE=003333' alt='Estrada do Galeão, 12 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8164087,-43.1865506]} icon={camera}><Popup><span className='title-content'>Estr. do Rio Jequiá - Fixa -  Zona Norte - Cacuia</span><img src='http://187.111.99.18:9004/?CODE=003334' alt='Estr. do Rio Jequiá - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.80883537,-43.36981230]} icon={camera}><Popup><span className='title-content'>R. Comendador Guerra, 425 - Fixa -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003335' alt='R. Comendador Guerra, 425 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.80875435,-43.37094428]} icon={camera}><Popup><span className='title-content'>Praça Nossa Sra. das Dores, 232 -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003336' alt='Praça Nossa Sra. das Dores, 232' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8165949867,-43.1874959865]} icon={camera}><Popup><span className='title-content'>Estrada da Bica x Estr. do Rio Jequiá - Fixa -  Zona Norte - Cacuia</span><img src='http://187.111.99.18:9004/?CODE=003337' alt='Estrada da Bica x Estr. do Rio Jequiá - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8161710964,-43.1885125016]} icon={camera}><Popup><span className='title-content'>Estrada do Galeão, 123 - Fixa -  Zona Norte - Cacuia</span><img src='http://187.111.99.18:9004/?CODE=003338' alt='Estrada do Galeão, 123 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8151636171,-43.187999086]} icon={camera}><Popup><span className='title-content'>Estrada do Galeão . Em Frente a Paranapuan  - Fixa -  Zona Norte - Cacuia</span><img src='http://187.111.99.18:9004/?CODE=003339' alt='Estrada do Galeão . Em Frente a Paranapuan  - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8136348,-43.1894917]} icon={camera}><Popup><span className='title-content'>Estrada do Galeão X Rua Iaco -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003340' alt='Estrada do Galeão X Rua Iaco' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8065981901,-43.1984414043]} icon={camera}><Popup><span className='title-content'>R. Bom Retiro, 31 - FIXA -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003341' alt='R. Bom Retiro, 31 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8045866,-43.3668765]} icon={camera}><Popup><span className='title-content'>Av. Automóvel Clube, 41 -  Zona Norte - Pavuna</span><img src='http://187.111.99.18:9004/?CODE=003342' alt='Av. Automóvel Clube, 41' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8061436,-43.1999468]} icon={camera}><Popup><span className='title-content'>Estrada do Galeão, 1803 -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003343' alt='Estrada do Galeão, 1803' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8041552,-43.2045045]} icon={camera}><Popup><span className='title-content'>R. República Árabe da Síria, 2289 - Fixa -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003344' alt='R. República Árabe da Síria, 2289 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.808138,-43.207306]} icon={camera}><Popup><span className='title-content'>Rua Cambaúba 6 -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003345' alt='Rua Cambaúba 6' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8056069,-43.2090232]} icon={camera}><Popup><span className='title-content'>Estrada do Galeão x Rua Cambaúba -  Zona Norte - Portuguesa</span><img src='http://187.111.99.18:9004/?CODE=003346' alt='Estrada do Galeão x Rua Cambaúba' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.817787,-43.201544]} icon={camera}><Popup><span className='title-content'>R. Eng. Rozauro Zambrano, 74 - Fixa -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003347' alt='R. Eng. Rozauro Zambrano, 74 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.937904,-43.671318]} icon={camera}><Popup><span className='title-content'>Estr. de Sepetiba, 527-501 - Santa Cruz- Fixa -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003350' alt='Estr. de Sepetiba, 527-501 - Santa Cruz- Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.941097,-43.674457]} icon={camera}><Popup><span className='title-content'>Estr. de Sepetiba, 995 - Santa Cruz- Fixa -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003351' alt='Estr. de Sepetiba, 995 - Santa Cruz- Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9745905,-43.6810559]} icon={camera}><Popup><span className='title-content'>Estr. do Piaí - FIXA -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003352' alt='Estr. do Piaí - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9721452954,-43.6838959757]} icon={camera}><Popup><span className='title-content'>Estr. do Piaí  4028-4176 - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003353' alt='Estr. do Piaí  4028-4176 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.982997,-43.469280]} icon={camera}><Popup><span className='title-content'>Rua José Duarte, 5 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003354' alt='Rua José Duarte, 5 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.983060,-43.469280]} icon={camera}><Popup><span className='title-content'>Rua José Duarte, 5 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003355' alt='Rua José Duarte, 5 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.982182,-43.466654]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 16231 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003356' alt='Estr. dos Bandeirantes, 16231 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.982282,-43.466654]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 16231  - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003357' alt='Estr. dos Bandeirantes, 16231  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.982512,-43.463208]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 15050 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003358' alt='Estr. dos Bandeirantes, 15050 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.982612,-43.463208]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 15050 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003359' alt='Estr. dos Bandeirantes, 15050 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.982854,-43.462664]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 14914 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003360' alt='Estr. dos Bandeirantes, 14914 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.982954,-43.462664]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 14914 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003361' alt='Estr. dos Bandeirantes, 14914 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.983833,-43.461807]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 14732-14772 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003362' alt='Estr. dos Bandeirantes, 14732-14772 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.983933,-43.461807]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 14732-14772 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003363' alt='Estr. dos Bandeirantes, 14732-14772 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984679,-43.460939]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 13840 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003364' alt='Estr. dos Bandeirantes, 13840 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984779,-43.460939]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 13840 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003365' alt='Estr. dos Bandeirantes, 13840 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.985465,-43.460122]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 14603-14485 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003366' alt='Estr. dos Bandeirantes, 14603-14485 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.985565,-43.460122]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 14603-14485 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003367' alt='Estr. dos Bandeirantes, 14603-14485 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.986195,-43.457426]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 14172-14254 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003368' alt='Estr. dos Bandeirantes, 14172-14254 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.986295,-43.457426]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 14172-14254 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003369' alt='Estr. dos Bandeirantes, 14172-14254 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.987046,-43.456313]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 14040 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003370' alt='Estr. dos Bandeirantes, 14040 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.987146,-43.456313]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 14040 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003371' alt='Estr. dos Bandeirantes, 14040 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.987773,-43.455468]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 13951 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003372' alt='Estr. dos Bandeirantes, 13951 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.987873,-43.455468]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 13951 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003373' alt='Estr. dos Bandeirantes, 13951 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.988371,-43.454566]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 13833 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003374' alt='Estr. dos Bandeirantes, 13833 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.988471,-43.454566]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 13833 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003375' alt='Estr. dos Bandeirantes, 13833 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98861,-43.45463]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 13787 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003376' alt='Estr. dos Bandeirantes, 13787 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98961,-43.45463]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 13787 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003377' alt='Estr. dos Bandeirantes, 13787 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.991720,-43.451088]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 13595-13257 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003378' alt='Estr. dos Bandeirantes, 13595-13257 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.991820,-43.451088]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 13595-13257 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003379' alt='Estr. dos Bandeirantes, 13595-13257 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.992676,-43.448693]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 12790 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003380' alt='Estr. dos Bandeirantes, 12790 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.992776,-43.448693]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 12790 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003381' alt='Estr. dos Bandeirantes, 12790 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.992414,-43.446726]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 12833-12817 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003382' alt='Estr. dos Bandeirantes, 12833-12817 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.992514,-43.446726]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 12833-12817 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003383' alt='Estr. dos Bandeirantes, 12833-12817 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.991737,-43.445642]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 12427  - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003384' alt='Estr. dos Bandeirantes, 12427  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.991837,-43.445642]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 12427  - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003385' alt='Estr. dos Bandeirantes, 12427  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.990033,-43.443091]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 12310 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003386' alt='Estr. dos Bandeirantes, 12310 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.990133,-43.443091]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 12310 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003387' alt='Estr. dos Bandeirantes, 12310 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.989462,-43.442276]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 12250 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003388' alt='Estr. dos Bandeirantes, 12250 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.989562,-43.442276]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 12250 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003389' alt='Estr. dos Bandeirantes, 12250 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.988949,-43.440787]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 12179-12067 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003390' alt='Estr. dos Bandeirantes, 12179-12067 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.989049,-43.440787]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 12179-12067 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003391' alt='Estr. dos Bandeirantes, 12179-12067 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9473681,-43.6774086]} icon={camera}><Popup><span className='title-content'>Estr. de Sepetiba x R. Maria Lorosa - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003392' alt='Estr. de Sepetiba x R. Maria Lorosa - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.948663,-43.680721]} icon={camera}><Popup><span className='title-content'>Estr. de Sepetiba x Av. Areia Branca -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003393' alt='Estr. de Sepetiba x Av. Areia Branca' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97068096,-43.68746135]} icon={camera}><Popup><span className='title-content'>Estr. do Piaí  5543 - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003394' alt='Estr. do Piaí  5543 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.95061761,-43.68151783]} icon={camera}><Popup><span className='title-content'>Estr. de Sepetiba, 2184 - FIXA -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003395' alt='Estr. de Sepetiba, 2184 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9539304,-43.6844476]} icon={camera}><Popup><span className='title-content'>Estr. de Sepetiba. Próx Av. Um - FIXA -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003396' alt='Estr. de Sepetiba. Próx Av. Um - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.956492,-43.688046]} icon={camera}><Popup><span className='title-content'>Estr. de Sepetiba. Próx Av. 04 - FIXA -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003397' alt='Estr. de Sepetiba. Próx Av. 04 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.959499,-43.695696]} icon={camera}><Popup><span className='title-content'>Estr. de Sepetiba, 4568 - FIXA -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003398' alt='Estr. de Sepetiba, 4568 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9700471045,-43.6913465305]} icon={camera}><Popup><span className='title-content'>Estr. do Piaí, 4819B - FIXA -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003399' alt='Estr. do Piaí, 4819B - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.969734086208000,-43.69382597553250]} icon={camera}><Popup><span className='title-content'>Estr. do Piaí, 5041 - FIXA -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003400' alt='Estr. do Piaí, 5041 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8715036,-43.4557122]} icon={camera}><Popup><span className='title-content'>R. Figueiredo Camargo x R. Sidnei -  Zona Oeste - Bangu</span><img src='http://187.111.99.18:9004/?CODE=003401' alt='R. Figueiredo Camargo x R. Sidnei' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.960698,-43.698703]} icon={camera}><Popup><span className='title-content'>Estr. de Sepetiba, 3202 - FIXA -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003402' alt='Estr. de Sepetiba, 3202 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.89658355,-43.23965004]} icon={camera}><Popup><span className='title-content'>R. Gen. Gustavo Cordeiro de Farias, 346 -  Zona Norte - Benfica</span><img src='http://187.111.99.18:9004/?CODE=003403' alt='R. Gen. Gustavo Cordeiro de Farias, 346' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9632439031,-43.7015081042]} icon={camera}><Popup><span className='title-content'>Estr. de Sepetiba, 4829 - FIXA -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003404' alt='Estr. de Sepetiba, 4829 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9646044257,-43.7049884814]} icon={camera}><Popup><span className='title-content'>Estr. de Sepetiba, 5541 - FIXA -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003405' alt='Estr. de Sepetiba, 5541 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9692738,-43.6967327]} icon={camera}><Popup><span className='title-content'>Estr. do Piaí, 5410 - FIXA -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003406' alt='Estr. do Piaí, 5410 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.969132,-43.7005631]} icon={camera}><Popup><span className='title-content'>Estr. do Piaí, 5829-5763 - FIXA -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003407' alt='Estr. do Piaí, 5829-5763 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.962839,-43.7065419]} icon={camera}><Popup><span className='title-content'>Rua Piraí do Sul, 321 - Bloco 2 - FIXA -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003408' alt='Rua Piraí do Sul, 321 - Bloco 2 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.969029,-43.703583]} icon={camera}><Popup><span className='title-content'>Estr. do Piaí, 6110 - FIXA -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003409' alt='Estr. do Piaí, 6110 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9781088,-43.6805384]} icon={camera}><Popup><span className='title-content'>Estr. São Tarcísio, 1874 - FIXA -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003410' alt='Estr. São Tarcísio, 1874 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984509,-43.506172]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 25.976 - FIXA -  Zona Oeste -  Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003411' alt='Estr. dos Bandeirantes, 25.976 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98451517,-43.50599765]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 25.976 - FIXA -  Zona Oeste -  Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003412' alt='Estr. dos Bandeirantes, 25.976 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.983798,-43.506167]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 25976 - FIXA -  Zona Oeste -  Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003413' alt='Estr. dos Bandeirantes, 25976 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98379800,-43.50607580]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 25976 - FIXA -  Zona Oeste -  Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003414' alt='Estr. dos Bandeirantes, 25976 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.981787,-43.506265]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 26325 - FIXA -  Zona Oeste -  Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003415' alt='Estr. dos Bandeirantes, 26325 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98179502,-43.50613491]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 26325 - FIXA -  Zona Oeste -  Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003416' alt='Estr. dos Bandeirantes, 26325 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.968308,-43.706893]} icon={camera}><Popup><span className='title-content'>Estr. de Sepetiba, 200 - FIXA -  Zona Oeste -  Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003417' alt='Estr. de Sepetiba, 200 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9706555,-43.7089066]} icon={camera}><Popup><span className='title-content'>Estr. de Sepetiba, 37 - FIXA -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003418' alt='Estr. de Sepetiba, 37 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.971338,-43.709675]} icon={camera}><Popup><span className='title-content'>Praia de Sepetiba, 1886 - FIXA -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003419' alt='Praia de Sepetiba, 1886 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.984334,-43.505867]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 25997 -  Zona Oeste -  Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003420' alt='Estr. dos Bandeirantes, 25997' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9777391,-43.6745128]} icon={camera}><Popup><span className='title-content'>Estr. do Piaí, 2617 -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003421' alt='Estr. do Piaí, 2617' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9787348,-43.6697012]} icon={camera}><Popup><span className='title-content'>Estr. do Piaí, 2289-2287 - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003422' alt='Estr. do Piaí, 2289-2287 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.986552,-43.489610]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes x Estr. do Rio Morto - FIXA -  Zona Oeste -  Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003423' alt='Estr. dos Bandeirantes x Estr. do Rio Morto - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.986421,-43.489690]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 22667 - FIXA -  Zona Oeste -  Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003424' alt='Estr. dos Bandeirantes, 22667 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.978412,-43.492566]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes x R. Manhuaçu - FIXA -  Zona Oeste -  Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003425' alt='Estr. dos Bandeirantes x R. Manhuaçu - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.978191,-43.492693]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes x R. Manhuaçu - FIXA -  Zona Oeste -  Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003426' alt='Estr. dos Bandeirantes x R. Manhuaçu - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.976666,-43.493969]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 24131 - FIXA -  Zona Oeste -  Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003427' alt='Estr. dos Bandeirantes, 24131 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.976492,-43.494036]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 24131 - FIXA -  Zona Oeste -  Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003428' alt='Estr. dos Bandeirantes, 24131 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.976424,-43.494349]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes x Estrada do Pacuí -  Zona Oeste -  Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003429' alt='Estr. dos Bandeirantes x Estrada do Pacuí' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9810774592,-43.6654974975]} icon={camera}><Popup><span className='title-content'>Estrada Piai, 19375 - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003430' alt='Estrada Piai, 19375 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9830193982,-43.6619090999]} icon={camera}><Popup><span className='title-content'>Estrada Piai. Próx a 43º DP - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003431' alt='Estrada Piai. Próx a 43º DP - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.982885,-43.668616]} icon={camera}><Popup><span className='title-content'>Av Nelson Moura Brasil x R. Breno Acioli -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003432' alt='Av Nelson Moura Brasil x R. Breno Acioli' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.979823,-43.505870]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 7416 - FIXA -  Zona Oeste -  Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003433' alt='Estr. dos Bandeirantes, 7416 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.980108,-43.505900]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 7416 - FIXA -  Zona Oeste -  Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003434' alt='Estr. dos Bandeirantes, 7416 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.987507,-43.662593]} icon={camera}><Popup><span className='title-content'>Av Nelson Moura Brasil x R. União Paulista -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003435' alt='Av Nelson Moura Brasil x R. União Paulista' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9846648202,-43.6585697321]} icon={camera}><Popup><span className='title-content'>Estr. do Piaí, 736 - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003436' alt='Estr. do Piaí, 736 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.80473162230180,-43.208052240336600]} icon={camera}><Popup><span className='title-content'>R. República Árabe da Síria, 2716 -  Zona Norte - Portuguesa</span><img src='http://187.111.99.18:9004/?CODE=003437' alt='R. República Árabe da Síria, 2716' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.804802,-43.206650]} icon={camera}><Popup><span className='title-content'>R. República Árabe da Síria, 111 -  Zona Norte - Portuguesa</span><img src='http://187.111.99.18:9004/?CODE=003438' alt='R. República Árabe da Síria, 111' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97512817,-43.70628622]} icon={camera}><Popup><span className='title-content'>Praia de Sepetiba x R. Dr. Ari Chargas - FIXA -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003439' alt='Praia de Sepetiba x R. Dr. Ari Chargas - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.977725,-43.704465]} icon={camera}><Popup><span className='title-content'>Praia de Sepetiba x R. Eng. Corrêa Nunes - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003440' alt='Praia de Sepetiba x R. Eng. Corrêa Nunes - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98098162,-43.70270501]} icon={camera}><Popup><span className='title-content'>Praia de Sepetiba, 598 - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003441' alt='Praia de Sepetiba, 598 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98590131,-43.69996460]} icon={camera}><Popup><span className='title-content'>Praia Recôncavo, 65 - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003442' alt='Praia Recôncavo, 65 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.976581,-43.693339]} icon={camera}><Popup><span className='title-content'>R. Trotadores Frederico, 3 - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003443' alt='R. Trotadores Frederico, 3 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.914062,-43.251770]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003444' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913644587888800,-43.25179475826640]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003445' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913433,-43.251867]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003446' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91305570472450,-43.25192761838530]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003447' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91269358771810,-43.251971134918200]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003448' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.973309301,-43.703098295]} icon={camera}><Popup><span className='title-content'>R. Dr. Ari Chagas, 370 -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003449' alt='R. Dr. Ari Chagas, 370' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.988824,-43.438931]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 11864-11912  - FIXA -  Zona Oeste - Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003450' alt='Estr. dos Bandeirantes, 11864-11912  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.988924,-43.438931]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 11864-11912  - FIXA -  Zona Oeste - Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003451' alt='Estr. dos Bandeirantes, 11864-11912  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.989230,-43.436909]} icon={camera}><Popup><span className='title-content'>Estrada Dos Bandeirantes, 11632 - FIXA -  Zona Oeste - Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003452' alt='Estrada Dos Bandeirantes, 11632 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.989330,-43.436909]} icon={camera}><Popup><span className='title-content'>Estrada Dos Bandeirantes, 11632 - FIXA -  Zona Oeste - Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003453' alt='Estrada Dos Bandeirantes, 11632 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.989126,-43.435108]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 11460-11630 - FIXA -  Zona Oeste - Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003454' alt='Estr. dos Bandeirantes, 11460-11630 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.989226,-43.435108]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 11460-11630 - FIXA -  Zona Oeste - Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003455' alt='Estr. dos Bandeirantes, 11460-11630 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.988072,-43.433910]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 11.216- FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003456' alt='Estr. dos Bandeirantes, 11.216- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.988172,-43.433910]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 11.216- FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003457' alt='Estr. dos Bandeirantes, 11.216- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.986507,-43.432039]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 11059 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003458' alt='Estr. dos Bandeirantes, 11059 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.986607,-43.432039]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 11059 - FIXA -  Zona Oeste - Vargem Pequena</span><img src='http://187.111.99.18:9004/?CODE=003459' alt='Estr. dos Bandeirantes, 11059 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.985115,-43.430104]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 10915-10639 - FIXA -  Zona Oeste - Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003460' alt='Estr. dos Bandeirantes, 10915-10639 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.985215,-43.430104]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 10915-10639 - FIXA -  Zona Oeste - Vargem Grande</span><img src='http://187.111.99.18:9004/?CODE=003461' alt='Estr. dos Bandeirantes, 10915-10639 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9757207,-43.7002148]} icon={camera}><Popup><span className='title-content'>Av. Santa Ursulina, 471 -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003462' alt='Av. Santa Ursulina, 471' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9852003864,-43.6946973778]} icon={camera}><Popup><span className='title-content'>Praia Recôncavo, 676 -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003463' alt='Praia Recôncavo, 676' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98515141,-43.69731581]} icon={camera}><Popup><span className='title-content'>Praia Recôncavo, 142 - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003464' alt='Praia Recôncavo, 142 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9874434697,-43.6921003727]} icon={camera}><Popup><span className='title-content'>Praia Recôncavo, 330 - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003465' alt='Praia Recôncavo, 330 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.98916044,-43.69078225]} icon={camera}><Popup><span className='title-content'>R. da Madeira, 200 - FIXA -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003466' alt='R. da Madeira, 200 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9896353,-43.6891691]} icon={camera}><Popup><span className='title-content'>Praia Recôncavo, 74 - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003467' alt='Praia Recôncavo, 74 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9881976919,-43.6890110135]} icon={camera}><Popup><span className='title-content'>Praia do Cardo, 922 - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003468' alt='Praia do Cardo, 922 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9871398296,-43.6882957913]} icon={camera}><Popup><span className='title-content'>Praia do Cardo, 746 - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003469' alt='Praia do Cardo, 746 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9854782521,-43.6866919531]} icon={camera}><Popup><span className='title-content'>Praia do Cardo, 898  - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003470' alt='Praia do Cardo, 898  - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9845425711,-43.6845908858]} icon={camera}><Popup><span className='title-content'>Praia do Cardo, 8  - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003471' alt='Praia do Cardo, 8  - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9814331973,-43.685415874]} icon={camera}><Popup><span className='title-content'>Estr. São Tarcísio, 1296 - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003472' alt='Estr. São Tarcísio, 1296 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9795087846,-43.6896902687]} icon={camera}><Popup><span className='title-content'>Estr. São Tarcísio, 786 - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003473' alt='Estr. São Tarcísio, 786 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9816210442,-43.6943508721]} icon={camera}><Popup><span className='title-content'>Rua Aristides Gouvéa, 1130 - Fixa -  Zona Norte - Sepetiba</span><img src='http://187.111.99.18:9004/?CODE=003474' alt='Rua Aristides Gouvéa, 1130 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.912152472407700,-43.252173580747904]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003475' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91180907056460,-43.252271493065800]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003476' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91137920161470,-43.25252361864770]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003477' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91104320497520,-43.25275697444270]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003478' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91059108333510,-43.25321026527480]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003479' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910153789595800,-43.25381109822170]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003480' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910269890012800,-43.25360187489340]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003481' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90970906652260,-43.25465061886070]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003482' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910000615578000,-43.25404178168880]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003483' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9095559019409,-43.25504493095090]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003484' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90930388,-43.25554917]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003485' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90903705037060,-43.25590322158990]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003486' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.90887892849030,-43.256139254393296]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003487' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908602195732300,-43.256418190262400]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003488' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908394654815100,-43.25670250415550]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003489' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908157466542500,-43.256949263229296]} icon={camera}><Popup><span className='title-content'>Túnel Noel Rosa sent. Del Castilho - FIXA -  Zona Norte - Vila Isabel</span><img src='http://187.111.99.18:9004/?CODE=003490' alt='Túnel Noel Rosa sent. Del Castilho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91959103,-43.68381847]} icon={camera}><Popup><span className='title-content'>R. do Cruzeiro, 10 - Fixa -  Zona Norte - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003491' alt='R. do Cruzeiro, 10 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91954902,-43.68450924]} icon={camera}><Popup><span className='title-content'>R. Teresa Cristina, 98 - Fixa -  Zona Norte - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003492' alt='R. Teresa Cristina, 98 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.919964,-43.684872]} icon={camera}><Popup><span className='title-content'>R. Pindaré, 375 - Fixa -  Zona Norte - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003493' alt='R. Pindaré, 375 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9182410012,-43.6848680344]} icon={camera}><Popup><span className='title-content'>R. Teresa Cristina, 62 -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003494' alt='R. Teresa Cristina, 62' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.810323,-43.208662]} icon={camera}><Popup><span className='title-content'>R. Marino da Costa, 725 - FIXA -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003495' alt='R. Marino da Costa, 725 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8119613,-43.2084123]} icon={camera}><Popup><span className='title-content'>Rua Cambaúba, 875- FIXA -  Zona Norte - Jardim Guanabara</span><img src='http://187.111.99.18:9004/?CODE=003496' alt='Rua Cambaúba, 875- FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.921713,-43.689872]} icon={camera}><Popup><span className='title-content'>R. Arnaldo Bastos, 108 - Fixa -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003497' alt='R. Arnaldo Bastos, 108 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9193040598,-43.6884241535]} icon={camera}><Popup><span className='title-content'>R. Álvaro Alberto, 601 - Fixa -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003498' alt='R. Álvaro Alberto, 601 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.925990,-43.690240]} icon={camera}><Popup><span className='title-content'>Av. Isabel, 362 - Fixa -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003499' alt='Av. Isabel, 362 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9246499418,-43.6950001039]} icon={camera}><Popup><span className='title-content'>R. São Tomé, 39 - Fixa -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003500' alt='R. São Tomé, 39 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.973995,-43.414602]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 8484 - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003501' alt='Estr. dos Bandeirantes, 8484 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.974186,-43.414674]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 8484 - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003502' alt='Estr. dos Bandeirantes, 8484 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.975027,-43.415011]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes x R. Pedro Calmon - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003503' alt='Estr. dos Bandeirantes x R. Pedro Calmon - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.975183,-43.415097]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes x R. Pedro Calmon - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003504' alt='Estr. dos Bandeirantes x R. Pedro Calmon - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.977020,-43.416811]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 8614 - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003505' alt='Estr. dos Bandeirantes, 8614 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.977121,-43.416883]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 8614 - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003506' alt='Estr. dos Bandeirantes, 8614 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.979051,-43.419163]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 275  - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003507' alt='Estr. dos Bandeirantes, 275  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.979023,-43.419076]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 275  - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003508' alt='Estr. dos Bandeirantes, 275  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.980016,-43.422012]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 9399-9133 - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003509' alt='Estr. dos Bandeirantes, 9399-9133 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.980000,-43.421971]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 9399-9133 - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003510' alt='Estr. dos Bandeirantes, 9399-9133 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.981280,-43.424169]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 9799-9753 - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003511' alt='Estr. dos Bandeirantes, 9799-9753 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.981302,-43.424190]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 9799-9753 - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003512' alt='Estr. dos Bandeirantes, 9799-9753 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.982836,-43.424606]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 9860-9890  - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003513' alt='Estr. dos Bandeirantes, 9860-9890  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.982888,-43.424616]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 9860-9890  - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003514' alt='Estr. dos Bandeirantes, 9860-9890  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.985001,-43.426804]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 10567-10561  - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003515' alt='Estr. dos Bandeirantes, 10567-10561  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.985021,-43.426894]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 10567-10561  - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003516' alt='Estr. dos Bandeirantes, 10567-10561  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.971562,-43.413988]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 8462  - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003517' alt='Estr. dos Bandeirantes, 8462  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.971618,-43.413996]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 8462  - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003518' alt='Estr. dos Bandeirantes, 8462  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9274239473,-43.690662142]} icon={camera}><Popup><span className='title-content'>Av. Areia Branca, 41 - Fixa -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003519' alt='Av. Areia Branca, 41 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9278691289,-43.6869845647]} icon={camera}><Popup><span className='title-content'>R. Primeira, 100-184 - Fixa -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003520' alt='R. Primeira, 100-184 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.922866833632300,-43.69189244164410]} icon={camera}><Popup><span className='title-content'>R. do Matadouro, 43 - Fixa -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003521' alt='R. do Matadouro, 43 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93062060,-43.68917754]} icon={camera}><Popup><span className='title-content'>Rua Auristela, 1 - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003522' alt='Rua Auristela, 1 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93499901,-43.68857311]} icon={camera}><Popup><span className='title-content'>Rua Auristela, 485 -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003523' alt='Rua Auristela, 485' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93783258,-43.68685623]} icon={camera}><Popup><span className='title-content'>Av. Eng. Gastão Rangel x Av. Areia Branca -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003524' alt='Av. Eng. Gastão Rangel x Av. Areia Branca' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.93583821,-43.68497660]} icon={camera}><Popup><span className='title-content'>Av. Eng. Gastão Rangel, 1206 -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003525' alt='Av. Eng. Gastão Rangel, 1206' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.81891708,-43.18464586]} icon={camera}><Popup><span className='title-content'>Estr. do Rio Jequiá, 1296 - Fixa -  Zona Norte - Cacuia</span><img src='http://187.111.99.18:9004/?CODE=003526' alt='Estr. do Rio Jequiá, 1296 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8194359533,-43.182713882]} icon={camera}><Popup><span className='title-content'>Estr. do Rio Jequiá, 1000 - Fixa -  Zona Norte - Cacuia</span><img src='http://187.111.99.18:9004/?CODE=003527' alt='Estr. do Rio Jequiá, 1000 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9333373062,-43.6829235106]} icon={camera}><Popup><span className='title-content'>Av. Eng. Gastão Rangel, 846 - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003528' alt='Av. Eng. Gastão Rangel, 846 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9309841467,-43.6809411989]} icon={camera}><Popup><span className='title-content'>Av. Eng. Gastão Rangel, 509 - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003529' alt='Av. Eng. Gastão Rangel, 509 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9287765962,-43.6791188068]} icon={camera}><Popup><span className='title-content'>Av. Eng. Gastão Rangel, 217 - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003530' alt='Av. Eng. Gastão Rangel, 217 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.820521,-43.179965]} icon={camera}><Popup><span className='title-content'>Estr. do Rio Jequiá - Fixa -  Zona Norte - Cacuia</span><img src='http://187.111.99.18:9004/?CODE=003531' alt='Estr. do Rio Jequiá - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8237324173,-43.1751094309]} icon={camera}><Popup><span className='title-content'>Estr. do Rio Jequiá, 518 - Fixa -  Zona Norte - Cacuia</span><img src='http://187.111.99.18:9004/?CODE=003532' alt='Estr. do Rio Jequiá, 518 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8201075335,-43.1784210393]} icon={camera}><Popup><span className='title-content'>Estr. do Rio Jequiá, 501 - Fixa -  Zona Norte - Cacuia</span><img src='http://187.111.99.18:9004/?CODE=003533' alt='Estr. do Rio Jequiá, 501 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.825413494883600,-43.17240039889490]} icon={camera}><Popup><span className='title-content'>Praia do Jequiá, 3- Fixa -  Zona Norte - Cacuia</span><img src='http://187.111.99.18:9004/?CODE=003534' alt='Praia do Jequiá, 3- Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9404657099,-43.6852467943]} icon={camera}><Popup><span className='title-content'>Av. Areia Branca, 1686 - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003535' alt='Av. Areia Branca, 1686 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9434891397,-43.6830648618]} icon={camera}><Popup><span className='title-content'>Av. Areia Branca x  R. Jose de Menezes - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003536' alt='Av. Areia Branca x  R. Jose de Menezes - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.824728,-43.169422]} icon={camera}><Popup><span className='title-content'>R. Maldonado, 297 - Fixa -  Zona Norte - Cacuia</span><img src='http://187.111.99.18:9004/?CODE=003537' alt='R. Maldonado, 297 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.82051363,-43.17970018]} icon={camera}><Popup><span className='title-content'>Estr. do Rio Jequiá, 518 -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003538' alt='Estr. do Rio Jequiá, 518' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.82129583,-43.17415738]} icon={camera}><Popup><span className='title-content'>Praia do Zumbi, 25 - Fixa -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003539' alt='Praia do Zumbi, 25 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.82544819,-43.17188350]} icon={camera}><Popup><span className='title-content'>R. Maldonado, 46 - Ribeira -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003540' alt='R. Maldonado, 46 - Ribeira' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91532948,-43.68615216]} icon={camera}><Popup><span className='title-content'>R. do Prado, 3 -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003541' alt='R. do Prado, 3' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9161755626,-43.6874047847]} icon={camera}><Popup><span className='title-content'>R. Martim Francisco, 63 - Fixa -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003542' alt='R. Martim Francisco, 63 - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.9167356278,-43.6883061595]} icon={camera}><Popup><span className='title-content'>R. Martim Francisco x R. Campeiro Mor - Fixa -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003543' alt='R. Martim Francisco x R. Campeiro Mor - Fixa' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.82126943,-43.17330718]} icon={camera}><Popup><span className='title-content'>Praia do Zumbi, 5 - Zumbi -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003544' alt='Praia do Zumbi, 5 - Zumbi' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.81992481,-43.17766444]} icon={camera}><Popup><span className='title-content'>R. Formosa do Zumbí, 183 -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003545' alt='R. Formosa do Zumbí, 183' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.825380,-43.169337]} icon={camera}><Popup><span className='title-content'>Rua Fernandes da Fonseca - Ribeira 7 -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003546' alt='Rua Fernandes da Fonseca - Ribeira 7' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.819184,-43.182904]} icon={camera}><Popup><span className='title-content'>Estr. do Rio Jequiá, 1118 -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003547' alt='Estr. do Rio Jequiá, 1118' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.902897,-43.174804]} icon={camera}><Popup><span className='title-content'>Largo do Paço, 34 - Centro  - FIXA -  Centro da Cidade - Praca XI</span><img src='http://187.111.99.18:9004/?CODE=003548' alt='Largo do Paço, 34 - Centro  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.902244,-43.172817]} icon={camera}><Popup><span className='title-content'>Largo do Paço - Centro  - FIXA -  Centro da Cidade - Praca XI</span><img src='http://187.111.99.18:9004/?CODE=003549' alt='Largo do Paço - Centro  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.818661,-43.184914]} icon={camera}><Popup><span className='title-content'>Estr. do Rio Jequiá, 582 - Zumbi -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003550' alt='Estr. do Rio Jequiá, 582 - Zumbi' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.910969,-43.226454]} icon={camera}><Popup><span className='title-content'>Maracanã - Portaria A -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003551' alt='Maracanã - Portaria A' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.912676,-43.233085]} icon={camera}><Popup><span className='title-content'>Maracanã - Portaria C -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003552' alt='Maracanã - Portaria C' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91239298,-43.23176564]} icon={camera}><Popup><span className='title-content'>Maracanã - Portaria E -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003553' alt='Maracanã - Portaria E' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91101505,-43.23089427]} icon={camera}><Popup><span className='title-content'>Maracanã - Portaria F -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003554' alt='Maracanã - Portaria F' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.913352,-43.227934]} icon={camera}><Popup><span className='title-content'>Maracanã - Estátua do  Belini I -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003555' alt='Maracanã - Estátua do  Belini I' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91354,-43.22810]} icon={camera}><Popup><span className='title-content'>Maracanã - Estátua do  Belini II -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003556' alt='Maracanã - Estátua do  Belini II' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91088,-43.22818]} icon={camera}><Popup><span className='title-content'>Maracanã - Acesso Norte E -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003557' alt='Maracanã - Acesso Norte E' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.91311,-43.23130]} icon={camera}><Popup><span className='title-content'>Maracanã - Acesso Sul B -  Zona Norte - Tijuca</span><img src='http://187.111.99.18:9004/?CODE=003558' alt='Maracanã - Acesso Sul B' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.810752,-43.186777]} icon={camera}><Popup><span className='title-content'>Estr. do Cacuia 458  - FIXA -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003559' alt='Estr. do Cacuia 458  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.916713,-43.688197]} icon={camera}><Popup><span className='title-content'>R. Campeiro Mor  545 - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003560' alt='R. Campeiro Mor  545 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.916018,-43.687108]} icon={camera}><Popup><span className='title-content'>R. Martim Francisco, 61 - FIXA -  Zona Oeste - Santa Cruz</span><img src='http://187.111.99.18:9004/?CODE=003561' alt='R. Martim Francisco, 61 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.8114537372311,-43.183909096889400]} icon={camera}><Popup><span className='title-content'>Estr. Visc. de Lamare, 657 -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003562' alt='Estr. Visc. de Lamare, 657' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.811116,-43.184515]} icon={camera}><Popup><span className='title-content'>Estr. da Cacuia, 745 - FIXA -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003563' alt='Estr. da Cacuia, 745 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.908099,-43.172981]} icon={camera}><Popup><span className='title-content'>Av. Presidente Antonio Carlos X R. Araújo Porto Alegre -  Centro da Cidade - Castelo</span><img src='http://187.111.99.18:9004/?CODE=003564' alt='Av. Presidente Antonio Carlos X R. Araújo Porto Alegre' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.903397,-43.175241]} icon={camera}><Popup><span className='title-content'>R. Primeiro de Março x R. Sete de Setembro -  Centro da Cidade - Praca XI</span><img src='http://187.111.99.18:9004/?CODE=003565' alt='R. Primeiro de Março x R. Sete de Setembro' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.80820096,-43.18255613]} icon={camera}><Popup><span className='title-content'>Estr. da Cacuia x R. Moravia - FIXA -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003566' alt='Estr. da Cacuia x R. Moravia - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.80797853,-43.18287491]} icon={camera}><Popup><span className='title-content'>Rua Moravia, 38 -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003567' alt='Rua Moravia, 38' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.80545516,-43.18115732]} icon={camera}><Popup><span className='title-content'>Praia da Olaria x R. Mareante - FIXA -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003568' alt='Praia da Olaria x R. Mareante - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.80302183,-43.18173504]} icon={camera}><Popup><span className='title-content'>Av. Paranapuam, 2326 - FIXA -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003569' alt='Av. Paranapuam, 2326 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.80368271,-43.17902650]} icon={camera}><Popup><span className='title-content'>Parque Poeta Manuel Bandeira, S/N - FIXA -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003570' alt='Parque Poeta Manuel Bandeira, S/N - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.80445940,-43.17912073]} icon={camera}><Popup><span className='title-content'>Praia da Bandeira, 726-728 -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003571' alt='Praia da Bandeira, 726-728' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.80317637,-43.18164117]} icon={camera}><Popup><span className='title-content'>Av. Paranapuam, 2326 -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003572' alt='Av. Paranapuam, 2326' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.805400,-43.181298]} icon={camera}><Popup><span className='title-content'>Rua Mareante - (Cocotá) -  Zona Norte - Ilha do Governador</span><img src='http://187.111.99.18:9004/?CODE=003573' alt='Rua Mareante - (Cocotá)' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.854195,-43.312727]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 5000 - FIXA -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=003575' alt='Av. Pastor Martin Luther King Júnior, 5000 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.853229,-43.313962]} icon={camera}><Popup><span className='title-content'>Av. Vicente de Carvalho, 1052 -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=003576' alt='Av. Vicente de Carvalho, 1052' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96058,-43.39245]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 5450 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003577' alt='Estr. dos Bandeirantes, 5450 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96058,-43.39245]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 5450 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003578' alt='Estr. dos Bandeirantes, 5450 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96104,-43.39431]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 5832 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003579' alt='Estr. dos Bandeirantes, 5832 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96104,-43.39431]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 5832 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003580' alt='Estr. dos Bandeirantes, 5832 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96137,-43.39573]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 5900 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003581' alt='Estr. dos Bandeirantes, 5900 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96137,-43.39573]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 5900 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003582' alt='Estr. dos Bandeirantes, 5900 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96161,-43.39670]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 5920 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003583' alt='Estr. dos Bandeirantes, 5920 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96161,-43.39670]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 5920 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003584' alt='Estr. dos Bandeirantes, 5920 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96466,-43.40665]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 6994 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003585' alt='Estr. dos Bandeirantes, 6994 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96466,-43.40665]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 6994 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003586' alt='Estr. dos Bandeirantes, 6994 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96574,-43.41043]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 7276 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003587' alt='Estr. dos Bandeirantes, 7276 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96574,-43.41043]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 7276 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003588' alt='Estr. dos Bandeirantes, 7276 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96632,-43.41186]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 7590 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003589' alt='Estr. dos Bandeirantes, 7590 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96632,-43.41186]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 7590 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003590' alt='Estr. dos Bandeirantes, 7590 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96753,-43.41312]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 7700 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003591' alt='Estr. dos Bandeirantes, 7700 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.96753,-43.41312]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 7700 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003592' alt='Estr. dos Bandeirantes, 7700 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97820,-43.41774]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 8626 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003593' alt='Estr. dos Bandeirantes, 8626 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.97820,-43.41774]} icon={camera}><Popup><span className='title-content'>Estr. dos Bandeirantes, 8626 - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003594' alt='Estr. dos Bandeirantes, 8626 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.851251,-43.316807]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 6388 - FIXA -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=003595' alt='Av. Pastor Martin Luther King Júnior, 6388 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.851014,-43.317227]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 6400 -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=003596' alt='Av. Pastor Martin Luther King Júnior, 6400' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.850445,-43.318389]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior x Estr. Cel. Vieira - FIXA -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=003597' alt='Av. Pastor Martin Luther King Júnior x Estr. Cel. Vieira - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.850609,-43.318050]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior x Estr. Cel. Vieira -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=003598' alt='Av. Pastor Martin Luther King Júnior x Estr. Cel. Vieira' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.851316,-43.317446]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 6407 - FIXA -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=003599' alt='Av. Pastor Martin Luther King Júnior, 6407 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.851130,-43.317752]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior x R. Luísa de Carvalho -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=003600' alt='Av. Pastor Martin Luther King Júnior x R. Luísa de Carvalho' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.852564,-43.315701]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior x R. Eng. Mario Carvalho - FIXA -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=003601' alt='Av. Pastor Martin Luther King Júnior x R. Eng. Mario Carvalho - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.854227,-43.313313]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior x R. Dona Maria Enfermeira - FIXA -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=003602' alt='Av. Pastor Martin Luther King Júnior x R. Dona Maria Enfermeira - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.854433,-43.312986]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior x R. Dona Maria Enfermeira -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=003603' alt='Av. Pastor Martin Luther King Júnior x R. Dona Maria Enfermeira' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.937090,-43.335415]} icon={camera}><Popup><span className='title-content'>Estr. dos Três Rios x Rua Geminiano Góis - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003604' alt='Estr. dos Três Rios x Rua Geminiano Góis - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.937493,-43.337169]} icon={camera}><Popup><span className='title-content'>Estr. dos Três Rios x R. Cmte. Rubens Silva - FIXA -  Zona Oeste - Jacarepagua</span><img src='http://187.111.99.18:9004/?CODE=003605' alt='Estr. dos Três Rios x R. Cmte. Rubens Silva - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.855600,-43.310839]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 401 - FIXA -  Zona Norte - Vicente de Carvalho</span><img src='http://187.111.99.18:9004/?CODE=003606' alt='Av. Pastor Martin Luther King Júnior, 401 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.856417,-43.310571]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003607' alt='Av. Pastor Martin Luther King Júnior - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.856654,-43.310407]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003608' alt='Av. Pastor Martin Luther King Júnior' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.857438,-43.308967]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003609' alt='Av. Pastor Martin Luther King Júnior - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.857663,-43.308639]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 5282 -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003610' alt='Av. Pastor Martin Luther King Júnior, 5282' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.937015,-43.335859]} icon={camera}><Popup><span className='title-content'>Estr. dos Três Rios, 961-875 - FIXA -  Zona Oeste - Freguesia de Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003611' alt='Estr. dos Três Rios, 961-875 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.936807,-43.334757]} icon={camera}><Popup><span className='title-content'>Estr. dos Três Rios, 920-998 - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003612' alt='Estr. dos Três Rios, 920-998 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.937295,-43.336612]} icon={camera}><Popup><span className='title-content'>Estr. dos Três Rios, 873-697 - FIXA -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003613' alt='Estr. dos Três Rios, 873-697 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.863281,-43.307367]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 4589 - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003614' alt='Av. Pastor Martin Luther King Júnior, 4589 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.859439,-43.307871]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 5011 - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003615' alt='Av. Pastor Martin Luther King Júnior, 5011 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.863281,-43.307367]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior x Rua Itapuca - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003616' alt='Av. Pastor Martin Luther King Júnior x Rua Itapuca - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.864895,-43.307130]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior x Rua Arcádia -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003617' alt='Av. Pastor Martin Luther King Júnior x Rua Arcádia' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.866589,-43.306060]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 4221 - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003618' alt='Av. Pastor Martin Luther King Júnior, 4221 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86671,-43.30226]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 3839 - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003619' alt='Av. Pastor Martin Luther King Júnior, 3839 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86687,-43.30165]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 3779 -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003620' alt='Av. Pastor Martin Luther King Júnior, 3779' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86798,-43.29757]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 968 -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003621' alt='Av. Pastor Martin Luther King Júnior, 968' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86794,-43.29766]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 3401 - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003622' alt='Av. Pastor Martin Luther King Júnior, 3401 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86884,-43.29477]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 3053 - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003623' alt='Av. Pastor Martin Luther King Júnior, 3053 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86899,-43.29428]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 2983 - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003624' alt='Av. Pastor Martin Luther King Júnior, 2983 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.87001,-43.29129]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 2651 - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003625' alt='Av. Pastor Martin Luther King Júnior, 2651 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.87017,-43.29098]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 2617 - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003626' alt='Av. Pastor Martin Luther King Júnior, 2617 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.863936,-43.306273]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003627' alt='Av. Pastor Martin Luther King Júnior - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.866739,-43.305709]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003628' alt='Av. Pastor Martin Luther King Júnior - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.859770,-43.307279]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003629' alt='Av. Pastor Martin Luther King Júnior - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.859275,-43.307540]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003630' alt='Av. Pastor Martin Luther King Júnior - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.873178,-43.287599]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 2113 - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003631' alt='Av. Pastor Martin Luther King Júnior, 2113 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.873479,-43.287288]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 2091 - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003632' alt='Av. Pastor Martin Luther King Júnior, 2091 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.874549,-43.286168]} icon={camera}><Popup><span className='title-content'>Praça Alm. José Joaquim Inácio, 1899 - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003633' alt='Praça Alm. José Joaquim Inácio, 1899 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.874543,-43.286187]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 1915 -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003634' alt='Av. Pastor Martin Luther King Júnior, 1915' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.875120,-43.282725]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 426 - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003635' alt='Av. Pastor Martin Luther King Júnior, 426 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.875123,-43.281622]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 126 - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003636' alt='Av. Pastor Martin Luther King Júnior, 126 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.867306,-43.298537]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 3416 -  Zona Norte - Engenho da Rainha</span><img src='http://187.111.99.18:9004/?CODE=003637' alt='Av. Pastor Martin Luther King Júnior, 3416' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.867112,-43.299277]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 3480 - FIXA -  Zona Norte - Engenho da Rainha</span><img src='http://187.111.99.18:9004/?CODE=003638' alt='Av. Pastor Martin Luther King Júnior, 3480 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.868055,-43.295751]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 3844 -  Zona Norte - Engenho da Rainha</span><img src='http://187.111.99.18:9004/?CODE=003639' alt='Av. Pastor Martin Luther King Júnior, 3844' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86827,-43.29494]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 3838 - FIXA -  Zona Norte - Engenho da Rainha</span><img src='http://187.111.99.18:9004/?CODE=003640' alt='Av. Pastor Martin Luther King Júnior, 3838 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.869520,-43.291093]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 3700 - FIXA -  Zona Norte - Engenho da Rainha</span><img src='http://187.111.99.18:9004/?CODE=003641' alt='Av. Pastor Martin Luther King Júnior, 3700 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.870179,-43.289980]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 3525 - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003642' alt='Av. Pastor Martin Luther King Júnior, 3525 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.870668327347200,-43.289196794967600]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 3525 - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003643' alt='Av. Pastor Martin Luther King Júnior, 3525 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.873752,-43.286157]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 1 -  Zona Norte - Del Castilho</span><img src='http://187.111.99.18:9004/?CODE=003644' alt='Av. Pastor Martin Luther King Júnior, 1' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86573,-43.30402]} icon={camera}><Popup><span className='title-content'>Estr. Velha da Pavuna, 4982 - FIXA -  Zona Norte - Engenho da Rainha</span><img src='http://187.111.99.18:9004/?CODE=003645' alt='Estr. Velha da Pavuna, 4982 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86566,-43.30442]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior 4138 - FIXA -  Zona Norte - Engenho da Rainha</span><img src='http://187.111.99.18:9004/?CODE=003646' alt='Av. Pastor Martin Luther King Júnior 4138 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.86566,-43.30442]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 7269 - FIXA -  Zona Norte - Iraja</span><img src='http://187.111.99.18:9004/?CODE=003647' alt='Av. Pastor Martin Luther King Júnior, 7269 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.84734,-43.32519]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 7337 - FIXA -  Zona Norte - Iraja</span><img src='http://187.111.99.18:9004/?CODE=003648' alt='Av. Pastor Martin Luther King Júnior, 7337 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.84806,-43.32370]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 7225 - FIXA -  Zona Norte - Iraja</span><img src='http://187.111.99.18:9004/?CODE=003649' alt='Av. Pastor Martin Luther King Júnior, 7225 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.84734,-43.32440]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 1020 -  Zona Norte - Iraja</span><img src='http://187.111.99.18:9004/?CODE=003650' alt='Av. Pastor Martin Luther King Júnior, 1020' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.84743,-43.32483]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 1000 -  Zona Norte - Iraja</span><img src='http://187.111.99.18:9004/?CODE=003651' alt='Av. Pastor Martin Luther King Júnior, 1000' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.84779,-43.32429]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 7249 -  Zona Norte - Iraja</span><img src='http://187.111.99.18:9004/?CODE=003652' alt='Av. Pastor Martin Luther King Júnior, 7249' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.874603,-43.281488]} icon={camera}><Popup><span className='title-content'>Avenida Automóvel Clube próximo ao 74 - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003653' alt='Avenida Automóvel Clube próximo ao 74 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.874771,-43.280598]} icon={camera}><Popup><span className='title-content'>Avenida Automóvel Clube próximo ao 70 - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003654' alt='Avenida Automóvel Clube próximo ao 70 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.846218,-43.327648]} icon={camera}><Popup><span className='title-content'>Avenida Automóvel Clube próximo ao 7632 - FIXA -  Zona Norte - Inhauma</span><img src='http://187.111.99.18:9004/?CODE=003655' alt='Avenida Automóvel Clube próximo ao 7632 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.843984,-43.330380]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 8000 -  Zona Norte - Iraja</span><img src='http://187.111.99.18:9004/?CODE=003656' alt='Av. Pastor Martin Luther King Júnior, 8000' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.843981,-43.330452]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 8046 - FIXA -  Zona Norte - Iraja</span><img src='http://187.111.99.18:9004/?CODE=003657' alt='Av. Pastor Martin Luther King Júnior, 8046 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.843672,-43.330706]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 8102 - FIXA -  Zona Norte - Iraja</span><img src='http://187.111.99.18:9004/?CODE=003658' alt='Av. Pastor Martin Luther King Júnior, 8102 - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.936349,-43.333114]} icon={camera}><Popup><span className='title-content'>Estr. dos Três Rios x Estr. do Bananal -  Zona Oeste - Jacarepaguá</span><img src='http://187.111.99.18:9004/?CODE=003659' alt='Estr. dos Três Rios x Estr. do Bananal' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.847653,-43.323607]} icon={camera}><Popup><span className='title-content'>Av. Pastor Martin Luther King Júnior, 7130  - FIXA -  Zona Norte - Iraja</span><img src='http://187.111.99.18:9004/?CODE=003660' alt='Av. Pastor Martin Luther King Júnior, 7130  - FIXA' width='100%' height='100%'></img></Popup></Marker>
<Marker position={[-22.842359,-43.334886]} icon={camera}><Popup><span className='title-content'>Estrada do Colegio 57  - FIXA -  Zona Norte - Iraja</span><img src='http://187.111.99.18:9004/?CODE=003661' alt='Estrada do Colegio 57  - FIXA' width='100%' height='100%'></img></Popup></Marker>
</>
:
       <div></div>}
</>

    )
  }

export const Home = () => {
  const moment = require('moment');
  
  const [eventosAbertos, setEventosAbertos] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartDataTable, setChartDataTable] = useState([]);
  const [chartDataPop, setChartDataPop] = useState([]);

  const [primario, setPrimario] = useState([]);
  const [secundario, setSecundario] = useState([]);
  const [countdown, setCountdown] = useState(300000)
  const [filtroaplicado, setFiltroAplicado] = useState([]);
  const [search, setSearch] = useState("TODOS");
  const [showcameras, setShowCameras] = useState("Não");
  const [estagio, setEstagio] = useState("Branco");
  const [tempoestagio, setTempoEstagio] = useState(" ");
  const [corestagio, setCorEstagio] = useState("#ffffff");
  const [mensagemestagio, setMensagemEstagio] = useState("");
  const [mensagem2estagio, setMensagem2Estagio] = useState("");

  
  

  const [status, setStatus] = useState({
    loading: false
  });

    function ObterCorLinha(prioridade) {
        if (prioridade === 'BAIXO' || prioridade === null) {
            return 'table-success';
        }
        if (prioridade === 'MEDIO') {
            return 'table-warning';
        }

        if (prioridade === 'ALTA') {
            return 'table-danger';
        }
    }

    function ObterCamera(props) {
      //if (props.includes('CAM ')) {return props;}

      const titulo = props.normalize('NFD').replace(/[\])}[{(]/g, '').toLowerCase();
      const dataPtBr = titulo.split(' ')
      const lista = dataPtBr.length
      // let id = food.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '').replace(/\./g, '').replace(/[\])}[{(]/g, '').toLowerCase() + '&&' + food; 
      
      
        if (dataPtBr.length >= (dataPtBr.length-1)) {
          const lista = dataPtBr.length-1
          const cam = parseInt(dataPtBr[lista])
          if(typeof(cam)){
            return cam
           }else{
            return '150'
           }
        }
    }
    
  function icone(props) {

    if (props === '') {return open;}
    if (props.includes('Operação policial')) {return operacaopolicial;}
    if (props === 'Afundamento de Pista / Buraco na via') {return buraconavia;}
    if (props.includes('Atropelamento')) {return acidenteleve;}
    if (props.includes('Obra em local público')) {return obrasnavia;}
    if (props.includes('Evento')) {return evento;}
    if (props.includes('Asfalto')) {return asfaltoliso;}
    if (props.includes('Acidente/enguiço sem vítima')) {return acidenteleve;}
    if (props === "Acidente com vítima(s)") {return acidenteleve;}
    if (props.includes('Manifestação em local público')) {return manifestacao;}
    if (props.includes('Deslizamento')) {return deslizamento;}
    if (props.includes('Incêndio no entorno de vias públicas')) {  return incendio;}
    if (props.includes('Incêndio em imóvel')) {return incendio;}
    if (props.includes('Incêndio em veículo(s)')) {return incendio;}
    if (props ==='Sinais de trânsito com mau funcionamento') {return falhanosemaforo;}
    if (props ==='Vazamento de água / esgoto') { return vazamentodeagua;}
    if (props ==='Galho sobre fiação') {return open;}
    if (props ==='Bueiro') {return bueiro;}
    if (props ==='Queda de árvore') {return quedadearvore;}

    

    
    

    return open;
  }

  



  const filtroPrimario = (data) => {

    const filteres = data.filter((valorAtual) => {
      return valorAtual.tipo.includes("PRIMARIO")
          })
      return filteres
   }

   const filtroSecundario = (data) => {

    const filteres = data.filter((valorAtual) => {
      return valorAtual.tipo.includes("SECUNDARIO")
          })
      return filteres
   }

  const loadData = (data) => {
      const values = _.groupBy(data, (value) => zonas(value.bairro));
      const result = _.map(values, (value, key) => [
          key,
          values[key].length
      ]);
      return [["Ocorrência", "Abertas"], ...result];
  }



  const loadDataPop = (data) => {
      const values = _.groupBy(data, (value) => pops(value.pop_id));
      const result = _.map(values, (value, key) => [
          key,
          values[key].length
      ]);
      return [["Ocorrência", "Abertas"], ...result];
  }
  
  

  const loadTableData = (data) => {
    const values = _.groupBy(data, (value) => zonas(value.bairro));
      const result = _.map(values, (value, key) => [
          key,
          values[key].length
      ]);
      return [["ZONA", "ABERTAS"], ...result];
  }

  
  const getAlertas = async () => {
    console.count("Reiniciou getAlertas   " +  moment(Date.now()).format('DD/MM/YYYY HH:mm:ss'));
    await axios({
        method: 'get',
        baseURL: 'https://aplicativo.cocr.com.br/estagio_api'
                        
      }).then(estagioatual => {
        
  try {
    setTempoEstagio(moment(estagioatual.data.inicio).format('DD/MM/YYYY HH:mm') + " " + "(" + moment(estagioatual.data.inicio).startOf('hour').fromNow() + "" + ")");
    setEstagio(estagioatual.data.estagio);
    setCorEstagio(estagioatual.data.cor);
    setMensagemEstagio(estagioatual.data.mensagem);
    setMensagem2Estagio(estagioatual.data.mensagem2);
  
} catch (error) {
  console.log(error)
  setTempoEstagio("Não conseguimos o acesso a api que retorna informações do Estágio Atual da Cidade");
  setEstagio("Branco");
  setCorEstagio("#ffffff")
  setMensagemEstagio("");
  setMensagem2Estagio("");

}


          

    }).catch(err => {
      console.log(err)
      setTempoEstagio("Não conseguimos o acesso a api que retorna informações do Estágio Atual da Cidade");
      setCorEstagio("#ffffff")
      setMensagemEstagio("");
  setMensagem2Estagio("");
    });
}
useEffect(() => {getAlertas();}, [])

  const getEventosAbertos = async () => {
    console.count("Reiniciou getEventosAbertos   " +  moment(Date.now()).format('DD/MM/YYYY HH:mm:ss'));
    await axios({
        method: 'get',
        baseURL: 'https://aplicativo.cocr.com.br/comando/ocorrencias_api/'
        
//        https://api.dados.rio/v2/adm_cor_comando/ocorrencias_abertas/
                        
    }).then(evento => {


        try {
          setEventosAbertos(evento);
          setChartData(loadData(evento));
          setChartData(loadData(evento));
          setChartDataTable(loadTableData(evento));
          setChartDataPop(loadDataPop(evento));
          setFiltroAplicado(evento);
          setPrimario(filtroPrimario(evento));
          setSecundario(filtroSecundario(evento));
          setSearch(favorito.tipo);

          if (evento.status > 200) {
            setCountdown(10001)
            console.count("status: " + evento.status + "Contador em 10001   " +  moment(Date.now()).format('DD/MM/YYYY HH:mm:ss'));
          } else {setCountdown(300000)
            console.count("status: " + evento.status + " Contador em 300000   " +  moment(Date.now()).format('DD/MM/YYYY HH:mm:ss'));
          }


        } catch (error) {

            setCountdown(10001)
            console.count("Contador em 10001 Erro catch   " +  moment(Date.now()).format('DD/MM/YYYY HH:mm:ss'));
        }

      setStatus({
        loading: true
    })


    }).catch(err => {
      setCountdown(10001)
      console.count("Erro catch then Contador em 10001    " +  moment(Date.now()).format('DD/MM/YYYY HH:mm:ss'));
        setStatus({
          loading: false
      })
    });
}
useEffect(() => {getEventosAbertos();}, [])

const getEventosJaFiltrados = async () => {
    

  if(search === "TODOS") {

      setFiltroAplicado(eventosAbertos);
      setChartData(loadData(eventosAbertos));
      setChartDataPop(loadDataPop(eventosAbertos));
      setFiltroAplicado(eventosAbertos);
  }


  if(search === "PRIMARIO") {

      setChartData(loadData(primario));
      setChartDataPop(loadDataPop(primario));
      setFiltroAplicado(primario);
  }

  if(search === "SECUNDARIO") {

      setChartData(loadData(secundario));
      setChartDataPop(loadDataPop(secundario));
      setFiltroAplicado(secundario);
  }
 
}
useEffect(() => {getEventosJaFiltrados();}, [search, filtroaplicado]);

  useEffect(() => {
    const timerId = setInterval(() => {
      setCountdown(prevState => prevState - 1)
      
      getEventosAbertos();
      getAlertas();
      
    }, countdown);
    return () => clearInterval(timerId)
  }, [countdown])

    return (
        <>
            <Navbar />
            <div className="content">

                

                <div className="wrapper">

            <div className="row"  style={{ background: '#ECEDF0'}}> 

                <div className="row" style={{ background: corestagio}}>
                <div className="top-content-adm">

                <div className="top-content-adm-left">
                {estagio === "Estágio 1" ? 
                  <>
                    <button className="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseExample${estagio.replace(/\s/g, '')}`} aria-expanded="false" aria-controls={`collapseExample${estagio.replace(/\s/g, '')}`} style={{ background: corestagio, border: corestagio}}>
                    <img className="estagio" src="/estagio1.png" alt="Estágio 1" />
                    </button>
                    <div className="collapse" id={`collapseExample${estagio.replace(/\s/g, '')}`}>
                      <div className="card card-body">
                      <spam><strong>{mensagemestagio}{mensagem2estagio}</strong></spam>  
                      <spam>A Cidade Entrou em Estágio de: <strong>{estagio}</strong> Na Data: <strong>{tempoestagio}</strong></spam>  
                      <span><strong>Quando:</strong> Não há ocorrências que provoquem alteração significativa no dia a dia do carioca. Não foram identificados fatores de risco de curto prazo que impactem a rotina da cidade</span>
                      <span><strong>Impacto:</strong> Sem ou com pouco impacto para a fluidez do trânsito e das operações de infraestrutura e logística da cidade.</span>
                      </div>
                    </div>
                  </>
                   : "" }

              {estagio === "Estágio 2" ? 
                  <>
                    <button className="btn" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseExample${estagio.replace(/\s/g, '')}`} aria-expanded="false" aria-controls={`collapseExample${estagio.replace(/\s/g, '')}`} style={{ background: corestagio, border: corestagio}}>
                    <img className="estagio" src="/estagio2.jpeg" alt="Estágio 2" />
                    </button>
                    <div className="collapse" id={`collapseExample${estagio.replace(/\s/g, '')}`}>
                      <div className="card card-body">
                      <spam><strong>{mensagemestagio}{mensagem2estagio}</strong></spam>  
                      <spam>A Cidade Entrou em Estágio de: <strong>{estagio}</strong> Na Data: <strong>{tempoestagio}</strong></spam>  
                      <span><strong>Quando:</strong>Risco de haver ocorrências de alto impacto na cidade, devido a um evento previsto ou a partir da análise de dados provenientes de especialistas. Há ocorrência com elevado potencial de agravamento.</span>
                      <span><strong>Impacto:</strong>Ainda não há impactos na rotina da cidade, mas os cidadãos devem se manter informados.</span>
                      </div>
                    </div>
                  </>
                   : "" }

                    {estagio === "Estágio 3" ?
                      <>
                        <button className="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseExample${estagio.replace(/\s/g, '')}`} aria-expanded="false" aria-controls={`collapseExample${estagio.replace(/\s/g, '')}`} style={{ background: corestagio, border: corestagio}}>
                          <img className="estagio" src="/estagio3.jpg" alt="Estágio 3" />
                        </button>
                        <div className="collapse" id={`collapseExample${estagio.replace(/\s/g, '')}`}>
                          <div className="card card-body">
                          <spam><strong>{mensagemestagio}{mensagem2estagio}</strong></spam>  
                          <spam>A Cidade Entrou em Estágio de: <strong>{estagio}</strong> Na Data: <strong>{tempoestagio}</strong></spam>  
                            <span><strong>Quando:</strong>Uma ou mais ocorrências estão impactando a cidade. Há certeza de que haverá ocorrência de alto impacto, no curto prazo.</span>
                            <span><strong>Impacto:</strong>Pelo menos uma região da cidade está impactada, causando reflexos relevantes na infraestrutura e logística urbana, e afetando diretamente a rotina da população (ou de parte dela).</span>
                          </div>
                        </div>
                      </>
                      : ""}

                    {estagio === "Estágio 4" ?
                      <>
                        <button className="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseExample${estagio.replace(/\s/g, '')}`} aria-expanded="false" aria-controls={`collapseExample${estagio.replace(/\s/g, '')}`} style={{ background: corestagio, border: corestagio}}>
                          <img className="estagio" src="/estagio4.png" alt="Estágio 4" />
                        </button>
                        <div className="collapse" id={`collapseExample${estagio.replace(/\s/g, '')}`}>
                          <div className="card card-body">
                          <spam><strong>{mensagemestagio}{mensagem2estagio}</strong></spam>  
                          <spam>A Cidade Entrou em Estágio de: <strong>{estagio}</strong> Na Data: <strong>{tempoestagio}</strong></spam>  
                            <span><strong>Quando:</strong>Uma ou mais ocorrências graves impactam a cidade ou há incidência simultânea de diversos problemas de médio e alto impacto em diferentes regiões da cidade. Os múltiplos danos e impactos causados começam a extrapolar a capacidade de resposta imediata das equipes da cidade.</span>
                            <span><strong>Impacto:</strong>Uma ou mais regiões estão impactadas, causando reflexos graves / importantes na infraestrutura e logística urbana, e afetando severamente a rotina da população (ou de parte dela).</span>
                          </div>
                        </div>
                      </>
                      : ""}

                    {estagio === "Estágio 5" ?
                      <>
                        <button className="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseExample${estagio.replace(/\s/g, '')}`} aria-expanded="false" aria-controls={`collapseExample${estagio.replace(/\s/g, '')}`} style={{ background: corestagio, border: corestagio}}>
                          <img className="estagio" src="/estagio5.png" alt="Estágio 5" />
                        </button>
                        <div className="collapse" id={`collapseExample${estagio.replace(/\s/g, '')}`}>
                          <div className="card card-body">
                          <spam><strong>{mensagemestagio}{mensagem2estagio}</strong></spam>  
                          <spam>A Cidade Entrou em Estágio de: <strong>{estagio}</strong> Na Data: <strong>{tempoestagio}</strong></spam>  
                            <span><strong>Quando:</strong>Uma ou mais ocorrências graves impactam a cidade ou há incidência simultânea de diversos problemas de médio e alto impacto em diferentes regiões da cidade. Os múltiplos danos e impactos causados extrapolam de forma relevante a capacidade de resposta imediata das equipes da cidade.</span>
                            <span><strong>Impacto:</strong>Uma ou mais regiões estão impactadas, causando reflexos graves / importantes na infraestrutura e logística urbana, e afetando severamente a rotina da população (ou de parte dela).</span>
                          </div>
                        </div>
                      </>
                      : ""}

                    {estagio === "Branco" ?
                      <>
                        <button className="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseExample${estagio.replace(/\s/g, '')}`} aria-expanded="false" aria-controls={`collapseExample${estagio.replace(/\s/g, '')}`} style={{ background: corestagio, border: corestagio}}>
                          <img className="estagio" src="/branco.png" alt="Branco" />
                        </button>
                        <div className="collapse" id={`collapseExample${estagio.replace(/\s/g, '')}`}>
                          <div className="card card-body">
                          <spam><strong>{mensagemestagio}{mensagem2estagio}</strong></spam>  
                            <spam><strong>{estagio} {tempoestagio}</strong></spam>
                          </div>
                        </div>
                      </>
                      : ""}
                </div>

                
                <div className="top-content-adm-right">
               
                    <button className="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    <img className="seta" src="/icone_usuarioseta.png" alt="MENU" /><spam className="top-content-adm">Opções</spam>
                    </button>
                    <div className="collapse" id="collapseExample">
                      <div className="card card-body">
                      
                      <div className="top-content-adm-left"> <a href="https://cor.rio/paineldocomando"><input type="button" className="btn-info btn-block" value="Sala de Situação" /></a> </div>
                      <div className="top-content-adm-right"> <button onClick={() => getEventosAbertos()} type="button" className="btn-info btn-block">ATUALIZAR</button></div>   
                      <div className="top-content-adm-right"> <button onClick={() => setSearch("TODOS")} type="button" className="btn-primary btn-block">TODOS</button></div>  
                      <div className="top-content-adm-right"> <button onClick={() => setSearch("PRIMARIO")} type="button" className="btn-primary btn-block">PRIMÁRIO</button></div>  
                      <div className="top-content-adm-right"> <button onClick={() => setSearch("SECUNDARIO")} type="button" className="btn-primary btn-block">SECUNDÁRIO</button></div>  

                      </div>
                    </div>

                </div>
                

                      

                </div>
                </div>
                
                </div>
                    {/*  Content Row */}

                

                <div className="table-responsive">
                    <div className="row" style={{ background: '#ECEDF0'}}>


                

                          {eventosAbertos?.length === 0 ? 

                          
                          <div><p>Consultando dados do Escritório de Dados...</p><img src="/loading.gif" alt="carregando" className="logo" /></div>
                          
                              :   
                                  <>

                                          {/*  Coluna */}
                                          <div className="col-xl-3 col-lg-7">
                                              
                                              <div className="card shadow mb-4">
                                                  <div className="card-header py-3">
                                                      <h6 className="m-0 font-weight-bold text-primary">{filtroaplicado.length} Abertas ({search}) </h6>
                                                  </div>
                                                  <div className="card-body">
                                                      <Chart
                                                          chartType="Bar"
                                                          width="100%"
                                                          height="400px"
                                                          data={chartData}
                                                          options={options}

                                                      />

                                                  </div>
                                              </div>
                                          </div>
                                          {/*  Fim Coluna */}
                  
                                          {/*  POP */}
                                          <div className="col-xl-4 col-lg-7">

                                              <div className="card shadow mb-4">
                                                  <div className="card-header py-3">
                                                      <h6 className="m-0 font-weight-bold text-primary">Abertas por POP ({search}) </h6>
                                                  </div>
                                                  <div className="card-body">
                                                      <Chart
                                                          chartType="BarChart"
                                                          width="100%"
                                                          height="400px"
                                                          data={chartDataPop}
                                                          options={optionsPop}



                                                      />

                                                  </div>
                                              </div>
                                          </div>
                                          {/*  Fim POP */}

                                          {/*  Mapa */}
                                          <div className="col-xl-5 col-lg-7">

                                                          <div className="card shadow mb-4">
                                                                        <div className="card-header py-3">
                                                                            <h6 className="m-0 font-weight-bold text-primary">Geolocalização ({search}) </h6>
                                                                        </div>
                                                                                <div className="card-body">
                                                                                            <div className="leaflet-container">
                                                                                            <MapContainer center={[-22.932611, -43.42839]} zoom={10}>

                                                                                                <LayersControl collapsed={true} position="topright">

                                                                                                    <TileLayer
                                                                                                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                                                                        url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                                                                                                    />
                                                                                                    <ScaleControl>
                                                                                                    </ScaleControl>



                                                                                                    {filtroaplicado.map(evento => (
                                                                                                        <Marker key={evento.id}
                                                                                                            position={[evento.latitude, evento.longitude]}
                                                                                                            icon={icone(pops(evento.pop_id))}

                                                                                                        >

                                                                                                            <Tooltip><ul><strong>{evento.titulo} - {evento.bairro}</strong>
                                                                                                                <li>Descrição: {evento.descricao}</li>

                                                                                                            </ul></Tooltip>

                                                                                                            <Popup>

                                                                                                                <li className="list-head-content">Id: {evento.id} | {evento.titulo}</li>
                                                                                                                <li className="list-head-content">Descrição: {evento.descricao}</li>
                                                                                                                <li className="list-head-content">Bairro: <strong>{evento.bairro}</strong> Zona:<strong> {zonas(evento.bairro)}</strong></li>
                                                                                                                <li className="list-head-content">Início: {moment(evento.inicio).format('DD/MM/YYYY HH:mm')} Gravidade: {evento.gravidade}</li>
                                                                                                                <li className="list-head-content">Pop: {pops(evento.pop_id)} - {evento.pop_id}</li>
                                                                                                                <li className="list-head-content">Prazo: {evento.prazo} Status: {evento.status} tipo: {evento.tipo}</li>
                                                                                                                <div>

                                                                                                                    <img src={`http://187.111.99.18:9004/?CODE=${ObterCamera(evento.titulo)}`} alt=" Sem Câmera Atribuída ao Evento." width="100%" height="100%"></img>
                                                                                                                </div>




                                                                                                            </Popup>
                                                                                                        </Marker>
                                                                                                    ))}

                                                                                                    <>

                                                                                                        

                                                                                                    </>

                                                                                                </LayersControl>
                                                                                            </MapContainer>
                                                                                            </div>
                                                                                </div>
                                                          </div>
                                          </div>
                                          {/*  Fim Mapa */}


                                  </>              
                            }

                    </div> 
                
                
                </div>
                    
                <div className="row" style={{ background: '#ECEDF0', height: "260px"}}>


                           {/*  Coluna */}
                           <div className="col-xl-3 col-lg-7"  style={{height: "180px"}}>
                                              
                                              <div className="card shadow mb-4">
                                                  <div className="card-header py-3">
                                                      <h6 className="m-0 font-weight-bold text-primary">{eventosAbertos.length} POR REGIÃO (PRIMÁRIO E SECUNDÁRIO) </h6>
                                                  </div>
                                                  <div className="card-body"   style={{height: "180px"}}>
                                                    <Chart
                                                      chartType="Table"
                                                      width="100%"
                                                      height="400px"
                                                      data={chartDataTable}
                                                      options={tableOptions}
                                                      formatters={formatters}
                                                    />

                                                  </div>
                                              </div>
                                          </div>
                                          {/*  Fim Coluna */}


        </div>

                    <div className="row" style={{ background: '#ECEDF0'}}>



                    <div className="col-xl-6 col-lg-7">

                              <div className="card shadow mb-4">
                                        <div className="card-header py-3">
                                            <h6 className="m-0 font-weight-bold text-primary">{primario.length} - PRIMÁRIAS</h6>

                                            <h4 className="small font-weight-bold"><span className="float-right">{Math.round(primario.length / (primario.length + secundario.length) * 100)}%</span></h4>
                                    <div className="progress mb-4">
                                        <div className="progress-bar bg-primary" role="progressbar" style={{width: `${Math.round(primario.length / (primario.length + secundario.length) * 100)}`+"%"}}
                                            aria-valuenow={Math.round(primario.length / (primario.length + secundario.length) * 100)} aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                        </div>
                                            <div className="card-body">
                                                    <div className="table-responsive">
                                                                          
                                                                          <table className="table table-sm">
                                                                            <thead className="list-head">
                                                                                <tr> 
                                                                                <th></th>
                                                                                    
                                                                                </tr>
                                                                            </thead>

                                                                            <tbody className="list-body">
                                                                                                                      
                                                                                {primario.map(evento => (
                                                                                    <>
                                                                                    {((evento.status === "ABERTO") && (evento.tipo === "PRIMARIO") ) &&
                                                                                    <tr key={evento.id} className={ObterCorLinha(evento.gravidade)}>
                                                                                        <td><p>{evento.titulo}&nbsp;{evento.descricao}&nbsp;<strong>({moment(evento.inicio).startOf('hour').fromNow()})</strong>&nbsp;

                                                                                        <>
                                                                                            <button className="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseExample${evento.id}`} aria-expanded="false" aria-controls={`collapseExample${evento.id}`}>
                                                                                            <i className="fas fa-plus"></i>
                                                                                            </button>
                                                                                            <div className="collapse" id={`collapseExample${evento.id}`}>
                                                                                              <div className="card card-body">

                                                                                                <p>BAIRRO:&nbsp;{evento.bairro}&nbsp;ZONA&nbsp;{zonas(evento.bairro)}</p>
                                                                                                <p>PRAZO:&nbsp;{evento.prazo}&nbsp;Id:&nbsp;{evento.id}</p>
                                                                                                <p>{moment(evento.inicio).format('DD/MM/YYYY HH:mm')}&nbsp;{evento.gravidade}&nbsp;{pops(evento.pop_id)}</p>
                                                                                                <img src={`http://187.111.99.18:9004/?CODE=${ObterCamera(evento.titulo)}`} alt="" width="100%" height="100%" />
                                                                                              </div>
                                                                                            </div>
                                                                                          </>                                            

                                                                                        
                                                                                        </p>

                                                                                                                                      
                                                                                        </td>
                                                                                        
                                                                                    </tr>}
                                                                                    </>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                    </div>
                                            </div>
                              </div>
                    </div>

                    <div className="col-xl-6 col-lg-7">

                              <div className="card shadow mb-4">
                                  <div className="card-header py-3">
                                      <h6 className="m-0 font-weight-bold text-primary">{secundario.length} - SECUNDÁRIAS</h6>

                                      <h4 className="small font-weight-bold"><span className="float-right">{Math.round(secundario.length / (primario.length + secundario.length) * 100)}%</span></h4>
                                    <div className="progress mb-4">
                                        <div className="progress-bar bg-primary" role="progressbar" style={{width: `${Math.round(secundario.length / (primario.length + secundario.length) * 100)}`+"%"}}
                                            aria-valuenow={Math.round(secundario.length / (primario.length + secundario.length) * 100)} aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>


                                  </div>
                                          <div className="card-body">

                                                  <div className="table-responsive">
                                          <table className="table table-sm">
                                            <thead className="list-head">
                                                <tr>
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody className="list-body">
                                                                                      
                                                {secundario.map(evento => (
                                                    <>
                                                    {((evento.status === "ABERTO") && (evento.tipo === "SECUNDARIO") ) &&
                                                    <tr key={evento.id} className={ObterCorLinha(evento.gravidade)}>
                                                  <td><p>{evento.titulo}&nbsp;{evento.descricao}&nbsp;<strong>({moment(evento.inicio).startOf('hour').fromNow()})</strong>&nbsp;
                                                        <>
                                                            <button className="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseExample${evento.id}`} aria-expanded="false" aria-controls={`collapseExample${evento.id}`}>
                                                            <i className="fas fa-plus"></i>
                                                            </button>
                                                            <div className="collapse" id={`collapseExample${evento.id}`}>
                                                              <div className="card card-body">

                                                                <p>BAIRRO:&nbsp;{evento.bairro}&nbsp;ZONA&nbsp;{zonas(evento.bairro)}</p>
                                                                <p>PRAZO:&nbsp;{evento.prazo}&nbsp;Id:&nbsp;{evento.id}</p>
                                                                <p>{moment(evento.inicio).format('DD/MM/YYYY HH:mm')}&nbsp;{evento.gravidade}&nbsp;{pops(evento.pop_id)}</p>
                                                                <img src={`http://187.111.99.18:9004/?CODE=${ObterCamera(evento.titulo)}`} alt="" width="100%" height="100%" />
                                                              </div>
                                                            </div>
                                                          </>                                            

                                                        
                                                        </p>

                                                                                                      
                                                        </td>
                                                    </tr>
                                                    
                                                    
                                                    }
                                                    </>
                                                ))}
                                            </tbody>
                                        </table>
                                                  </div>

                                          </div>
                              </div>
                    </div>



                

                    </div>
            </div>

          <nav className="nav nav-underline text-white fixed-bottom navbar-light shadow-sm" style={{ background: '#004A80' }}>
            &nbsp; &nbsp;
            <a className="navbar-brand" href="/" aria-haspopup="true" aria-expanded="false">
              <h2> <i className="fas fa-home text-white" aria-hidden="true"></i></h2>
            </a>
            &nbsp;

            &nbsp; &nbsp;
            <a className="navbar-brand" href="/login" aria-haspopup="true" aria-expanded="false">
              <h2> <i className="icon fas fa-user text-white" aria-hidden="true"></i></h2>
            </a>
            &nbsp;

            <a className="navbar-brand" href="/ajuda" aria-haspopup="true" aria-expanded="false">

              <h2 className='text-white'>&nbsp;<i className="fas fa-question fa-fw text-white"></i></h2>
            </a>
            <h2 className="d-none d-lg-block">&nbsp; {primario.length + secundario.length} OCORRÊNCIAS ABERTAS, SENDO {primario.length}&nbsp; PRIMÁRIAS ({Math.round(primario.length / (primario.length + secundario.length) * 100)}%) E&nbsp;  {secundario.length} SECUNDÁRIAS ({Math.round(secundario.length / (primario.length + secundario.length) * 100)}%) &nbsp; </h2>
          </nav>

            </div>
            </>
    );
}