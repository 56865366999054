import React, {useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../Context/AuthContext';


import 'moment/locale/pt-br'
import _ from 'lodash';
import axios from 'axios';



const tableOptions = {
  allowHtml: true
};

const formatters = [
  {
      type: "BarFormat",
      column: 1,
      options: {
          width: 120
      },
  },
];


export const options = {
    title: "Total de Ocorrências",
    legend: { position: 'none' },
    colors: ['#ce6b01'],
   
    backgroundColor: '#FFF',
    chartArea: { width: "100%", height: "100%" },
    
    hAxis: {
//      title: "Total de Ocorrências Abertas",
      minValue: 0,
      format: 0
      
    },
    vAxis: {
      //title: "Ocorrências por POP",
      scaleType: 'log'
    },
  };


                                             

export const CetRio = () => {
  const moment = require('moment');
  
  const [eventosAbertos, setEventosAbertos] = useState([]);
  const [chartData, setChartData] = useState([]);

  const [primario, setPrimario] = useState([]);
  const [secundario, setSecundario] = useState([]);
  const [countdown, setCountdown] = useState(300000)
  const [filtroaplicado, setFiltroAplicado] = useState([]);
  const [search, setSearch] = useState("TODOS");

  const [image] = useState(localStorage.getItem('image'));
  const [name] = useState(localStorage.getItem('name'));

  const { handleLogout } = useContext(Context);

  const dropdownUserNavbar = async () => {
      document.getElementById("dropNavbarUser").classList.toggle("dropdown-menu-action");
  }

  const barsSidebar = async () => {
      document.getElementById("barsSidebar").classList.toggle("sidebar-active");
  }


  
  


    return (
        <>
            <nav className="navbar">
            <div className="navbar-content">
                
                <img src="/cetrio.png" alt="CETRIO" className="logo" />
            </div>
            <div className="navbar-content">

                <div className="avatar">
                    <span onClick={() => dropdownUserNavbar()} className="drop-nav-bar-user">


                    



                    </span>
                    <div id="dropNavbarUser" className="dropdown-menu setting">
                    <div className="item">
                            <Link to="/login">
                                <span className="fas fa-user"></span> Login
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="/view-profile">
                                <span className="fas fa-user"></span> Perfil
                            </Link>
                        </div>
                        <div className="item" onClick={handleLogout}>
                            <span className="fas fa-sign-out-alt"></span> Sair
                        </div>
                        
                    </div>
                </div>
            </div>
        </nav>



            
            <div className="content">

                

                <div className="wrapper">


                <div className="table-responsive">
                    <div className="row" style={{ background: '#ECEDF0'}}>


                


                          

                                          {/*  Coluna */}
                                          <div className="col-xl-6 col-lg-7">
                                              
                                              <div className="card shadow mb-4">
                                                  <div className="card-header py-3">
                                                      <h6 className="m-0 font-weight-bold text-primary">Agenda do dia 22/11/2023 Quarta-feira </h6>
                                                  </div>
                                                  <div className="card-body">

                                                  <div className="table-responsive">

                                                  <table class="table">
                                                          <thead>
                                                            <tr>
                                                              <th scope="col">Horário</th>
                                                              <th scope="col">Nome</th>
                                                              <th scope="col">Descrição</th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr>
                                                              <th scope="row">14:00 às 15:00</th>
                                                              <td>VIOLÊNCIA URBANA x SEGURANÇA PREVENTIVA: Noções básicas do dia a dia!
                                                              </td>
                                                              <td>Com a Polícia Militar do Estado do Rio de Janeiro</td>
                                                            </tr>
                                                            
                                                            <tr>
                                                              <th scope="row">15:00 às 16:00</th>
                                                              <td>VIOLÊNCIA URBANA x SEGURANÇA PREVENTIVA: Noções básicas do dia a dia!
                                                              </td>
                                                              <td>Com a Polícia Militar do Estado do Rio de Janeiro</td>
                                                            </tr>
                                                            
                                                            <tr>
                                                              <th scope="row">16:00 às 17:30</th>
                                                              <td>VIOLÊNCIA URBANA x SEGURANÇA PREVENTIVA: Noções básicas do dia a dia!
                                                              </td>
                                                              <td>Com a Polícia Militar do Estado do Rio de Janeiro</td>
                                                            </tr>
                                                            
                                                            <tr>
                                                              <th scope="row">17:30 às 18:00</th>
                                                              <td>VIOLÊNCIA URBANA x SEGURANÇA PREVENTIVA: Noções básicas do dia a dia!
                                                              </td>
                                                              <td>Com a Polícia Militar do Estado do Rio de Janeiro</td>
                                                            </tr>
                                                            

                                                          </tbody>
                                                        </table>
                                                                
                                                    </div>
                                                      

                                                  </div>
                                              </div>
                                          </div>

                                          <div className="col-xl-6 col-lg-7">

                                                          <div className="card shadow mb-4">
                                                                        <div className="card-header py-3">
                                                                            <h6 className="m-0 font-weight-bold text-primary">Divulgação </h6>
                                                                        </div>
                                                                                <div className="card-body">

                                                                                <img src="/convite.jpeg" alt="Convite" className="banner" />
                                                                                            
                                                                                </div>
                                                          </div>
                                          </div>
                                          {/*  Fim Mapa */}



                    </div> 
                
                
                </div>
                    
        

                 
            </div>

          <nav className="nav nav-underline text-white fixed-bottom navbar-light shadow-sm" style={{ background: '#004A80' }}>
            
            <h2 className="d-none d-lg-block"><marquee>22/11/23 Quarta-feira VIOLÊNCIA URBANA x SEGURANÇA PREVENTIVA: Noções básicas do dia a dia!</marquee></h2>
          </nav>

            </div>
            </>
    );
}