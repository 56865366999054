import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Context } from '../Context/AuthContext';

import { Home } from '../pages/Home';
import { CetRio } from '../pages/CetRio';

import { Saladesituacao } from '../pages/Saladesituacao';
import { Portfolio } from '../pages/Portfolio';
import { Operacoesrio } from '../pages/Portfolio/operacoesrio';
import { RioAguas } from '../pages/Portfolio/rioaguas';
import { Eventos } from '../pages/Portfolio/eventos';

import { Login } from '../pages/Login';
import { AddUserLogin } from '../pages/AddUserLogin';
import { RecoverPassword } from '../pages/RecoverPassword';
import { UpdatePassword } from '../pages/UpdatePassword';

import { Mapa } from '../pages/Mapa';
import { Dashboard } from '../pages/Dashboard';
import { Ajuda } from '../pages/Ajuda';
import { Users } from '../pages/Users';
import { AddUser } from '../pages/AddUser';
import { ViewUser } from '../pages/ViewUser';
import { EditUser } from '../pages/EditUser';

import { RelatorioOperacional } from '../pages/RelatorioOperacional';
import { Agenda } from '../pages/Agenda';


import { EditUserPassword } from '../pages/EditUserPassword';
import { ViewProfile } from '../pages/ViewProfile';
import { EditProfile } from '../pages/EditProfile';
import { EditProfilePassword } from '../pages/EditProfilePassword';
import { EditProfileImage } from '../pages/EditProfileImage';
import { EditUserImage } from '../pages/EditUserImage';


function CustomRoute({ isPrivate, ...rest }) {
    const { authenticated } = useContext(Context);

    if (isPrivate && !authenticated) {
        return <Redirect to="/" />
    }


    return <Route {...rest} />
}

export default function RoutesAdm() {
    return (
        <Switch>



            <CustomRoute exact path="/" component={Home} />
            <CustomRoute exact path="/cetrio" component={CetRio} />
            <CustomRoute exact path="/sala" component={Saladesituacao} />
            <CustomRoute exact path="/login" component={Login} />
            <CustomRoute exact path="/ajuda" component={Ajuda} />
            <CustomRoute exact path="/add-user-login" component={AddUserLogin} />
            <CustomRoute exact path="/recover-password" component={RecoverPassword} />
            <CustomRoute exact path="/update-password/:key" component={UpdatePassword} />

            <CustomRoute exact isPrivate path="/mapa" component={Mapa} />
            <CustomRoute exact isPrivate path="/dashboard" component={Dashboard} />
            <CustomRoute exact isPrivate path="/portfolio" component={Portfolio} />
            <CustomRoute exact isPrivate path="/operacoesrio" component={Operacoesrio} />
            <CustomRoute exact isPrivate path="/rioaguas" component={RioAguas} />
            <CustomRoute exact isPrivate path="/eventos" component={Eventos} />

            <CustomRoute exact isPrivate path="/relatoriooperacional" component={RelatorioOperacional} />
            <CustomRoute exact isPrivate path="/agenda" component={Agenda} />

            <CustomRoute exact isPrivate path="/users" component={Users} />
            <CustomRoute exact isPrivate path="/add-user" component={AddUser} />
            <CustomRoute exact isPrivate path="/view-user/:id" component={ViewUser} />
            <CustomRoute exact isPrivate path="/edit-user/:id" component={EditUser} />



            <CustomRoute exact isPrivate path="/edit-user-password/:id" component={EditUserPassword} />
            <CustomRoute exact isPrivate path="/view-profile" component={ViewProfile} />
            <CustomRoute exact isPrivate path="/edit-profile" component={EditProfile} />
            <CustomRoute exact isPrivate path="/edit-profile-password" component={EditProfilePassword} />
            <CustomRoute exact isPrivate path="/edit-profile-image" component={EditProfileImage} />
            <CustomRoute exact isPrivate path="/edit-user-image/:id" component={EditUserImage} />

        </Switch>
    );
};