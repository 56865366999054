import React, { useContext } from 'react';
import { Context } from '../../Context/AuthContext';
import { Link } from 'react-router-dom';


export const SidebarPublica = (props) => {


    return (
        <div id="barsSidebar" className="sidebar">

              <Link to="/" className={props.active === "home" ? "sidebar-nav active" : "sidebar-nav"} ><i className="icon fas fa-home"></i><span> Home</span></Link>
              <Link to="/ajuda" className={props.active === "ajuda" ? "sidebar-nav active" : "sidebar-nav"} ><i className="icon fas fa-question"></i><span> Ajuda</span></Link>
              <Link to="/login" className={props.active === "login" ? "sidebar-nav active" : "sidebar-nav"} ><i className="icon fas fa-user"></i><span> Login</span></Link>

        </div>
    )
}